import React from 'react';
import strings from '../../lang/strings.js';
import { PageSettings } from "../../config/page-settings";
import './../../config/util';
import { Bar } from 'react-chartjs-2';
import moment from 'moment';

class IapChart extends React.Component {
	static contextType = PageSettings;

	constructor(props) {
		super(props);

		this.state = {
			date_list: [],
			value_list: [],
			count_list: [],
		};
	}

	componentDidMount() {
		this.getIapChart();
	}

	getIapChart = () => {
		let self = this;

		this.context.get(
			'dashboard/iap_chart',
			{},
			function (response) {
				let iap_chart = response.result_data.iap_chart;

				const date_list = [];
				const value_list = [];
				const count_list = [];

				iap_chart.forEach((entry) => {
					date_list.push(moment(entry.date).format('M/D'));
					value_list.push(entry.total_value);
					count_list.push(entry.total_count);
				})

				self.setState({
					date_list: date_list,
					value_list: value_list,
					count_list: count_list,
				});
			});
	};

	render() {
		this.barChart = {
			data: {
				labels: this.state.date_list,
				datasets: [{
					type: 'line',
					label: strings.iap_chart_money,
					fill: false,
					borderColor: '#EC932F',
					backgroundColor: '#EC932F',
					pointBorderColor: '#EC932F',
					pointBackgroundColor: '#EC932F',
					pointHoverBackgroundColor: '#EC932F',
					pointHoverBorderColor: '#EC932F',
					data: this.state.value_list,
					yAxisID: 'y-axis-1'
				}, {
					type: 'bar',
					label: strings.iap_chart_count,
					fill: false,
					backgroundColor: '#71B37C',
					borderColor: '#71B37C',
					hoverBackgroundColor: '#71B37C',
					hoverBorderColor: '#71B37C',
					data: this.state.count_list,
					yAxisID: 'y-axis-2'
				}]
			},
			options: {
				responsive: true,
				maintainAspectRatio: false,
				hover: {
					mode: 'nearest',
					intersect: true
				},
				tooltips: {
					mode: 'index',
					intersect: false,
				},
				scales: {
					yAxes: [{
						type: 'linear',
						display: true,
						position: 'left',
						id: 'y-axis-1',
						gridLines: {
							display: true
						},
						labels: {
							show: true
						},
						ticks: {
							stepSize: 50000,
							unitStepSize: 50000
						},
					}, {
						type: 'linear',
						display: true,
						position: 'right',
						id: 'y-axis-2',
						gridLines: {
							display: true
						},
						labels: {
							show: true
						}
					}],
					xAxes: [{
						display: true,
						ticks: {
							stepSize: 6,
							unitStepSize: 5
						},
						autoSkip: true,
						autoSkipPadding: 30,
					}],
				},
			}
		};

		return (
			<div>
				<Bar data={this.barChart.data} options={this.barChart.options} />
			</div>
		)
	}
}

export default IapChart;