import React from 'react';
import strings from '../../lang/strings.js';
import { PageSettings } from "../../config/page-settings";
import './../../config/util';

class TermFragment extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
            content: '',
            link: '',
        };
    }

    componentDidMount() {
        this.getTermInfo();
    }

    getTermInfo = () => {
        let self = this;
        self.context.get(
            'support/term',
            {
                type: self.props.type,
            },
            function (response) {
                self.setState({
                    content: response.result_data.content,
                    link: response.result_data.url
                })
            });
    };

    saveTerm = () => {
        let self = this;

        self.context.showConfirm(strings.confirm_save_title, (response) => {
            if (response) {
                self.context.post(
                    'support/save_term',
                    {
                        type: self.props.type,
                        content: self.state.content
                    },
                    (response) => {
                        self.context.addNotification('success', strings.alert_title, strings.save_done);
                        self.setState({
                            link: response.result_data.url
                        })
                    }
                );
            }
        });
    };

    render() {
        let self = this;

        return (
            <div>
                <div className='row'>
                    <div className='col-md-12'>
                        <table className="table table-bordered">
                            <thead>
                                <tr className="">
                                    <th className="text-center" style={{ width: '50%' }}>{strings.preview}</th>
                                    <th className="text-center">
                                        <button type="button" className="btn btn-success btn-sm"
                                            onClick={() => self.saveTerm()}>{strings.save}</button>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div style={{ width: '100%', height: '600px' }}>
                                            <iframe src={this.state.link} title='preview' width="100%" height="600" />
                                        </div>
                                    </td>
                                    <td>
                                        <form id="frmTerm" method="post">
                                            <textarea className="form-control bg-light" style={{ height: '600px' }}
                                                onChange={(e) => this.setState({ content: e.target.value })}
                                                value={this.state.content}
                                                name="content" />
                                        </form>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }
}

export default TermFragment;