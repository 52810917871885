import React from 'react';
import { withRouter } from 'react-router-dom';
import { inject } from 'mobx-react';
import strings from '../../lang/strings.js';
import $ from 'jquery';
import { PageSettings } from "../../config/page-settings";
import { Panel, PanelBody, PanelHeader } from '../../components/panel/panel.jsx';
import DataTable from './../../components/content/data-table.jsx';
import SearchInput from './../../components/content/search-input.js';
import SearchSelect from './../../components/content/search-select.js';
import './../../config/util';
import Const from './../../config/const';
import MemberDetailPopup from './../member-detail/member-detail-popup'
const queryString = require('query-string');

class TodayCardList extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        let searchQS = queryString.parse(this.props.location.search);

        this.state = {
            search_info: searchQS
        };

        this.refreshTable = this.refreshTable.bind(this);
        this.showMemberDetailPopup = this.showMemberDetailPopup.bind(this);
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.location.search != this.props.location.search) {
            let searchQS = queryString.parse(this.props.location.search);
            this.setState({ search_info: searchQS }, () => this.refreshTable());
        }
    }

    refreshTable = (refresh) => {
        this.tblMember.draw(refresh);
    };

    searchTable = () => {
        let searchQS = queryString.parse(this.props.location.search);
        Object.assign(searchQS, this.state.search_info)
        const new_query = queryString.stringify(searchQS);
        if (new_query == this.props.location.search.substr(1)) {
            this.refreshTable();
        } else {
            this.props.history.push('?' + new_query);
        }
    }

    showMemberDetailPopup(uid) {
        this.setState({
            show_member_profile: true,
            selected_uid: uid
        });
    }

    render() {
        let self = this;
        let columnNo = 0;
        const { search_info } = this.state;

        return (
            <div>
                <Panel>
                    <PanelHeader noButton={true}>{strings.menu.card_today}</PanelHeader>
                    <PanelBody>
                        <MemberDetailPopup isOpen={this.state.show_member_profile} uid={this.state.selected_uid} onClose={() => {
                            this.setState({ show_member_profile: false });
                        }} />

                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='form-horizontal form-inline'>
                                    {/* 키워드검색 */}
                                    <SearchSelect
                                        data={strings.const.SEARCH_CATEGORY2}
                                        value={search_info.column || strings.const.SEARCH_CATEGORY2[0].code}
                                        onChange={(value) => {
                                            this.setState({
                                                search_info: {
                                                    ...search_info,
                                                    column: value,
                                                    start: 0,
                                                }
                                            }, this.searchTable);
                                        }}
                                    />
                                    <SearchInput
                                        value={search_info.keyword}
                                        onChange={(value) => {
                                            this.setState({
                                                search_info: {
                                                    ...search_info,
                                                    keyword: value,
                                                    column: search_info.column || strings.const.SEARCH_CATEGORY2[0].code,
                                                    start: 0,
                                                }
                                            })
                                        }}
                                        handler={(value) => this.searchTable()}
                                    />

                                    <button type='button' className='btn btn-success btn-sm ml-md-2' onClick={() => this.searchTable()}>{strings.search}</button>
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-md-12 mt-3'>
                                <DataTable ref={(ref) => this.tblMember = ref}
                                    customPaginate={true}
                                    initialPage={search_info.start || 0}
                                    onPageChange={(value) => {
                                        this.setState({
                                            search_info: {
                                                ...search_info,
                                                start: value,
                                            }
                                        }, this.searchTable);
                                    }}
                                    options={{
                                        columnDefs: [
                                            {
                                                targets: [columnNo++], data: 'id', orderable: false,
                                                render: function (value) {
                                                    return value;
                                                }
                                            },
                                            {
                                                targets: [columnNo++], data: 'uid', orderable: false,
                                                render: function (value) {
                                                    return value;
                                                }
                                            },
                                            {
                                                targets: [columnNo++], data: 'user_id', orderable: false,
                                                render: function (value) {
                                                    return value;
                                                }
                                            },
                                            {
                                                targets: [columnNo++], data: 'nickname', orderable: false,
                                                createdCell: function (td, value, item) {
                                                    return $(td).nickname_label(value, item.gender, () => {
                                                        self.showMemberDetailPopup(item.uid);
                                                    });
                                                }
                                            },
                                            {
                                                targets: [columnNo++], data: 'birthday', orderable: false,
                                                render: function (value) {
                                                    return value;
                                                }
                                            },
                                            {
                                                targets: [columnNo++], data: null, orderable: false,
                                                createdCell: function (td, value, item) {
                                                    return $(td).html(item.school ? item.school : item.job);
                                                }
                                            },
                                            {
                                                targets: [columnNo++], data: 'target_uid', orderable: false,
                                                render: function (value) {
                                                    return value;
                                                }
                                            },
                                            {
                                                targets: [columnNo++], data: 'target_user_id', orderable: false,
                                                render: function (value) {
                                                    return value;
                                                }
                                            },
                                            {
                                                targets: [columnNo++], data: 'target_nickname', orderable: false,
                                                createdCell: function (td, value, item) {
                                                    return $(td).nickname_label(value, item.target_gender, () => {
                                                        self.showMemberDetailPopup(item.target_uid);
                                                    });
                                                }
                                            },
                                            {
                                                targets: [columnNo++], data: 'target_birthday', orderable: false,
                                                render: function (value) {
                                                    return value;
                                                }
                                            },
                                            {
                                                targets: [columnNo++], data: null, orderable: false,
                                                createdCell: function (td, value, item) {
                                                    return $(td).html(item.target_school ? item.target_school : item.target_job);
                                                }
                                            },
                                            {
                                                targets: [columnNo++], data: 'type', orderable: false,
                                                render: function (value) {
                                                    return strings.const.CARD_TYPE[value];
                                                }
                                            },
                                            {
                                                targets: [columnNo++], data: 'ok', orderable: false,
                                                createdCell: function (td, value, item) {
                                                    if (value > 0) {
                                                        $(td).html(`<span class="${strings.const.OK_TYPE[value].color}">${strings.const.OK_TYPE[value].name}</span>`);
                                                    } else {
                                                        $(td).html('')
                                                    }
                                                }
                                            },
                                            {
                                                targets: [columnNo++], data: 'create_date', orderable: false,
                                                render: function (value) {
                                                    return value;
                                                }
                                            },
                                        ],
                                        iDisplayLength: 15,
                                        order: [[1, 'DESC']],
                                        ajax: {
                                            url: `card/getCardList`,
                                            data: function (req) {
                                                req.columns = null;

                                                const searchQS = queryString.parse(self.props.location.search);
                                                req.start = searchQS.start || 0;
                                                req.length = searchQS.length || 20;
                                                req.column = searchQS.column;
                                                req.keyword = searchQS.keyword;
                                            }
                                        },
                                        drawCallback: function () {
                                        },
                                        fnCreatedRow: function (row, data, dataInd) {
                                        }
                                    }}>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>UID</th>
                                            <th>{strings.user_id}</th>
                                            <th>{strings.nickname}</th>
                                            <th>{strings.birthday}</th>
                                            <th>{strings.school_job}</th>
                                            <th>{strings.target_user} UID</th>
                                            <th>{strings.target_user} {strings.user_id}</th>
                                            <th>{strings.target_user} {strings.nickname}</th>
                                            <th>{strings.target_user} {strings.birthday}</th>
                                            <th>{strings.target_user} {strings.school_job}</th>
                                            <th>{strings.matching_type}</th>
                                            <th>OK/TOPOK</th>
                                            <th>{strings.dist_time}</th>
                                        </tr>
                                    </thead>
                                    <tbody />
                                </DataTable>
                            </div>
                        </div>
                    </PanelBody>
                </Panel>
            </div>
        )
    }
}

export default withRouter(inject('rootStore')(TodayCardList));