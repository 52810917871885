import React from 'react';
import strings from '../../lang/strings.js';
import { PageSettings } from "../../config/page-settings.js";
import { withRouter } from 'react-router-dom';
import { inject } from 'mobx-react';
import { Panel, PanelBody, PanelHeader } from '../../components/panel/panel.jsx';
import DataTable from '../../components/content/data-table.jsx';
import SearchInput from '../../components/content/search-input.js';
import SearchSelect from '../../components/content/search-select.js';
import $ from 'jquery';
import '../../config/util.js';
import Const from '../../config/const.js';
import WeddingDressEditPopup from './dress-edit-popup.js';
const queryString = require('query-string');

class WeddingDress extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        let searchQS = queryString.parse(this.props.location.search);

        this.state = {
            search_info: searchQS,
            show_edit_popup: false,
            dress_data: null,
        };

        this.refreshTable = this.refreshTable.bind(this);
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.location.search != this.props.location.search) {
            let searchQS = queryString.parse(this.props.location.search);
            this.setState({ search_info: searchQS }, () => this.refreshTable());
        }
    }

    refreshTable = (refresh) => {
        this.tblMember.draw(refresh);
    };

    searchTable = () => {
        let searchQS = queryString.parse(this.props.location.search);
        Object.assign(searchQS, this.state.search_info)
        const new_query = queryString.stringify(searchQS);
        if (new_query == this.props.location.search.substr(1)) {
            this.refreshTable();
        } else {
            this.props.history.push('?' + new_query);
        }
    }

    showDetailPopup(data) {
        this.setState({
            show_edit_popup: true,
            dress_data: data
        });
    }

    deleteWeddingDress(id) {
        let self = this;
        this.context.showConfirm(strings.confirm_delete_title, function (response) {
            if (response) {
                self.context.post('wedding/deleteDressItem', { id }, function () {
                    self.context.addNotification('success', strings.alert_title, strings.confirm_delete_done);
                    self.refreshTable(false);
                }, true);
            }
        });
    }

    render() {
        let self = this;
        let columnNo = 0;
        const { search_info } = this.state;

        return (
            <div>
                <Panel>
                    <PanelHeader noButton={true}>웨딩드레스 목록</PanelHeader>
                    <PanelBody>
                        <WeddingDressEditPopup
                            isOpen={this.state.show_edit_popup}
                            data={this.state.dress_data}
                            category_list={this.state.category_list}
                            company_list={this.state.company_list}
                            onClose={() => {
                                this.setState({ show_edit_popup: false });
                            }}
                            onSave={() => {
                                this.setState({ show_edit_popup: false });
                                this.refreshTable(false);
                            }}
                        />
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='form-horizontal form-inline'>
                                    {/* 키워드검색 */}
                                    <SearchSelect
                                        data={strings.const.SEARCH_PRODUCT}
                                        value={search_info.column || strings.const.SEARCH_PRODUCT[0].code}
                                        onChange={(value) => {
                                            this.setState({
                                                search_info: {
                                                    ...search_info,
                                                    column: value,
                                                    start: 0,
                                                }
                                            }, this.searchTable);
                                        }}
                                    />
                                    <SearchInput
                                        value={search_info.keyword}
                                        onChange={(value) => {
                                            this.setState({
                                                search_info: {
                                                    ...search_info,
                                                    keyword: value,
                                                    column: search_info.column || strings.const.SEARCH_PRODUCT[0].code,
                                                    start: 0,
                                                }
                                            })
                                        }}
                                        handler={(value) => this.searchTable()}
                                    />

                                    <button type='button' className='btn btn-success btn-sm ml-md-2' onClick={() => this.searchTable()}>{strings.search}</button>

                                    <button type='button' className='btn btn-danger btn-sm ml-md-2' onClick={() => {
                                        self.showDetailPopup(null);
                                    }}>{strings.add}</button>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-12 mt-3'>
                                <DataTable ref={(ref) => this.tblMember = ref}
                                    customPaginate={true}
                                    initialPage={search_info.start || 0}
                                    onPageChange={(value) => {
                                        this.setState({
                                            search_info: {
                                                ...search_info,
                                                start: value,
                                            }
                                        }, this.searchTable);
                                    }}
                                    options={{
                                        columnDefs: [
                                            {
                                                targets: [columnNo++], data: 'id', orderable: false,
                                                render: function (value) {
                                                    return value;
                                                }
                                            },
                                            {
                                                targets: [columnNo++], data: 'main_image', orderable: false,
                                                createdCell: function (td, value, item) {
                                                    return $(td).image_lightbox(value, () => {
                                                        self.context.showGallery([value].concat(item.image_list.split('#')).map((it) => { return Const.BASE_URL + it }))
                                                    });
                                                }
                                            },
                                            {
                                                targets: [columnNo++], data: 'title', orderable: false,
                                                render: function (value) {
                                                    return value;
                                                }
                                            },
                                            {
                                                targets: [columnNo++], data: 'price', orderable: false,
                                                render: function (value) {
                                                    return value;
                                                }
                                            },
                                            {
                                                targets: [columnNo++], data: 'create_date', orderable: false,
                                                render: function (value) {
                                                    return value;
                                                }
                                            },
                                            {
                                                targets: [columnNo++], data: 'id', orderable: false,
                                                createdCell: function (td, value, item) {
                                                    $(td).html('');
                                                    $(td).append(`<button type='button' class='btn btn-success btn-sm m-2'>${strings.detail}</button>`)
                                                        .find('button')
                                                        .on('click', function () {
                                                            self.showDetailPopup(item);
                                                        });
                                                    $(td).append(`<button type='button' class='btn btn-danger btn-sm m-2'>${strings.delete}</button>`)
                                                        .find('button:last')
                                                        .on('click', function () {
                                                            self.deleteWeddingDress(item.id);
                                                        });
                                                }
                                            }
                                        ],
                                        order: [[1, 'DESC']],
                                        ajax: {
                                            url: 'wedding/getDressList',
                                            data: function (req) {
                                                req.columns = null;

                                                const searchQS = queryString.parse(self.props.location.search);
                                                req.start = searchQS.start || 0;
                                                req.length = searchQS.length || 20;
                                                req.column = searchQS.column;
                                                req.keyword = searchQS.keyword;
                                            }
                                        },
                                        drawCallback: function () {
                                        },
                                        fnCreatedRow: function (row, data, dataInd) {
                                        }
                                    }}>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>{strings.main_image}</th>
                                            <th>{strings.title}</th>
                                            <th>{'가격'}</th>
                                            <th>{strings.create_date}</th>
                                            <th>{strings.manage}</th>
                                        </tr>
                                    </thead>
                                    <tbody />
                                </DataTable>
                            </div>
                        </div>
                    </PanelBody>
                </Panel>
            </div>
        )
    }
}

export default withRouter(inject('rootStore')(WeddingDress));