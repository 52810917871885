import React from 'react';
import strings from '../../lang/strings.js';
import { PageSettings } from "../../config/page-settings";
import { Panel, PanelBody } from '../../components/panel/panel.jsx';
import { Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import '../../config/util';
import { withRouter } from 'react-router-dom';
import { inject } from 'mobx-react';
import BandListFragment from './band-list-fragment'
const queryString = require('query-string');

class BandList extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);
        this.state = {
        };

        this.toggle = this.toggle.bind(this);
    }

    componentDidMount() {
    }

    toggle(tab) {
        this.props.history.push('?status=' + tab);
    }

    render() {
        const searchQS = queryString.parse(this.props.location.search);
        const tab = searchQS.status || 'normal';

        return (
            <div>
                <div className="row">
                    <div className="col-xs-12">
                        <Panel>
                            <Nav tabs className="nav-tabs-inverse">
                                <NavItem>
                                    <NavLink className={classnames({ active: tab === 'normal' })} onClick={() => { this.toggle('normal'); }}>
                                        <span className="d-none d-xs-inline">{strings.band_list}</span>
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <PanelBody>
                                {tab === "normal" && (<BandListFragment status={tab} />)}
                            </PanelBody>
                        </Panel>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(inject('rootStore')(BandList));