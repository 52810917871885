import React from 'react';
import { withRouter } from 'react-router-dom';
import { inject } from 'mobx-react';
import strings from '../../lang/strings.js';
import { PageSettings } from "../../config/page-settings";
import { Panel, PanelBody } from '../../components/panel/panel.jsx';
import { Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import Container from './../../components/content/container.js';
import './../../config/util';
import MemberDetailPopup from './../member-detail/member-detail-popup'
import IapLogFragment from './iap-log-fragment'
import IapChart from './iap-chart'
import IapSta from './iap-sta'
const queryString = require('query-string');

class IapLog extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);
        this.state = {
            activeTab: '1'
        };

        this.toggle = this.toggle.bind(this);
        this.showMemberDetailPopup = this.showMemberDetailPopup.bind(this);
    }

    componentDidMount() {
    }

    toggle(tab) {
        this.props.history.push('?status=' + tab);
    }

    showMemberDetailPopup(uid) {
        this.setState({
            show_member_profile: true,
            selected_uid: uid
        });
    }

    render() {
        const searchQS = queryString.parse(this.props.location.search);
        const tab = searchQS.status || -1;

        return (
            <div>
                <div className="row">
                    <div className="col-xl-4 col-md-5">
                        <IapSta />
                    </div>
                    <div className="col-xl-8 col-md-7">
                        <Container title={strings.iap_chart_title}>
                            <div style={{ height: "150px" }}>
                                <IapChart />
                            </div>
                        </Container>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12">
                        <Panel>
                            <Nav tabs className="nav-tabs-inverse">
                                <NavItem>
                                    <NavLink className={classnames({ active: tab === -1 })} onClick={() => { this.toggle(-1); }}>
                                        <span className="d-none d-xs-inline">{strings.iap_total_log}</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ active: tab == 0 })} onClick={() => { this.toggle(0); }}>
                                        <span className="d-none d-xs-inline">{strings.iap_normal_log}</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ active: tab == 1 })} onClick={() => { this.toggle(1); }}>
                                        <span className="d-none d-xs-inline">{strings.iap_refund_log}</span>
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <PanelBody>
                                <MemberDetailPopup isOpen={this.state.show_member_profile} uid={this.state.selected_uid} onClose={() => {
                                    this.setState({ show_member_profile: false });
                                }} />

                                {tab == -1 && (<IapLogFragment onClickRow={this.showMemberDetailPopup} />)}
                                {tab == 0 && (<IapLogFragment status={0} onClickRow={this.showMemberDetailPopup} />)}
                                {tab == 1 && (<IapLogFragment status={1} onClickRow={this.showMemberDetailPopup} />)}
                            </PanelBody>
                        </Panel>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(inject('rootStore')(IapLog));