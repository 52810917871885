import React from 'react';
import { withRouter } from 'react-router-dom';
import { PageSettings } from './config/page-settings.js';
import axios from 'axios';

import Header from './components/header/header.jsx';
import Sidebar from './components/sidebar/sidebar.jsx';
import SidebarRight from './components/sidebar-right/sidebar-right.jsx';
import Content from './components/content/content.jsx';
import Footer from './components/footer/footer.jsx';
import FloatSubMenu from './components/float-sub-menu/float-sub-menu.jsx';
// Notification
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
// Modal, LoadingBar
import ProgressBar from './components/content/progress-bar.jsx';
import CommonModal from './components/content/common-modal.jsx';
// Constant
import Const from './config/const';
import { inject } from "mobx-react";
// LightBox
import Lightbox from 'react-image-lightbox';

class App extends React.Component {
    constructor(props) {
        super(props);

        this.notificationRef = React.createRef();

        this.toggleSidebarMinify = (e) => {
            e.preventDefault();
            if (this.state.pageSidebarMinify) {
                this.setState(state => ({
                    pageFloatSubMenuActive: false
                }));
            }
            this.setState(state => ({
                pageSidebarMinify: !this.state.pageSidebarMinify
            }));
        };
        this.toggleMobileSidebar = (e) => {
            this.setState(state => ({
                pageSidebarToggled: !this.state.pageSidebarToggled
            }));
        };
        this.handleSetPageSidebar = (value) => {
            this.setState(state => ({
                pageSidebar: value
            }));
        };
        this.handleSetPageSidebarMinified = (value) => {
            this.setState(state => ({
                pageSidebarMinify: value
            }));
        };
        this.handleSetPageSidebarWide = (value) => {
            this.setState(state => ({
                pageSidebarWide: value
            }));
        };
        this.handleSetPageSidebarLight = (value) => {
            this.setState(state => ({
                pageSidebarLight: value
            }));
        };
        this.handleSetPageSidebarTransparent = (value) => {
            this.setState(state => ({
                pageSidebarTransparent: value
            }));
        };

        this.toggleRightSidebar = (e) => {
            e.preventDefault();
            this.setState(state => ({
                pageRightSidebarCollapsed: !this.state.pageRightSidebarCollapsed
            }));
        };
        this.toggleMobileRightSidebar = (e) => {
            e.preventDefault();
            this.setState(state => ({
                pageMobileRightSidebarToggled: !this.state.pageMobileRightSidebarToggled
            }));
        };
        this.handleSetPageRightSidebar = (value) => {
            this.setState(state => ({
                pageRightSidebar: value
            }));
        };

        var floatSubMenuRemove;
        var floatSubMenuCalculate;
        var floatSubMenuRemoveTime = 250;
        this.handleFloatSubMenuOnMouseOver = (e) => {
            clearTimeout(floatSubMenuRemove);
            clearTimeout(floatSubMenuCalculate);
        };
        this.handleFloatSubMenuOnMouseOut = (e) => {
            floatSubMenuRemove = setTimeout(() => {
                this.setState(state => ({
                    pageFloatSubMenuActive: false
                }));
            }, floatSubMenuRemoveTime);
        };
        this.handleSidebarOnMouseOver = (e, menu) => {
            if (this.state.pageSidebarMinify) {
                if (menu.children) {
                    var left = (document.getElementById('sidebar').offsetWidth + document.getElementById('sidebar').offsetLeft) + 'px';

                    clearTimeout(floatSubMenuRemove);
                    clearTimeout(floatSubMenuCalculate);

                    this.setState(state => ({
                        pageFloatSubMenu: menu,
                        pageFloatSubMenuActive: true,
                        pageFloatSubMenuLeft: left
                    }));

                    var offset = e.currentTarget.offsetParent.getBoundingClientRect();

                    floatSubMenuCalculate = setTimeout(() => {
                        var targetTop = offset.top;
                        var windowHeight = window.innerHeight;
                        var targetHeight = document.querySelector('.float-sub-menu-container').offsetHeight;
                        var top, bottom, arrowTop, arrowBottom, lineTop, lineBottom;

                        if ((windowHeight - targetTop) > targetHeight) {
                            top = offset.top + 'px';
                            bottom = 'auto';
                            arrowTop = '20px';
                            arrowBottom = 'auto';
                            lineTop = '20px';
                            lineBottom = 'auto';
                        } else {
                            var aBottom = (windowHeight - targetTop) - 21;
                            top = 'auto';
                            bottom = '0';
                            arrowTop = 'auto';
                            arrowBottom = aBottom + 'px';
                            lineTop = '20px';
                            lineBottom = aBottom + 'px';
                        }

                        this.setState(state => ({
                            pageFloatSubMenuTop: top,
                            pageFloatSubMenuBottom: bottom,
                            pageFloatSubMenuLineTop: lineTop,
                            pageFloatSubMenuLineBottom: lineBottom,
                            pageFloatSubMenuArrowTop: arrowTop,
                            pageFloatSubMenuArrowBottom: arrowBottom,
                            pageFloatSubMenuOffset: offset
                        }));
                    }, 0);

                } else {
                    floatSubMenuRemove = setTimeout(() => {
                        this.setState(state => ({
                            pageFloatSubMenu: '',
                            pageFloatSubMenuActive: false
                        }));
                    }, floatSubMenuRemoveTime);
                }
            }
        };
        this.handleSidebarOnMouseOut = (e) => {
            if (this.state.pageSidebarMinify) {
                floatSubMenuRemove = setTimeout(() => {
                    this.setState(state => ({
                        pageFloatSubMenuActive: false
                    }));
                }, floatSubMenuRemoveTime);
            }
        };
        this.handleFloatSubMenuClick = () => {
            if (this.state.pageSidebarMinify) {
                const windowHeight = window.innerHeight;
                const targetHeight = document.getElementById('float-sub-menu').offsetHeight;
                const targetTop = this.state.pageFloatSubMenuOffset.top;
                const top = ((windowHeight - targetTop) > targetHeight) ? targetTop : 'auto';
                const left = (this.state.pageFloatSubMenuOffset.left + document.getElementById('sidebar').offsetWidth) + 'px';
                const bottom = ((windowHeight - targetTop) > targetHeight) ? 'auto' : '0';
                const arrowTop = ((windowHeight - targetTop) > targetHeight) ? '20px' : 'auto';
                const arrowBottom = ((windowHeight - targetTop) > targetHeight) ? 'auto' : ((windowHeight - targetTop) - 21) + 'px';
                const lineTop = ((windowHeight - targetTop) > targetHeight) ? '20px' : 'auto';
                const lineBottom = ((windowHeight - targetTop) > targetHeight) ? 'auto' : ((windowHeight - targetTop) - 21) + 'px';

                this.setState(state => ({
                    pageFloatSubMenuTop: top,
                    pageFloatSubMenuLeft: left,
                    pageFloatSubMenuBottom: bottom,
                    pageFloatSubMenuLineTop: lineTop,
                    pageFloatSubMenuLineBottom: lineBottom,
                    pageFloatSubMenuArrowTop: arrowTop,
                    pageFloatSubMenuArrowBottom: arrowBottom
                }));
            }
        };

        this.handleSetPageContent = (value) => {
            this.setState(state => ({
                pageContent: value
            }));
        };
        this.handleSetPageContentClass = (value) => {
            this.setState(state => ({
                pageContentClass: value
            }));
        };
        this.handleSetPageContentFullHeight = (value) => {
            this.setState(state => ({
                pageContentFullHeight: value
            }));
        };
        this.handleSetPageContentFullWidth = (value) => {
            this.setState(state => ({
                pageContentFullWidth: value
            }));
        };
        this.handleSetPageContentInverseMode = (value) => {
            this.setState(state => ({
                pageContentInverseMode: value
            }));
        };

        this.handleSetPageHeader = (value) => {
            this.setState(state => ({
                pageHeader: value
            }));
        };
        this.handleSetPageHeaderMegaMenu = (value) => {
            this.setState(state => ({
                pageHeaderMegaMenu: value
            }));
        };
        this.handleSetPageHeaderLanguageBar = (value) => {
            this.setState(state => ({
                pageHeaderLanguageBar: value
            }));
        };

        this.handleSetPageFooter = (value) => {
            this.setState(state => ({
                pageFooter: value
            }));
        };
        this.handleSetPageTwoSidebar = (value) => {
            this.setState(state => ({
                pageTwoSidebar: value
            }));
        };
        this.handleSetPageBoxedLayout = (value) => {
            if (value === true) {
                document.body.classList.add('boxed-layout');
            } else {
                document.body.classList.remove('boxed-layout');
            }
        };
        this.handleSetBodyWhiteBg = (value) => {
            if (value === true) {
                document.body.classList.add('bg-white');
            } else {
                document.body.classList.remove('bg-white');
            }
        };

        this.addNotification = (notificationType, notificationTitle, notificationMessage, notificationPosition, notificationContent) => {
            if (notificationContent) {
                notificationContent = (
                    <div class="widget-list widget-list-rounded inverse-mode w-100">
                        <div class="widget-list-item">
                            <div class="widget-list-media">
                                <img src="/assets/img/user/user-12.jpg" alt="" class="rounded" />
                            </div>
                            <div class="widget-list-content">
                                <h4 class="widget-list-title">Christopher Struth</h4>
                                <p class="widget-list-desc">Bank Transfer</p>
                            </div>
                        </div>
                    </div>
                );
            }
            /*this.notificationRef && this.notificationRef.current && */this.notificationRef.current.addNotification({
                title: notificationTitle,
                message: notificationMessage,
                type: notificationType,
                insert: "top",
                container: notificationPosition ? notificationPosition : 'top-right',
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {duration: 2000},
                dismissable: {click: true},
                content: notificationContent
            });
        };

        this.showCommonModal = (title, text, subtext, type, buttons, callback) => {
            this.setState({
                modalShow: true,
                modalTitle: title,
                modalSubText: subtext,
                modalType: type,
                modalText: text,
                modalCallback: callback ? callback : () => {
                },
                modalButtons: buttons,
            });
        };
        this.showModal = (text, type, buttons, callback) => {
            this.showCommonModal('', text, '', type, buttons, callback);
        };
        this.showAlert = (text, callback) => {
            this.showModal(text, CommonModal.ALERT, false, callback);
        };
        this.showConfirm = (text, callback) => {
            this.showModal(text, CommonModal.CONFIRM, false, callback);
        };
        this.onModalClose = (result) => {
            this.setState({
                modalShow: false,
                modalText: ''
            });
            this.state.modalCallback(result);
        };

        this.post = async (url, param, callback, showProgress = false) => {
            if (showProgress)
                this.handleSetPageLoading(true);

            axios.defaults.headers.post[Const.HEADER.AUTH_TOKEN] = this.props.rootStore.getToken();
            axios.defaults.headers.post[Const.HEADER.LANG] = 'ko';

            try {
                let result = await axios.post(Const.BASE_URL + Const.API_PATH + url, param);
                // console.log(result);

                if (showProgress)
                    this.handleSetPageLoading(false);

                let response = result.data;
                if (Number(response.result_code) === Const.RESULT_CODE.SUCCESS) {
                    if (callback) {
                        callback(response);
                    }
                } else {
                    this.handleApiError(response);
                }
            } catch (error) {
                console.log(error);

                if (showProgress)
                    this.handleSetPageLoading(false);

                this.showAlert(error.message);
            }
        };

        this.get = async (url, param, callback, showProgress = false) => {
            if (showProgress)
                this.handleSetPageLoading(true);

            axios.defaults.headers.get[Const.HEADER.AUTH_TOKEN] = this.props.rootStore.getToken();
            axios.defaults.headers.get[Const.HEADER.LANG] = 'ko';

            try {
                let result = await axios.get(Const.BASE_URL + Const.API_PATH + url, { params: param });
                // console.log(result);

                if (showProgress)
                    this.handleSetPageLoading(false);

                let response = result.data;
                if (Number(response.result_code) === Const.RESULT_CODE.SUCCESS) {
                    if (callback) {
                        callback(response);
                    }
                } else {
                    this.handleApiError(response);
                }
            } catch (error) {
                console.log(error);

                if (showProgress)
                    this.handleSetPageLoading(false);

                this.showAlert(error.message);
            }
        };

        this.handleApiError = (e) => {
            if (Number(e.result_code) === Const.RESULT_CODE.EMPTY_TOKEN ||
                Number(e.result_code) === Const.RESULT_CODE.TOKEN_EXPIRED) {
                this.props.history.replace('/login');
                return;
            }

            let msg = e.result_msg;
            if (msg === '') {
                msg = 'API 오류가 발생하였습니다.';
            }

            this.showAlert(msg);
        };

        this.handleSetPageLoading = (value) => {
            this.setState(state => ({
                pageLoading: value
            }));
        };

        this.showGallery = (image_list, index = 0) => {
            this.setState({
                galleryShow: true,
                galleryData: image_list,
                galleryIndex: index,
            });
        };

        this.state = {
            pageHeader: true,
            pageheaderMegaMenu: false,
            pageHeaderLanguageBar: false,
            hasScroll: false,
            handleSetPageHeader: this.handleSetPageHeader,
            handleSetPageHeaderLanguageBar: this.handleSetPageHeaderLanguageBar,
            handleSetPageHeaderMegaMenu: this.handleSetPageHeaderMegaMenu,

            pageSidebar: true,
            pageSidebarWide: false,
            pageSidebarLight: false,
            pageSidebarMinify: false,
            pageSidebarToggled: false,
            pageSidebarTransparent: false,
            handleSetPageSidebar: this.handleSetPageSidebar,
            handleSetPageSidebarWide: this.handleSetPageSidebarWide,
            handleSetPageSidebarLight: this.handleSetPageSidebarLight,
            handleSetPageSidebarMinified: this.handleSetPageSidebarMinified,
            handleSetPageSidebarTransparent: this.handleSetPageSidebarTransparent,
            handleSidebarOnMouseOut: this.handleSidebarOnMouseOut,
            handleSidebarOnMouseOver: this.handleSidebarOnMouseOver,
            toggleSidebarMinify: this.toggleSidebarMinify,
            toggleMobileSidebar: this.toggleMobileSidebar,

            pageFloatSubMenuActive: false,
            pageFloatSubMenu: '',
            pageFloatSubMenuTop: 'auto',
            pageFloatSubMenuLeft: 'auto',
            pageFloatSubMenuBottom: 'auto',
            pageFloatSubMenuLineTop: 'auto',
            pageFloatSubMenuLineBottom: 'auto',
            pageFloatSubMenuArrowTop: 'auto',
            pageFloatSubMenuArrowBottom: 'auto',
            pageFloatSubMenuOffset: '',
            handleFloatSubMenuOnMouseOver: this.handleFloatSubMenuOnMouseOver,
            handleFloatSubMenuOnMouseOut: this.handleFloatSubMenuOnMouseOut,
            handleFloatSubMenuClick: this.handleFloatSubMenuClick,

            pageContent: true,
            pageContentClass: '',
            pageContentFullHeight: false,
            pageContentFullWidth: false,
            pageContentInverseMode: false,
            handleSetPageContent: this.handleSetPageContent,
            handleSetPageContentClass: this.handleSetPageContentClass,
            handleSetPageContentFullHeight: this.handleSetPageContentFullHeight,
            handleSetPageContentFullWidth: this.handleSetPageContentFullWidth,
            handleSetPageContentInverseMode: this.handleSetPageContentInverseMode,

            pageFooter: false,
            handleSetPageFooter: this.handleSetPageFooter,

            pageTwoSidebar: false,
            handleSetPageTwoSidebar: this.handleSetPageTwoSidebar,

            pageRightSidebar: false,
            pageRightSidebarToggled: true,
            pageMobileRightSidebarToggled: false,
            toggleRightSidebar: this.toggleRightSidebar,
            toggleMobileRightSidebar: this.toggleMobileRightSidebar,
            handleSetPageRightSidebar: this.handleSetPageRightSidebar,

            handleSetBodyWhiteBg: this.handleSetBodyWhiteBg,
            handleSetPageBoxedLayout: this.handleSetPageBoxedLayout,

            pageLoading: false,
            handleSetPageLoading: this.handleSetPageLoading,

            addNotification: this.addNotification,

            modalShow: false,
            modalText: '',
            modalButtons: false,
            modalTitle: '',
            modalSubText: '',
            modalCallback: () => {
            },

            showModal: this.showModal,
            showCommonModal: this.showCommonModal,
            showQuestion: this.showQuestion,
            showAlert: this.showAlert,
            showConfirm: this.showConfirm,

            post: this.post,
            get: this.get,
            handleApiError: this.handleApiError,

            showGallery: this.showGallery,
            galleryShow: false,
            galleryData: [],
            galleryIndex: 0,
        };
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll)
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll)
    }

    handleScroll = () => {
        if (window.scrollY > 0) {
            this.setState(state => ({
                hasScroll: true
            }));
        } else {
            this.setState(state => ({
                hasScroll: false
            }));
        }
    };

    render() {
        return (
            <PageSettings.Provider value={this.state}>
                <div className={
                    'fade page-sidebar-fixed show page-container ' +
                    (this.state.pageHeader ? 'page-header-fixed ' : '') +
                    (this.state.pageSidebar ? '' : 'page-without-sidebar ') +
                    (this.state.pageRightSidebar ? 'page-with-right-sidebar ' : '') +
                    (this.state.pageSidebarWide ? 'page-with-wide-sidebar ' : '') +
                    (this.state.pageSidebarLight ? 'page-with-light-sidebar ' : '') +
                    (this.state.pageSidebarMinify ? 'page-sidebar-minified ' : '') +
                    (this.state.pageSidebarToggled ? 'page-sidebar-toggled ' : '') +
                    (this.state.pageContentFullHeight ? 'page-content-full-height ' : '') +
                    (this.state.pageTwoSidebar ? 'page-with-two-sidebar ' : '') +
                    (this.state.pageRightSidebarCollapsed ? 'page-right-sidebar-collapsed ' : '') +
                    (this.state.pageMobileRightSidebarToggled ? 'page-right-sidebar-toggled ' : '') +
                    (this.state.hasScroll ? 'has-scroll ' : '')
                }>
                    <ReactNotification ref={this.notificationRef} />
                    <CommonModal isOpen={this.state.modalShow}
                        type={this.state.modalType}
                        buttons={this.state.modalButtons}
                        title={this.state.modalTitle}
                        text={this.state.modalText}
                        subtext={this.state.modalSubText}
                        onClose={this.onModalClose} />
                    {this.state.pageHeader && (<Header />)}
                    {this.state.pageSidebar && (<Sidebar />)}
                    {this.state.pageTwoSidebar && (<SidebarRight />)}
                    {this.state.pageContent && (<Content />)}
                    {this.state.pageFooter && (<Footer />)}
                    {this.state.pageLoading && (<ProgressBar />)}
                    <FloatSubMenu />
                    {this.state.galleryShow &&
                        <Lightbox
                            reactModalStyle={{ overlay: { zIndex: 10000 } }}
                            mainSrc={this.state.galleryData[this.state.galleryIndex]}
                            nextSrc={this.state.galleryData[(this.state.galleryIndex + 1) % this.state.galleryData.length]}
                            prevSrc={this.state.galleryData[(this.state.galleryIndex + this.state.galleryData.length - 1) % this.state.galleryData.length]}
                            onCloseRequest={() => this.setState({
                                galleryShow: false,
                                galleryData: [],
                                galleryIndex: 0
                            })}
                            onMovePrevRequest={() =>
                                this.setState({
                                    galleryIndex: (this.state.galleryIndex + this.state.galleryData.length - 1) % this.state.galleryData.length,
                                })
                            }
                            onMoveNextRequest={() =>
                                this.setState({
                                    galleryIndex: (this.state.galleryIndex + 1) % this.state.galleryData.length,
                                })
                            }
                        />
                    }
                </div>
            </PageSettings.Provider>
        )
    }
}

export default withRouter(inject('rootStore')(App));