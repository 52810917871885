import React from 'react';
import strings from '../../lang/strings.js';
import { PageSettings } from "../../config/page-settings";

class GenderStaFragment extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
            gender_sta: null,
        };
    }

    componentDidMount() {
        this.getIapSta();
    }

    getIapSta = () => {
        let self = this;

        this.context.get(
            'dashboard/gender_sta',
            {},
            function (response) {
                self.setState({
                    gender_sta: response.result_data.gender_sta
                });
            });
    };

    render() {
        let self = this;

        return (
            <div>
                <div className="widget widget-stats bg-teal height-100">
                    <div className="d-flex align-items-center text-white">
                        <div className="d-flex w-100">
                            <div>{strings.signup_ratio}</div>
                            <div className="ml-auto"> </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center text-white">
                        <div className="d-flex w-100">
                            <div>{strings.male}</div>
                            <div className="ml-auto">
                                {
                                    self.state.gender_sta ?
                                        `${self.state.gender_sta.male_count.currencyFormat()}명 (${self.state.gender_sta.total_count > 0 ? (self.state.gender_sta.male_count / self.state.gender_sta.total_count * 100).toFixed(0) : 0}%, 정상: ${self.state.gender_sta.male_normal_count.currencyFormat()}명, 휴면: ${self.state.gender_sta.male_sleep_count.currencyFormat()})`
                                        : `-`
                                }
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center text-white">
                        <div className="d-flex w-100">
                            <div>{strings.female}</div>
                            <div className="ml-auto">
                                {
                                    self.state.gender_sta ?
                                        `${self.state.gender_sta.female_count.currencyFormat()}명 (${self.state.gender_sta.total_count > 0 ? (self.state.gender_sta.female_count / self.state.gender_sta.total_count * 100).toFixed(0) : 0}%, 정상: ${self.state.gender_sta.female_normal_count.currencyFormat()}명, 휴면: ${self.state.gender_sta.female_sleep_count.currencyFormat()})`
                                        : `-`
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default GenderStaFragment;