import React from 'react';


class SearchSelect extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};

        this.className = 'form-control ' + (typeof this.props.className === 'undefined' ? '' : this.props.className);
        this.onChange = this.onChange.bind(this);
    }

    onChange(e) {
        if (this.props.onChange) {
            this.props.onChange(e.target.value);
        }
    }

    render() {
        return (
            <div className='form-horizontal form-inline'>
                <select className={this.className} onChange={this.onChange} value={this.props.value || ''}>
                    {
                        this.props.data &&
                        this.props.data.map((item, index) => {
                            return (
                                <option value={item.code} key={index}>{item.name}</option>
                            )
                        })
                    }
                </select>
            </div>
        );
    }

}

export default SearchSelect;