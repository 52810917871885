import $ from "jquery";

const moment = require('moment');
const Const = require('./const');

String.prototype.currencyFormat = function () {
    return this.replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

String.prototype.dateFormat = function (format) {
    if (typeof format === 'undefined') {
        format = 'YYYY-MM-DD HH:mm:ss';
    }

    return this.isEmpty() ? '' : moment(this).format(format);
};

String.prototype.customDateFormat = function () {
    if (this.isEmpty()) {
        return '';
    }

    let days = ['sun', 'mon', 'tue', 'wed', 'thr', 'fri', 'sat'];
    let colors = ['text-danger', '', '', '', '', '', 'text-blue'];

    return this + '<span class="' + colors[moment(this).weekday()] + '"> (' + days[moment(this).weekday()] + ')</span>';
};

String.prototype.isEmpty = function () {
    return this === '';
};

String.prototype.nl2br = function () {
    return this.replace(/\n/g, '<br/>');
};

Number.prototype.currencyFormat = function () {
    return this.toString().currencyFormat();
};

String.prototype.getThumbnailUrl = function () {
    const ext = this.substr(this.lastIndexOf('.'));
    return this.replace(ext, `_thumb${ext}`);
};

$.fn.dateFormat = function (date, format) {
    if (date == null || date === '') {
        return '';
    }

    if (typeof format === 'undefined') {
        format = 'YYYY-MM-DD';
    }

    return moment(date).format(format);
};

$.fn.currencyFormat = function (cost) {
    return cost.replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

$.fn.manage_select = function (callback) {
    return this
        .html('')
        .append('<select class="form-control manage-select"></select>')
        .find('select')
        .append('<option value="0">Normal</option>')
        .append('<option value="6">Nickname</option>')
        .append('<option value="7">P-Approve</option>')
        .append('<option value="8">P-Delete</option>')
        .append('<option value="1">Leave</option>')
        .append('<option value="2">Block</option>')
        .append('<option value="10">1-Day</option>')
        .append('<option value="11">3-Day</option>')
        .append('<option value="12">7-Day</option>')
        .append('<option value="13">30-Day</option>')
        .append('<option value="14">180-Day</option>')
        .append('<option value="4">Video Limit</option>')
        .append('<option value="5">DB Recycle</option>')
        .bind('change', function () {
            callback($(this).val());
        });
};

$.fn.nickname_label = function (nickname, gender, callback) {
    let clsName = '';
    if (gender == 'M') {
        clsName = 'male';
    } else if (gender == 'F') {
        clsName = 'female';
    };
    let label = '<a href="javascript:void(0);" class="' + clsName + '">' + nickname + '</a>';

    return this
        .html(label)
        .find('a')
        .bind('click', () => {
            if (callback) {
                callback();
            }
        });
};

$.fn.image_label = function (images, callback) {
    if (images && images.length > 0) {
        this.html(`<div></div>`)
        images.split('#').map(async (image, index) => {
            const full_url = Const.BASE_URL + image;
            this.append(`<a><img src="${full_url.getThumbnailUrl()}" style="width: auto; height: 50px; margin: 0 1px;" /></a>`)
        })
        this.find('a')
            .bind('click', () => {
                if (callback) {
                    callback();
                }
            })
    } else {
        this.html('<img style="width: 50px; height: 50px; border: 1px; border-color: #e2e7eb"/>');
    }
};

$.fn.image_lightbox = function (images, callback) {
    if (images && images.length > 0) {
        const image_list = images.split('#')
        if (image_list.length > 0) {
            const full_url = Const.BASE_URL + image_list[0];

            return this
                .html(`<a href="javascript:;"><img src="${full_url.getThumbnailUrl()}" style="width: auto; height: 50px; margin: 0 1px;" /></a>`)
                .find('a')
                .bind('click', () => {
                    if (callback) {
                        callback();
                    }
                })
        }
    }

    this.html('<img style="width: 50px; height: 50px; border: 1px; border-color: #e2e7eb"/>');
};

$.fn.video_label = function (videos, callback) {
    if (videos && videos.length > 0) {
        const video_list = videos.split('#')
        if (video_list.length > 0) {
            const ext = video_list[0].split('.').pop();
            const full_url = Const.BASE_URL + video_list[0].replace(ext, 'jpg');

            return this
                .html(`<div style="display: inline-block; position: relative;width: 100px; height: 60px; margin: 0 1px; background-image: url(${full_url}); background-position: center; background-size: cover">
                    <span style="position: absolute;
                        top: 50%;
                        left: 50%;
                        width: 30px;
                        height: 30px;
                        margin: -20% 0 0 -20%;
                        border-radius: 50%;
                        background: rgba(0,0,0,.75);
                        color: #fff;
                        text-align: center;
                        display: flex;
                        align-items: center;
                        justify-content: center;">
                        <i class="fa fa-play"></i>
                    </span>
                </div>`)
                .find('div')
                .bind('click', () => {
                    if (callback) {
                        callback();
                    }
                })
        }
    }

    this.html('<img style="width: 50px; height: 50px; border: 1px; border-color: #e2e7eb"/>');
};

$.fn.school_job_label = function (school, job) {
    if (school && job) {
        return this.html(school + " / " + job);
    } else if (school) {
        return this.html(school);
    } else if (job) {
        return this.html(job);
    } else {
        return this.html('미인증');
    }
};

Number.prototype.status2String = function () {
    if (this === Const.USER_STATUS.BLOCK) {
        return "<span style='color: purple'>차단됨</span>";
    } else if (this === Const.USER_STATUS.LEAVE) {
        return "<span style='color: black'>탈퇴</span>";
    } else if (this === Const.USER_STATUS.SLEEP) {
        return "<span style='color: gray'>휴면</span>";
    } else if (this === Const.USER_STATUS.REJECT) {
        return "<span style='color: green'>인증반려</span>";
    } else if (this === Const.USER_STATUS.PENDING) {
        return "<span style='color: red'>인증대기</span>";
    } else if (this === Const.USER_STATUS.NORMAL) {
        return "<span style='color: blue'>정상</span>";
    } else {
        return "unknown";
    }
};

Number.prototype.status2String2 = function () {
    if (this === Const.USER_STATUS.BLOCK) {
        return "차단됨";
    } else if (this === Const.USER_STATUS.LEAVE) {
        return "탈퇴";
    } else if (this === Const.USER_STATUS.SLEEP) {
        return "휴면";
    } else if (this === Const.USER_STATUS.REJECT) {
        return "인증반려";
    } else if (this === Const.USER_STATUS.PENDING) {
        return "인증대기";
    } else if (this === Const.USER_STATUS.NORMAL) {
        return "정상";
    } else {
        return "unknown";
    }
};

Number.prototype.pendingStatus2String = function () {
    if (this === Const.USER_PENDING_STATUS.CANCEL) {
        return "<span style='color: gray'>사용자취소</span>";
    } else if (this === Const.USER_PENDING_STATUS.REJECT || this === Const.USER_PENDING_STATUS.REJECT_RETRY) {
        return "<span style='color: green'>반려됨</span>";
    } else if (this === Const.USER_PENDING_STATUS.PENDING) {
        return "<span style='color: red'>심사대기</span>";
    } else if (this === Const.USER_PENDING_STATUS.NORMAL) {
        return "<span style='color: blue'>승인됨</span>";
    } else {
        return "unknown";
    }
};

String.prototype.gender2String = function () {
    if (this === 'F') {
        return "여자";
    } else if (this === 'M') {
        return "남자";
    } else {
        return "모름";
    }
};

String.prototype.verify2String = function () {
    if (this !== '') {
        return "인증됨";
    } else {
        return "인증안됨";
    }
};

/**
 * 이미지 FULL URL 리턴
 */
String.prototype.urlString2Array = function () {
    var image_url_list = [];
    this !== '' && this.split("#").map(async (entry) => {
        image_url_list.push(Const.BASE_URL + entry);
    });

    return image_url_list;
};

/**
 * 이미지 FULL URL 리턴
 */
Array.prototype.urlArray2String = function () {
    return this.filter((url) => {
        return url !== ''
    }).map((url) => {
        return url.replace(Const.BASE_URL, '');
    }).join('#');
};

/**
 * 이미지 FULL URL 리턴
 */
String.prototype.url2String = function () {
    return this.replace(Const.BASE_URL, '');
};

export default {
    getFullUrl(image_url) {
        if (image_url == null || image_url === '') {
            return '';
        }

        return Const.BASE_URL + image_url;
    },

    getThumbUrl(image_url) {
        if (image_url == null || image_url === '') {
            return '';
        }

        return (Const.BASE_URL + image_url).getThumbnailUrl();
    },

    getVideoThumbUrl(video_url) {
        if (video_url == null || video_url === '') {
            return '';
        }

        const ext = video_url.split('.').pop();
        return Const.BASE_URL + video_url.replace(Const.BASE_URL, '').replace(ext, 'jpg');
    },

    getImageList(image_urls) {
        var res = []
        if (image_urls) {
            image_urls.split("#").forEach(element => {
                res.push(Const.BASE_URL + element)
            })
        }
        return res
    },

    getThumbList(image_urls) {
        var res = []
        if (image_urls) {
            image_urls.split("#").forEach(element => {
                if (element) {
                    res.push((Const.BASE_URL + element).getThumbnailUrl())
                }
            })
        }
        return res
    },

    dateFormat(value, format = 'YYYY-MM-DD HH:mm:ss') {
        return value && !value.isEmpty() ? moment(value).format(format) : '';
    },

    getKeyword(keyword) {
        var result = '';
        keyword.split('#').forEach(it => {
            result = result + `#${it} `;
        })
        return result;
    },

    currencyFormat(value) {
        if (!value) {
            return value;
        }

        return value.toString().replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    birth2Age(birthday) {
        if (birthday) {
            return Math.abs(moment().diff(birthday, 'year'));
        } else {
            return '';
        }
    }
}