import React from 'react';
import strings from '../../lang/strings.js';
import $ from 'jquery';
import { PageSettings } from "../../config/page-settings";
import DataTable from './../../components/content/data-table.jsx';
import './../../config/util';
import Const from './../../config/const';
import U from './../../config/util';

class MemberNotificationHistory extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {
    }

    render() {
        let self = this;
        let user_info = this.props.user_info;
        let columnNo = 0;

        return (
            <div>
                <div className="row">
                    <div className="col-xs-3">
                        <div className='row'>
                            <label>{strings.birthday}: {U.birth2Age(user_info.birthday)}</label>
                        </div>
                    </div>
                    <div className="col-xs-3">
                        <div className='row'>
                            <label>{strings.height}: {user_info.height}cm</label>
                        </div>
                    </div>
                    <div className="col-xs-6">
                        <div className='row'>
                            <label>{strings.location}: {user_info.location}</label>
                        </div>
                    </div>
                </div>
                <div className="row mt-2">
                    <DataTable ref={(ref) => this.tblMember = ref}
                        options={{
                            columnDefs: [
                                {
                                    targets: [columnNo++], data: 'id', orderable: false,
                                    render: function (value) {
                                        return value
                                    }
                                },
                                {
                                    targets: [columnNo++], data: 'title', orderable: false,
                                    render: function (value) {
                                        return value
                                    }
                                },
                                {
                                    targets: [columnNo++], data: 'content', orderable: false,
                                    render: function (value) {
                                        return value
                                    }
                                },
                                {
                                    targets: [columnNo++], data: 'status', orderable: false,
                                    render: function (value) {
                                        return value == 1 ? '<span style="color: gray">확인함</span>' : '<span style="color: red">미확인</span>'
                                    }
                                },
                                {
                                    targets: [columnNo++], data: 'create_date', orderable: false,
                                    render: function (value) {
                                        return value
                                    }
                                },
                            ],
                            iDisplayLength: 10,
                            order: [[1, 'DESC']],
                            ajax: {
                                url: 'member/getActivityList',
                                data: function (req) {
                                    req.columns = null;
                                    req.uid = user_info.id;
                                }
                            },
                            drawCallback: function () {
                            },
                            fnCreatedRow: function (row, data, dataInd) {
                            }
                        }}>
                        <thead>
                            <tr>
                                <th>UID</th>
                                <th>{strings.title}</th>
                                <th width="50%">{strings.content}</th>
                                <th>{strings.status}</th>
                                <th>{strings.create_date}</th>
                            </tr>
                        </thead>
                        <tbody />
                    </DataTable>
                </div>
            </div >
        )
    }
}

export default MemberNotificationHistory;