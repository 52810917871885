import React from 'react';
import strings from '../../lang/strings.js';
import $ from 'jquery';
import { PageSettings } from "../../config/page-settings";
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import './../../config/util';
import Const from './../../config/const';
import SearchSelect from './../../components/content/search-select.js';

class MemberCouponStatusPopup extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
            coupon_no: '',
            target_coupon_no: '',
        }
        this.toggleModal = this.toggleModal.bind(this);
    }

    toggleModal(result) {
        if (this.props.onClose) {
            this.props.onClose(result);
        }
    }

    onApply() {
        if (this.props.onApply) {
            this.props.onApply(this.state.coupon_no, this.state.target_coupon_no);
        }
    }

    componentDidMount() {
    }

    render() {
        return (
            <div>
                <Modal isOpen={this.props.isOpen} toggle={this.toggleModal}>
                    <ModalHeader className="popup-title" toggle={this.toggleModal}>{strings.coupon_no_input}</ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <div className="col-xl-12">
                                <input type='text' autoComplete='off' className='form-control'
                                    placeholder={'초대자 쿠폰번호'}
                                    onChange={(e) => {
                                        this.setState({ coupon_no: e.target.value });
                                    }} />
                            </div>
                            <div className="col-xl-12">
                                <input type='text' autoComplete='off' className='form-control mt-3'
                                    placeholder={'수락자 쿠폰번호'}
                                    onChange={(e) => {
                                        this.setState({ target_coupon_no: e.target.value });
                                    }} />
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-default" onClick={this.toggleModal}>{strings.close}</button>
                        <button className="btn btn-success" onClick={() => this.onApply()}>{strings.apply}</button>
                    </ModalFooter>
                </Modal>
            </div >
        )
    }
}

export default MemberCouponStatusPopup;