import React from 'react';
import { inject } from 'mobx-react';
import strings from '../../lang/strings.js';
import { PageSettings } from "../../config/page-settings";
import './../../config/util';
import Const from './../../config/const';
import DateTime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';

class SettingCond extends React.Component {
    static contextType = PageSettings;

    condition_group = {
        cond1_enable: 'N',
        cond2_enable: 'N',
        cond3_enable: 'N',
        cond4_enable: 'N',
        cond5_enable: 'N',
        cond6_enable: 'N',
        cond7_enable: 'N',
        cond8_enable: 'N',
        cond9_enable: 'N',
        cond10_enable: 'N',
        cond11_enable: 'N',
        cond12_enable: 'N',
        cond13_enable: 'N',

        search_user_type: "nickname",
        search_user_value: "",
        search_gender: "",
        search_iap_date: "",
        search_iap_value: "",
        search_age_from: "",
        search_age_to: "",
        search_device_os: "",
        search_location: strings.const.SEARCH_LOCATION[0],
        search_job: '',
        search_height_from: "",
        search_height_to: "",
        search_religion: strings.const.SEARCH_RELIGION[0],
        search_from_signup_date: "",
        search_min_invite_count: "",
        search_score_from: "",
        search_score_to: "",
        search_invite_code: "",
    };

    constructor(props) {
        super(props);

        this.state = props.cond;
        this.state.cond = this.condition_group;

        this.onChange = this.onChange.bind(this);
        this.onChangeRadio = this.onChangeRadio.bind(this);
        this.onChangeDate = this.onChangeDate.bind(this);
    }

    componentDidMount() {
    }

    componentWillReceiveProps(newProps) {
        if (!newProps.cond.cond.cond1_enable) {
            newProps.cond.cond = this.condition_group;
        }

        this.setState(newProps.cond);
    }

    onChange(e) {
        const cond = this.state.cond;
        cond[e.target.id] = e.target.value;
        this.setState({ cond });
    }

    onChangeRadio(e) {
        const cond = this.state.cond;
        cond[e.target.name] = e.target.value;
        this.setState({ cond });
    }

    onChangeDate(id, value, format) {
        this.setState({
            [id]: typeof value === 'object' ? value.format(format) : value
        });
    }

    render() {
        let self = this;
        let job_list_all = this.props.rootStore.getJobList();

        let cond = self.state.cond;

        return (
            <div>
                <div className="row">
                    <div className='col-md-12'>
                        <div className='form-horizontal form-inline'>
                            <span className='width-50'>{strings.option} 1</span>
                            <select id='cond1_enable' className='form-control ml-md-2'
                                value={cond.cond1_enable} onChange={self.onChange}>
                                <option value={'Y'}>{'Y'}</option>
                                <option value={'N'}>{'N'}</option>
                            </select>
                            <select id='search_user_type' className='form-control ml-md-4'
                                disabled={cond.cond1_enable === 'N'} onChange={self.onChange}
                                value={cond.search_user_type}>
                                <option value="nickname">{strings.nickname}</option>
                                <option value="user_id">{strings.user_id}</option>
                                <option value="id">UID</option>
                            </select>
                            <input type='text' autoComplete='off' className='form-control' id='search_user_value'
                                disabled={cond.cond1_enable === 'N'} onChange={self.onChange}
                                value={cond.search_user_value || ''} />
                        </div>
                    </div>
                </div>
                <div className="row mt-1">
                    <div className='col-md-12'>
                        <div className='form-horizontal form-inline'>
                            <span className='width-50'>{strings.option} 2</span>
                            <select id='cond2_enable' className='form-control ml-md-2'
                                value={cond.cond2_enable} onChange={self.onChange}>
                                <option value={'Y'}>{'Y'}</option>
                                <option value={'N'}>{'N'}</option>
                            </select>
                            <div className="form-check ml-md-4">
                                <input className="form-check-input" type="radio" id="cond2_all" name="search_gender"
                                    value='' defaultChecked
                                    disabled={cond.cond2_enable === 'N'} onChange={self.onChangeRadio} />
                                <label className="form-check-label" htmlFor="cond2_all">{strings.total}</label>
                            </div>
                            <div className="form-check ml-2">
                                <input className="form-check-input" type="radio" id="cond2_male" name="search_gender"
                                    value='M'
                                    disabled={cond.cond2_enable === 'N'} onChange={self.onChangeRadio} />
                                <label className="form-check-label" htmlFor="cond2_male">{strings.male}</label>
                            </div>
                            <div className="form-check ml-2">
                                <input className="form-check-input" type="radio" id="cond2_female" name="search_gender"
                                    value='F'
                                    disabled={cond.cond2_enable === 'N'} onChange={self.onChangeRadio} />
                                <label className="form-check-label" htmlFor="cond2_female">{strings.female}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-1">
                    <div className='col-md-12'>
                        <div className='form-horizontal form-inline'>
                            <span className='width-50'>{strings.option} 3</span>
                            <select id='cond3_enable' className='form-control ml-md-2'
                                value={cond.cond3_enable} onChange={self.onChange}>
                                <option value={'Y'}>{'Y'}</option>
                                <option value={'N'}>{'N'}</option>
                            </select>
                            <DateTime dateFormat={'YYYY-MM-DD'} timeFormat={false} inputProps={{ placeholder: strings.select_date, disabled: cond.cond3_enable === 'N' }}
                                className="ml-md-4" id="search_iap_date"
                                disabled={cond.cond3_enable === 'N'}
                                onChange={(e) => { self.onChangeDate('search_iap_date', e, 'YYYY-MM-DD') }}
                            />
                            <span>&nbsp;에</span>
                            <input type='number' autoComplete='off' className='form-control ml-md-2' id='search_iap_value'
                                disabled={cond.cond3_enable === 'N'} onChange={self.onChange} />
                            <span>&nbsp;원 이상 결제한 회원</span>
                        </div>
                    </div>
                </div>
                <div className="row mt-1">
                    <div className='col-md-12'>
                        <div className='form-horizontal form-inline'>
                            <span className='width-50'>{strings.option} 4</span>
                            <select id='cond4_enable' className='form-control ml-md-2'
                                value={cond.cond4_enable} onChange={self.onChange}>
                                <option value={'Y'}>{'Y'}</option>
                                <option value={'N'}>{'N'}</option>
                            </select>
                            <input type='number' autoComplete='off' className='form-control ml-md-4' id='search_age_from'
                                disabled={cond.cond4_enable === 'N'} onChange={self.onChange} />
                            <span>&nbsp;&nbsp;~</span>
                            <input type='number' autoComplete='off' className='form-control ml-md-2' id='search_age_to'
                                disabled={cond.cond4_enable === 'N'} onChange={self.onChange} />
                            <span>&nbsp;사이의 나이</span>
                        </div>
                    </div>
                </div>
                <div className="row mt-1">
                    <div className='col-md-12'>
                        <div className='form-horizontal form-inline'>
                            <span className='width-50'>{strings.option} 5</span>
                            <select id='cond5_enable' className='form-control ml-md-2'
                                value={cond.cond5_enable} onChange={self.onChange}>
                                <option value={'Y'}>{'Y'}</option>
                                <option value={'N'}>{'N'}</option>
                            </select>
                            <label className="ml-md-4">{strings.device}</label>
                            <div className="ml-md-4 form-check">
                                <input className="form-check-input" type="radio" id="cond5_all" name="search_device_os"
                                    value='' defaultChecked
                                    disabled={cond.cond5_enable === 'N'} onChange={self.onChangeRadio} />
                                <label className="form-check-label" htmlFor="cond5_all">{strings.total}</label>
                            </div>
                            <div className="form-check ml-2">
                                <input className="form-check-input" type="radio" id="cond5_android" name="search_device_os"
                                    value='A'
                                    disabled={cond.cond5_enable === 'N'} onChange={self.onChangeRadio} />
                                <label className="form-check-label" htmlFor="cond5_android">Android</label>
                            </div>
                            <div className="form-check ml-2">
                                <input className="form-check-input" type="radio" id="cond5_ios" name="search_device_os"
                                    value='I'
                                    disabled={cond.cond5_enable === 'N'} onChange={self.onChangeRadio} />
                                <label className="form-check-label" htmlFor="cond5_ios">iOS</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-1">
                    <div className='col-md-12'>
                        <div className='form-horizontal form-inline'>
                            <span className='width-50'>{strings.option} 6</span>
                            <select id='cond6_enable' className='form-control ml-md-2'
                                value={cond.cond6_enable} onChange={self.onChange}>
                                <option value={'Y'}>{'Y'}</option>
                                <option value={'N'}>{'N'}</option>
                            </select>
                            <label className="ml-md-4">{strings.location}</label>
                            <select id='search_location' className='form-control ml-md-2'
                                disabled={cond.cond6_enable === 'N'} onChange={self.onChange}
                                value={cond.search_location}>
                                {strings.const.SEARCH_LOCATION.map((entry, index) => (
                                    <option key={index} value={entry}>{entry}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="row mt-1">
                    <div className='col-md-12'>
                        <div className='form-horizontal form-inline'>
                            <span className='width-50'>{strings.option} 7</span>
                            <select id='cond7_enable' className='form-control ml-md-2'
                                value={cond.cond7_enable} onChange={self.onChange}>
                                <option value={'Y'}>{'Y'}</option>
                                <option value={'N'}>{'N'}</option>
                            </select>
                            <label className="ml-md-4">{strings.job}</label>
                            <select id='search_job' className='form-control ml-md-2'
                                disabled={cond.cond7_enable === 'N'} onChange={self.onChange}
                                value={cond.search_job || job_list_all[0].title}>
                                {job_list_all.map((entry, index) => (
                                    <option key={index} value={entry.title}>{entry.title}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="row mt-1">
                    <div className='col-md-12'>
                        <div className='form-horizontal form-inline'>
                            <span className='width-50'>{strings.option} 8</span>
                            <select id='cond8_enable' className='form-control ml-md-2'
                                value={cond.cond8_enable} onChange={self.onChange}>
                                <option value={'Y'}>{'Y'}</option>
                                <option value={'N'}>{'N'}</option>
                            </select>
                            <input type='number' autoComplete='off' className='form-control ml-md-4' id='search_height_from'
                                disabled={cond.cond8_enable === 'N'} onChange={self.onChange} />
                            <span>&nbsp;&nbsp;~</span>
                            <input type='number' autoComplete='off' className='form-control ml-md-2' id='search_height_to'
                                disabled={cond.cond8_enable === 'N'} onChange={self.onChange} />
                            <span>&nbsp;사이의 키</span>
                        </div>
                    </div>
                </div>
                <div className="row mt-1">
                    <div className='col-md-12'>
                        <div className='form-horizontal form-inline'>
                            <span className='width-50'>{strings.option} 9</span>
                            <select id='cond9_enable' className='form-control ml-md-2'
                                value={cond.cond9_enable} onChange={self.onChange}>
                                <option value={'Y'}>{'Y'}</option>
                                <option value={'N'}>{'N'}</option>
                            </select>
                                <label className="ml-md-4">{strings.religion}</label>
                            <select id='search_religion' className='form-control ml-md-2'
                                disabled={cond.cond9_enable === 'N'} onChange={self.onChange}
                                value={cond.search_religion}>
                                {strings.const.SEARCH_RELIGION.map((entry, index) => (
                                    <option key={index} value={entry}>{entry}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="row mt-1">
                    <div className='col-md-12'>
                        <div className='form-horizontal form-inline'>
                            <span className='width-50'>{strings.option} 10</span>
                            <select id='cond10_enable' className='form-control ml-md-2'
                                value={cond.cond10_enable} onChange={self.onChange}>
                                <option value={'Y'}>{'Y'}</option>
                                <option value={'N'}>{'N'}</option>
                            </select>
                            <DateTime dateFormat={'YYYY-MM-DD'} timeFormat={false}
                                inputProps={{ placeholder: strings.select_date, disabled: cond.cond10_enable === 'N' }}
                                className="ml-md-4" id="search_from_signup_date"
                                onChange={(e) => { self.onChangeDate('search_from_signup_date', e, 'YYYY-MM-DD') }} />
                            <span>&nbsp;이후 가입자</span>
                        </div>
                    </div>
                </div>
                <div className="row mt-1">
                    <div className='col-md-12'>
                        <div className='form-horizontal form-inline'>
                            <span className='width-50'>{strings.option} 11</span>
                            <select id='cond11_enable' className='form-control ml-md-2'
                                value={cond.cond11_enable} onChange={self.onChange}>
                                <option value={'Y'}>{'Y'}</option>
                                <option value={'N'}>{'N'}</option>
                            </select>
                            <label className="ml-md-4">친구가입을</label>
                            <input type='number' autoComplete='off' className='form-control ml-md-2' id='search_min_invite_count'
                                disabled={cond.cond11_enable === 'N'} onChange={self.onChange} />
                            <span>&nbsp; 이상 시킨 회원</span>
                        </div>
                    </div>
                </div>
                <div className="row mt-1">
                    <div className='col-md-12'>
                        <div className='form-horizontal form-inline'>
                            <span className='width-50'>{strings.option} 12</span>
                            <select id='cond12_enable' className='form-control ml-md-2'
                                value={cond.cond12_enable} onChange={self.onChange}>
                                <option value={'Y'}>{'Y'}</option>
                                <option value={'N'}>{'N'}</option>
                            </select>
                            <label className="ml-md-4">인기도 상위</label>
                            <input type='number' autoComplete='off' className='form-control ml-md-2' id='search_score_from'
                                disabled={cond.cond12_enable === 'N'} onChange={self.onChange} />
                            <span>&nbsp;&nbsp;~</span>
                            <input type='number' autoComplete='off' className='form-control ml-md-2' id='search_score_to'
                                disabled={cond.cond12_enable === 'N'} onChange={self.onChange} />
                            <span>&nbsp;% 사이</span>
                        </div>
                    </div>
                </div>
                <div className="row mt-1">
                    <div className='col-md-12'>
                        <div className='form-horizontal form-inline'>
                            <span className='width-50'>{strings.option} 13</span>
                            <select id='cond13_enable' className='form-control ml-md-2'
                                value={cond.cond13_enable} onChange={self.onChange}>
                                <option value={'Y'}>{'Y'}</option>
                                <option value={'N'}>{'N'}</option>
                            </select>
                            <label className="ml-md-4">추천인코드가</label>
                            <input autoComplete='off' className='form-control ml-md-2' id='search_invite_code'
                                disabled={cond.cond13_enable === 'N'} onChange={self.onChange} />
                            <span>&nbsp; 인 회원</span>
                        </div>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className='col-md-12'>
                        <label className="f-w-500 f-s-25">에게</label>
                    </div>
                </div>
            </div>
        )
    }
}

export default inject('rootStore')(SettingCond);