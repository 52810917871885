import React from 'react';
import { withRouter } from 'react-router-dom';
import { inject } from 'mobx-react';
import strings from '../../lang/strings.js';
import $ from 'jquery';
import { PageSettings } from "../../config/page-settings";
import DataTable from './../../components/content/data-table.jsx';
import SearchInput from './../../components/content/search-input.js';
import SearchSelect from './../../components/content/search-select.js';
import Const from './../../config/const';
import './../../config/util';
import AliasStarPopup from './alias-star-popup'
const queryString = require('query-string');

class AliasStarFragment extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        let searchQS = queryString.parse(this.props.location.search);

        this.state = {
            search_info: searchQS,
            show_alias_star_popup: false,
            selected_star_id: 0,
        };

        this.refreshTable = this.refreshTable.bind(this);
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.location.search != this.props.location.search) {
            let searchQS = queryString.parse(this.props.location.search);
            this.setState({ search_info: searchQS }, () => this.refreshTable());
        }
    }

    refreshTable = (refresh) => {
        this.tblMember.draw(refresh);
    };

    searchTable = () => {
        let searchQS = queryString.parse(this.props.location.search);
        Object.assign(searchQS, this.state.search_info)
        const new_query = queryString.stringify(searchQS);
        if (new_query == this.props.location.search.substr(1)) {
            this.refreshTable();
        } else {
            this.props.history.push('?' + new_query);
        }
    }

    showEditPopup = (id) => {
        this.setState({
            show_alias_star_popup: true,
            selected_star_id: id
        })
    }

    deleteStarItem = (id) => {
        let self = this;
        this.context.showConfirm(strings.confirm_delete_title, function (response) {
            if (response) {
                self.context.post('alias/deleteStar', { id }, function () {
                    self.context.addNotification('success', strings.alert_title, strings.confirm_delete_done);
                    self.refreshTable(false);
                }, true);
            }
        });
    }

    render() {
        let self = this;
        let columnNo = 0;

        const { search_info } = this.state;

        return (
            <div>
                <AliasStarPopup isOpen={this.state.show_alias_star_popup} star_id={this.state.selected_star_id} gender={this.props.gender} onClose={() => {
                    this.setState({ show_alias_star_popup: false });
                }} onSave={() => {
                    this.setState({ show_alias_star_popup: false });
                    this.refreshTable(false);
                }} />
                <div className='row'>
                    <div className='col-md-10 col-xl-6 col-xxl-4'>
                        <div className='form-horizontal form-inline'>
                            {/* 키워드검색 */}
                            <SearchSelect
                                data={[{ code: 'name', name: strings.alias_name }]}
                                value={search_info.column || 'name'}
                                onChange={(value) => {
                                    this.setState({
                                        search_info: {
                                            ...search_info,
                                            column: value,
                                            start: 0,
                                        }
                                    }, this.searchTable);
                                }}
                            />
                            <SearchInput
                                value={search_info.keyword}
                                onChange={(value) => {
                                    this.setState({
                                        search_info: {
                                            ...search_info,
                                            keyword: value,
                                            column: search_info.column || 'name',
                                            start: 0,
                                        }
                                    })
                                }}
                                handler={(value) => this.searchTable()}
                            />
                            <button type='button' className='btn btn-success btn-sm ml-md-2' onClick={() => this.searchTable()}>{strings.search}</button>
                            <button type='button' className='btn btn-danger btn-sm ml-md-2' onClick={() => this.showEditPopup(0)}>{strings.add}</button>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-10 col-xl-6 col-xxl-4 mt-3'>
                        <DataTable ref={(ref) => this.tblMember = ref}
                            customPaginate={true}
                            initialPage={search_info.start || 0}
                            onPageChange={(value) => {
                                this.setState({
                                    search_info: {
                                        ...search_info,
                                        start: value,
                                    }
                                }, this.searchTable);
                            }}
                            options={{
                                columnDefs: [
                                    {
                                        targets: [columnNo++], data: 'id', orderable: false,
                                        render: function (value) {
                                            return value;
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'name', orderable: false,
                                        render: function (value) {
                                            return value;
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'gender', orderable: false,
                                        render: function (value) {
                                            return value.gender2String();
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'id', orderable: false,
                                        createdCell: function (td, value, item) {
                                            $(td).html('');
                                            $(td).append(`<button type='button' class='btn btn-primary btn-sm m-2'>${strings.edit}</button>`)
                                                .find('button:eq(0)')
                                                .on('click', function () {
                                                    self.showEditPopup(item.id);
                                                });
                                            $(td).append(`<button type='button' class='btn btn-danger btn-sm m-2'>${strings.delete}</button>`)
                                                .find('button:eq(1)')
                                                .on('click', function () {
                                                    self.deleteStarItem(item.id);
                                                });
                                        }
                                    }
                                ],
                                order: [[1, 'DESC']],
                                ajax: {
                                    url: 'alias/getStarList',
                                    data: function (req) {
                                        req.columns = null;
                                        const searchQS = queryString.parse(self.props.location.search);
                                        req.start = searchQS.start || 0;
                                        req.length = searchQS.length || 20;
                                        req.gender = self.props.gender;
                                        req.column = searchQS.column;
                                        req.keyword = searchQS.keyword;
                                    }
                                },
                                drawCallback: function () {
                                },
                                fnCreatedRow: function (row, data, dataInd) {
                                }
                            }}>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>{strings.alias_name}</th>
                                    <th>{strings.gender}</th>
                                    <th>{strings.manage}</th>
                                </tr>
                            </thead>
                            <tbody />
                        </DataTable>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(inject('rootStore')(AliasStarFragment));