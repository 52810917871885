import React from 'react';
import { withRouter } from 'react-router-dom';
import { inject } from 'mobx-react';
import strings from '../../lang/strings.js';
import { PageSettings } from "../../config/page-settings";
import { Panel, PanelBody } from '../../components/panel/panel.jsx';
import { Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import './../../config/util';
import MemberDetailPopup from './../member-detail/member-detail-popup'
import StatusHistoryFragment from './status-history-fragment'
import ProfileHistoryFragment from './profile-history-fragment'
import Const from './../../config/const';
const queryString = require('query-string');

class MemberStatusHistory extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);
        this.state = {
        };

        this.toggle = this.toggle.bind(this);
        this.showMemberDetailPopup = this.showMemberDetailPopup.bind(this);
    }

    componentDidMount() {
    }

    toggle(tab) {
        this.props.history.push('?type=' + tab);
    }

    showMemberDetailPopup(uid) {
        this.setState({
            show_member_profile: true,
            selected_uid: uid
        });
    }

    render() {
        const searchQS = queryString.parse(this.props.location.search);
        const tab = searchQS.type || '';

        return (
            <div>
                <div className="row">
                    <div className="col-xs-12">
                        <Panel>
                            <Nav tabs className="nav-tabs-inverse">
                                <NavItem>
                                    <NavLink className={classnames({ active: tab === '' })} onClick={() => { this.toggle(''); }}>
                                        <span className="d-none d-xs-inline">{strings.total}</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ active: tab === Const.USER_PENDING_TYPE.PROFILE_IMAGE })} onClick={() => { this.toggle(Const.USER_PENDING_TYPE.PROFILE_IMAGE); }}>
                                        <span className="d-none d-xs-inline">{strings.profile} {strings.pending_log}</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ active: tab === Const.USER_PENDING_TYPE.INTRO })} onClick={() => { this.toggle(Const.USER_PENDING_TYPE.INTRO); }}>
                                        <span className="d-none d-xs-inline">{strings.intro} {strings.pending_log}</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ active: tab === Const.USER_PENDING_TYPE.SCHOOL })} onClick={() => { this.toggle(Const.USER_PENDING_TYPE.SCHOOL); }}>
                                        <span className="d-none d-xs-inline">{strings.school} {strings.pending_log}</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ active: tab === Const.USER_PENDING_TYPE.JOB })} onClick={() => { this.toggle(Const.USER_PENDING_TYPE.JOB); }}>
                                        <span className="d-none d-xs-inline">{strings.job} {strings.pending_log}</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ active: tab === Const.USER_PENDING_TYPE.MONEY })} onClick={() => { this.toggle(Const.USER_PENDING_TYPE.MONEY); }}>
                                        <span className="d-none d-xs-inline">{strings.cert_money} {strings.pending_log}</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ active: tab === Const.USER_PENDING_TYPE.STAR })} onClick={() => { this.toggle(Const.USER_PENDING_TYPE.STAR); }}>
                                        <span className="d-none d-xs-inline">{strings.cert_star} {strings.pending_log}</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ active: tab === 'profile_log' })} onClick={() => { this.toggle('profile_log'); }}>
                                        <span className="d-none d-xs-inline">{strings.profile_log}</span>
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <PanelBody>
                                <MemberDetailPopup isOpen={this.state.show_member_profile} uid={this.state.selected_uid} onClose={() => {
                                    this.setState({ show_member_profile: false });
                                }} />

                                {tab == '' && <StatusHistoryFragment type={tab} onClickRow={this.showMemberDetailPopup} />}
                                {tab == Const.USER_PENDING_TYPE.PROFILE_IMAGE && <StatusHistoryFragment type={tab} onClickRow={this.showMemberDetailPopup} />}
                                {tab == Const.USER_PENDING_TYPE.INTRO && <StatusHistoryFragment type={tab} onClickRow={this.showMemberDetailPopup} />}
                                {tab == Const.USER_PENDING_TYPE.SCHOOL && <StatusHistoryFragment type={tab} onClickRow={this.showMemberDetailPopup} />}
                                {tab == Const.USER_PENDING_TYPE.JOB && <StatusHistoryFragment type={tab} onClickRow={this.showMemberDetailPopup} />}
                                {tab == Const.USER_PENDING_TYPE.MONEY && <StatusHistoryFragment type={tab} onClickRow={this.showMemberDetailPopup} />}
                                {tab == Const.USER_PENDING_TYPE.STAR && <StatusHistoryFragment type={tab} onClickRow={this.showMemberDetailPopup} />}
                                {tab == 'profile_log' && <ProfileHistoryFragment onClickRow={this.showMemberDetailPopup} />}
                            </PanelBody>
                        </Panel>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(inject('rootStore')(MemberStatusHistory));