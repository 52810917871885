import React from 'react';
import strings from '../../lang/strings.js';
import { PageSettings } from "../../config/page-settings";
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import 'react-datetime/css/react-datetime.css';
import './../../config/util';
import U from './../../config/util';
import ImageSelect from '../../components/content/image-select.jsx';


class BoardAutoEditPopup extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
            item: {},
            image_list_urls: [],
        };

        this.toggleModal = this.toggleModal.bind(this);
        this.image_list_files = [];
    }

    componentDidMount() {
    }

    toggleModal(result) {
        if (this.props.onClose) {
            this.props.onClose(result);
        }
    }

    getData = () => {
        this.setState({
            item: this.props.data ?? {},
            image_list_urls: U.getThumbList(this.props.data?.image_urls ?? "")
        });
        this.image_list_urls = [];
    };

    uploadData = (image_files, callback) => {

        let formData = new FormData();
        image_files.forEach((entry, index) => {
            formData.append('file', entry, entry.name);
        });


        this.context.post(
            'upload/images/restaurant',
            formData,
            function (response) {
                callback(response.result_data.upload_path);
            },
            true
        );
    }

    saveData = () => {
        let self = this;

        if (this.image_list_files.length > 0) {
            this.uploadData(this.image_list_files, function (image_urls) {
                let response_index = 0;
                self.image_list_files.forEach((entry, index) => {
                    self.state.image_list_urls[index] = U.getThumbUrl(image_urls[response_index])
                    response_index = response_index + 1
                });

                self.image_list_files = [];

                self.saveData();
            });
            return;
        }

        const item = self.state.item;
        item.image_urls = self.state.image_list_urls.map(it => { return it.replace('_thumb.', '.') }).urlArray2String();

        if (!item.category) {
            self.context.addNotification('warning', strings.alert_title, strings.empty_category);
            return;
        }
        if (!item.title) {
            self.context.addNotification('warning', strings.alert_title, strings.empty_title);
            return;
        }
        if (!item.content) {
            self.context.addNotification('warning', strings.alert_title, strings.empty_content);
            return;
        }

        this.context.post('board/saveAutoItem',
            {
                auto_info: item
            }, function (response) {
                self.context.addNotification('success', strings.alert_title, strings.save_done);
                if (self.props.onSave) {
                    self.props.onSave();
                }
            }, true);
    };

    onDataChange = (e) => {
        let item = this.state.item;
        item[e.target.id] = e.target.value;

        this.setState({
            item: item
        });
    };

    onImageChange = (e, idx) => {
        let self = this;

        if (e.target.files.length < 1) {
            return;
        }

        let file = e.target.files[0];
        let reader = new FileReader();

        reader.onloadend = function () {
            self.state.image_list_urls[idx] = reader.result;
            self.image_list_files[idx] = file;
            self.setState({ image_list_urls: self.state.image_list_urls });
        };
        reader.readAsDataURL(file);
    };

    render() {
        let item = this.state.item;

        return (
            <div>
                <Modal isOpen={this.props.isOpen} toggle={this.toggleModal} onOpened={() => this.getData()}>
                    <ModalHeader className="popup-nickname" toggle={this.toggleModal}>{'자동글등록'}</ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <div className="col-xs-12">
                                <div className='row'>
                                    <label className='col-md-3 col-form-label'>{strings.gender} *</label>
                                    <div className="col-md-9">
                                        <select className='form-control'
                                            id='gender'
                                            onChange={(e) => this.onDataChange(e)}
                                            value={item.gender ?? ''}>
                                            <option value={''}>{'전체'}</option>
                                            <option value={'F'}>{'여자'}</option>
                                            <option value={'M'}>{'남자'}</option>
                                        </select>
                                    </div>
                                </div>
                                <div className='row'>
                                    <label className='col-md-3 col-form-label'>{strings.category} *</label>
                                    <div className="col-md-9">
                                        <select className='form-control'
                                            id='category'
                                            onChange={(e) => this.onDataChange(e)}
                                            value={item.category ?? ''}>
                                            <option value={''}>{''}</option>
                                            {strings.const.BOARD_CATEGORY.map((entry, index) => (
                                                <option key={index} value={entry}>{entry}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className='row'>
                                    <label className='col-md-3 col-form-label'>{strings.title} *</label>
                                    <div className="col-md-9">
                                        <input type='text' autoComplete='off' className='form-control' id='title'
                                            value={item.title ?? ''}
                                            onChange={(e) => this.onDataChange(e)} />
                                    </div>
                                </div>
                                <div className='row'>
                                    <label className='col-md-3 col-form-label'>{strings.content} *</label>
                                    <div className="col-md-9">
                                        <textarea
                                            id='content'
                                            className='form-control'
                                            rows={7}
                                            value={item.content ?? ''}
                                            onChange={(e) => this.onDataChange(e)} />
                                    </div>
                                </div>
                                <div className='row mt-1'>
                                    <label className='col-md-3 col-form-label'>{strings.image}</label>
                                    <div className="col-md-9">
                                        <div className='row mb-1'>
                                            {
                                                this.state.image_list_urls.map((image_url, idx) => (
                                                    <div className='col-xs-4 col-sm-3 col-md-4 col-xl-4 col-xxl-3' key={idx}>
                                                        <ImageSelect
                                                            height="70%"
                                                            image_url={image_url}
                                                            onSelect={(e) => this.onImageChange(e, idx)}
                                                            onDelete={() => {
                                                                this.state.image_list_urls.splice(idx, 1);
                                                                this.image_list_files.splice(idx, 1);
                                                                this.setState({ image_list_urls: this.state.image_list_urls });
                                                            }} />
                                                    </div>
                                                ))
                                            }

                                            <div className='col-xs-4 col-sm-3 col-md-4 col-xl-4 col-xxl-3'>
                                                <ImageSelect
                                                    height="70%"
                                                    onSelect={(e) => this.onImageChange(e, this.state.image_list_urls.length)}
                                                    onDelete={() => { }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-default" onClick={this.toggleModal}>{strings.close}</button>
                        <button className="btn btn-success" onClick={() => this.saveData()}>{strings.apply}</button>
                    </ModalFooter>
                </Modal>
            </div >
        )
    }
}

export default BoardAutoEditPopup;