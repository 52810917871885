import React from 'react';
import strings from '../../lang/strings.js';
import { PageSettings } from '../../config/page-settings.js';
import Container from './../../components/content/container';

import $ from 'jquery';

class ChangePassword extends React.Component {

    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(event) {
        event.preventDefault();
        let self = this;

        if ($('#new_pwd').val() !== $('#pwd_confirm').val()) {
            self.context.addNotification('warning', strings.alert_title, strings.msg_password_not_correct);
            return;
        }

        // 로그인 api 호출
        self.context.post(
            'manager/change_password',
            {
                cur_pwd: $('#cur_pwd').val(),
                new_pwd: $('#new_pwd').val(),
            },
            function (response) {
                // 성공에 따른 처리를 진행한다.
                self.context.addNotification('success', strings.alert_title, strings.msg_applied);
            },
            true
        );
    }

    render() {
        return (
            <div>
                <Container title={strings.change_change}>
                    <div className='col-md-4 table-bordered pr-0 pl-0'>
                        <form id='frmPwd' className='form-horizontal form-bordered' onSubmit={this.handleSubmit}>
                            <div className='row form-group'>
                                <label className='col-md-4 text-right col-form-label'>{strings.current_password}</label>
                                <div className='col-md-8'>
                                    <input type='password' className='form-control' id='cur_pwd' autoComplete='off' required />
                                </div>
                            </div>
                            <div className='row form-group'>
                                <label className='col-md-4 text-right col-form-label'>{strings.new_password}</label>
                                <div className='col-md-8'>
                                    <input type='password' className='form-control' id='new_pwd' autoComplete='off' required />
                                </div>
                            </div>
                            <div className='row form-group'>
                                <label className='col-md-4 text-right col-form-label'>{strings.confirm_password}</label>
                                <div className='col-md-8'>
                                    <input type='password' className='form-control' id='pwd_confirm' autoComplete='off' required />
                                </div>
                            </div>
                            <div className='row form-group mt-2 mb-2'>
                                <label className='col-md-4 text-right'>&nbsp;</label>
                                <button type='submit' className='btn btn-primary'>{strings.change}</button>
                            </div>
                        </form>
                    </div>
                </Container>
            </div>
        )
    }
}

export default ChangePassword;