import React from 'react';
import strings from '../../lang/strings.js';
import { withRouter } from 'react-router-dom';
import { inject } from 'mobx-react';
import $ from 'jquery';
import { PageSettings } from "../../config/page-settings";
import DataTable from './../../components/content/data-table.jsx';
import SearchInput from './../../components/content/search-input.js';
import SearchSelect from './../../components/content/search-select.js';
import './../../config/util';
import Const from './../../config/const';
import MemberDetailPopup from './../member-detail/member-detail-popup'
import MeetingUserPopup from './meeting-user-popup'
import MeetingDetailPopup from './meeting-detail-popup'
const queryString = require('query-string');

class MemberMeetingFragment extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        let searchQS = queryString.parse(this.props.location.search);

        this.state = {
            search_info: searchQS,
            user_id: 0,
            show_member_profile: false,

            meeting_id: 0,
            show_meeting_user_popup: false,
        };

        this.refreshTable = this.refreshTable.bind(this);
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.location.search != this.props.location.search) {
            let searchQS = queryString.parse(this.props.location.search);
            this.setState({ search_info: searchQS }, () => this.refreshTable());
        }
    }

    refreshTable = (refresh) => {
        this.tblMember.draw(refresh);
    };

    searchTable = () => {
        let searchQS = queryString.parse(this.props.location.search);
        Object.assign(searchQS, this.state.search_info)
        const new_query = queryString.stringify(searchQS);
        if (new_query == this.props.location.search.substr(1)) {
            this.refreshTable();
        } else {
            this.props.history.push('?' + new_query);
        }
    }

    showMemberDetailPopup(uid) {
        this.setState({
            show_member_profile: true,
            selected_uid: uid
        });
    }

    showMeetingCardPopup(item) {
        this.setState({
            show_meeting_user_popup: true,
            meeting_id: item.id
        });
    }

    showMeetingDetailPopup(id) {
        this.setState({
            show_meeting_detail_popup: true,
            meeting_id: id
        });
    }

    showMeetingDetailPage(id) {
        this.props.history.push(`/meeting/meeting/${id}`);
    }

    showRestaurantDetail(id) {
        this.props.history.push(`/meeting/restaurant/${id}`);
    }

    deleteMeeting(item) {
        let self = this;
        this.context.showConfirm(strings.confirm_delete_title, function (response) {
            if (response) {
                self.context.post('meeting/delete', { id: item.id }, function () {
                    self.context.addNotification('success', strings.alert_title, strings.confirm_delete_done);
                    self.refreshTable(false);
                }, true);
            }
        });
    }

    render() {
        let self = this;
        let columnNo = 0;
        const { search_info } = this.state;

        return (
            <div>
                <MemberDetailPopup isOpen={this.state.show_member_profile} uid={this.state.selected_uid} onClose={() => {
                    this.setState({ show_member_profile: false });
                }} />
                <MeetingUserPopup isOpen={this.state.show_meeting_user_popup} meeting_id={this.state.meeting_id} onClose={() => {
                    this.setState({ show_meeting_user_popup: false });
                }} />
                <MeetingDetailPopup isOpen={this.state.show_meeting_detail_popup} meeting_id={this.state.meeting_id} onClose={() => {
                    this.setState({ show_meeting_detail_popup: false });
                }} />
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='form-horizontal form-inline'>
                            <SearchSelect
                                data={[{ code: '', name: strings.select_meeting_type }].concat(strings.const.MEETING_TYPE)}
                                value={search_info.type || ''}
                                onChange={(value) => {
                                    this.setState({
                                        search_info: {
                                            ...search_info,
                                            type: value,
                                            start: 0,
                                        }
                                    }, this.searchTable);
                                }}
                            />

                            {/* 키워드검색 */}
                            <SearchSelect
                                data={strings.const.SEARCH_CATEGORY2.concat({ code: 'restaurant_title', name: strings.restaurant + strings.title })}
                                value={search_info.column || strings.const.SEARCH_CATEGORY2[0].code}
                                onChange={(value) => {
                                    this.setState({
                                        search_info: {
                                            ...search_info,
                                            column: value,
                                            start: 0,
                                        }
                                    }, this.searchTable);
                                }}
                            />
                            <SearchInput
                                value={search_info.keyword}
                                onChange={(value) => {
                                    this.setState({
                                        search_info: {
                                            ...search_info,
                                            keyword: value,
                                            column: search_info.column || strings.const.SEARCH_CATEGORY2[0].code,
                                            start: 0,
                                        }
                                    })
                                }}
                                handler={(value) => this.searchTable()}
                            />

                            <button type='button' className='btn btn-success btn-sm ml-md-2' onClick={() => this.searchTable()}>{strings.search}</button>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12 mt-3'>
                        <DataTable ref={(ref) => this.tblMember = ref}
                            customPaginate={true}
                            initialPage={search_info.start || 0}
                            onPageChange={(value) => {
                                this.setState({
                                    search_info: {
                                        ...search_info,
                                        start: value,
                                    }
                                }, this.searchTable);
                            }}
                            options={{
                                columnDefs: [
                                    {
                                        targets: [columnNo++], data: 'id', orderable: false,
                                        render: function (value) {
                                            return value;
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'restaurant_title', orderable: false,
                                        createdCell: function (td, value, item) {
                                            if (value) {
                                                return $(td)
                                                    .html(`<a href="javascript:void(0);" class="text-black">${value}</a>`)
                                                    .find('a')
                                                    .bind('click', () => {
                                                        self.showRestaurantDetail(item.restaurant_id);
                                                    });
                                            } else {
                                                return $(td).html(`-`);
                                            }
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'tag', orderable: false,
                                        render: function (value) {
                                            return value.replace(/#/g, ', ');
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'title', orderable: false,
                                        createdCell: function (td, value, item) {
                                            $(td).html('');
                                            $(td).append(`<a href="javascript:;">${value}</a>`)
                                            $(td).find('a')
                                                .on('click', function () {
                                                    self.showMeetingDetailPopup(item.id);
                                                });
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'image_url', orderable: false,
                                        createdCell: function (td, value, item) {
                                            return $(td).image_lightbox(value, () => {
                                                self.context.showGallery(value.split('#').map((it) => { return Const.BASE_URL + it }))
                                            });
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'type', orderable: false,
                                        render: function (value) {
                                            const item = strings.const.MEETING_TYPE.find(it => it.code == value);
                                            if (item) {
                                                return `<span class="${item.color}">${item.short}</span>`;
                                            } else {
                                                return value;
                                            }
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'create_date', orderable: false,
                                        render: function (value) {
                                            return value;
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'nickname', orderable: false,
                                        createdCell: function (td, value, item) {
                                            $(td).nickname_label(value, item.gender, () => {
                                                self.showMemberDetailPopup(item.uid);
                                            });
                                            if (item.alias) {
                                                $(td).append(`<br/>(${item.alias})`);
                                            }
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'user_count', orderable: false,
                                        render: function (value) {
                                            return value > 0 ? value : '-';
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'id', orderable: false,
                                        createdCell: function (td, value, item) {
                                            $(td).html('');
                                            $(td).append(`<button type='button' class='btn btn-primary btn-sm m-2'>${strings.detail}</button>`)
                                                .find('button:eq(0)')
                                                .on('click', function () {
                                                    self.showMeetingDetailPage(item.id);
                                                });
                                            $(td).append(`<button type='button' class='btn btn-danger btn-sm m-2'>${strings.delete}</button>`)
                                                .find('button:eq(1)')
                                                .on('click', function () {
                                                    self.deleteMeeting(item);
                                                });
                                            $(td).append(`<button type='button' class='btn btn-success btn-sm m-2'>${strings.card_list}</button>`)
                                                .find('button:eq(2)')
                                                .on('click', function () {
                                                    self.showMeetingCardPopup(item);
                                                });
                                        }
                                    }
                                ],
                                order: [[1, 'DESC']],
                                ajax: {
                                    url: 'meeting/getList',
                                    data: function (req) {
                                        req.columns = null;

                                        const searchQS = queryString.parse(self.props.location.search);
                                        req.start = searchQS.start || 0;
                                        req.length = searchQS.length || 20;
                                        req.status = self.props.status;
                                        req.type = searchQS.type;
                                        req.column = searchQS.column;
                                        req.keyword = searchQS.keyword;
                                    }
                                },
                                drawCallback: function () {
                                },
                                fnCreatedRow: function (row, data, dataInd) {
                                }
                            }}>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>{strings.restaurant}</th>
                                    <th>{strings.tag}</th>
                                    <th>{strings.title}</th>
                                    <th>{strings.image}</th>
                                    <th>{strings.type}</th>
                                    <th>{strings.create_date}</th>
                                    <th>{strings.creator}</th>
                                    <th>{strings.card}</th>
                                    <th>{strings.manage}</th>
                                </tr>
                            </thead>
                            <tbody />
                        </DataTable>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(inject('rootStore')(MemberMeetingFragment));