import React from 'react';
import strings from '../../lang/strings.js';
import { PageSettings } from "../../config/page-settings";
import { Panel, PanelBody, PanelHeader } from '../../components/panel/panel.jsx';
import { Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import $ from 'jquery';
import './../../config/util';
import U from './../../config/util';
import Const from './../../config/const';
import RestaurantDetailInfo from './restaurant-detail-info';
import RestaurantDetailLike from './restaurant-detail-like';
import RestaurantDetailMeeting from './restaurant-detail-meeting';

class RestaurantDetail extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.restaurant_id = Number(this.props.match.params.restaurantId);

        this.state = {
            activeTab: '1',
        }

        this.toggle = this.toggle.bind(this);
    }

    componentDidMount() {
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }
    }

    render() {
        return (
            <div className="row">
                <div className="col-xl-6">
                    <RestaurantDetailInfo restaurant_id={this.restaurant_id} onSave={() => {
                        if (this.restaurant_id < 1) {
                            this.props.history.goBack();
                        }
                    }} />
                </div>
                {
                    this.restaurant_id > 0 &&
                    <div className="col-xl-6">
                        <Panel>
                            <Nav tabs className="nav-tabs-inverse">
                                <NavItem>
                                    <NavLink className={classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.toggle('1'); }}>
                                        <span className="d-none d-xs-inline">{strings.meeting_list}</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ active: this.state.activeTab === '2' })} onClick={() => { this.toggle('2'); }}>
                                        <span className="d-none d-xs-inline">{strings.like}</span>
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <PanelBody>
                                {this.state.activeTab === "1" && (<RestaurantDetailMeeting restaurant_id={this.restaurant_id} />)}
                                {this.state.activeTab === "2" && (<RestaurantDetailLike restaurant_id={this.restaurant_id} />)}
                            </PanelBody>
                        </Panel>
                    </div>
                }
            </div>
        )
    }
}

export default RestaurantDetail;