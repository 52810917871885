import React from 'react';
import { PageSettings } from "../../config/page-settings";
import { withRouter } from 'react-router-dom';
import { inject } from 'mobx-react';
import { Panel, PanelBody, PanelHeader } from '../../components/panel/panel.jsx';
import DataTable from './../../components/content/data-table.jsx';
import $ from 'jquery';
import './../../config/util';
import Const from './../../config/const';
import DateTime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import strings from '../../lang/strings';
const moment = require('moment');

class StaMatching extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);
        this.state = {
            type: 0, // 일별
            date_from: moment().subtract(1, 'year').format('YYYY-MM-DD'),
            date_to: moment().format('YYYY-MM-DD'),
        };
    }

    componentDidMount() {
    }

    refreshTable = (refresh) => {
        this.tblMember.draw(refresh);
    };

    onChangeDate(id, value, format) {
        this.setState({
            [id]: typeof value === 'object' ? value.format(format) : value
        });
    }

    changeViewMode = (type) => {
        this.setState({
            type: type
        }, () => {
            this.refreshTable(true);
        });
    };

    render() {
        let self = this;
        let columnNo = 0;

        return (
            <div className='col-xl-7 pl-0 pr-0'>
                <Panel>
                    <PanelHeader noButton={true}>{strings.menu.sta_matching}</PanelHeader>
                    <PanelBody>
                        <div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div className='form-horizontal form-inline'>
                                        <div className="btn-group">
                                            <button
                                                className={'btn btn-sm ' + (this.state.type === 0 ? 'btn-success' : 'btn-white')}
                                                onClick={() => this.changeViewMode(0)}>{strings.daily}</button>
                                            <button
                                                className={'btn btn-sm ' + (this.state.type === 1 ? 'btn-success' : 'btn-white')}
                                                onClick={() => this.changeViewMode(1)}>{strings.monthly}</button>
                                        </div>
                                        <DateTime
                                            dateFormat={'YYYY-MM-DD'}
                                            timeFormat={false}
                                            inputProps={{ placeholder: strings.select_start_time }}
                                            defaultValue={this.state.date_from}
                                            closeOnSelect={true}
                                            className="ml-md-2"
                                            id="date_from"
                                            onChange={(e) => this.onChangeDate('date_from', e, 'YYYY-MM-DD')} />
                                        <DateTime
                                            dateFormat={'YYYY-MM-DD'}
                                            timeFormat={false}
                                            inputProps={{ placeholder: strings.select_end_time }}
                                            defaultValue={this.state.date_to}
                                            closeOnSelect={true}
                                            className="ml-md-1"
                                            id="date_to"
                                            onChange={(e) => this.onChangeDate('date_to', e, 'YYYY-MM-DD')} />
                                        <button type='button' className='btn btn-success btn-sm ml-md-2' onClick={this.refreshTable}>{strings.search}</button>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12 mt-3'>
                                    <DataTable ref={(ref) => this.tblMember = ref}
                                        options={{
                                            columnDefs: [
                                                {
                                                    targets: [columnNo++], data: 'date', orderable: false,
                                                    render: function (value) {
                                                        if (self.state.type === 1) {
                                                            return value;
                                                        } else {
                                                            const week_idx = moment(value).weekday();
                                                            const week_color = week_idx == 0 ? 'red' : week_idx == 6 ? 'blue' : '';
                                                            return value + ` <span style="color: ${week_color}">(${strings.const.WEEK_LIST[week_idx]})</span>`;
                                                        }
                                                    }
                                                },
                                                {
                                                    targets: [columnNo++], data: 'card_count', orderable: false,
                                                    render: function (value) {
                                                        return value.currencyFormat();
                                                    }
                                                },
                                                {
                                                    targets: [columnNo++], data: 'meeting_count', orderable: false,
                                                    render: function (value) {
                                                        return value.currencyFormat();
                                                    }
                                                },
                                                {
                                                    targets: [columnNo++], data: 'board_count', orderable: false,
                                                    render: function (value) {
                                                        return value.currencyFormat();
                                                    }
                                                },
                                                {
                                                    targets: [columnNo++], data: 'band_count', orderable: false,
                                                    render: function (value) {
                                                        return value.currencyFormat();
                                                    }
                                                },
                                                {
                                                    targets: [columnNo++], data: 'keyword_count', orderable: false,
                                                    render: function (value) {
                                                        return value.currencyFormat();
                                                    }
                                                },
                                                {
                                                    targets: [columnNo++], data: 'now_count', orderable: false,
                                                    render: function (value) {
                                                        return value.currencyFormat();
                                                    }
                                                },
                                                {
                                                    targets: [columnNo++], data: 'total_count', orderable: false,
                                                    render: function (value) {
                                                        return '<b>' + value.currencyFormat() + '</b>';
                                                    }
                                                },
                                            ],
                                            order: [[1, 'DESC']],
                                            ajax: {
                                                url: 'sta/matching',
                                                data: function (req) {
                                                    req.columns = null;
                                                    req.date_from = self.state.date_from;
                                                    req.date_to = self.state.date_to;
                                                    req.is_monthly = self.state.type;
                                                }
                                            },
                                            drawCallback: function () {
                                            },
                                            fnCreatedRow: function (row, data, dataInd) {
                                            }
                                        }}>
                                        <thead>
                                            <tr>
                                                <th width="20%">{strings.date}</th>
                                                <th>{strings.today_card}</th>
                                                <th>{strings.topslang}</th>
                                                <th>{strings.board}</th>
                                                <th>{strings.band}</th>
                                                <th>{strings.keyword}</th>
                                                <th>{strings.now}</th>
                                                <th>{strings.total}</th>
                                            </tr>
                                        </thead>
                                        <tbody />
                                    </DataTable>
                                </div>
                            </div>
                        </div>
                    </PanelBody>
                </Panel>
            </div>
        )
    }
}

export default withRouter(inject('rootStore')(StaMatching));