import React from 'react';


class SearchInput extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};

        this.className = 'form-control ' + (typeof this.props.className === 'undefined' ? '' : this.props.className);
        this.handleKeypress = this.handleKeypress.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    handleKeypress(e) {
        if (e.keyCode === 13) {
            this.props.handler(e.target.value);
        }
    };

    onChange(e) {
        if (this.props.onChange) {
            this.props.onChange(e.target.value);
        }
    }

    render() {
        return (
            <div className='form-horizontal form-inline'>
                <input
                    type={this.props.type || 'text'}
                    autoComplete='off'
                    className={this.className}
                    id={this.props.id}
                    value={this.props.value || ''}
                    placeholder={this.props.placeholder || ''}
                    onChange={this.onChange}
                    onKeyDown={this.handleKeypress}
                />
            </div>
        );
    }

}

export default SearchInput;