export default {
    menu: {
        login: "로그인",
        dashboard: "대시보드",
        member: "회원 관리",
        member_detail: "회원 상세",
        member_profile_pending: "프로필 심사",
        member_normal: "전체 회원",
        member_status: "탈퇴/불량/휴면 관리",
        member_pending_log: "심사 이력",
        member_message: "메시지 관리",
        member_matching: "매칭 관리",
        member_ranking: "순위 관리",
        member_invite: "초대 순위 관리",
        member_referrer: "가입 경로 관리",
        member_shop: "하트/포인트/OK 로그",
        member_score: "별점 로그",
        member_coupon: "스타벅스 이벤트",
        card: "오늘의 카드",
        card_today: "카드 목록",
        card_message: "메시지 목록",
        card_matching: "카드 매칭",
        wedding_place: "웨딩홀",
        wedding_dress: "웨딩드레스",
        wedding_loan: "신혼부부 대출",
        meeting: "탑슐랭 관리",
        meeting_restaurant: "맛집 관리",
        restaurant_detail: "맛집 상세",
        meeting_meeting: "미팅 관리",
        meeting_detail: "미팅 상세",
        meeting_matching: "미팅 매칭",
        restaurant_banner: "배너 관리",
        board: "탑뮤니티 관리",
        board_board: "탑뮤니티 목록",
        board_detail: "탑뮤니티 상세",
        board_comment: "댓글 목록",
        board_suspend: "이용정지 목록",
        board_matching: "탑뮤니티 매칭",
        board_auto: "자동글 목록",
        band: "탑미팅 관리",
        band_list: "탑미팅 목록",
        band_detail: "탑미팅 상세",
        band_matching: "탑미팅 매칭",
        keyword: "키워드 소개팅",
        keyword_keyword: "키워드 목록",
        keyword_detail: "키워드 상세",
        keyword_matching: "키워드 매칭",
        now: "지금 당장 만나",
        now_list: "당장 목록",
        now_matching: "당장 매칭",
        zone: "피플T존",
        location_list: "지역목록",
        coupon_list: "쿠폰목록",
        event_list: "이벤트목록",
        product: "상품 관리",
        product_list: "상품 목록",
        product_purchase_list: "상품 구매내역",
        product_category_list: "카테고리 목록",
        product_company_list: "회사 목록",
        product_company_list: "회사 목록",
        event: "제휴 이벤트",
        event_join: "이벤트 참여목록",
        iap: "결제 관리",
        iap_log: "결제 로그",
        iap_sta: "결제 통계",
        iap_ranking: "결제 순위",
        sta: "통계 관리",
        sta_login: "로그인 통계",
        sta_signup: "가입 통계",
        sta_hourly: "시간대별 접속자",
        sta_matching: "매칭 통계",
        setting: "서비스 관리",
        setting_setting: "설정 목록",
        setting_cron: "예약 목록",
        setting_bonus: "보너스 카드 보내기",
        setting_push: "푸시 메시지",
        setting_point: "포인트 관리",
        setting_term: "약관 관리",
        setting_popup: "팝업 관리",
        setting_popup_detail: "팝업 상세",
        setting_video: "후기 관리",
        setting_alias: "별명 관리",
        setting_notice: "공지사항/이벤트",
        setting_notice_detail: "공지사항 상세",
        setting_job: "직업 관리",
        setting_school: "학교 관리",
        manager: "관리자",
        manager_all: "관리자 목록",
        manager_change_password: "비밀번호 변경",
    },

    alert_title: "알림",
    empty_alias: "별명을 입력해주세요.",
    empty_title: "제목을 입력해주세요.",
    empty_content: "내용을 입력해주세요.",
    empty_tag: "태그를 입력해주세요.",
    empty_suspend_period: "정지기간을 입력해주세요.",
    empty_suspend_reason: "정지사유를 입력해주세요.",
    empty_main_image: "대표 이미지를 선택해주세요.",
    empty_category: "카테고리를 선택해주세요.",
    empty_location: "위치를 선택해주세요.",
    empty_image_list: "이미지목록을 선택해주세요.",
    empty_reject_reason: "반려사유를 입력해주세요.",
    empty_alias_location: "동네이름을 입력해주세요.",
    empty_alias_name: "배우이름을 입력해주세요.",
    empty_job_name: "직업을 입력해주세요.",
    empty_gender: "성별을 선택해주세요.",
    empty_group: "1~4까지의 그룹을 입력해주세요.",
    empty_nickname: "닉네임을 입력해주세요.",
    empty_video: "동영상을 선택해주세요.",
    empty_point_heart: "포인트/하트 값을 설정해주세요.",
    empty_date: "날짜를 설정해주세요.",
    empty_message: "메시지를 입력해주세요.",
    empty_card_count: "카드 장수를 설정해주세요.",

    msg_pending_item_exist: "승인대기중인 항목이 있습니다.",

    app_name: "탑피플",
    admin: "관리자",
    save_done: "성과적으로 저장되었습니다.",
    top_admin: "탑피플 관리자",
    login_user_id: "아이디",
    password: "비밀번호",
    login: "로그인",
    match_success: "매칭성공",
    ok_sent: "호감발송",
    user: "사용자",
    ok_date: "호감날짜",
    match_result: "매칭결과",
    match_date: "매칭날짜",
    comment_blind_title: "정말 블라인드 처리하시겠습니까?",
    comment_blind_done: "블라인드 처리되었습니다.",
    comment_delete_title: "정말 완전삭제하시겠습니까?\n댓글에 달린 모든 대댓글도 삭제됩니다",
    comment_delete_done: "삭제되었습니다.",
    deleted: "삭제됨",
    suspend: "이용정지",
    blind: "블라인드",
    delete: "삭제",
    auto: "자동",
    auto_register_done: "등록되었습니다.",
    comment_content: "댓글내용",
    creator: "작성자",
    report: "신고",
    manage: "관리",
    board: "탑뮤니티",
    board_detail: "탑뮤니티 상세",
    category: "카테고리",
    company: "회사",
    alias: "별명",
    title: "제목",
    content: "내용",
    price: "가격",
    discount: "할인",
    image: "이미지",
    main_image: "대표 이미지",
    create_date: "생성일",
    list: "목록",
    save: "저장",
    dislike: "싫어요",
    like: "좋아요",
    date: "날짜",
    close: "닫기",
    comment_list: "댓글목록",
    comment_add: "댓글추가",
    card: "카드",
    today_card: "오늘의 카드",
    board_blind_title: "정말 블라인드처리 하시겠습니까?\n블라인드처리를 하시면 게시글 내용만 삭제되고 댓글, 호감정보들은 모두 남아있게 됩니다.",
    board_delete_title: "정말 완전삭제하시겠습니까?\n삭제처리를 하시면 게시글내용과 함께 댓글, 호감정보들도 모두 삭제됩니다.",
    select_category: "카테고리 선택",
    detail: "상세",
    play: "PLAY",
    visit_count: "조회",
    purchase_count: "구입수",
    comment: "댓글",
    answer: "답글",
    answer_add: "답글추가",
    board_list: "탑뮤니티 목록",
    search: "검색",
    add: "추가",
    status: "상태",
    deleted_list: "삭제된 목록",
    address_search: "주소검색",
    profile: "프로필",
    profile_image: "프로필이미지",
    user_id: "사용자아이디",
    nickname: "닉네임",
    birth: "나이",
    address: "주소",
    location: "지역",
    gender: "성별",
    school_job: "학교|직장",
    reason: "사유",
    current_suspend_date: "현재 정지기간",
    new_suspend_date: "새 정지기간",
    select_period: "기간선택",
    day: "일",
    week: "주일",
    month: "개월",
    year: "년",
    suspend_reason: "정지사유",
    select_reason: "사유선택",
    apply: "적용",
    video_player: "동영상 플레이어",
    audio_player: "뮤직 플레이어",
    matched_member_count: "매칭된 회원수",
    today: "오늘",
    yesterday: "어제",
    this_week: "이번주",
    this_month: "이번달",
    this_year: "올해",
    total: "전체",
    couple: "커플",
    male: "남자",
    female: "여자",
    sales: "매출",
    topslang: "탑슐랭",
    band: "탑미팅",
    keyword: "키워드소개팅",
    now: "지금당장만나",
    signup_ratio: "가입성비",
    iap_chart_title: "최근 30일 매출통계",
    iap_chart_money: "결제금액",
    iap_chart_count: "결제건수",
    login_chart_title: "최근 7일 로그인통계",
    signup_chart_title: "최근 7일 가입통계",
    signup_count: "가입자수",
    status_sta_title: "프로필 심사 요청한 인원수",
    status_sta_01: "12시간 동안 요청한 남자 합격율",
    status_sta_02: "12시간 동안 요청한 여자 합격율",
    status_sta_03: "이번달 요청한 남자 합격율",
    status_sta_04: "이번달 요청한 여자 합격율",
    status_sta_05: "전체 남자 합격율",
    status_sta_06: "전체 여자 합격율",
    complete: "완료",
    refund: "환불",
    phone_number: "전화번호",
    purchase_date: "주문날짜",
    purchase_method: "결제수단",
    purchase_money: "결제금액",
    purchase_heart: "구매하트",
    purchase_status: "결제상태",
    iap_total_log: "전체내역",
    iap_normal_log: "정상내역",
    iap_refund_log: "환불내역",
    iap_sta_title: "결제 현황",
    iap_sta_01: "오늘 결제 금액",
    iap_sta_02: "어제 결제 금액",
    iap_sta_03: "이번주 결제 금액",
    iap_sta_04: "이번달 결제 금액",
    iap_sta_05: "오늘 환불된 금액",
    iap_sta_06: "어제 환불된 금액",
    iap_sta_07: "이번주 환불된 금액",
    iap_sta_08: "이번달 환불된 금액",
    won: "원",
    target: "상대",
    target_user: "상대방",
    matching_type: "매칭타입",
    matching_time: "매칭시간",
    dist_time: "분배시간",
    score_type: "별점타입",
    score_time: "별점시간",
    coupon_no: "쿠폰번호",
    coupon_status: "상태",
    coupon_create_date: "수락시간",
    coupon_no_input: "발송할 쿠폰번호 입력",
    card_matching: "카드 매칭",
    meeting_matching: "미팅 매칭",
    community_matching: "탑뮤니티 매칭",
    matching_sta_title: "매칭 결과값",
    matching_sta_01: "남자 매칭 순위 TOP 100",
    matching_sta_02: "여자 매칭 순위 TOP 100",
    matching_sta_03: "1시간동안 매칭되는 숫자",
    matching_sta_04: "오늘 매칭된 커플 숫자",
    matching_sta_05: "어제 매칭된 커플 숫자",
    matching_sta_06: "1주일동안 매칭된 커플 숫자",
    matching_sta_07: "1달동안 매칭된 커플 숫자",
    matching_sta_08: "1년동안 매칭된 커플 숫자",
    matching_sta_09: "전체 매칭된 커플 숫자",
    restaurant: "맛집",
    tag: "태그",
    type: "타입",
    card_list: "카드목록",
    select_meeting_type: "미팅타입 선택",
    confirm_delete_title: "정말 삭제하시겠습니까?",
    confirm_delete_done: "삭제되었습니다.",
    confirm_leave_done: "탈퇴되었습니다.",
    school: "학교",
    job: "직업",
    generation: "연령대",
    unknown: "모름",
    badge: "인증뱃지",
    iap_ranking_01: "학교별 매출순위 TOP 20",
    iap_ranking_02: "직업별 매출순위 TOP 50",
    iap_ranking_03: "OK순위 TOP 50",
    iap_ranking_04: "가입직업순위 TOP 50",
    iap_ranking_05: "연령대별 매출순위",
    iap_ranking_06: "성별 매출 비율",
    iap_ranking_07: "지역별 매출순위",
    sales_title: "결제통계",
    daily: "일별",
    monthly: "월별",
    select_start_time: "시작날짜 선택",
    select_end_time: "마감날짜 선택",
    sales_google: "구글마켓",
    sales_appstore: "앱스토어",
    sales_onestore: "원스토어",
    sales_total: "총매출",
    sales_real_income: "실수익",
    sales_real_income_hint: "구글 85% + 앱스토어 85% + 원스토어 80%",
    money: "금액",
    count: "건수",
    msg_password_not_correct: "비밀번호가 일치하지 않습니다.",
    msg_applied: "적용되었습니다",
    change_change: "비밀번호 변경",
    current_password: "현재 비밀번호",
    new_password: "새 비밀번호",
    confirm_password: "비밀번호 확인",
    change: "변경",
    manager_add: "관리자 추가",
    manager_edit: "관리자 편집",
    manager_level: "관리자 레벨",
    manager_privilege: "관리자 권한",
    login_date: "로그인날짜",
    ip: "아이피",
    update_date: "변경일",
    manager_list: "관리자목록",
    profile_open: "프로필확인",
    recv_score: "받은점수",
    meeting: "미팅",
    meeting_detail: "미팅 상세",
    meeting_type: "공개타입",
    score: "점수",
    review: "리뷰",
    recv_ok: "받은OK",
    recv_review: "받은리뷰",
    recv_date: "받은날짜",
    meeting_list: "미팅목록",
    deleted_meeting: "삭제된 미팅",
    restaurant_detail: "맛집 상세",
    restaurant_type: "추천설정",
    restaurant_location: "자주찾는 지역",
    meeting_count: "미팅수",
    invite_title: "초대 순위 관리",
    invite_count: "초대 인원수",
    recv_heart: "받은 하트수",
    first_signup_date: "최초 가입일",
    latest_login_date: "최근 로그인 시간",
    matching_count: "매칭횟수",
    msg_confirm_block: "정말 차단하시겠습니까?",
    top_ok_message: "탑OK 메시지",
    ok_message: "OK 메시지",
    msg_confirm_release: "정말 해제하시겠습니까?",
    msg_confirm_vip: "정말 VIP 설정하시겠습니까?",
    msg_confirm_limit: "정말 매칭을 제한하시겠습니까?",
    msg_confirm_leave: "정말 탈퇴시키시겠습니까?",
    msg_confirm_inspect_leave: "탈퇴한 회원입니다.\n탈퇴한 회원을 심사하면 정상/반려가 됩니다.\n그래도 하시겠습니까?",
    msg_confirm_inspect_block: "차단된 회원입니다.\n차단된 회원을 심사하면 정상/반려가 됩니다.\n그래도 하시겠습니까?",
    msg_confirm_best: "정말 BEST 설정하시겠습니까?",
    total_member: "전체회원",
    normal: "정상",
    pending: "인증대기",
    reject: "인증반려",
    sleep: "휴면",
    leave: "탈퇴",
    block: "차단",
    unblock: "차단해제",
    not_verified: "미인증",
    release: "해제",
    vip_setting: "VIP 설정",
    best_setting: "BEST 설정",
    inspect: "심사",
    matching_limit: "매칭제한",
    db_delete: "DB삭제",
    account_create_date: "계정생성일",
    app_version: "설치버전",
    matching: "매칭",
    female_member: "여자회원",
    male_member: "남자회원",
    signup_path: "가입경로",
    signup_date: "가입날짜",
    path_sta_title: "가입경로 데이터",
    retry_count: "재도전 횟수",
    profile_approve_result: "사진통과여부",
    reinspect: "재심사",
    profile_image_changer: "사진 변경자",
    ratio: "비율",
    ranking_01: "매칭 학교 순위 TOP 50",
    ranking_02: "매칭 직업 순위 TOP 50",
    ranking_03: "가입 학교 순위 TOP 50",
    ranking_04: "가입 직업 순위 TOP 50",
    ranking_05: "가입 연령대",
    ranking_06: "매칭 연령대",
    ranking_07: "가입지역",
    ranking_08: "지역별 매칭",
    log_date: "로그날짜",
    change_heart: "변동하트",
    after_change: "변동후",
    heart: "하트",
    point: "포인트",
    change_ok: "변동OK",
    change_point: "변동포인트",
    heart_log: "하트 로그",
    point_log: "포인트 로그",
    ok_log: "OK/탑OK 로그",
    cert_type: "인증종류",
    cert_content: "인증내용",
    cert_data: "인증자료",
    request_date: "요청시간",
    process_date: "처리시간",
    pending_log: "심사이력",
    intro: "자기소개",
    cert_money: "재력",
    cert_star: "스타",
    status_reason_sta_title: "탈퇴사유 데이터",
    member_block: "회원차단",
    direct_input: "직접입력",
    height: "키",
    reject_profile_image: "프로필 이미지 반려",
    reject_school: "학교인증 반려",
    reject_job: "직장인증 반려",
    reject_money: "재력인증 반려",
    reject_star: "스타/연예인인증 반려",
    reject_intro: "자기소개 반려",
    profile_pending: "프로필 심사",
    cert_type_email: "이메일 인증",
    cert_type_image: "사진인증",
    cert_type_text: "본문인증",
    confirm_save_title: "정말 저장하시겠습니까?",
    member_detail: "회원상세",
    member_detail_01: "상세프로필",
    member_detail_02: "서술형 상세 프로필",
    member_detail_03: "심사 로그",
    member_detail_04: "카드 로그 (받음)",
    member_detail_05: "OK 로그",
    member_detail_06: "결제 로그",
    member_detail_07: "하트 로그",
    member_detail_08: "포인트 로그",
    member_detail_09: "가입경로",
    member_detail_10: "재도전 로그",
    member_detail_11: "휴면/탈퇴 로그",
    member_detail_12: "리뷰내역(미팅)",
    member_detail_13: "동의내역",
    member_detail_14: "친구소개",
    member_detail_15: "차단된 전화번호",
    member_detail_16: "설치된 버전",
    member_detail_17: "매니저의 1:1 조언",
    member_detail_18: "카드 로그 (보냄)",
    member_detail_20: "인증뱃지",
    member_detail_21: "후보목록",
    member_detail_22: "메시지 로그",
    member_detail_23: "알림 목록",
    member_detail_101: "평균 평점",
    member_detail_102: "휴면 이력",
    member_detail_103: "탈퇴 이력",
    member_detail_104: "지금까지 결제 금액",
    member_detail_105: "최근 1달간 결제 금액",
    member_detail_106: "보유 하트수",
    member_detail_107: "보유 포인트",
    member_detail_108: "보유 OK권수",
    member_detail_109: "보유 탑OK권수",
    member_detail_110: "도착한 카드 갯수",
    member_detail_111: "최근 1달간 도착한 카드 갯수",
    member_detail_112: "문의 내역",
    member_detail_113: "미팅 쓴 리뷰",
    member_detail_114: "미팅 받은 리뷰",
    member_detail_115: "가입시 단말기",
    member_detail_116: "로그인시 단말기",
    member_detail_117: "설치된 버전",
    member_detail_118: "1회당 평균 결제 금액",
    member_detail_119: "초대된 친구 숫자",
    member_detail_120: "탑피플에 가입된 전화번호",
    real_name: "실명",
    birthday: "생년월일",
    manager_select: "관리자",
    career: "커리어 소개",
    body_type: "신체조건",
    character: "성격",
    target_character: "선호하는 성격",
    religion: "종교",
    drinking: "음주유무",
    favor_all: "연상/연하 선호",
    favor_senior: "연상선호",
    favor_junior: "연하선호",
    block_schoolmate: "같은 대학교 피하기",
    block_jobmate: "같은 직종 피하기",
    interest: "관심사",
    hobby: "취미",
    i_have: "갖고 있는것",
    i_want: "갖고 싶은것",
    best_restaurant: "최애맛집",
    confirm_member_delete: "정말 삭제하시겠습니까?\n해당 회원의 모든 정보가 삭제됩니다.",
    member_info: "회원정보",
    badge_history: "인증내역",
    badge_history_delete: "정말 삭제하시겠습니까?\n재력인증과 스타인증이 모두 삭제됩니다.",
    current_member_status: "현재 회원상태",
    member_status_pending: "승인대기",
    member_status_reject: "반려됨",
    member_status_block: "차단됨",
    member_status_normal: "승인됨",
    member_status_leave: "탈퇴함",
    member_status_reinspect: "재심사요청",
    result: "결과",
    member_pending_status_01: "승인대기",
    member_pending_status_02: "반려예정",
    member_pending_status_03: "승인예정",
    member_pending_status_04: "오류",
    member_pending_status_05: "승인됨",
    member_pending_status_06: "미입력",
    member_approve: "승인",
    member_reject: "반려",
    member_cert_profile_image: "프로필 사진인증",
    member_cert_intro: "자기소개",
    member_cert_school: "학교인증",
    member_cert_job: "직장인증",
    member_cert_money: "재력인증",
    member_cert_star: "스타인증",
    invited_profile: "초대된 프로필",
    invited_nickname: "초대된 닉네임",
    ok_history_01: "보낸 OK",
    ok_history_02: "받은 OK",
    ok_history_03: "보낸 탑 OK",
    ok_history_04: "받은 탑 OK",
    ok_history_05: "보낸 미팅 OK",
    ok_history_06: "받은 미팅 OK",
    ok_history_07: "보낸 미팅 탑 OK",
    ok_history_08: "받은 미팅 탑 OK",
    ok_history_09: "지금까지 매칭된 횟수",
    ok_history_10: "최근 1달간 매칭된 횟수",
    sent: "보냄",
    received: "받음",
    retry_date: "재도전 날짜",
    reject_reason: "반려사유",
    sleep_count: "휴면 횟수",
    leave_count: "탈퇴 횟수",
    terms_01: "이용약관에 동의 : 동의함",
    terms_02: "개인정보보호정책에 동의 : 동의함",
    terms_03: "자동휴면정책에 동의 : 동의함",
    terms_04: "이용약관",
    terms_05: "개인정보취급방침",
    terms_06: "이용안내",
    version_01: "설치된 버전",
    version_02: "최신 업데이트 시간",
    version_03: "가입시 OS",
    version_04: "로그인시 OS",
    alias_location: "동네이름",
    alias_name: "배우이름",
    select_gender: "성별선택",
    select_group: "그룹선택",
    edit: "편집",
    notice_detail: "공지사항 상세",
    optinoal: "생략가능",
    show: "현시",
    hide: "숨김",
    just_show: "바로현시",
    just_go: "바로가기",
    notice_title: "공지사항/이벤트 관리",
    popup_title: "팝업 관리",
    popup_add: "팝업 추가",
    popup_detail: "팝업 상세",
    start_date: "시작날짜",
    end_date: "종료날짜",
    show_status: "현시상태",
    select_date: "날짜 선택",
    select_time: "시간 선택",
    setting_title: "설정목록",
    setting_value: "설정값",
    preview: "미리보기",
    review_edit: "후기편집",
    video: "동영상",
    video_review_list: "동영상 후기목록",
    send_reserve: "전송예약",
    reset: "초기화",
    reserve_date: "예약날짜",
    proceed_date: "실행날짜",
    send_result: "발송결과",
    send_condition: "발송조건",
    send_pending: "발송대기",
    send_completed: "발송완료",
    cron_title: "그룹발송 예약목록",
    confirm_cron_send: "정말 바로실행하시겠습니까?",
    bonus_title: "보너스 카드 보내기",
    option: "조건",
    device: "기기",
    push_message: "푸시 메시지",
    referrer_signup: "추천가입",
    login_sta: "로그인 통계",
    login_total: "누적 로그인",
    login_unique: "일일 로그인",
    signup_sta: "가입 통계",
    profile_log: "프로필 변경이력",
    sta_hourly: '시간대별 접속자',
    sta_hour_format: "{0}시",
    sta_hour_avg: "평균",
    have: "보유",
    badge_require_save: '변경내용은 적용을 해야 저장됩니다!',
    ugly_mode: '못난이',
    tag_placeholder: '반점으로 구분',
    keyword_title: '키워드 목록',
    keyword_detail: '키워드 상세',
    keyword_question_list: '질문 목록', 
    keyword_answer_list: '답변 목록', 
    keyword_card_list: '카드', 
    band_list: "탑미팅 목록",
    deleted_band: "삭제된 탑미팅",
    select_band_category: "카테고리 선택",
    select_band_type: "타입 선택",
    band_detail: "탑미팅 상세",

    const: {
        WEEK_LIST: ['일', '월', '화', '수', '목', '금', '토'],

        SEARCH_CATEGORY: {
            nickname: "닉네임",
            user_id: "사용자아이디",
            uid: "UID",
            phone_number: "전화번호",
            name: "이름",
            location: "지역",
        },

        SEARCH_CATEGORY2: [
            { code: 'nickname', name: '닉네임' },
            { code: 'user_id', name: '사용자아이디' },
            { code: 'uid', name: 'UID' },
            { code: 'phone_number', name: '전화번호' },
            { code: 'name', name: '이름' },
            { code: 'location', name: '지역' },
        ],

        SEARCH_RESTAURANT: [
            { code: 'title', name: '맛집제목' },
            { code: 'address', name: '맛집위치' },
        ],

        SEARCH_PRODUCT_CATEGORY: [
            { code: 'title', name: '제목' },
        ],

        SEARCH_PRODUCT: [
            { code: 'title', name: '제목' },
            { code: 'content', name: '내용' },
        ],

        SEARCH_GENDER: {
            M: "남자",
            F: "여자",
        },

        SEARCH_SCORE_TYPE: [
            '전체',
            '오늘카드',
            '탑슐랭',
            '탑뮤니티',
            '탑피플즈',
            '당장만나',
            '키워드소개팅',
            'T-존',
        ],

        SEARCH_LOCATION: [
            "서울",
            "경기",
            "인천",
            "강원도",
            "경상도",
            "부산",
            "전라도",
            "충청도",
            "제주도",
        ],

        SEARCH_RELIGION: [
            "무교",
            "기독교",
            "천주교",
            "불교",
            "원불교",
            "기타",
        ],

        BLOCK_REASON: [
            "음란사진 올림",
            "의심스러운 활동",
            "본인 명의 핸드폰으로 가입이 가능합니다.",
        ],

        OK_TYPE: {
            0: { code: 0, name: "", color: "text-danger" },
            1: { code: 1, name: "OK", color: "text-indigo" },
            2: { code: 2, name: "탑OK", color: "text-danger" },
            3: { code: 3, name: "슈퍼OK", color: "text-success" },
        },

        MONEY_LIST: {
            "20": { label: "20대 연소득 6천만원 이상", desc: "근로소득자의 경우, 원정징수영수증이나 연봉계약서 / 자영업자의 경우 소득금액증명원을 사진으로 첨부해주세요." },
            "30": { label: "30대 연소득 7천만원 이상", desc: "근로소득자의 경우, 원정징수영수증이나 연봉계약서 / 자영업자의 경우 소득금액증명원을 사진으로 첨부해주세요." },
            "wealthy": { label: "고액자산 보유\n개인자산 5억원 이상", desc: "부동산의 경우 소유권을 증명할 수 있는 부동산 등기서류 또는 매매계약서, 현금의 경우에는 시중 은행에서 발행한 잔고/잔액 증명서(최근 일자 서류), 그 외의 경우에는 자산을 인증할 수 있는 서류를 첨부해주세요." },
            "building": { label: "매매가 30억대 이상\n아파트 거주", desc: "이름, 사진, 아파트 주소가 나온 신분증 혹은 등본" },
            "car": { label: "1억원 이상 슈퍼카 보유", desc: "본인 성함이 기재된 보험계약서 또는 자동차등록증 또는 리스계약서" },
        },

        STAR_LIST: {
            "sports": { label: "올림픽 스타", desc: "수상한 내역이 나와있는 기사 첨부" },
            "movie": { label: "연예인", desc: "등록된 네이버 인물 화면 첨부" },
        },

        MEETING_TAG_LIST: [
            "셀프소개",
            "동네친구",
            "2:2/3:3/4:4",
            "드라이브",
            "급만남",
            "커피한잔",
            "맥주한잔",
            "일얘기",
            "주말에볼사람",
            "친구소개",
        ],

        CARD_TYPE: {
            0: "오늘카드",
            1: "맛매칭",
            2: "탑뮤니티",
        },

        MEETING_TYPE: [
            { code: 0, name: "오픈 프로필", short: "오픈", color: "text-black" },
            { code: 1, name: "프라이빗 프로필", short: "프라이빗", color: "text-danger" },
        ],

        RESTAURANT_TYPE: [
            { code: 0, name: "일반맛집", short: "일반", color: "text-black" },
            { code: 1, name: "추천맛집", short: "추천", color: "text-danger" },
        ],

        RESTAURANT_LOCATION: [
            "압구정청담", 
            "이태원한남",
            "부산",
            "강남역삼",
            "건대군자구의",
            "광화문종로",
            "대구",
            "성수동",
            "신사",
            "영등포여의도",
            "인천",
            "합정",
        ],

        RESTAURANT_CATEGORY: [
            "일식",
            "고기",
            "파인다이닝",
            "이자카야",
            "스키야끼",
            "한식",
            "레스토랑",
            "바(Bar)",
            "카페",
            "술집",
        ],

        BOARD_CATEGORY: [
            "연애",
            "자랑/인증",
            "뷰티",
            "재태크",
            "직장",
            "자유",
            "급만남",
            "셀소",
            "LIFE",
            "19금",
        ],

        KEYWORD_CATEGORY: [
            { code: '', name: "전체" },
            { code: 'best', name: "베스트" },
        ],

        NOTICE_CATEGORY: [
            "공지사항",
            "이벤트",
        ],

        USER_PROFILE_REJECT_REASON: [
            "사진 심사에서 떨어지셨습니다. 좀더 분위기 있는 사진을 업로드 해주세요.",
            "내부 사진 심사에서 떨어지셨습니다.",
            "사진 심사에서 떨어지셨습니다. 본인 얼굴이 나온 사진으로 업로드 해주세요.",
            "사진 심사에서 떨어지셨습니다. 얼굴이 잘 보이는 사진으로 업로드 해주세요.",
        ],

        USER_SCHOOL_REJECT_REASON: [
            "학교메일이 아닙니다.",
            "가입 가능한 학교에 해당하지 않습니다. 가입 조건에 해당하는 서류를 제출해주세요.",
            "서류가 잘 보이지 않습니다. 다시 업로드 해주세요.",
            "정확한 인증을 해주세요.",
        ],

        USER_JOB_REJECT_REASON: [
            "직장메일이 아닙니다.",
            "가입조건에 해당하지 않습니다. 가입 조건에 해당하는 서류를 제출해주세요.",
            "서류가 잘 보이지 않습니다. 다시 업로드 해주세요.",
            "공무원의 경우, 공무원증을 업로드해주세요.",
            "전문직(의사,변호사,회계사 등)의 경우, 자격증 또는 명함을 업로드 해주세요.",
            "정확한 인증을 해주세요.",
        ],

        USER_OTHER_REJECT_REASON: [
            "서류가 잘 보이지 않습니다. 다시 업로드 해주세요.",
            "가입조건에 해당하지 않습니다. 가입 조건에 해당하는 서류를 제출해주세요.",
            "정확한 인증을 해주세요.",
        ],

        USER_INTRO_REJECT_REASON: [
            "개인정보나 연락처, SNS등을 기재하실 없습니다.",
            "자기소개를 조금 더 성의있게 작성해주세요.",
            "광고성 표현을 사용하실수 없습니다.",
            "비속어, 등을 사용하실수 없습니다.",
            "가입 가능한 연령이 아닙니다.",
        ],

        USER_SUSPEND_REASON: [
            "탑뮤니티 비하행위는 이용정지 사유에 해당됩니다.",
            "과도한 노출이미지는 이용정지 사유에 해당됩니다.",
            "탑뮤니티 운영 정책에 해당하지 않습니다.",
            "신고 누적으로 탑뮤니티 이용이 정지됩니다.",
        ],

        MANAGER_LEVEL: {
            0: { name: "최고관리자", color: "text-black" },
            1: { name: "일반관리자", color: "text-danger" },
        },

        BAND_CATEGORY: [
            '2:2',
            '3:3',
            '친목모임',
            '스터디',
        ],

        BAND_TYPE: [
            {code: 'PUBLIC', name: '조각모임'},
            {code: 'PRIVATE', name: '지인모임'},
        ],

        BAND_TAG_LIST: [
            "실물사진",
            "금수저",
            "전문직",
            "대기업",
            "매너",
        ],

        BAND_LOCATION: [
            "서울",
            "경기",
            "충청",
            "경상",
            "전라",
            "강원",
            "제주",
        ],

        COUPON_STATUS: [
            '초대수락',
            '쿠폰발송',
            '쿠폰발송완료',
        ],

        PRODUCT_TYPE: [
            { code: 'best', name: "베스트" },
            { code: 'hot', name: "인기" },
        ],
    }
}