import React from 'react';
import strings from '../../lang/strings.js';
import $ from 'jquery';
import { PageSettings } from "../../config/page-settings";
import './../../config/util';
import { withRouter } from 'react-router-dom';
import { inject } from 'mobx-react';
import Const from './../../config/const';
import Container from '../../components/content/container';
import MemberChangeStatusPopup from './../member-detail/member-change-status-popup'
import MemberBlockPopup from './../member-detail/member-block-popup'
import MemberDetailPopup from './../member-detail/member-detail-popup'
import ReactTags from 'react-tag-autocomplete';
import U from './../../config/util';

/*
    탭에서 보여지는 사용자 프로필
*/
class MemberDetail extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
            uid: this.props.match.params.memberId,
            user_info: {},
            pending_info: [],
            more_info: {},
            cert_info: {},

            show_change_status_popup: false,
            show_block_popup: false,
            show_member_profile: false,
            status: 0,
            pending_item: {},
        };

        this.handleSchoolDelete = (i) => {
            const { user_info } = this.state;
            const school_admin_list = user_info.school_admin ? user_info.school_admin.split('#') : [];
            school_admin_list.splice(i, 1);
            user_info.school_admin = school_admin_list.join('#');
            this.setState({ user_info })
        }

        this.handleSchoolAddition = (item) => {
            const { user_info } = this.state;
            const school_admin_list = user_info.school_admin ? user_info.school_admin.split('#') : [];
            user_info.school_admin = [].concat(school_admin_list, item.code).join('#');
            this.setState({ user_info })
        }

        this.handleJobDelete = (i) => {
            const { user_info } = this.state;
            const job_admin_list = user_info.job_admin ? user_info.job_admin.split('#') : [];
            job_admin_list.splice(i, 1);
            user_info.job_admin = job_admin_list.join('#');
            this.setState({ user_info })
        }

        this.handleJobAddition = (item) => {
            const { user_info } = this.state;
            const job_admin_list = user_info.job_admin ? user_info.job_admin.split('#') : [];
            user_info.job_admin = [].concat(job_admin_list, item.code).join('#');
            this.setState({ user_info })
        }

    }

    componentDidMount() {
        this.getUserInfo();
    }

    getUserInfo = () => {
        let self = this;

        this.context.post(
            'member/getPendingItem',
            {
                uid: this.state.uid
            },
            function (response) {
                const {
                    user_info,
                    pending_info,
                    more_info,
                    cert_info,
                } = response.result_data;

                self.setState({
                    user_info,
                    more_info,
                    pending_info,
                    cert_info,
                });
            },
            true);
    };

    showMemberDetailPopup(uid) {
        this.setState({
            show_member_profile: true,
            selected_uid: uid
        });
    }

    showRejectPopup(item) {
        this.setState({
            show_change_status_popup: true,
            selected_pending_item: item,
        });
    }

    showBlockPopup(item) {
        this.setState({
            show_block_popup: true,
        });
    }

    changeStatus = () => {
        let self = this;

        if (this.state.pending_info.filter(it => it.status == Const.USER_PENDING_STATUS.PENDING).length > 0) {
            self.context.addNotification('warning', strings.alert_title, strings.msg_pending_item_exist);
            return;
        }

        this.context.post(
            'member/changeStatus',
            {
                uid: this.state.uid,
                pending_info: this.state.pending_info
            },
            function (response) {
                self.context.addNotification('success', strings.alert_title, strings.msg_applied);
                self.setState({ show_change_status_popup: false })
                self.getUserInfo();
            },
            true);
    };

    block = (status_reason) => {
        let self = this;

        this.context.post(
            'member/block',
            {
                uid: this.state.uid,
                status_reason: status_reason,
            },
            function (response) {
                self.context.addNotification('success', strings.alert_title, strings.msg_applied);
                self.setState({ show_block_popup: false })
                self.getUserInfo();
            },
            true);
    };

    unblock = () => {
        let self = this;

        this.context.post(
            'member/unblock',
            {
                uid: this.state.uid,
            },
            function (response) {
                self.context.addNotification('success', strings.alert_title, strings.msg_applied);
                self.getUserInfo();
            },
            true);
    };

    changeInfo = () => {
        let self = this;

        this.context.post(
            'member/save',
            {
                user_info: self.state.user_info,
                path: 'detail',
            },
            function (response) {
                self.context.addNotification('success', strings.alert_title, strings.save_done);
                self.getUserInfo();
            },
            true);
    };

    leaveUser = () => {
        let self = this;

        this.context.post(
            'member/leave',
            {
                uid: this.state.uid
            },
            function (response) {
                self.context.addNotification('success', strings.alert_title, strings.confirm_leave_done);
                self.getUserInfo();
            },
            true);
    };

    deleteUser = () => {
        let self = this;
        this.context.post(
            'member/delete',
            {
                uid: this.state.uid,
            },
            function (response) {
                self.context.addNotification('success', strings.alert_title, strings.confirm_delete_done);
                self.props.history.goBack();
            },
            true);
    };

    onDataChange = (e) => {
        let user_info = this.state.user_info;
        user_info[e.target.id] = e.target.value;

        this.setState({ user_info });
    };

    render() {
        let { user_info, pending_info, more_info, cert_info } = this.state;

        let school_list = this.props.rootStore.getSchoolList();
        let job_list_all = this.props.rootStore.getJobList();
        let job_list = [];
        if (user_info.gender && user_info.gender == 'F') {
            job_list = job_list_all.filter(it => it.gender == 'F');
        } else {
            job_list = job_list_all.filter(it => it.gender == 'M');
        };

        return (
            <div>
                <MemberDetailPopup isOpen={this.state.show_member_profile} uid={this.state.selected_uid} onClose={() => {
                    this.setState({ show_member_profile: false });
                }} />

                <MemberChangeStatusPopup
                    isOpen={this.state.show_change_status_popup}
                    pending_item={this.state.selected_pending_item}
                    onClose={() => {
                        this.setState({ show_change_status_popup: false });
                    }}
                    onApply={(status_reason) => {
                        this.state.selected_pending_item.status = Const.USER_PENDING_STATUS.REJECT
                        this.state.selected_pending_item.status_reason = status_reason
                        this.setState({
                            show_change_status_popup: false,
                            pending_info: this.state.pending_info
                        });
                    }}
                />

                <MemberBlockPopup isOpen={this.state.show_block_popup} onClose={() => {
                    this.setState({ show_block_popup: false });
                }} onApply={(status_reason) => { this.block(status_reason) }} />

                <Container title={strings.profile_pending}>
                    <div className="row">
                        <div className="col-xl-3 col-md-10">
                            <div className='row'>
                                <label className='col-xl-4 col-form-label'>{strings.profile}</label>
                                <div className="col-xl-8">
                                    {
                                        U.getThumbList(user_info.profile_image).map((image, index) => (
                                            <a key={index} onClick={() => {
                                                this.context.showGallery(user_info.profile_image.split('#').map((it) => { return Const.BASE_URL + it }), index)
                                            }}>
                                                <img src={image} style={{ height: '50px', width: 'auto', margin: 2 }} />
                                            </a>
                                        ))
                                    }
                                </div>
                            </div>

                            <div className='row'>
                                <label className='col-xl-4 col-form-label'>{strings.user_id}</label>
                                <div className="col-xl-8">
                                    <input type='text' autoComplete='off' className='form-control' id="user_id"
                                        value={user_info.user_id || ''}
                                        onChange={(e) => this.onDataChange(e)} />
                                </div>
                            </div>
                            <div className='row'>
                                <label className='col-xl-4 col-form-label'>{strings.nickname}</label>
                                <div className="col-xl-8">
                                    <input type='text' autoComplete='off' className='form-control' id="nickname"
                                        value={user_info.nickname || ''}
                                        onChange={(e) => this.onDataChange(e)} />
                                </div>
                            </div>
                            <div className='row'>
                                <label className='col-xl-4 col-form-label'>{strings.phone_number}</label>
                                <div className="col-xl-8">
                                    <input type='text' autoComplete='off' className='form-control' id="phone_verified"
                                        value={user_info.phone_verified || ''}
                                        onChange={(e) => this.onDataChange(e)} />
                                </div>
                            </div>
                            <div className='row'>
                                <label className='col-xl-4 col-form-label'>{strings.real_name}</label>
                                <div className="col-xl-8">
                                    <input type='text' autoComplete='off' className='form-control' id="realname_verified"
                                        value={user_info.realname_verified || ''}
                                        onChange={(e) => this.onDataChange(e)} />
                                </div>
                            </div>
                            <div className='row'>
                                <label className='col-xl-4 col-form-label'>{strings.gender}</label>
                                <div className="col-xl-8">
                                    <select id='gender' className='form-control'
                                        onChange={(e) => this.onDataChange(e)}
                                        value={user_info.gender || ''}>
                                        {Object.keys(strings.const.SEARCH_GENDER).map((key, index) => (
                                            <option key={index} value={key}>{strings.const.SEARCH_GENDER[key]}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className='row'>
                                <label className='col-xl-4 col-form-label'>{strings.birthday}</label>
                                <div className="col-xl-8">
                                    <input type='text' autoComplete='off' className='form-control' id="birthday"
                                        value={user_info.birthday || ''}
                                        onChange={(e) => this.onDataChange(e)} />
                                </div>
                            </div>
                            <div className='row'>
                                <label className='col-xl-4 col-form-label'>{strings.height}</label>
                                <div className="col-xl-8">
                                    <input type='text' autoComplete='off' className='form-control' id="height"
                                        value={user_info.height || ''}
                                        onChange={(e) => this.onDataChange(e)} />
                                </div>
                            </div>
                            <div className='row'>
                                <label className='col-xl-4 col-form-label'>{strings.school}</label>
                                <div className="col-xl-8">
                                    <input type='text' autoComplete='off' className='form-control' id="school"
                                        value={user_info.school || ''}
                                        onChange={(e) => this.onDataChange(e)} />
                                </div>
                            </div>
                            <div className='row'>
                                <label className='col-xl-4 col-form-label'>{strings.school}({strings.manager_select})</label>
                                <div className="col-xl-8 mb-1" style={{ zIndex: 1003 }}>
                                    {/* <select id='school_admin' className='form-control'
                                        onChange={(e) => this.onDataChange(e)}
                                        value={user_info.school_admin || ''}>
                                        <option key={-1} value={''}>{'(선택안함)'}</option>
                                        {school_list.map((entry, index) => (
                                            <option key={index} value={entry.title}>{entry.title}</option>
                                        ))}
                                    </select> */}
                                    <ReactTags
                                        tags={
                                            user_info.school_admin ?
                                                user_info.school_admin.split('#').map((item, index) => {
                                                    const find_item = school_list.find(it => it.title == item);
                                                    return { id: index, name: find_item ? find_item.title : '' }
                                                })
                                                : []
                                        }
                                        suggestions={
                                            school_list.filter(it => {
                                                return user_info.school_admin && user_info.school_admin.split('#').indexOf(it.title) >= 0 ? false : true;
                                            }).map((item, index) => {
                                                return { id: index, name: item.title, code: item.title }
                                            })
                                        }
                                        handleDelete={this.handleSchoolDelete.bind(this)}
                                        handleAddition={this.handleSchoolAddition.bind(this)}
                                        placeholder={' '}
                                        minQueryLength={0}
                                        maxSuggestionsLength={100}
                                        autofocus={false}
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <label className='col-xl-4 col-form-label'>{strings.job}</label>
                                <div className="col-xl-8">
                                    <input type='text' autoComplete='off' className='form-control' id="job"
                                        value={user_info.job || ''}
                                        onChange={(e) => this.onDataChange(e)} />
                                </div>
                            </div>
                            <div className='row'>
                                <label className='col-xl-4 col-form-label'>{strings.job}({strings.manager_select})</label>
                                <div className="col-xl-8 mb-1" style={{ zIndex: 1002 }}>
                                    <ReactTags
                                        tags={
                                            user_info.job_admin ?
                                                user_info.job_admin.split('#').map((item, index) => {
                                                    const find_item = job_list.find(it => it.title == item);
                                                    return { id: index, name: find_item ? find_item.title : '' }
                                                })
                                                : []
                                        }
                                        suggestions={
                                            job_list.filter(it => {
                                                return user_info.job_admin && user_info.job_admin.split('#').indexOf(it.title) >= 0 ? false : true;
                                            }).map((item, index) => {
                                                return { id: index, name: item.title, code: item.title }
                                            })
                                        }
                                        handleDelete={this.handleJobDelete.bind(this)}
                                        handleAddition={this.handleJobAddition.bind(this)}
                                        placeholder={' '}
                                        minQueryLength={0}
                                        maxSuggestionsLength={100}
                                        autofocus={false}
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <label className='col-xl-4 col-form-label'>{strings.vip_setting}</label>
                                <div className="col-xl-8">
                                    <select id='vip' className='form-control'
                                        onChange={(e) => this.onDataChange(e)}
                                        value={user_info.vip || 'N'}>
                                        <option value={'N'}>{''}</option>
                                        <option value={'Y'}>{'VIP'}</option>
                                        <option value={'B'}>{strings.matching_limit}</option>
                                    </select>
                                </div>
                            </div>
                            <div className='row'>
                                <label className='col-xl-4'>{strings.intro}</label>
                                <label className='col-xl-8'>
                                    <textarea type='text' autoComplete='off' className='form-control m-b-2' id='introduction'
                                        value={user_info.introduction || ''}
                                        rows={20}
                                        onChange={(e) => this.onDataChange(e)} />
                                </label>
                            </div>
                            <div className='row'>
                                <label className='col-xl-4'>{strings.career}</label>
                                <label className='col-xl-8'>{user_info.career && user_info.career.split('#').join(', ')}</label>
                            </div>
                            <div className='row'>
                                <label className='col-xl-4'>{strings.location}</label>
                                <label className='col-xl-8'>{user_info.location || ''}</label>
                            </div>
                            <div className='row'>
                                <label className='col-xl-4'>{strings.body_type}</label>
                                <label className='col-xl-8'>{user_info.body_type || ''}</label>
                            </div>
                            <div className='row'>
                                <label className='col-xl-4'>{strings.character}</label>
                                <label className='col-xl-8'>{user_info.character && user_info.character.split('#').join(', ')}</label>
                            </div>
                            <div className='row'>
                                <label className='col-xl-4'>{strings.target_character}</label>
                                <label className='col-xl-8'>{user_info.target_character && user_info.target_character.split('#').join(', ')}</label>
                            </div>
                            <div className='row'>
                                <label className='col-xl-4'>{strings.religion}</label>
                                <label className='col-xl-8'>{user_info.religion || ''}</label>
                            </div>
                            <div className='row'>
                                <label className='col-xl-4'>{strings.drinking}</label>
                                <label className='col-xl-8'>{user_info.drinking || ''}</label>
                            </div>
                            <div className='row'>
                                <label className='col-xl-4'>{strings.favor_all}</label>
                                <label className='col-xl-8'>
                                    {user_info.favor_senior === 'Y' && strings.favor_senior}
                                    {user_info.favor_junior === 'Y' && strings.favor_junior}
                                </label>
                            </div>
                            <div className='row'>
                                <label className='col-xl-4'>{strings.interest}</label>
                                <label className='col-xl-8'>{user_info.interest && user_info.interest.split('#').join(', ')}</label>
                            </div>
                            <div className='row'>
                                <label className='col-xl-4'>{strings.hobby}</label>
                                <label className='col-xl-8'>{user_info.hobby && user_info.hobby.split('#').join(', ')}</label>
                            </div>
                            <div className='row'>
                                <label className='col-xl-4'>{strings.i_have}</label>
                                <label className='col-xl-8'>{user_info.i_have && user_info.i_have.split('#').join(', ')}</label>
                            </div>
                            <div className='row'>
                                <label className='col-xl-4'>{strings.i_want}</label>
                                <label className='col-xl-8'>{user_info.i_want && user_info.i_want.split('#').join(', ')}</label>
                            </div>
                            <div className='row'>
                                <label className='col-xl-4'>{strings.best_restaurant}</label>
                                <label className='col-xl-8'>{user_info.restaurant && user_info.restaurant.split('#').join(', ')}</label>
                            </div>
                            <div className='row mt-3'>
                                <label className='col-xl-4'>{strings.account_create_date}</label>
                                <label className='col-xl-8'>{user_info.signup_date || ''}</label>
                            </div>
                            {
                                user_info.status == Const.USER_STATUS.SLEEP &&
                                <div className='row'>
                                    <label className='col-xl-4'>{strings.sleep}{strings.date}</label>
                                    <label className='col-xl-8'>{user_info.status_date || ''}</label>
                                </div>
                            }
                            <div className='row'>
                                <label className='col-xl-4'>{strings.latest_login_date}</label>
                                <label className='col-xl-8'>{user_info.login_date || ''}</label>
                            </div>
                            <div className='row'>
                                <label className='col-xl-4'>{strings.ip}</label>
                                <label className='col-xl-8'>{user_info.login_ip || ''}</label>
                            </div>
                        </div>
                        <div className="col-xl-1 col-md-1">
                            <button type="button" className="btn btn-primary m-b-5" style={{ width: '100%' }} onClick={() => {
                                const self = this;
                                if (user_info.status == Const.USER_STATUS.LEAVE) {
                                    this.context.showConfirm(strings.msg_confirm_inspect_leave, function (response) {
                                        if (response) {
                                            self.changeStatus()
                                        }
                                    });
                                } else if (user_info.status == Const.USER_STATUS.BLOCK) {
                                    this.context.showConfirm(strings.msg_confirm_inspect_block, function (response) {
                                        if (response) {
                                            self.changeStatus()
                                        }
                                    });
                                } else {
                                    this.changeStatus()
                                }
                            }}>{strings.inspect}<br />{strings.apply}</button>
                            <button type="button" className="btn btn-inverse m-b-5" style={{ width: '100%' }} onClick={() => {
                                const self = this;
                                this.context.showConfirm(strings.msg_confirm_leave, function (response) {
                                    if (response) {
                                        self.leaveUser()
                                    }
                                });
                            }}>{strings.leave}</button>
                            {/* <button type="button" className="btn btn-inverse m-b-5" style={{ width: '100%' }} onClick={() => {
                                this.showRejectPopup(Const.USER_STATUS.REJECT)
                            }}>탈락</button> */}
                            {/* <button type="button" className="btn btn-yellow m-b-5" style={{ width: '100%' }} onClick={() => {
                            this.showRejectPopup(Const.USER_STATUS.BLOCK)
                        }}>경고</button> */}
                            <button type="button" className="btn btn-danger m-b-5" style={{ width: '100%' }} onClick={() => {
                                if (user_info.status !== Const.USER_STATUS.BLOCK) {
                                    this.showBlockPopup()
                                } else {
                                    const self = this;
                                    this.context.showConfirm(strings.msg_confirm_release, function (response) {
                                        if (response) {
                                            self.unblock(user_info.id);
                                        }
                                    });
                                }
                            }}>{user_info.status !== Const.USER_STATUS.BLOCK ? strings.block : strings.unblock}</button>
                            <button type="button" className="btn btn-warning m-b-5" style={{ width: '100%' }} onClick={() => {
                                const self = this;
                                this.context.showConfirm(strings.confirm_member_delete, function (response) {
                                    if (response) {
                                        self.deleteUser(user_info.id);
                                    }
                                });
                            }}>{strings.delete}</button>
                            <button type="button" className="btn btn-info m-b-5" style={{ width: '100%' }} onClick={() => {
                                this.showMemberDetailPopup(user_info.id);
                            }}>{strings.detail}<br />보기</button>
                            <button type="button" className="btn btn-inverse m-b-5" style={{ width: '100%' }} onClick={() => {
                                const self = this;
                                this.context.showConfirm(strings.confirm_save_title, function (response) {
                                    if (response) {
                                        self.changeInfo()
                                    }
                                });
                            }}>{strings.member_info} <br />{strings.save}</button>
                        </div>
                        <div className="col-xl-5 col-md-6">
                            <div className='row'>
                                <label>{strings.current_member_status}: </label>
                                <h4 className="ml-2">
                                    {
                                        user_info.status == Const.USER_STATUS.PENDING ? <span style={{ color: 'red' }}>{strings.member_status_pending}</span>
                                            : user_info.status == Const.USER_STATUS.REJECT ? <span style={{ color: 'black' }}>{strings.member_status_reject}</span>
                                                : user_info.status == Const.USER_STATUS.BLOCK ? <span style={{ color: 'gray' }}>{strings.member_status_block} ({user_info.status_reason})</span>
                                                    : (user_info.status == Const.USER_STATUS.NORMAL || user_info.status == Const.USER_STATUS.SLEEP) ? <span style={{ color: 'blue' }}>{strings.member_status_normal}</span>
                                                        : user_info.status == Const.USER_STATUS.LEAVE ? <span style={{ color: 'blue' }}>{strings.member_status_leave}</span>
                                                            : " "
                                    }
                                    {
                                        user_info.status != Const.USER_STATUS.PENDING && pending_info.length > 0 && <span style={{ color: 'red' }}> ({strings.member_status_reinspect})</span>
                                    }
                                </h4>
                            </div>
                            <div className='row'>
                                <table className="table table-bordered table-td-valign-middle table-th-valign-middle">
                                    <thead>
                                        <tr>
                                            <th width='10%'>{strings.cert_content}</th>
                                            <th width='60%'>{strings.cert_data}</th>
                                            <th width='10%'>{strings.result}</th>
                                            <th width='10%'>{strings.manage}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            pending_info.map((item, index) => {
                                                const badge_data = this.props.rootStore.getBadge();
                                                const badge_group = badge_data.find(it => it.code == item.category);
                                                const badge_item = badge_group && badge_group.badges.find(it => it.code == item.code)
                                                return (
                                                    <tr key={index}>
                                                        <td>
                                                            {item.category == 'profile_image' && strings.member_cert_profile_image}
                                                            {item.category == 'intro' && strings.member_cert_intro}
                                                            {badge_item && badge_item.title}{item.title && '(' + item.title + ')'}
                                                        </td>
                                                        <td>
                                                            {
                                                                item.cert_type == Const.USER_PENDING_CATEGORY.IMAGE &&
                                                                item.cert_value.split('#').map((image, index) => (
                                                                    <a key={index} onClick={() => {
                                                                        this.context.showGallery(item.cert_value.split('#').map((it) => { return Const.BASE_URL + it }), index)
                                                                    }}>
                                                                        <img src={Const.BASE_URL + image} style={{ height: '100px', width: 'auto', margin: 2 }} />
                                                                    </a>
                                                                ))
                                                            }
                                                            {
                                                                item.cert_type == Const.USER_PENDING_CATEGORY.EMAIL &&
                                                                <label className='col-xl-12'><b>{item.cert_value}</b></label>
                                                            }
                                                            {
                                                                item.cert_type == Const.USER_PENDING_CATEGORY.TEXT &&
                                                                <textarea type='text' autoComplete='off' className='form-control m-b-2' id='introduction'
                                                                    value={item.cert_value}
                                                                    rows={15}
                                                                    onChange={(e) => {
                                                                        item.cert_value = e.target.value;
                                                                        this.setState({ pending_info: this.state.pending_info });
                                                                    }} />
                                                            }
                                                        </td>
                                                        <td>{item.status == Const.USER_PENDING_STATUS.PENDING ? <span style={{ color: 'red' }}>{strings.member_pending_status_01}</span>
                                                            : item.status == Const.USER_PENDING_STATUS.REJECT || item.status == Const.USER_PENDING_STATUS.REJECT_RETRY ? <span style={{ color: 'purple' }}>{strings.member_pending_status_02}</span>
                                                                : item.status == Const.USER_PENDING_STATUS.NORMAL ? <span style={{ color: 'green' }}>{strings.member_pending_status_03}</span>
                                                                    : <span style={{ color: 'purple' }}>{strings.member_pending_status_04}</span>}</td>
                                                        <td>
                                                            <div>
                                                                <button type="button" className="btn btn-sm btn-primary" onClick={() => {
                                                                    item.status = Const.USER_PENDING_STATUS.NORMAL;
                                                                    this.setState({ pending_info: this.state.pending_info });
                                                                }}>{strings.member_approve}</button>
                                                                <button type="button" className="btn btn-sm btn-danger m-2" onClick={() => {
                                                                    this.showRejectPopup(item)
                                                                }}>{strings.member_reject}</button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-6 pl-2">
                            <div className='col-md-12 col-form-label'>
                                <label className='col-form-label'>{strings.badge}: </label>
                                {user_info.badge && user_info.badge.split('#').map((item, index) => {
                                    const badge_data = this.props.rootStore.getBadge();
                                    const badge_group = badge_data.find(group => group.badges.find(it => it.code == item));
                                    const badge_item = badge_group && badge_group.badges.find(it => it.code == item);
                                    if (!badge_item) {
                                        return null;
                                    }

                                    return (
                                        <img
                                            key={index}
                                            title={badge_item.title}
                                            alt={badge_item.title}
                                            src={Const.BASE_URL + badge_item.icon_on}
                                            style={{ height: '24px', width: 'auto', margin: 2 }}
                                        />
                                    )
                                })}
                            </div>
                            <label className='col-md-12 col-form-label'>{strings.member_detail_101}: {user_info.score && (3 + ((5 - 3) * (user_info.score / 5)))} (상위 {more_info.score_percent && more_info.score_percent}%)</label>
                            <label className='col-md-12 col-form-label'>{strings.member_detail_102}: {more_info.sleep_count && more_info.sleep_count}번</label>
                            <label className='col-md-12 col-form-label'>{strings.member_detail_103}: {more_info.leave_count && more_info.leave_count}번</label>
                            <label className='col-md-12 col-form-label'>{strings.member_detail_104}: {more_info.iap_total && more_info.iap_total.currencyFormat()}원</label>
                            <label className='col-md-12 col-form-label'>{strings.member_detail_105}: {more_info.iap_month && more_info.iap_month.currencyFormat()}원</label>
                            <label className='col-md-12 col-form-label'>{strings.member_detail_106}: {user_info.heart && user_info.heart.currencyFormat()} 개</label>
                            <label className='col-md-12 col-form-label'>{strings.member_detail_107}: {user_info.point && user_info.point.currencyFormat()} 포인트</label>
                            <label className='col-md-12 col-form-label'>{strings.member_detail_108}: {user_info.ok && user_info.ok.currencyFormat()} 개</label>
                            <label className='col-md-12 col-form-label'>{strings.member_detail_109}: {user_info.top_ok && user_info.top_ok.currencyFormat()} 개</label>
                            <label className='col-md-12 col-form-label'>{strings.member_detail_110}: {more_info.matching_total && more_info.matching_total}개</label>
                            <label className='col-md-12 col-form-label'>{strings.member_detail_111}: {more_info.matching_month && more_info.matching_month}개</label>
                            <label className='col-md-12 col-form-label'>{strings.member_detail_112}: {more_info.qna_resolved && more_info.qna_resolved}건(답변완료)/{more_info.qna_new && more_info.qna_new}건(미답변)</label>
                            <label className='col-md-12 col-form-label'>{strings.member_detail_113}: {more_info.review_sent && more_info.review_sent}건</label>
                            <label className='col-md-12 col-form-label'>{strings.member_detail_114}: {more_info.review_recevied && more_info.review_recevied}건</label>
                            <label className='col-md-12 col-form-label'>{strings.member_detail_115}: {user_info.signup_os && user_info.signup_os}</label>
                            <label className='col-md-12 col-form-label'>{strings.member_detail_116}: {user_info.login_os && user_info.login_os}</label>
                            <label className='col-md-12 col-form-label'>{strings.member_detail_117}: {user_info.version_name && user_info.version_name} ({user_info.version_code && user_info.version_code})</label>
                        </div>
                    </div>
                </Container>
            </div>
        )
    }
}

export default withRouter(inject('rootStore')(MemberDetail));
// export default MemberDetail;