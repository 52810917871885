import React from 'react';
import strings from '../../lang/strings.js';
import { PageSettings } from "../../config/page-settings";
import DataTable from './../../components/content/data-table.jsx';
import './../../config/util';

class MemberIapHistory extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {
    }

    render() {
        let user_info = this.props.user_info;
        let more_info = this.props.more_info;
        let columnNo = 0;

        return (
            <div>
                <div className="row">
                    <div className="col-xs-6">
                        <div className='row'>
                            <label>{strings.member_detail_105}&nbsp;:&nbsp;</label>
                            <label>{more_info.iap_month_amount}</label>
                        </div>
                        <div className='row'>
                            <label>{strings.member_detail_104}&nbsp;:&nbsp;</label>
                            <label>{more_info.iap_total_amount}</label>
                        </div>
                    </div>
                    <div className="col-xs-6">
                        <div className='row'>
                            <label>{strings.iap_chart_count}&nbsp;:&nbsp;</label>
                            <label>{more_info.iap_total_count}</label>
                        </div>
                        <div className='row'>
                            <label>{strings.member_detail_118}&nbsp;:&nbsp;</label>
                            <label>{more_info.iap_total_count > 0 ? more_info.iap_total_amount / more_info.iap_total_count : 0}</label>
                        </div>
                    </div>
                </div>
                <div className="row mt-2">
                    <DataTable ref={(ref) => this.tblMember = ref}
                        options={{
                            columnDefs: [
                                {
                                    targets: [columnNo++], data: 'create_date', orderable: false,
                                    render: function (value) {
                                        return value;
                                    }
                                },
                                {
                                    targets: [columnNo++], data: 'path', orderable: false,
                                    render: function (value) {
                                        return value;
                                    }
                                },
                                {
                                    targets: [columnNo++], data: 'price', orderable: false,
                                    render: function (value) {
                                        return value + strings.won
                                    }
                                },
                                {
                                    targets: [columnNo++], data: 'point', orderable: false,
                                    render: function (value) {
                                        return value + strings.heart;
                                    }
                                },
                            ],
                            iDisplayLength: 10,
                            order: [[1, 'DESC']],
                            ajax: {
                                url: 'member/getIapHistory',
                                data: function (req) {
                                    req.columns = null;
                                    req.uid = user_info.id;
                                }
                            },
                            drawCallback: function () {
                            },
                            fnCreatedRow: function (row, data, dataInd) {
                            }
                        }}>
                        <thead>
                            <tr>
                                <th>{strings.purchase_date}</th>
                                <th>{strings.purchase_method}</th>
                                <th>{strings.purchase_money}</th>
                                <th>{strings.purchase_heart}</th>
                            </tr>
                        </thead>
                        <tbody />
                    </DataTable>
                </div>
            </div >
        )
    }
}

export default MemberIapHistory;