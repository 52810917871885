import React from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import IapStaFragment from './iap-sta-fragment';
import SignupStaFragment from './signup-sta-fragment';
import GenderStaFragment from './gender-sta-fragment';
import IapChartFragment from './iap-chart-fragment';
import SignupChartFragment from './signup-chart-fragment';
import LoginChartFragment from './login-chart-fragment';
import StatusStaFragment from './status-sta-fragment';
import CardStaFragment from './card-sta-fragment';
import AuStaFragment from './au-sta-fragment'

class Dashboard extends React.Component {

    render() {
        if (!this.props.rootStore.isOwner()) {
            return null;
        }
        
        return (
            <div>
                <div className="row">
                    <div className="col-xl-3 col-md-5">
                        <IapStaFragment />
                    </div>
                    <div className="col-xl-4 col-md-7">
                        <SignupStaFragment />
                    </div>
                    <div className="col-xl-3 col-md-6">
                        <GenderStaFragment />
                    </div>
                    <div className="col-xl-2 col-md-6">
                        <AuStaFragment />
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-8">
                        <IapChartFragment />
                    </div>
                    <div className="col-xl-4">
                        <div className="row">
                            <div className="col-xl-12">
                                <SignupChartFragment />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-12">
                                <LoginChartFragment />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-5 col-md-7">
                        <StatusStaFragment />
                    </div>
                    <div className="col-xl-3 col-md-5">
                        <CardStaFragment />
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(inject('rootStore')(observer(Dashboard)));