import React from 'react';
import { withRouter } from 'react-router-dom';
import { inject } from 'mobx-react';
import strings from '../../lang/strings.js';
import $ from 'jquery';
import { PageSettings } from "../../config/page-settings";
import { Panel, PanelBody, PanelHeader } from '../../components/panel/panel.jsx';
import DataTable from './../../components/content/data-table.jsx';
import './../../config/util';
import ZoneCouponPopup from './coupon-popup.js';
const queryString = require('query-string');

class ZoneCoupon extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        let searchQS = queryString.parse(this.props.location.search);

        this.state = {
            search_info: searchQS,
            show_coupon_popup: false,
            selected_coupon_id: null,
        };

        this.refreshTable = this.refreshTable.bind(this);
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.location.search != this.props.location.search) {
            let searchQS = queryString.parse(this.props.location.search);
            this.setState({ search_info: searchQS }, () => this.refreshTable());
        }
    }

    refreshTable = (refresh) => {
        this.tblMember.draw(refresh);
    };

    searchTable = () => {
        let searchQS = queryString.parse(this.props.location.search);
        Object.assign(searchQS, this.state.search_info)
        const new_query = queryString.stringify(searchQS);
        if (new_query == this.props.location.search.substr(1)) {
            this.refreshTable();
        } else {
            this.props.history.push('?' + new_query);
        }
    }

    showCouponDetailPopup(item) {
        this.setState({
            show_coupon_popup: true,
            selected_coupon: item
        });
    }

    deleteCoupon(id) {
        let self = this;
        this.context.showConfirm(strings.confirm_delete_title, function (response) {
            if (response) {
                self.context.post('zone/deleteCoupon', { id }, function () {
                    self.context.addNotification('success', strings.alert_title, strings.confirm_delete_done);
                    self.refreshTable(false);
                }, true);
            }
        });
    }

    render() {
        let self = this;
        let columnNo = 0;
        const { search_info } = this.state;

        return (
            <div>
                <Panel>
                    <PanelHeader noButton={true}>{strings.menu.coupon_list}</PanelHeader>
                    <PanelBody>
                        <ZoneCouponPopup isOpen={this.state.show_coupon_popup} coupon_item={this.state.selected_coupon} onClose={() => {
                            this.setState({ show_coupon_popup: false });
                        }} onSave={() => {
                            this.setState({ show_coupon_popup: false });
                            this.refreshTable(false);
                        }} />
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='form-horizontal form-inline'>
                                    <button type='button' className='btn btn-danger btn-sm ml-md-2' onClick={() => {
                                        self.showCouponDetailPopup(null);
                                    }}>{strings.add}</button>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-12'>
                                <DataTable ref={(ref) => this.tblMember = ref}
                                    customPaginate={true}
                                    initialPage={search_info.start || 0}
                                    onPageChange={(value) => {
                                        this.setState({
                                            search_info: {
                                                ...search_info,
                                                start: value,
                                            }
                                        }, this.searchTable);
                                    }}
                                    options={{
                                        columnDefs: [
                                            {
                                                targets: [columnNo++], data: 'id', orderable: false,
                                                render: function (value) {
                                                    return value;
                                                }
                                            },
                                            {
                                                targets: [columnNo++], data: 'type', orderable: false,
                                                render: function (value) {
                                                    return value;
                                                }
                                            },
                                            {
                                                targets: [columnNo++], data: 'title', orderable: false,
                                                render: function (value) {
                                                    return value;
                                                }
                                            },
                                            {
                                                targets: [columnNo++], data: 'code', orderable: false,
                                                render: function (value) {
                                                    return value;
                                                }
                                            },
                                            {
                                                targets: [columnNo++], data: 'create_date', orderable: false,
                                                render: function (value) {
                                                    return value;
                                                }
                                            },
                                            {
                                                targets: [columnNo++], data: 'rate', orderable: false,
                                                render: function (value) {
                                                    return value;
                                                }
                                            },
                                            {
                                                targets: [columnNo++], data: 'status', orderable: false,
                                                render: function (value) {
                                                    return value == 1 ? '사용됨' : '정상';
                                                }
                                            },
                                            {
                                                targets: [columnNo++], data: 'id', orderable: false,
                                                createdCell: function (td, value, item) {
                                                    $(td).html('');
                                                    $(td).append(`<button type='button' class='btn btn-success btn-sm m-2'>${strings.detail}</button>`)
                                                        .find('button')
                                                        .on('click', function () {
                                                            self.showCouponDetailPopup(item);
                                                        });
                                                    $(td).append(`<button type='button' class='btn btn-danger btn-sm m-2'>${strings.delete}</button>`)
                                                        .find('button:last')
                                                        .on('click', function () {
                                                            self.deleteCoupon(item.id);
                                                        });
                                                }
                                            }
                                        ],
                                        order: [[1, 'DESC']],
                                        ajax: {
                                            url: 'zone/getCouponList',
                                            data: function (req) {
                                                req.columns = null;

                                                const searchQS = queryString.parse(self.props.location.search);
                                                req.start = searchQS.start || 0;
                                                req.length = searchQS.length || 20;
                                            }
                                        },
                                        drawCallback: function () {
                                        },
                                        fnCreatedRow: function (row, data, dataInd) {
                                        }
                                    }}>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>{'타입'}</th>
                                            <th>{'제목'}</th>
                                            <th>{'바코드'}</th>
                                            <th>{'생성일'}</th>
                                            <th>{'출현빈도(%)'}</th>
                                            <th>{'상태'}</th>
                                            <th>{'관리'}</th>
                                        </tr>
                                    </thead>
                                    <tbody />
                                </DataTable>
                            </div>
                        </div>
                    </PanelBody>
                </Panel>
            </div>
        )
    }
}

export default withRouter(inject('rootStore')(ZoneCoupon));