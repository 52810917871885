import strings from '../lang/strings.js';

const Menu = [
    {
        path: '/dashboard', icon: 'fa fa-th-large', title: strings.menu.dashboard,
        middle_manage: false,
    },
    {
        path: '/member', icon: 'fa fa-user', title: strings.menu.member,
        children: [
            { path: '/member/profile_pending', title: strings.menu.member_profile_pending },
            { path: '/member/normal', title: strings.menu.member_normal },
            { path: '/member/status', title: strings.menu.member_status },
            { path: '/member/pending_log', title: strings.menu.member_pending_log },
            { path: '/member/ranking', title: strings.menu.member_ranking },
            { path: '/member/invite', title: strings.menu.member_invite },
            { path: '/member/referrer', title: strings.menu.member_referrer },
            { path: '/member/shop', title: strings.menu.member_shop },
            { path: '/member/score', title: strings.menu.member_score },
            { path: '/member/coupon', title: strings.menu.member_coupon },
        ],
        middle_manage: true,
    },
    {
        path: '/card', icon: 'fa fa-address-card', title: strings.menu.card,
        children: [
            { path: '/card/today', title: strings.menu.card_today },
            { path: '/card/message', title: strings.menu.card_message },
            { path: '/card/matching', title: strings.menu.card_matching },
            { path: '/wedding/place', title: strings.menu.wedding_place },
            { path: '/wedding/dress', title: strings.menu.wedding_dress },
            { path: '/wedding/loan', title: strings.menu.wedding_loan },
        ],
        middle_manage: true,
    },
    {
        path: '/meeting', icon: 'fa fa-coffee', title: strings.menu.meeting,
        children: [
            { path: '/meeting/restaurant', title: strings.menu.meeting_restaurant },
            { path: '/meeting/meeting', title: strings.menu.meeting_meeting },
            { path: '/meeting/matching', title: strings.menu.meeting_matching },
            { path: '/meeting/banner', title: strings.menu.restaurant_banner },
        ],
        middle_manage: true,
    },
    {
        path: '/board', icon: 'fa fa-image', title: strings.menu.board,
        children: [
            { path: '/board/board', title: strings.menu.board_board },
            { path: '/board/comment', title: strings.menu.board_comment },
            { path: '/board/suspend', title: strings.menu.board_suspend },
            { path: '/board/matching', title: strings.menu.board_matching },
            { path: '/board/auto', title: strings.menu.board_auto },
        ],
        middle_manage: true,
    },
    {
        path: '/band', icon: 'fa fa-users', title: strings.menu.band,
        children: [
            { path: '/band/band', title: strings.menu.band_list },
            { path: '/band/matching', title: strings.menu.band_matching },
        ],
        middle_manage: true,
    },
    {
        path: '/keyword', icon: 'fa fa-tag', title: strings.menu.keyword,
        children: [
            { path: '/keyword/keyword', title: strings.menu.keyword_keyword },
            { path: '/keyword/matching', title: strings.menu.keyword_matching },
        ],
        middle_manage: true,
    },
    {
        path: '/now', icon: 'fa fa-forward', title: strings.menu.now,
        children: [
            { path: '/now/now', title: strings.menu.now_list },
            { path: '/now/matching', title: strings.menu.now_matching },
        ],
        middle_manage: true,
    },
    {
        path: '/zone', icon: 'fa fa-map-marker-alt', title: strings.menu.zone,
        children: [
            { path: '/zone/location', title: strings.menu.location_list },
            { path: '/zone/coupon', title: strings.menu.coupon_list },
            { path: '/zone/event', title: strings.menu.event_list },
        ],
        middle_manage: true,
    },
    {
        path: '/product', icon: 'fa fa-gift', title: strings.menu.product,
        children: [
            { path: '/product/list', title: strings.menu.product_list },
            { path: '/product/purchase', title: strings.menu.product_purchase_list },
            { path: '/product/category', title: strings.menu.product_category_list },
            { path: '/product/company', title: strings.menu.product_company_list },
            { path: '/product/event', title: strings.menu.event },
            { path: '/product/join', title: strings.menu.event_join },
        ],
        middle_manage: true,
    },
    {
        path: '/iap', icon: 'fa fa-dollar-sign', title: strings.menu.iap,
        children: [
            { path: '/iap/log', title: strings.menu.iap_log },
            { path: '/iap/sta', title: strings.menu.iap_sta },
            { path: '/iap/ranking', title: strings.menu.iap_ranking },
        ],
        middle_manage: true,
    },
    {
        path: '/sta', icon: 'fa fa-chart-line', title: strings.menu.sta,
        children: [
            { path: '/sta/login', title: strings.menu.sta_login },
            { path: '/sta/signup', title: strings.menu.sta_signup },
            { path: '/sta/hourly', title: strings.menu.sta_hourly },
            { path: '/sta/matching', title: strings.menu.sta_matching },
        ],
        middle_manage: true,
    },
    {
        path: '/setting', icon: 'fa fa-cog', title: strings.menu.setting,
        children: [
            { path: '/setting/setting', title: strings.menu.setting_setting },
            { path: '/setting/cron', title: strings.menu.setting_cron },
            { path: '/setting/bonus', title: strings.menu.setting_bonus },
            { path: '/setting/push', title: strings.menu.setting_push },
            { path: '/setting/point', title: strings.menu.setting_point },
            { path: '/setting/term', title: strings.menu.setting_term },
            { path: '/setting/popup', title: strings.menu.setting_popup },
            { path: '/setting/video', title: strings.menu.setting_video },
            { path: '/setting/alias', title: strings.menu.setting_alias },
            { path: '/setting/notice', title: strings.menu.setting_notice },
            { path: '/setting/job', title: strings.menu.setting_job },
            { path: '/setting/school', title: strings.menu.setting_school },
        ],
        middle_manage: true,
    },
    {
        path: '/manager', icon: 'fa fa-user-secret', title: strings.menu.manager,
        children: [
            { path: '/manager/all', title: strings.menu.manager_all },
            { path: '/manager/change_password', title: strings.menu.manager_change_password },
        ],
        middle_manage: false,
    },
];

export default Menu;
