import React from 'react';
import {Card, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';


class CommonModal extends React.Component {

    static ALERT = 0;
    static CONFIRM = 1;

    constructor(props) {
        super(props);

        this.onClose = this.onClose.bind(this);
        this.getTitle = this.getTitle.bind(this);
    }

    onClose(result) {
        if (typeof result !== 'boolean') {
            result = false;
        }

        if (this.props.onClose) {
            this.props.onClose(result);
        }
    }

    getTitle() {
        if (this.props.title !== "") {
            return this.props.title;
        }

        let title = '';
        switch (this.props.type) {
            case CommonModal.CONFIRM:
                title = '확인';
                break;
            case CommonModal.ALERT:
                title = '알림';
                break;
            default:
                title = '알림';
        }

        return title;
    }

    render() {
        let props = this.props;

        return (
            <Modal isOpen={props.isOpen} toggle={this.onClose}>
                <ModalHeader className="popup-title" toggle={this.onClose}>{this.getTitle()}</ModalHeader>
                <ModalBody>
                    <div className="text-center m-t-20">
                        <div className="text-center">
                            {props.text.split('\n').map((line, i) => (
                                <h5 className="" key={i}>
                                    {line}
                                </h5>
                            ))}
                            {
                                props.subtext !== "" ?
                                    (
                                        <Card body outline color=""
                                              className="card-outline-secondary bg-white text-secondary text-center m-b-10">
                                            <blockquote className="card-blockquote mb-0">
                                                <p className="f-s-14 f-w-600">{props.subtext}</p>
                                            </blockquote>
                                        </Card>
                                    )
                                    :
                                    (
                                        ''
                                    )
                            }
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    {
                        props.buttons ?
                            (
                                <div className={"m-auto"}>
                                    {
                                        props.buttons.map((button, i) => (
                                            <button key={i} className="btn btn-primary m-l-10"
                                                    onClick={() => this.onClose(i)}>{button}</button>
                                        ))
                                    }
                                </div>
                            )
                            : props.type === CommonModal.ALERT ? (
                                <button className="m-auto btn btn-primary" onClick={() => this.onClose(false)}>확인</button>
                            ) : (
                                <div className={''}>
                                    <button className="btn btn-primary m-r-10" onClick={() => this.onClose(true)}>네
                                    </button>
                                    <button className="btn btn-default" onClick={() => this.onClose(false)}>아니요</button>
                                </div>
                            )
                    }
                </ModalFooter>
            </Modal>
        );
    }

}

export default CommonModal;