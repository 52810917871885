import React from 'react';
import { withRouter } from 'react-router-dom';
import { inject } from 'mobx-react';
import strings from '../../lang/strings.js';
import { PageSettings } from "../../config/page-settings.js";
import { Panel, PanelBody } from '../../components/panel/panel.jsx';
import { Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import '../../config/util.js';
import JobFragment from './job-fragment.js';
const queryString = require('query-string');

class Job extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
        };

        this.toggle = this.toggle.bind(this);
    }

    componentDidMount() {
    }

    toggle(gender) {
        const searchQS = {};
        if (gender) {
            searchQS.gender = gender;
        }
        this.props.history.push('?' + queryString.stringify(searchQS));
    }

    render() {
        const searchQS = queryString.parse(this.props.location.search);
        const gender = searchQS.gender || 'M';

        return (
            <div>
                <div className="row">
                    <div className="col-xs-12">
                        <Panel>
                            <Nav tabs className="nav-tabs-inverse">
                                <NavItem>
                                    <NavLink className={classnames({ active: gender === 'M' })} onClick={() => { this.toggle('M'); }}>
                                        <span className="d-none d-xs-inline">{strings.job} ({strings.male})</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ active: gender === 'F' })} onClick={() => { this.toggle('F'); }}>
                                        <span className="d-none d-xs-inline">{strings.job} ({strings.female})</span>
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <PanelBody>
                                {gender === 'M' && (<JobFragment gender={'M'} />)}
                                {gender === 'F' && (<JobFragment gender={'F'} />)}
                            </PanelBody>
                        </Panel>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(inject('rootStore')(Job));