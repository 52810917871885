import React from 'react';
import strings from '../../lang/strings.js';
import { PageSettings } from "../../config/page-settings";
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import classnames from 'classnames';
import $ from 'jquery';
import './../../config/util';
import U from './../../config/util';
import Const from './../../config/const';

class CommentCreatePopup extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
            comment: ''
        };

        this.toggleModal = this.toggleModal.bind(this);
    }

    componentDidMount() {
    }

    toggleModal(result) {
        if (this.props.onClose) {
            this.props.onClose(result);
        }
    }

    saveData = () => {
        let self = this;

        this.context.post('board/postComment',
            {
                board_id: this.props.board_id,
                comment: this.state.comment,
                parent: this.props.parent,
            }, function (response) {
                self.context.addNotification('success', strings.alert_title, strings.save_done);
                if (self.props.onSave) {
                    self.props.onSave();
                }
            }, true);
    };

    render() {
        return (
            <div>
                <Modal isOpen={this.props.isOpen} toggle={this.toggleModal} size={'md'}>
                    <ModalHeader className="popup-title" toggle={this.toggleModal}>{this.props.parent === 0 ? strings.comment_add : strings.answer_add}</ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <div className="col-xs-12">
                                <div className='row'>
                                    <label className='col-md-3 col-form-label'>{this.props.parent === 0 ? strings.comment : strings.answer}</label>
                                    <div className="col-md-9">
                                        <textarea
                                            className='form-control'
                                            rows={7}
                                            value={this.state.comment}
                                            onChange={(e) => {
                                                this.setState({ comment: e.target.value });
                                            }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-default" onClick={this.toggleModal}>{strings.close}</button>
                        <button className="btn btn-success" onClick={() => this.saveData()}>{strings.apply}</button>
                    </ModalFooter>
                </Modal>
            </div >
        )
    }
}

export default CommentCreatePopup;