import React from 'react';
import strings from '../../lang/strings.js';
import { PageSettings } from "../../config/page-settings";
import Container from './../../components/content/container.js';
import './../../config/util';

class MemberPathSta extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
            path_sta: null
        }
    }

    componentDidMount() {
        this.getMatchingSta();
    }

    getMatchingSta = () => {
        let self = this;

        this.context.get(
            'member/getPathSta',
            {},
            function (response) {
                self.setState({
                    path_sta: response.result_data.path_sta
                });
            });
    };

    render() {
        let self = this;

        return (
            <div>
                <Container title={strings.path_sta_title} style={{ height: "130px" }}>
                    <div className="row">
                        <div className="col-xs-6">
                            {
                                self.state.path_sta && self.state.path_sta.map((entry, index) => index <= 5 && (
                                    <p className="p-0 m-0" key={index}>{index + 1}. {entry.path}: {entry.total_count.currencyFormat()} ({strings.male} {entry.male_count.currencyFormat()} / {strings.female} {entry.female_count.currencyFormat()})</p>
                                ))
                            }
                        </div>
                        <div className="col-xs-6">
                            {
                                self.state.path_sta && self.state.path_sta.map((entry, index) => index > 5 && (
                                    <p className="p-0 m-0" key={index}>{index + 1}. {entry.path}: {entry.total_count.currencyFormat()} ({strings.male} {entry.male_count.currencyFormat()} / {strings.female} {entry.female_count.currencyFormat()})</p>
                                ))
                            }
                        </div>
                    </div>
                </Container>
            </div>
        )
    }
}

export default MemberPathSta;