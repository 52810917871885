import React from 'react';
import strings from '../../lang/strings.js';
import $ from 'jquery';
import { PageSettings } from "../../config/page-settings";
import './../../config/util';
import DataTable from './../../components/content/data-table.jsx';
import MemberDetailPopup from './../member-detail/member-detail-popup'
import { string } from 'prop-types';

class BoardDetailLike extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
            show_member_profile: false,
            selected_uid: 0
        }

    }

    componentDidMount() {
    }

    showMemberDetailPopup(uid) {
        this.setState({
            show_member_profile: true,
            selected_uid: uid
        });
    }

    render() {
        let self = this;
        let columnNo = 0;

        return (
            <div>
                <MemberDetailPopup isOpen={this.state.show_member_profile} uid={this.state.selected_uid} onClose={() => {
                    this.setState({ show_member_profile: false });
                }} />
                <div className='row'>
                    <div className='col-md-12 mt-3'>
                        <DataTable ref={(ref) => this.tblMember = ref}
                            options={{
                                columnDefs: [
                                    {
                                        targets: [columnNo++], data: 'id', orderable: false,
                                        render: function (value) {
                                            return value;
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'nickname', orderable: false,
                                        createdCell: function (td, value, item) {
                                            return $(td).nickname_label(value, item.gender, () => {
                                                self.showMemberDetailPopup(item.uid);
                                            });
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'type', orderable: false,
                                        render: function (value) {
                                            return value == 1 ? strings.dislike : strings.like
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'create_date', orderable: false,
                                        render: function (value) {
                                            return value
                                        }
                                    },
                                ],
                                order: [[1, 'DESC']],
                                ajax: {
                                    url: 'board/getLikeList',
                                    data: function (req) {
                                        req.columns = null;
                                        req.board_id = self.props.board_id;
                                    }
                                },
                                drawCallback: function () {
                                },
                                fnCreatedRow: function (row, data, dataInd) {
                                }
                            }}>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>{strings.user}</th>
                                    <th>{strings.like}/{strings.dislike}</th>
                                    <th>{strings.date}</th>
                                </tr>
                            </thead>
                            <tbody />
                        </DataTable>
                    </div>
                </div>
            </div>
        )
    }
}

export default BoardDetailLike;