import React from 'react';
import strings from '../../lang/strings.js';
import { PageSettings } from "../../config/page-settings";
import { withRouter } from 'react-router-dom';
import { inject } from 'mobx-react';
import { Panel, PanelBody, PanelHeader } from '../../components/panel/panel.jsx';
import $ from 'jquery';
import './../../config/util';
import Const from './../../config/const';

class Setting extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);
        this.state = {
            setting_list: [],
        };
    }

    componentDidMount() {
        this.getData();
    }

    getData() {
        let self = this;
        this.context.post(
            'setting/getList',
            {},
            function (response) {
                self.setState({
                    setting_list: response.result_data.setting_list
                });
            });
    }

    saveSetting(id, value) {
        let self = this;
        this.context.showConfirm(strings.confirm_save_title, function (response) {
            if (response) {
                self.context.post('setting/save', { id, value }, function () {
                    self.context.addNotification('success', strings.alert_title, strings.msg_applied);
                    self.getData();
                }, true);
            }
        });
    }

    render() {
        let self = this;
        let columnNo = 0;

        return (
            <div className='col-xl-8 col-lg-10 pl-0 pr-0'>
                <Panel>
                    <PanelHeader noButton={true}>{strings.setting_title}</PanelHeader>
                    <PanelBody>
                        <div>
                            <table className="table table-bordered table-td-valign-middle table-th-valign-middle">
                                <thead>
                                    <th>#</th>
                                    <th>{strings.setting_value}</th>
                                    <th>{strings.title}</th>
                                    <th>{strings.content}</th>
                                    <th>{strings.update_date}</th>
                                    <th>{strings.creator}</th>
                                    <th>{strings.manage}</th>
                                </thead>
                                <tbody>
                                    {this.state.setting_list.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{item.id}</td>
                                                <td>
                                                    <input type='number' autoComplete='off' className='form-control' id='title'
                                                        value={item.value || ''}
                                                        onChange={(e) => {
                                                            item.value = e.target.value;
                                                            this.setState({ setting_list: this.state.setting_list });
                                                        }} />
                                                </td>
                                                <td>{item.title}</td>
                                                <td>{item.content}</td>
                                                <td>{item.update_date}</td>
                                                <td>{item.admin}</td>
                                                <td>
                                                    <button className="btn btn-primary btn-sm m-l-5" onClick={() => this.saveSetting(item.id, item.value)}>{strings.save}</button>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </PanelBody>
                </Panel>
            </div>
        )
    }
}

export default withRouter(inject('rootStore')(Setting));