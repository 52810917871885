import React from 'react';
import strings from '../../lang/strings.js';
import { PageSettings } from "../../config/page-settings";
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import classnames from 'classnames';
import $ from 'jquery';
import 'react-datetime/css/react-datetime.css';
import './../../config/util';
import U from './../../config/util';
import Const from './../../config/const';


class AliasStarPopup extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
            item: {
                gender: this.props.gender
            },
        };

        this.toggleModal = this.toggleModal.bind(this);
    }

    componentDidMount() {
    }

    componentWillReceiveProps(newProps) {
        if (newProps.star_id != this.state.item.id) {
            this.state = {
                item: {
                    gender: newProps.gender
                },
            };
        }
    }

    toggleModal(result) {
        if (this.props.onClose) {
            this.props.onClose(result);
        }
    }

    getData = () => {
        if (!this.props.star_id) {
            this.setState({
                item: {
                    gender: this.props.gender
                },
            });
            return;
        }

        let self = this;
        this.context.post(
            'alias/getStarItem',
            {
                id: this.props.star_id
            },
            function (response) {
                let item = response.result_data.star_item;

                self.setState({
                    item,
                });
            });
    };

    saveData = () => {
        let self = this;

        const item = self.state.item;

        if (!item.name) {
            self.context.addNotification('warning', strings.alert_title, strings.empty_alias_name);
            return;
        }

        if (!item.gender) {
            self.context.addNotification('warning', strings.alert_title, strings.empty_gender);
            return;
        }

        this.context.post('alias/saveStar',
            {
                star_info: item
            }, function (response) {
                self.context.addNotification('success', strings.alert_title, strings.save_done);
                if (self.props.onSave) {
                    self.props.onSave();
                }
            }, true);
    };

    onDataChange = (e) => {
        let item = this.state.item;
        item[e.target.id] = e.target.value;

        this.setState({
            item: item
        });
    };

    render() {
        let item = this.state.item;

        return (
            <div>
                <Modal isOpen={this.props.isOpen} toggle={this.toggleModal} onOpened={() => this.getData()}>
                    <ModalHeader className="popup-nickname" toggle={this.toggleModal}>{strings.alias_name} {strings.edit}</ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <div className="col-xs-12">
                                <div className='row'>
                                    <label className='col-md-3 col-form-label'>{strings.alias_name} *</label>
                                    <div className="col-md-9">
                                        <input type='text' autoComplete='off' className='form-control' id='name'
                                            value={item.name || ''}
                                            onChange={(e) => this.onDataChange(e)} />
                                    </div>
                                </div>
                                <div className='row'>
                                    <label className='col-md-3 col-form-label'>{strings.gender}</label>
                                    <div className="col-md-9">
                                        <select className='form-control' id='gender'
                                            onChange={(e) => this.onDataChange(e)}
                                            value={item.gender}>
                                            <option value="">{strings.select_gender}</option>
                                            {Object.keys(strings.const.SEARCH_GENDER).map((key, index) => (
                                                <option key={index} value={key}>{strings.const.SEARCH_GENDER[key]}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-default" onClick={this.toggleModal}>{strings.close}</button>
                        <button className="btn btn-success" onClick={() => this.saveData()}>{strings.apply}</button>
                    </ModalFooter>
                </Modal>
            </div >
        )
    }
}

export default AliasStarPopup;