import React from 'react';
import strings from '../../lang/strings.js';
import { PageSettings } from "../../config/page-settings";
import Container from '../../components/content/container';
import { Link } from 'react-router-dom';
import './../../config/util';
import Const from './../../config/const';
import DateTime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';

class PopupDetail extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
            popup_id: this.props.match.params.popupId,
            popup_info: {},
            image_file: null,
            image_full_url: null
        }

        this.fileRef = [];
    }

    componentDidMount() {
        this.getData();
    }

    getData = () => {
        let self = this;

        if (this.state.popup_id === 'add') {
            return;
        }

        this.context.post(
            'popup/getItem',
            {
                popup_id: this.state.popup_id
            }, function (response) {
                let popup_info = response.result_data.popup_info;
                let image_full_url = Const.BASE_URL + popup_info.image_url;
                let image_file = null;

                self.setState({
                    popup_info,
                    image_full_url,
                    image_file,
                });
            });
    };

    uploadData = () => {

        let self = this;
        let image_file = this.state.image_file;
        let image_full_url = this.state.image_full_url;
        const popup_info = this.state.popup_info;

        if (!image_file) {
            popup_info.image_url = image_full_url.replace(Const.BASE_URL, '');
            this.saveData();
            return;
        }

        let formData = new FormData();
        formData.set('file', image_file);

        this.context.post(
            'upload/image/popup',
            formData,
            function (response) {
                self.state.image_file = null;

                popup_info.image_url = response.result_data.upload_path;
                self.saveData();
            },
            true
        );
    }

    saveData = () => {
        let self = this;

        this.context.post(
            'popup/save',
            {
                popup_info: this.state.popup_info
            }, function (response) {

                self.setState({
                    popup_info: self.state.popup_info,
                    image_full_url: Const.BASE_URL + self.state.popup_info.image_url,
                    image_file: [],
                });

                self.context.addNotification('success', strings.alert_title, strings.save_done);
            },
            true
        );
    }

    onDataChange = (e, prop) => {
        let popup_info = this.state.popup_info;
        popup_info[prop] = e.target.value;

        this.setState({
            popup_info
        });
    };

    onChangeDate(id, value, format) {
        let popup_info = this.state.popup_info;
        popup_info[id] = typeof value === 'object' ? value.format(format) : value;

        this.setState({
            popup_info
        });
    }

    onImageChange = (e) => {
        let self = this;

        if (e.target.files.length < 1) {
            return;
        }

        let file = e.target.files[0];
        let reader = new FileReader();

        reader.onloadend = function () {
            self.state.image_full_url = reader.result;
            self.state.image_file = file;

            self.setState({
                image_full_url: self.state.image_full_url,
                image_file: self.state.image_file
            });
        };
        reader.readAsDataURL(file);
    };

    render() {
        let self = this;
        let popup_info = this.state.popup_info;
        let image_full_url = this.state.image_full_url;

        return (
            <div>
                <div className="row">
                    <div className='col-xl-8 col-lg-10 col-md-12'>
                        <Container title={self.state.popup_id === 'add' ? strings.popup_add : strings.popup_detail}>
                            <div className='col-md-12 table-bordered pr-0 pl-0'>
                                <form id='frmMember' className='form-horizontal form-bordered'>
                                    <div className='row form-group'>
                                        <label className='col-md-2 text-right col-form-label'>{strings.title}</label>
                                        <div className='col-md-10'>
                                            <input className='form-control'
                                                value={popup_info.title}
                                                onChange={(e) => this.onDataChange(e, 'title')} />
                                        </div>
                                    </div>
                                    <div className='row form-group'>
                                        <label className='col-md-2 text-right col-form-label'>{strings.image}</label>
                                        <div className='col-md-10'>
                                            <img
                                                src={image_full_url ? image_full_url : ''}
                                                alt={''}
                                                style={{ height: '60px', width: 'auto' }} />
                                            <input type='file' accept='image/*' className='hide'
                                                onChange={(e) => this.onImageChange(e)}
                                                ref={(ref) => this.fileRef = ref} />
                                            <button type='button' className='btn btn-success btn-sm m-2'
                                                onClick={() => this.fileRef.click()}>Change</button>
                                        </div>
                                    </div>
                                    <div className='row form-group'>
                                        <label
                                            className='col-md-2 text-right col-form-label'>{strings.start_date}</label>
                                        <div className='col-md-10'>
                                            <DateTime dateFormat={'YYYY-MM-DD'} timeFormat={false} inputProps={{ placeholder: strings.select_date }}
                                                closeOnSelect={true} id="start_date" className='width-100'
                                                value={popup_info.start_date}
                                                onChange={(e) => { self.onChangeDate('start_date', e, 'YYYY-MM-DD') }}
                                            />
                                        </div>
                                    </div>
                                    <div className='row form-group'>
                                        <label
                                            className='col-md-2 text-right col-form-label'>{strings.end_date}</label>
                                        <div className='col-md-10'>
                                            <DateTime dateFormat={'YYYY-MM-DD'} timeFormat={false} inputProps={{ placeholder: strings.select_date }}
                                                closeOnSelect={true} id="end_date" className='width-100'
                                                value={popup_info.end_date}
                                                onChange={(e) => { self.onChangeDate('end_date', e, 'YYYY-MM-DD') }}
                                            />
                                        </div>
                                    </div>
                                    <div className='row form-group'>
                                        <label
                                            className='col-md-2 text-right col-form-label'>{strings.show}/{strings.hide}</label>
                                        <div className='col-md-10'>
                                            <select id='status' className='form-control width-100'
                                                value={popup_info.status ? popup_info.status : "N"} onChange={(e) => self.onDataChange(e, 'status')}>
                                                <option value={'Y'}>{strings.show}</option>
                                                <option value={'N'}>{strings.hide}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className='row form-group mt-10 mb-10'>
                                        <div className="d-flex w-100">
                                            <div>
                                                <Link to="/setting/popup">
                                                    <button type='button' className='btn btn-dark btn-sm m-2'>{strings.list}</button>
                                                </Link>
                                            </div>
                                            <div className="ml-auto">
                                                <button type='button' className='btn btn-success btn-sm m-2' onClick={() => { this.uploadData() }}>{strings.save}</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </Container>
                    </div>
                </div>
            </div>
        )
    }
}

export default PopupDetail;