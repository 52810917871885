import React from 'react';
import strings from '../../lang/strings.js';
import { PageSettings } from "../../config/page-settings";
import { Panel, PanelBody } from '../../components/panel/panel.jsx';
import { Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import './../../config/util';
import { withRouter } from 'react-router-dom';
import { inject } from 'mobx-react';
import MemberProfilePendingFragment from './profile-pending-fragment'
import MemberProfilePendingLog from './profile-pending-log'
import MemberProfilePendingPhoto from './profile-pending-photo'
const queryString = require('query-string');

class MemberProfilePending extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);
        this.state = {
        };

        this.toggle = this.toggle.bind(this);
        this.showMemberDetail = this.showMemberDetail.bind(this);
    }

    componentDidMount() {
    }

    toggle(tab) {
        this.props.history.push('?tab=' + tab);
    }

    showMemberDetail(uid) {
        this.props.history.push(`/member/detail/${uid}`);
    }

    render() {
        const searchQS = queryString.parse(this.props.location.search);
        const tab = searchQS.tab || '1';

        return (
            <div>
                <div className="row">
                    <div className="col-xs-12">
                        <Panel>
                            <Nav tabs className="nav-tabs-inverse">
                                <NavItem>
                                    <NavLink className={classnames({ active: tab === '1' })} onClick={() => { this.toggle('1'); }}>
                                        <span className="d-none d-xs-inline">{strings.total}</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ active: tab === '2' })} onClick={() => { this.toggle('2'); }}>
                                        <span className="d-none d-xs-inline">{strings.female}</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ active: tab === '3' })} onClick={() => { this.toggle('3'); }}>
                                        <span className="d-none d-xs-inline">{strings.male}</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ active: tab === '4' })} onClick={() => { this.toggle('4'); }}>
                                        <span className="d-none d-xs-inline">{strings.female} {strings.reinspect}</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ active: tab === '5' })} onClick={() => { this.toggle('5'); }}>
                                        <span className="d-none d-xs-inline">{strings.male} {strings.reinspect}</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ active: tab === '6' })} onClick={() => { this.toggle('6'); }}>
                                        <span className="d-none d-xs-inline">{strings.profile_image_changer}</span>
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <PanelBody>
                                {tab === "1" && (<MemberProfilePendingFragment onClickRow={this.showMemberDetail} />)}
                                {tab === "2" && (<MemberProfilePendingFragment gender='F' onClickRow={this.showMemberDetail} />)}
                                {tab === "3" && (<MemberProfilePendingFragment gender='M' onClickRow={this.showMemberDetail} />)}
                                {tab === "4" && (<MemberProfilePendingLog gender='F' onClickRow={this.showMemberDetail} />)}
                                {tab === "5" && (<MemberProfilePendingLog gender='M' onClickRow={this.showMemberDetail} />)}
                                {tab === "6" && (<MemberProfilePendingPhoto onClickRow={this.showMemberDetail} />)}
                            </PanelBody>
                        </Panel>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(inject('rootStore')(MemberProfilePending));