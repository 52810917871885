import React from 'react';
import strings from '../../lang/strings.js';
import $ from 'jquery';
import { PageSettings } from "../../config/page-settings";
import DataTable from './../../components/content/data-table.jsx';
import './../../config/util';
import Const from './../../config/const';
import U from './../../config/util';

class MemberCardHistory extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {
    }

    render() {
        let self = this;
        let user_info = this.props.user_info;
        let columnNo = 0;

        return (
            <div>
                <div className="row">
                    <label className="">{strings.birthday}: {U.birth2Age(user_info.birthday)}</label>
                    <label className="ml-1">, {strings.height}: {user_info.height}cm</label>
                    <label className="ml-1">, {strings.location}: {user_info.location}</label>
                    <label className="ml-1">, {strings.status}: {user_info.status}</label>
                    <label className="ml-1">, VIP: {user_info.vip}</label>
                    <label className="ml-1">, {user_info.favor_junior === 'Y' ? strings.favor_junior : ''}{user_info.favor_senior === 'Y' ? strings.favor_senior : ''}</label>
                </div>
                <div className="row">
                    <label className="">{strings.block_schoolmate}: {user_info.block_schoolmate}</label>
                    <label className="ml-1">, {strings.block_jobmate}: {user_info.block_jobmate}</label>
                </div>
                <div className="row mt-2">
                    <DataTable ref={(ref) => this.tblMember = ref}
                        options={{
                            columnDefs: [
                                {
                                    targets: [columnNo++], data: 'target_uid', orderable: false,
                                    render: function (value) {
                                        return value
                                    }
                                },
                                {
                                    targets: [columnNo++], data: 'profile_image', orderable: false,
                                    createdCell: function (td, value, item) {
                                        return $(td).image_lightbox(value, () => {
                                            self.context.showGallery(value.split('#').map((it) => { return Const.BASE_URL + it }))
                                        });
                                    }
                                },
                                {
                                    targets: [columnNo++], data: 'user_id', orderable: false,
                                    createdCell: function (td, value, item) {
                                        $(td).html(item.nickname);
                                    }
                                },
                                {
                                    targets: [columnNo++], data: 'id', orderable: false,
                                    createdCell: function (td, value, item) {
                                        $(td).html(
                                            item.gender.gender2String() + ' ' +
                                            U.birth2Age(item.birthday) + '세 <br/>' +
                                            item.height + 'cm ' +
                                            (item.location.split(",").length > 0 ? item.location.split(",")[0] : item.location)
                                        );
                                    }
                                },
                                {
                                    targets: [columnNo++], data: 'login_date', orderable: false,
                                    render: function (value) {
                                        return value;
                                    }
                                },
                                {
                                    targets: [columnNo++], data: 'type', orderable: false,
                                    createdCell: function (td, value, item) {
                                        $(td).html(
                                            (item.type == 1 ? '맛매칭' : '일반') + '<br/>' +
                                            '(' +
                                            (item.status === 0 ? '신규' : item.status === 1 ? '오픈됨' : item.status === -1 ? '삭제됨' : item.status === -2 ? '대기' : '') +
                                            ')'
                                        );
                                    }
                                },
                                {
                                    targets: [columnNo++], data: 'vip', orderable: false,
                                    render: function (value) {
                                        if (value == 'Y') {
                                            return value;
                                        } else {
                                            return '-';
                                        }
                                    }
                                },
                                {
                                    targets: [columnNo++], data: 'user_status', orderable: false,
                                    render: function (value) {
                                        return Number(value).status2String();
                                    }
                                },
                                {
                                    targets: [columnNo++], data: 'create_date', orderable: false,
                                    render: function (value) {
                                        return value;
                                    }
                                },
                            ],
                            iDisplayLength: 10,
                            order: [[1, 'DESC']],
                            ajax: {
                                url: 'card/getCardHistory',
                                data: function (req) {
                                    req.columns = null;
                                    req.uid = user_info.id;
                                    req.type = self.props.type;
                                }
                            },
                            drawCallback: function () {
                            },
                            fnCreatedRow: function (row, data, dataInd) {
                            }
                        }}>
                        <thead>
                            <tr>
                                <th>UID</th>
                                <th>{strings.profile}</th>
                                <th>{strings.nickname}</th>
                                <th>{strings.gender}, {strings.birthday}<br />{strings.height}, {strings.location}</th>
                                <th width="15%">{strings.latest_login_date}</th>
                                <th>{strings.matching}</th>
                                <th>VIP</th>
                                <th>{strings.status}</th>
                                <th width="15%">{strings.date}</th>
                            </tr>
                        </thead>
                        <tbody />
                    </DataTable>
                </div>
            </div >
        )
    }
}

export default MemberCardHistory;