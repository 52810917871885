import React from 'react';
import { Link } from 'react-router-dom';
import strings from '../../lang/strings.js';
import { PageSettings } from "../../config/page-settings";
import { Line } from 'react-chartjs-2';
import Container from './../../components/content/container.js';
import moment from 'moment';
import './../../config/util';
import U from './../../config/util';

class IapChartFragment extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
            date_list: [],
            value_list: [],
            count_list: [],
        };

        this.lineChartOptions = {
            maintainAspectRatio: false,
            responsive: true,
            hover: {
                mode: 'nearest',
                intersect: true
            },
            tooltips: {
                mode: 'index',
                intersect: false,
            },
            scales: {
                yAxes: [{
                    type: 'linear',
                    display: true,
                    position: 'left',
                    id: 'y-axis-1',
                    ticks: {
                        beginAtZero: true,
                        min: 0,
                        max: 0,
                        userCallback: function (value, index, values) {
                            return U.currencyFormat(value);
                        }
                    },
                },
                {
                    type: 'linear',
                    display: false,
                    position: 'right',
                    id: 'y-axis-2',
                    ticks: {
                        beginAtZero: true,
                        min: 0,
                        max: 0,
                    }
                }],
                xAxes: [{
                    display: true,
                }],
            },
        };
    }

    componentDidMount() {
        this.getIapChart();
    }

    getIapChart = () => {
        let self = this;

        this.context.get(
            'dashboard/iap_chart',
            {},
            function (response) {
                let iap_chart = response.result_data.iap_chart;

                const date_list = [];
                const value_list = [];
                const count_list = [];

                iap_chart.forEach((entry) => {
                    date_list.push(moment(entry.date).format('M/D'));
                    value_list.push(entry.total_value);
                    count_list.push(entry.total_count);
                })

                const max_value = Math.max.apply(Math, value_list);
                const max_count = Math.max.apply(Math, count_list);

                self.lineChartOptions.scales.yAxes[0].ticks.max = Math.max(max_value * 2, 1000000);
                self.lineChartOptions.scales.yAxes[1].ticks.max = Math.max(max_count * 5, 200);

                self.setState({
                    date_list: date_list,
                    value_list: value_list,
                    count_list: count_list,
                });
            });
    };

    render() {
        this.lineChartData = {
            labels: this.state.date_list,
            datasets: [{
                label: strings.iap_chart_money,
                fill: true,
                lineTension: 0.1,
                backgroundColor: 'rgba(0, 172, 172, 0.5)',
                borderColor: 'rgba(0, 172, 172, 0.6)',
                borderWidth: 2,
                // pointBorderColor: '#00acac',
                // pointBackgroundColor: '#fff',
                // pointBorderWidth: 2,
                // pointHoverRadius: 5,
                // pointHoverBackgroundColor: '#fff',
                // pointHoverBorderColor: '#00acac',
                // pointHoverBorderWidth: 3,
                // pointRadius: 3,
                // pointHitRadius: 10,
                pointRadius: 0,
                data: this.state.value_list,
                yAxisID: 'y-axis-1'
            }, {
                label: strings.iap_chart_count,
                fill: true,
                lineTension: 0.1,
                backgroundColor: 'rgba(52, 143, 226, 0.5)',
                borderColor: 'rgba(52, 143, 226, 0.6)',
                borderWidth: 2,
                // pointBorderColor: '#348fe2',
                // pointBackgroundColor: '#fff',
                // pointBorderWidth: 2,
                // pointHoverRadius: 5,
                // pointHoverBackgroundColor: '#fff',
                // pointHoverBorderColor: '#348fe2',
                // pointHoverBorderWidth: 3,
                // pointRadius: 3,
                // pointHitRadius: 10,
                pointRadius: 0,
                data: this.state.count_list,
                yAxisID: 'y-axis-2'
            }]
        };

        return (
            <div>
                <Container title={strings.iap_chart_title} rightButton={(
                    <Link to="/iap/sta" style={{ color: '#fff' }}>{strings.just_go} <i className="fa fa-arrow-alt-circle-right"></i></Link>
                )}>
                    <Line data={this.state.date_list.length > 0 ? this.lineChartData : {}} height={400} options={this.lineChartOptions} />
                </Container>
            </div>
        )
    }
}

export default IapChartFragment;