import React from 'react';
import strings from '../../lang/strings.js';
import { withRouter } from 'react-router-dom';
import { inject } from 'mobx-react';
import $ from 'jquery';
import { PageSettings } from "../../config/page-settings";
import DataTable from '../../components/content/data-table.jsx';
import SearchInput from '../../components/content/search-input.js';
import SearchSelect from '../../components/content/search-select.js';
import '../../config/util';
import Const from '../../config/const';
import MemberDetailPopup from '../member-detail/member-detail-popup'
import BandCardPopup from './popup/band-card-popup'
import BandDetailPopup from './popup/band-detail-popup'
import AudioPlayerPopup from '../common/audio-player-popup.js';
const queryString = require('query-string');

class BandListFragment extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        let searchQS = queryString.parse(this.props.location.search);

        this.state = {
            search_info: searchQS,
            user_id: 0,
            show_member_profile: false,

            band_id: 0,
            show_band_card_popup: false,

            selected_audio_url: '',
            show_audio_player_popup: false,
        };

        this.refreshTable = this.refreshTable.bind(this);
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.location.search != this.props.location.search) {
            let searchQS = queryString.parse(this.props.location.search);
            this.setState({ search_info: searchQS }, () => this.refreshTable());
        }
    }

    refreshTable = (refresh) => {
        this.tblMember.draw(refresh);
    };

    searchTable = () => {
        let searchQS = queryString.parse(this.props.location.search);
        Object.assign(searchQS, this.state.search_info)
        const new_query = queryString.stringify(searchQS);
        if (new_query == this.props.location.search.substr(1)) {
            this.refreshTable();
        } else {
            this.props.history.push('?' + new_query);
        }
    }

    showMemberDetailPopup(uid) {
        this.setState({
            show_member_profile: true,
            selected_uid: uid
        });
    }

    showBandCardPopup(item) {
        this.setState({
            show_band_card_popup: true,
            band_id: item.id
        });
    }

    showBandDetailPopup(id) {
        this.setState({
            show_band_detail_popup: true,
            band_id: id
        });
    }

    showBandDetailPage(id) {
        this.props.history.push(`/band/band/${id}`);
    }

    deleteBand(item) {
        let self = this;
        this.context.showConfirm(strings.confirm_delete_title, function (response) {
            if (response) {
                self.context.post('band/delete', { id: item.id }, function () {
                    self.context.addNotification('success', strings.alert_title, strings.confirm_delete_done);
                    self.refreshTable(false);
                }, true);
            }
        });
    }

    showVideoPlayer(video_url) {
        this.setState({
            show_audio_player_popup: true,
            selected_audio_url: video_url
        })
    }

    render() {
        let self = this;
        let columnNo = 0;
        const { search_info } = this.state;

        return (
            <div>
                <MemberDetailPopup isOpen={this.state.show_member_profile} uid={this.state.selected_uid} onClose={() => {
                    this.setState({ show_member_profile: false });
                }} />
                <BandCardPopup isOpen={this.state.show_band_card_popup} band_id={this.state.band_id} onClose={() => {
                    this.setState({ show_band_card_popup: false });
                }} />
                <BandDetailPopup isOpen={this.state.show_band_detail_popup} band_id={this.state.band_id} onClose={() => {
                    this.setState({ show_band_detail_popup: false });
                }} />
                <AudioPlayerPopup isOpen={this.state.show_audio_player_popup} audio_url={this.state.selected_audio_url} onClose={() => {
                    this.setState({ show_audio_player_popup: false });
                }} />
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='form-horizontal form-inline'>
                            <SearchSelect
                                data={[{ code: '', name: strings.select_band_category }].concat(strings.const.BAND_CATEGORY.map(it => { return { code: it, name: it } }))}
                                value={search_info.category || ''}
                                onChange={(value) => {
                                    this.setState({
                                        search_info: {
                                            ...search_info,
                                            category: value,
                                            start: 0,
                                        }
                                    }, this.searchTable);
                                }}
                            />
                            <SearchSelect
                                data={[{ code: '', name: strings.select_band_type }].concat(strings.const.BAND_TYPE)}
                                value={search_info.type || ''}
                                onChange={(value) => {
                                    this.setState({
                                        search_info: {
                                            ...search_info,
                                            type: value,
                                            start: 0,
                                        }
                                    }, this.searchTable);
                                }}
                            />

                            {/* 키워드검색 */}
                            <SearchSelect
                                data={[
                                    { code: 'title', name: strings.title },
                                    { code: 'content', name: strings.content },
                                ].concat(strings.const.SEARCH_CATEGORY2)}
                                value={search_info.column || 'title'}
                                onChange={(value) => {
                                    this.setState({
                                        search_info: {
                                            ...search_info,
                                            column: value,
                                            start: 0,
                                        }
                                    }, this.searchTable);
                                }}
                            />
                            <SearchInput
                                value={search_info.keyword}
                                onChange={(value) => {
                                    this.setState({
                                        search_info: {
                                            ...search_info,
                                            keyword: value,
                                            column: search_info.column || 'title',
                                            start: 0,
                                        }
                                    })
                                }}
                                handler={(value) => this.searchTable()}
                            />

                            <button type='button' className='btn btn-success btn-sm ml-md-2' onClick={() => this.searchTable()}>{strings.search}</button>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12 mt-3'>
                        <DataTable ref={(ref) => this.tblMember = ref}
                            customPaginate={true}
                            initialPage={search_info.start || 0}
                            onPageChange={(value) => {
                                this.setState({
                                    search_info: {
                                        ...search_info,
                                        start: value,
                                    }
                                }, this.searchTable);
                            }}
                            options={{
                                columnDefs: [
                                    {
                                        targets: [columnNo++], data: 'id', orderable: false,
                                        render: function (value) {
                                            return value;
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'category', orderable: false,
                                        render: function (value) {
                                            return value;
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'tag', orderable: false,
                                        render: function (value) {
                                            return value.replace(/#/g, ', ');
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'title', orderable: false,
                                        createdCell: function (td, value, item) {
                                            $(td).html('');
                                            $(td).append(`<a href="javascript:;">${value}</a>`)
                                            $(td).find('a')
                                                .on('click', function () {
                                                    self.showBandDetailPopup(item.id);
                                                });
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'image', orderable: false,
                                        createdCell: function (td, value, item) {
                                            return $(td).image_lightbox(value, () => {
                                                self.context.showGallery(value.split('#').map((it) => { return Const.BASE_URL + it }))
                                            });
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'type', orderable: false,
                                        render: function (value) {
                                            const item = strings.const.MEETING_TYPE.find(it => it.code == value);
                                            if (item) {
                                                return `<span class="${item.color}">${item.short}</span>`;
                                            } else {
                                                return value;
                                            }
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'location', orderable: false,
                                        render: function (value) {
                                            return value;
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'is_highend', orderable: false,
                                        render: function (value) {
                                            return value;
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'is_private', orderable: false,
                                        render: function (value) {
                                            return value;
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'audio', orderable: false,
                                        createdCell: function (td, value, item) {
                                            $(td).html('');
                                            if (value) {
                                                $(td).append(`<button type='button' class='btn btn-primary btn-sm m-2'>${strings.play}</button>`)
                                                    .find('button:eq(0)')
                                                    .on('click', function () {
                                                        self.showVideoPlayer(Const.BASE_URL + value);
                                                    });
                                            }
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'create_date', orderable: false,
                                        render: function (value) {
                                            return value;
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'nickname', orderable: false,
                                        createdCell: function (td, value, item) {
                                            $(td).nickname_label(value, item.gender, () => {
                                                self.showMemberDetailPopup(item.uid);
                                            });
                                            if (item.alias) {
                                                $(td).append(`<br/>(${item.alias})`);
                                            }
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'card_count', orderable: false,
                                        render: function (value) {
                                            return value > 0 ? value : '-';
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'id', orderable: false,
                                        createdCell: function (td, value, item) {
                                            $(td).html('');
                                            $(td).append(`<button type='button' class='btn btn-primary btn-sm m-2'>${strings.detail}</button>`)
                                                .find('button:eq(0)')
                                                .on('click', function () {
                                                    self.showBandDetailPage(item.id);
                                                });
                                            $(td).append(`<button type='button' class='btn btn-danger btn-sm m-2'>${strings.delete}</button>`)
                                                .find('button:eq(1)')
                                                .on('click', function () {
                                                    self.deleteBand(item);
                                                });
                                            $(td).append(`<button type='button' class='btn btn-success btn-sm m-2'>${strings.card_list}</button>`)
                                                .find('button:eq(2)')
                                                .on('click', function () {
                                                    self.showBandCardPopup(item);
                                                });
                                        }
                                    }
                                ],
                                order: [[1, 'DESC']],
                                ajax: {
                                    url: 'band/getList',
                                    data: function (req) {
                                        req.columns = null;

                                        const searchQS = queryString.parse(self.props.location.search);
                                        req.start = searchQS.start || 0;
                                        req.length = searchQS.length || 20;
                                        req.category = searchQS.category;
                                        req.type = searchQS.type;
                                        req.column = searchQS.column;
                                        req.keyword = searchQS.keyword;
                                    }
                                },
                                drawCallback: function () {
                                },
                                fnCreatedRow: function (row, data, dataInd) {
                                }
                            }}>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>{strings.category}</th>
                                    <th>{strings.tag}</th>
                                    <th>{strings.title}</th>
                                    <th>{strings.image}</th>
                                    <th>{strings.type}</th>
                                    <th>{'지역'}</th>
                                    <th>{'하이엔드'}</th>
                                    <th>{'비공개'}</th>
                                    <th>{'목소리'}</th>
                                    <th>{strings.create_date}</th>
                                    <th>{strings.creator}</th>
                                    <th>{strings.card}</th>
                                    <th>{strings.manage}</th>
                                </tr>
                            </thead>
                            <tbody />
                        </DataTable>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(inject('rootStore')(BandListFragment));