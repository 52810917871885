import React from 'react';
import strings from '../../lang/strings.js';
import { PageSettings } from "../../config/page-settings";
import { Panel, PanelBody } from '../../components/panel/panel.jsx';
import { Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import './../../config/util';
import TermFragment from './term-fragment'

class Term extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);
        this.state = {
            activeTab: '1'
        };

        this.toggle = this.toggle.bind(this);
    }

    componentDidMount() {
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }
    }

    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-xs-12">
                        <Panel>
                            <Nav tabs className="nav-tabs-inverse">
                                <NavItem>
                                    <NavLink className={classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.toggle('1'); }}>
                                        <span className="d-none d-xs-inline">{strings.terms_04}</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ active: this.state.activeTab === '2' })} onClick={() => { this.toggle('2'); }}>
                                        <span className="d-none d-xs-inline">{strings.terms_05}</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ active: this.state.activeTab === '3' })} onClick={() => { this.toggle('3'); }}>
                                        <span className="d-none d-xs-inline">{strings.terms_06}</span>
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <PanelBody>
                                {this.state.activeTab === "1" && (<TermFragment type={'usage'} />)}
                                {this.state.activeTab === "2" && (<TermFragment type={'privacy'} />)}
                                {this.state.activeTab === "3" && (<TermFragment type={'about'} />)}
                            </PanelBody>
                        </Panel>
                    </div>
                </div>
            </div>
        )
    }
}

export default Term;