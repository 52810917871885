import React from 'react';
import strings from '../../lang/strings.js';
import $ from 'jquery';
import { PageSettings } from "../../config/page-settings";
import DataTable from './../../components/content/data-table.jsx';
import SearchInput from './../../components/content/search-input.js';
import SearchSelect from './../../components/content/search-select.js';
import './../../config/util';
import Const from './../../config/const';
import { withRouter } from 'react-router-dom';
import { inject } from 'mobx-react';
import MemberDetailPopup from './../member-detail/member-detail-popup'
import MemberSuspendPopup from './../common/member-suspend-popup'
import BoardDetailPopup from './board-detail-popup'
const queryString = require('query-string');

class BoardFragment extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        let searchQS = queryString.parse(this.props.location.search);

        this.state = {
            search_info: searchQS
        };
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.location.search != this.props.location.search) {
            let searchQS = queryString.parse(this.props.location.search);
            this.setState({ search_info: searchQS }, () => this.refreshTable());
        }
    }

    refreshTable = (refresh) => {
        this.tblMember.draw(refresh);
    };

    searchTable = () => {
        let searchQS = queryString.parse(this.props.location.search);
        Object.assign(searchQS, this.state.search_info)
        const new_query = queryString.stringify(searchQS);
        if (new_query == this.props.location.search.substr(1)) {
            this.refreshTable();
        } else {
            this.props.history.push('?' + new_query);
        }
    }

    showMemberDetailPopup(uid) {
        this.setState({
            show_member_profile: true,
            selected_uid: uid
        });
    }

    showDetailPopup(id) {
        this.setState({
            show_board_detail: true,
            board_id: id
        });
    }

    showDetailPage(id) {
        this.props.history.push(`/board/board/${id}`);
    }

    blindBoard(item) {
        let self = this;
        this.context.showConfirm(strings.board_blind_title, function (response) {
            if (response) {
                self.context.post('board/blind', { id: item.id }, function () {
                    self.context.addNotification('success', strings.alert_title, strings.comment_blind_done);
                    self.refreshTable(false);
                }, true);
            }
        });
    }

    deleteBoard(item) {
        let self = this;
        this.context.showConfirm(strings.board_delete_title, function (response) {
            if (response) {
                self.context.post('board/delete', { id: item.id }, function () {
                    self.context.addNotification('success', strings.alert_title, strings.comment_delete_done);
                    self.refreshTable(false);
                }, true);
            }
        });
    }

    autoBoard(item) {
        let self = this;
        self.context.post('board/saveAutoItem', {
            auto_info: {
                board_id: item.id,
                gender: item.gender,
                category: item.category,
                title: item.title,
                content: item.content,
                image_urls: item.image_urls,
            }
        }, function () {
            self.context.addNotification('success', strings.alert_title, strings.auto_register_done);
            self.refreshTable(false);
        }, true);
    }

    suspendMember(uid) {
        this.setState({
            show_member_suspend: true,
            selected_uid: uid
        });
    }

    render() {
        let self = this;
        let columnNo = 0;
        const { search_info } = this.state;

        return (
            <div>
                <MemberDetailPopup isOpen={this.state.show_member_profile} uid={this.state.selected_uid} onClose={() => {
                    this.setState({ show_member_profile: false });
                }} />
                <MemberSuspendPopup isOpen={this.state.show_member_suspend} uid={this.state.selected_uid} onClose={() => {
                    this.setState({ show_member_suspend: false });
                }} onSave={() => {
                    this.setState({ show_member_suspend: false }, () => {
                        self.refreshTable(false);
                    });
                }} />
                <BoardDetailPopup isOpen={this.state.show_board_detail} board_id={this.state.board_id} onClose={() => {
                    this.setState({ show_board_detail: false });
                }} />
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='form-horizontal form-inline'>
                            <select className='form-control'
                                value={search_info.category || ''}
                                onChange={(e) => {
                                    this.setState({
                                        search_info: {
                                            ...search_info,
                                            category: e.target.value,
                                            start: 0,
                                        }
                                    }, this.searchTable);
                                }}
                            >
                                <option value="">{strings.select_category}</option>
                                {strings.const.BOARD_CATEGORY.map((entry, index) => (
                                    <option key={index} value={entry}>{entry}</option>
                                ))}
                            </select>

                            {/* 키워드검색 */}
                            <SearchSelect
                                data={[
                                    { code: 'title', name: strings.title },
                                    { code: 'content', name: strings.content },
                                ].concat(strings.const.SEARCH_CATEGORY2)}
                                value={search_info.column || 'title'}
                                onChange={(value) => {
                                    this.setState({
                                        search_info: {
                                            ...search_info,
                                            column: value,
                                            start: 0,
                                        }
                                    }, this.searchTable);
                                }}
                            />
                            <SearchInput
                                value={search_info.keyword}
                                onChange={(value) => {
                                    this.setState({
                                        search_info: {
                                            ...search_info,
                                            keyword: value,
                                            column: search_info.column || 'title',
                                            start: 0,
                                        }
                                    })
                                }}
                                handler={(value) => this.searchTable()}
                            />

                            <button type='button' className='btn btn-success btn-sm ml-md-2' onClick={() => this.searchTable()}>{strings.search}</button>
                        </div>
                    </div>
                </div>
                <div className='row mt-3'>
                    <div className='col-md-12'>
                        <DataTable ref={(ref) => this.tblMember = ref}
                            customPaginate={true}
                            initialPage={search_info.start || 0}
                            onPageChange={(value) => {
                                this.setState({
                                    search_info: {
                                        ...search_info,
                                        start: value,
                                    }
                                }, this.searchTable);
                            }}
                            options={{
                                columnDefs: [
                                    {
                                        targets: [columnNo++], data: 'id', orderable: false,
                                        render: function (value) {
                                            return value;
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'category', orderable: false,
                                        render: function (value) {
                                            return value;
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'title', orderable: false,
                                        createdCell: function (td, value, item) {
                                            $(td).html('');
                                            $(td).append(`<a href="javascript:;">${value}</a>`)
                                            if (item.status == -1) {
                                                $(td).append(`<span style="color: red"> [${strings.deleted}]</span>`)
                                            }
                                            $(td).find('a')
                                                .on('click', function () {
                                                    self.showDetailPopup(item.id);
                                                });
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'image_urls', orderable: false,
                                        createdCell: function (td, value, item) {
                                            if (value) {
                                                return $(td).image_lightbox(value, () => {
                                                    self.context.showGallery(value.split('#').map((it) => { return Const.BASE_URL + it }))
                                                });
                                            } else {
                                                return $(td).html('');
                                            }
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'visit_count', orderable: false,
                                        render: function (value) {
                                            return value.currencyFormat()
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'card_count', orderable: false,
                                        render: function (value) {
                                            return value.currencyFormat()
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'like_count', orderable: false,
                                        render: function (value) {
                                            return value.currencyFormat()
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'unlike_count', orderable: false,
                                        render: function (value) {
                                            return value.currencyFormat()
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'report_count', orderable: false,
                                        render: function (value) {
                                            if (value >= 10) {
                                                return `<span style="color: red">${value.currencyFormat()}</span>`;
                                            } else {
                                                return value.currencyFormat()
                                            }
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'comment_count', orderable: false,
                                        render: function (value) {
                                            return value.currencyFormat()
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'comment_report_count', orderable: false,
                                        render: function (value) {
                                            if (value >= 10) {
                                                return `<span style="color: red">${value.currencyFormat()}</span>`;
                                            } else {
                                                return value.currencyFormat()
                                            }
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'create_date', orderable: false,
                                        render: function (value) {
                                            return value
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'nickname', orderable: false,
                                        createdCell: function (td, value, item) {
                                            $(td).nickname_label(value, item.gender, () => {
                                                self.showMemberDetailPopup(item.uid);
                                            });
                                            if (item.alias) {
                                                $(td).append(`<br/>(${item.alias})`);
                                            }
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'id', orderable: false,
                                        createdCell: function (td, value, item) {
                                            $(td).html('');
                                            $(td).append(`<button type='button' class='btn btn-success btn-sm m-2'>${strings.detail}</button>`)
                                                .find('button:eq(0)')
                                                .on('click', function () {
                                                    self.showDetailPage(item.id);
                                                });
                                            $(td).append(`<button type='button' class='btn btn-danger btn-sm m-2'>${strings.delete}</button>`)
                                                .find('button:eq(1)')
                                                .on('click', function () {
                                                    self.deleteBoard(item);
                                                });
                                            if (!item.board_auto_id
                                                && (item.user_status == Const.USER_STATUS.LEAVE
                                                    || item.user_status == Const.USER_STATUS.SLEEP)) {
                                                //     $(td).append(`<button type='button' class='btn btn-secondary btn-sm m-2 disabled'>${strings.auto}</button>`)
                                                //         .find('button:eq(2)')
                                                //         .on('click', function () {
                                                //             // self.autoBoard(item);
                                                //         });
                                                // } else {
                                                $(td).append(`<button type='button' class='btn btn-green btn-sm m-2'>${strings.auto}</button>`)
                                                    .find('button:eq(2)')
                                                    .on('click', function () {
                                                        self.autoBoard(item);
                                                    });
                                                $(td).append(`<br/>`);
                                                $(td).append(`<button type='button' class='btn btn-inverse btn-sm m-2'>${strings.blind}</button>`)
                                                    .find('button:eq(3)')
                                                    .on('click', function () {
                                                        self.blindBoard(item);
                                                    });
                                                $(td).append(`<button type='button' class='btn btn-yellow btn-sm m-2'>${strings.suspend}</button>`)
                                                    .find('button:eq(4)')
                                                    .on('click', function () {
                                                        self.suspendMember(item.uid);
                                                    });
                                            } else {
                                                $(td).append(`<br/>`);
                                                $(td).append(`<button type='button' class='btn btn-inverse btn-sm m-2'>${strings.blind}</button>`)
                                                    .find('button:eq(2)')
                                                    .on('click', function () {
                                                        self.blindBoard(item);
                                                    });
                                                $(td).append(`<button type='button' class='btn btn-yellow btn-sm m-2'>${strings.suspend}</button>`)
                                                    .find('button:eq(3)')
                                                    .on('click', function () {
                                                        self.suspendMember(item.uid);
                                                    });
                                            }
                                        }
                                    },
                                ],
                                order: [[1, 'DESC']],
                                ajax: {
                                    url: 'board/getList',
                                    data: function (req) {
                                        req.columns = null;

                                        const searchQS = queryString.parse(self.props.location.search);
                                        req.start = searchQS.start || 0;
                                        req.length = searchQS.length || 20;
                                        req.column = searchQS.column;
                                        req.keyword = searchQS.keyword;
                                        req.category = searchQS.category;
                                    }
                                },
                                drawCallback: function () {
                                },
                                fnCreatedRow: function (row, data, dataInd) {
                                }
                            }}>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>{strings.category}</th>
                                    <th width="35%">{strings.title}</th>
                                    <th>{strings.image}</th>
                                    <th>{strings.visit_count}</th>
                                    <th>{strings.card}</th>
                                    <th>{strings.like}</th>
                                    <th>{strings.dislike}</th>
                                    <th>{strings.report}</th>
                                    <th>{strings.comment}</th>
                                    <th>{strings.comment}{strings.report}</th>
                                    <th width="6%">{strings.create_date}</th>
                                    <th width="10%">{strings.creator}</th>
                                    <th>{strings.manage}</th>
                                </tr>
                            </thead>
                            <tbody />
                        </DataTable>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(inject('rootStore')(BoardFragment));