import React from 'react';
import strings from '../../lang/strings.js';
import { withRouter } from 'react-router-dom';
import { PageSettings } from '../../config/page-settings.js';
import { inject } from 'mobx-react';

import $ from 'jquery';

class Login extends React.Component {

    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
            activeBg: '/assets/img/login-bg/login-bg.jpg'
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.context.handleSetPageSidebar(false);
        this.context.handleSetPageHeader(false);
        $('#id').focus();
    }

    componentWillUnmount() {
        this.context.handleSetPageSidebar(true);
        this.context.handleSetPageHeader(true);
    }

    handleSubmit(event) {
        event.preventDefault();
        let self = this;

        self.context.post(
            'auth/login',
            {
                user_id: $('#id').val(),
                password: $('#pwd').val()
            },
            function (response) {
                let result_data = response.result_data;
                // login 성공에 따른 처리를 진행한다.
                self.props.rootStore.login(result_data);
                self.props.history.push('/dashboard');
            },
            true
        );
    }

    render() {
        return (
            <React.Fragment>
                <div className="login-cover">
                    <div className="login-cover-image"
                        style={{ backgroundImage: 'url(' + this.state.activeBg + ')' }} />
                    <div className="login-cover-bg" />
                </div>

                <div className="login login-v2">
                    <div className="login-header">
                        <div className="brand">
                            <span className="logo" /> {strings.top_admin}
                        </div>
                        <div className="icon hide">
                            <i className="fa fa-lock" />
                        </div>
                    </div>
                    <div className="login-content">
                        <form className="margin-bottom-0" onSubmit={this.handleSubmit}>
                            <div className="form-group m-b-20">
                                <input id={'id'} type="text" autoComplete={'off'}
                                    className="form-control form-control-lg" placeholder={strings.login_user_id}
                                    required />
                            </div>
                            <div className="form-group m-b-20">
                                <input id={'pwd'} type="password" className="form-control form-control-lg"
                                    placeholder={strings.password}
                                    required />
                            </div>
                            <div className="login-buttons">
                                <button type="submit" className="btn btn-success btn-block btn-lg">{strings.login}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(inject('rootStore')(Login));