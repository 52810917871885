import React from 'react';
import strings from '../../lang/strings.js';
import { PageSettings } from "../../config/page-settings";

class IapStaFragment extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
            iap_sta: null,
        };
    }

    componentDidMount() {
        this.getIapSta();
    }

    getIapSta = () => {
        let self = this;

        this.context.get(
            'dashboard/iap_sta',
            {},
            function (response) {
                let iap_sta = response.result_data.iap_sta;

                self.setState({
                    iap_sta: iap_sta,
                });
            });
    };

    render() {
        let self = this;

        return (
            <div>
                <div className="widget widget-stats bg-red height-100">
                    <div className="d-flex align-items-center text-white">
                        <div className="d-flex w-100">
                            <div>{strings.today} {strings.sales}</div>
                            <div className="ml-auto">{self.state.iap_sta ? `${self.state.iap_sta.today.currencyFormat()}원` : `-`}</div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center text-white">
                        <div className="d-flex w-100">
                            <div>{strings.this_week} {strings.sales}</div>
                            <div className="ml-auto">{self.state.iap_sta ? `${self.state.iap_sta.this_week.currencyFormat()}원` : `-`}</div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center text-white">
                        <div className="d-flex w-100">
                            <div>{strings.this_month} {strings.sales}</div>
                            <div className="ml-auto">{self.state.iap_sta ? `${self.state.iap_sta.this_month.currencyFormat()}원` : `-`}</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default IapStaFragment;