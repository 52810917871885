import React from 'react';
import strings from '../../lang/strings.js';
import { PageSettings } from "../../config/page-settings";
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Const from './../../config/const';
import U from './../../config/util';

class BoardDetailPopup extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
            board_info: {}
        }

        this.toggleModal = this.toggleModal.bind(this);
    }

    componentDidMount() {
    }

    toggleModal(result) {
        if (this.props.onClose) {
            this.props.onClose(result);
        }
    }

    getData = () => {
        let self = this;

        if (!this.props.board_id) {
            this.setState({
                board_info: {},
            });
            return;
        }

        this.context.post(
            'board/getItem',
            {
                board_id: this.props.board_id
            }, function (response) {
                let item = response.result_data.board_info;

                self.setState({
                    board_info: item,
                });
            });
    };

    render() {
        let board_info = this.state.board_info;

        return (
            <div>
                <Modal isOpen={this.props.isOpen} toggle={this.toggleModal} onOpened={() => this.getData()}>
                    <ModalHeader className="popup-nickname" toggle={this.toggleModal}>{strings.board_detail}</ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <div className='col-md-12'>
                                <div className='row'>
                                    <label className='col-md-2 col-form-label'>{strings.category}</label>
                                    <label className='col-md-10 col-form-label'>{board_info.category}</label>
                                </div>
                                <div className='row'>
                                    <label className='col-md-2 col-form-label'>{strings.alias}</label>
                                    <label className='col-md-10 col-form-label'>{board_info.alias}</label>
                                </div>
                                <div className='row'>
                                    <label className='col-md-2 col-form-label'>{strings.title}</label>
                                    <label className='col-md-10 col-form-label'>{board_info.title}</label>
                                </div>
                                <div className='row'>
                                    <label className='col-md-2 col-form-label'>{strings.content}</label>
                                    <label className='col-md-10 col-form-label'>{board_info.content}</label>
                                </div>
                                <div className='row'>
                                    <label className='col-md-2 col-form-label'>{strings.image}</label>
                                    <div className='col-md-10'>
                                        {
                                            U.getThumbList(board_info.image_urls).map((image_url, idx) => (
                                                <a onClick={() => {
                                                    this.context.showGallery(board_info.image_urls.split("#").map((it) => { return Const.BASE_URL + it }), idx)
                                                }}>
                                                    <img src={image_url} style={{ height: '50px', width: 'auto', margin: 2 }} />
                                                </a>
                                            ))
                                        }
                                    </div>
                                </div>
                                <div className='row'>
                                    <label className='col-md-2 col-form-label'>{strings.create_date}</label>
                                    <label className='col-md-10 col-form-label'>{board_info.create_date}</label>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-default" onClick={this.toggleModal}>{strings.close}</button>
                    </ModalFooter>
                </Modal>
            </div >
        )
    }
}

export default BoardDetailPopup;