import React from 'react';
import strings from '../../lang/strings.js';
import { PageSettings } from "../../config/page-settings";
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import classnames from 'classnames';
import $ from 'jquery';
import './../../config/util';
import U from './../../config/util';
import Const from './../../config/const';
import menus from './../../config/menu.jsx';

class ManagerEditPopup extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
            item: {},
        };

        this.toggleModal = this.toggleModal.bind(this);
    }

    componentDidMount() {
    }

    toggleModal(result) {
        if (this.props.onClose) {
            this.props.onClose(result);
        }
    }

    getData = () => {
        if (!this.props.manager_id) {
            this.setState({
                item: {},
            });
            return;
        }

        let self = this;

        this.context.post(
            'manager/getItem',
            {
                id: this.props.manager_id
            },
            function (response) {
                let item = response.result_data.manager_item;

                self.setState({
                    item,
                });
            });
    };

    saveData = () => {
        let self = this;

        const manager_info = self.state.item;

        const password = $('#password').val();
        const password_confirm = $('#password_confirm').val();
        if (password.length > 0) {
            if (password !== password_confirm) {
                self.context.addNotification('warning', strings.alert_title, strings.msg_password_not_correct);
                return;
            } else {
                manager_info.password = password;
            }
        }

        if (manager_info.level > 0) {
            const privilege_list = []
            $('input[name="manager_privilege"]:checked').each((index, item) => {
                privilege_list.push(item.value);
            })
            manager_info.privilege = privilege_list.join('#');
        } else {
            manager_info.privilege = '';
        }

        this.context.post('manager/save',
            {
                manager_info
            }, function (response) {
                self.context.addNotification('success', strings.alert_title, strings.save_done);
                if (self.props.onSave) {
                    self.props.onSave();
                }
            }, true);
    };

    onDataChange = (e) => {
        let item = this.state.item;
        item[e.target.id] = e.target.value;

        this.setState({
            item: item
        });
    };

    render() {
        let item = this.state.item;
        const privilege_list = item.privilege ? item.privilege.split('#') : [];

        return (
            <div>
                <Modal isOpen={this.props.isOpen} toggle={this.toggleModal} onOpened={() => this.getData()} size={'md'}>
                    <ModalHeader className="popup-title" toggle={this.toggleModal}>{item.id ? strings.manager_edit : strings.manager_add}</ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <div className="col-xs-12">
                                <div className='row'>
                                    <label className='col-md-3 col-form-label'>{strings.user_id}</label>
                                    <div className="col-md-9">
                                        <input type='text' autoComplete='off' className='form-control' id='user_id'
                                            value={item.user_id || ''}
                                            onChange={(e) => this.onDataChange(e)} />
                                    </div>
                                </div>
                                <div className='row'>
                                    <label className='col-md-3 col-form-label'>{strings.nickname}</label>
                                    <div className="col-md-9">
                                        <input type='text' autoComplete='off' className='form-control' id='nickname'
                                            value={item.nickname || ''}
                                            onChange={(e) => this.onDataChange(e)} />
                                    </div>
                                </div>
                                <div className='row'>
                                    <label className='col-md-3 col-form-label'>{strings.password}</label>
                                    <div className="col-md-9">
                                        <input type='password' autoComplete='off' className='form-control' id='password' />
                                    </div>
                                </div>
                                <div className='row'>
                                    <label className='col-md-3 col-form-label'>{strings.confirm_password}</label>
                                    <div className="col-md-9">
                                        <input type='password' autoComplete='off' className='form-control' id='password_confirm' />
                                    </div>
                                </div>
                                <div className='row'>
                                    <label className='col-md-3 col-form-label'>{strings.manager_level}</label>
                                    <div className="col-md-9">
                                        <select className='form-control' id='level'
                                            onChange={(e) => this.onDataChange(e)}
                                            value={item.level || ''}>
                                            {Object.keys(strings.const.MANAGER_LEVEL).map((key, index) => (
                                                <option key={index} value={key}>{strings.const.MANAGER_LEVEL[key].name}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className='row'>
                                    <label className='col-md-3 col-form-label'>{strings.manager_privilege}</label>
                                    <div className="col-md-9">
                                        {
                                            this.state.item.level > 0 && menus.filter(it => it.middle_manage).map((item, index) => {
                                                const code = item.path.replace(/^\/|\/$/g, '')
                                                const checked = privilege_list.indexOf(code) >= 0;
                                                return (
                                                    <div className="checkbox checkbox-css" key={index}>
                                                        <input type="checkbox" id={code} name="manager_privilege" value={code} defaultChecked={checked} />
                                                        <label htmlFor={code}>{item.title}</label>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                {
                                    item.id &&
                                    <div>
                                        <div className='row'>
                                            <label className='col-md-3 col-form-label'>{strings.login_date}</label>
                                            <label className='col-md-9 col-form-label'>{item.login_date}</label>
                                        </div>
                                        <div className='row'>
                                            <label className='col-md-3 col-form-label'>{strings.ip}</label>
                                            <label className='col-md-9 col-form-label'>{item.login_ip}</label>
                                        </div>
                                        <div className='row'>
                                            <label className='col-md-3 col-form-label'>{strings.create_date}</label>
                                            <label className='col-md-9 col-form-label'>{item.create_date}</label>
                                        </div>
                                        <div className='row'>
                                            <label className='col-md-3 col-form-label'>{strings.update_date}</label>
                                            <label className='col-md-9 col-form-label'>{item.update_date}</label>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-default" onClick={this.toggleModal}>{strings.close}</button>
                        <button className="btn btn-success" onClick={() => this.saveData()}>{strings.apply}</button>
                    </ModalFooter>
                </Modal>
            </div >
        )
    }
}

export default ManagerEditPopup;