import React from 'react';
import strings from '../lang/strings.js';
import { Redirect } from 'react-router';

import Dashboard from '../pages/home/dashboard';
import Login from './../pages/auth/login';
import MemberDetail from './../pages/member-detail/member-detail';
import MemberProfilePending from './../pages/member/profile-pending';
import MemberNormal from './../pages/member/normal';
import MemberStatusHistory from './../pages/member/status-history';
import MemberStatus from './../pages/member/status';
import MemberRanking from './../pages/member/ranking';
import MemberInvite from './../pages/member/invite';
import MemberPath from './../pages/member/path';
import MemberScore from './../pages/member/score';
import MemberShop from './../pages/member/shop';
import MemberCoupon from './../pages/member/coupon';
import TodayCard from './../pages/card/today-card';
import CardMessage from './../pages/card/message';
import CardMatching from './../pages/card/matching';
import Restaurant from './../pages/meeting/restaurant';
import RestaurantDetail from './../pages/meeting/restaurant-detail';
import RestaurantBanner from './../pages/meeting/banner';
import Meeting from './../pages/meeting/meeting';
import MeetingDetail from './../pages/meeting/meeting-detail';
import IapLog from './../pages/iap/iap-log';
import IapRanking from './../pages/iap/ranking';
import Sales from './../pages/iap/sales';
import SettingCron from './../pages/setting/cron';
import PointSend from './../pages/setting/point-send';
import SettingBonus from './../pages/setting/bonus';
import SettingPush from './../pages/setting/push';
import Term from './../pages/setting/term';
import Popup from './../pages/setting/popup';
import PopupDetail from './../pages/setting/popup-detail';
import Video from './../pages/setting/video';
import Manager from './../pages/manager/manager';
import ChangePassword from './../pages/manager/change-password';
import Alias from './../pages/setting/alias';
import Setting from './../pages/setting/setting';
import Board from './../pages/board/board';
import BoardDetail from './../pages/board/board-detail';
import BoardSuspend from './../pages/board/board-suspend';
import Comment from './../pages/board/comment';
import BoardAuto from './../pages/board/auto';
import Notice from './../pages/setting/notice';
import NoticeDetail from './../pages/setting/notice-detail';
import StaLogin from './../pages/sta/login';
import StaSignup from './../pages/sta/signup';
import StaHourly from './../pages/sta/hourly';
import StaMatching from './../pages/sta/matching';
import Keyword from './../pages/keyword/keyword';
import KeywordDetail from './../pages/keyword/keyword-detail';
import BandList from './../pages/band/band-list';
import BandDetail from './../pages/band/band-detail';
import Now from './../pages/now/now';
import ZoneLocation from './../pages/zone/location';
import ZoneCoupon from './../pages/zone/coupon';
import ZoneEvent from './../pages/zone/event';
import SettingJob from './../pages/setting/job';
import SettingSchool from './../pages/setting/school';
import Product from './../pages/product/product';
import ProductCategory from './../pages/product/category';
import ProductCompany from './../pages/product/company';
import ProductPurchase from './../pages/product/purchase';
import ProductEvent from './../pages/product/event';
import ProductEventJoin from './../pages/product/join';
import WeddingPlace from './../pages/wedding/place';
import WeddingDress from './../pages/wedding/dress';
import WeddingLoan from './../pages/wedding/loan';

const routes = [
  {
    path: '/',
    exact: true,
    title: '',
    access: 'private',
    component: () => <Redirect to='/dashboard' />
  },
  {
    path: '/dashboard',
    exact: true,
    title: strings.menu.dashboard,
    access: 'private',
    component: () => <Dashboard />
  },
  {
    path: '/login',
    exact: true,
    title: strings.menu.login,
    access: 'public',
    component: () => <Login />
  },
  {
    path: '/member/detail/:memberId',
    exact: true,
    title: strings.menu.member_detail,
    access: 'private',
    component: MemberDetail
  },
  {
    path: '/member/profile_pending',
    exact: true,
    title: strings.menu.member_profile_pending,
    access: 'private',
    component: () => <MemberProfilePending />
  },
  {
    path: '/member/normal',
    exact: true,
    title: strings.menu.member_normal,
    access: 'private',
    component: () => <MemberNormal />
  },
  {
    path: '/member/status',
    exact: true,
    title: strings.menu.member_status,
    access: 'private',
    component: () => <MemberStatus />
  },
  {
    path: '/member/pending_log',
    exact: true,
    title: strings.menu.member_pending_log,
    access: 'private',
    component: () => <MemberStatusHistory />
  },
  {
    path: '/member/ranking',
    exact: true,
    title: strings.menu.member_ranking,
    access: 'private',
    component: () => <MemberRanking />
  },
  {
    path: '/member/invite',
    exact: true,
    title: strings.menu.member_invite,
    access: 'private',
    component: () => <MemberInvite />
  },
  {
    path: '/member/referrer',
    exact: true,
    title: strings.menu.member_referrer,
    access: 'private',
    component: () => <MemberPath />
  },
  {
    path: '/member/shop',
    exact: true,
    title: strings.menu.member_shop,
    access: 'private',
    component: () => <MemberShop />
  },
  {
    path: '/member/score',
    exact: true,
    title: strings.menu.member_score,
    access: 'private',
    component: () => <MemberScore />
  },
  {
    path: '/member/coupon',
    exact: true,
    title: strings.menu.member_coupon,
    access: 'private',
    component: () => <MemberCoupon />
  },
  {
    path: '/card/today',
    exact: true,
    title: strings.menu.card_today,
    access: 'private',
    component: () => <TodayCard />
  },
  {
    path: '/card/message',
    exact: true,
    title: strings.menu.card_message,
    access: 'private',
    component: () => <CardMessage />
  },
  {
    path: '/card/matching',
    exact: true,
    title: strings.menu.card_matching,
    access: 'private',
    component: () => <CardMatching />
  },
  {
    path: '/wedding/place',
    exact: true,
    title: strings.menu.wedding_place,
    access: 'private',
    component: () => <WeddingPlace />
  },
  {
    path: '/wedding/dress',
    exact: true,
    title: strings.menu.wedding_dress,
    access: 'private',
    component: () => <WeddingDress />
  },
  {
    path: '/wedding/loan',
    exact: true,
    title: strings.menu.wedding_loan,
    access: 'private',
    component: () => <WeddingLoan />
  },
  {
    path: '/meeting/restaurant',
    exact: true,
    title: strings.menu.meeting_restaurant,
    access: 'private',
    component: () => <Restaurant />
  },
  {
    path: '/meeting/restaurant/:restaurantId',
    exact: true,
    title: strings.menu.restaurant_detail,
    access: 'private',
    component: RestaurantDetail
  },
  {
    path: '/meeting/banner',
    exact: true,
    title: strings.menu.restaurant_banner,
    access: 'private',
    component: () => <RestaurantBanner />
  },
  {
    path: '/meeting/meeting',
    exact: true,
    title: strings.menu.meeting_meeting,
    access: 'private',
    component: () => <Meeting />
  },
  {
    path: '/meeting/meeting/:meetingId',
    exact: true,
    title: strings.menu.meeting_detail,
    access: 'private',
    component: MeetingDetail
  },
  {
    path: '/meeting/matching',
    exact: true,
    title: strings.menu.meeting_matching,
    access: 'private',
    component: () => <CardMatching tab={'meeting'} tab_title={strings.menu.meeting_matching} />
  },
  {
    path: '/iap/log',
    exact: true,
    title: strings.menu.iap_log,
    access: 'private',
    component: () => <IapLog />
  },
  {
    path: '/iap/sta',
    exact: true,
    title: strings.menu.iap_sta,
    access: 'private',
    component: () => <Sales />
  },
  {
    path: '/iap/ranking',
    exact: true,
    title: strings.menu.iap_ranking,
    access: 'private',
    component: () => <IapRanking />
  },
  {
    path: '/setting/setting',
    exact: true,
    title: strings.menu.setting_setting,
    access: 'private',
    component: () => <Setting />
  },
  {
    path: '/setting/cron',
    exact: true,
    title: strings.menu.setting_cron,
    access: 'private',
    component: () => <SettingCron />
  },
  {
    path: '/setting/bonus',
    exact: true,
    title: strings.menu.setting_bonus,
    access: 'private',
    component: () => <SettingBonus />
  },
  {
    path: '/setting/push',
    exact: true,
    title: strings.menu.setting_push,
    access: 'private',
    component: () => <SettingPush />
  },
  {
    path: '/setting/point',
    exact: true,
    title: strings.menu.setting_point,
    access: 'private',
    component: () => <PointSend />
  },
  {
    path: '/board/board',
    exact: true,
    title: strings.menu.board_board,
    access: 'private',
    component: () => <Board />
  },
  {
    path: '/board/board/:boardId',
    exact: false,
    title: strings.menu.board_detail,
    access: 'private',
    component: BoardDetail
  },
  {
    path: '/board/comment',
    exact: true,
    title: strings.menu.board_comment,
    access: 'private',
    component: () => <Comment />
  },
  {
    path: '/board/suspend',
    exact: true,
    title: strings.menu.board_suspend,
    access: 'private',
    component: () => <BoardSuspend />
  },
  {
    path: '/board/matching',
    exact: true,
    title: strings.menu.board_matching,
    access: 'private',
    component: () => <CardMatching tab={'board'} tab_title={strings.menu.board_matching} />
  },
  {
    path: '/board/auto',
    exact: true,
    title: strings.menu.board_auto,
    access: 'private',
    component: () => <BoardAuto />
  },
  {
    path: '/band/band',
    exact: true,
    title: strings.menu.band_list,
    access: 'private',
    component: () => <BandList />
  },
  {
    path: '/band/band/:bandId',
    exact: true,
    title: strings.menu.band_detail,
    access: 'private',
    component: BandDetail
  },
  {
    path: '/band/matching',
    exact: true,
    title: strings.menu.band_matching,
    access: 'private',
    component: () => <CardMatching tab={'band'} tab_title={strings.menu.band_matching} />
  },
  {
    path: '/keyword/keyword',
    exact: true,
    title: strings.menu.keyword_keyword,
    access: 'private',
    component: () => <Keyword />
  },
  {
    path: '/keyword/keyword/:keywordId',
    exact: true,
    title: strings.menu.keyword_detail,
    access: 'private',
    component: KeywordDetail
  },
  {
    path: '/keyword/matching',
    exact: true,
    title: strings.menu.keyword_matching,
    access: 'private',
    component: () => <CardMatching tab={'keyword'} tab_title={strings.menu.keyword_matching} />
  },
  {
    path: '/now/now',
    exact: true,
    title: strings.menu.now_list,
    access: 'private',
    component: () => <Now />
  },
  {
    path: '/now/matching',
    exact: true,
    title: strings.menu.keyword_matching,
    access: 'private',
    component: () => <CardMatching tab={'now'} tab_title={strings.menu.now_matching} />
  },
  {
    path: '/zone/location/',
    exact: true,
    title: strings.menu.location_list,
    access: 'private',
    component: () => <ZoneLocation />
  },  
  {
    path: '/zone/location/:locationId',
    exact: true,
    title: strings.menu.location_list,
    access: 'private',
    component: ZoneLocation
  },
  {
    path: '/zone/coupon/',
    exact: true,
    title: strings.menu.coupon_list,
    access: 'private',
    component: () => <ZoneCoupon />
  },
  {
    path: '/zone/event/',
    exact: true,
    title: strings.menu.event_list,
    access: 'private',
    component: () => <ZoneEvent />
  },
  {
    path: '/product/list',
    exact: true,
    title: strings.menu.product_list,
    access: 'private',
    component: () => <Product />
  },
  {
    path: '/product/purchase',
    exact: true,
    title: strings.menu.product_purchase_list,
    access: 'private',
    component: () => <ProductPurchase />
  },
  {
    path: '/product/category',
    exact: true,
    title: strings.menu.product_category_list,
    access: 'private',
    component: () => <ProductCategory />
  },
  {
    path: '/product/company',
    exact: true,
    title: strings.menu.product_company_list,
    access: 'private',
    component: () => <ProductCompany />
  },
  {
    path: '/product/event',
    exact: true,
    title: strings.menu.event,
    access: 'private',
    component: () => <ProductEvent />
  },
  {
    path: '/product/join',
    exact: true,
    title: strings.menu.event_join,
    access: 'private',
    component: () => <ProductEventJoin />
  },
  {
    path: '/setting/term',
    exact: true,
    title: strings.menu.setting_term,
    access: 'private',
    component: () => <Term />
  },
  {
    path: '/setting/popup',
    exact: true,
    title: strings.menu.setting_popup,
    access: 'private',
    component: () => <Popup />
  },
  {
    path: '/setting/popup/:popupId',
    exact: true,
    title: strings.menu.setting_popup_detail,
    access: 'private',
    component: PopupDetail
  },
  {
    path: '/setting/video',
    exact: true,
    title: strings.menu.setting_video,
    access: 'private',
    component: () => <Video />
  },
  {
    path: '/setting/alias',
    exact: true,
    title: strings.menu.setting_alias,
    access: 'private',
    component: () => <Alias />
  },
  {
    path: '/setting/notice',
    exact: true,
    title: strings.menu.setting_notice,
    access: 'private',
    component: () => <Notice />
  },
  {
    path: '/setting/notice/:noticeId',
    exact: true,
    title: strings.menu.setting_notice_detail,
    access: 'private',
    component: NoticeDetail
  },
  {
    path: '/setting/job',
    exact: true,
    title: strings.menu.setting_job,
    access: 'private',
    component: () => <SettingJob />
  },
  {
    path: '/setting/school',
    exact: true,
    title: strings.menu.setting_school,
    access: 'private',
    component: () => <SettingSchool />
  },
  {
    path: '/sta/login',
    exact: true,
    title: strings.menu.sta_login,
    access: 'private',
    component: () => <StaLogin />
  },
  {
    path: '/sta/signup',
    exact: true,
    title: strings.menu.sta_signup,
    access: 'private',
    component: () => <StaSignup />
  },
  {
    path: '/sta/hourly',
    exact: true,
    title: strings.menu.sta_hourly,
    access: 'private',
    component: () => <StaHourly />
  },
  {
    path: '/sta/matching',
    exact: true,
    title: strings.menu.sta_matching,
    access: 'private',
    component: () => <StaMatching />
  },
  {
    path: '/manager/all',
    exact: true,
    title: strings.menu.manager_all,
    access: 'private',
    component: () => <Manager />
  },
  {
    path: '/manager/change_password',
    exact: true,
    title: strings.menu.manager_change_password,
    access: 'private',
    component: () => <ChangePassword />
  },
];

export default routes;