import React from 'react';
import strings from '../../lang/strings.js';
import { PageSettings } from "../../config/page-settings";
import Container from './../../components/content/container.js';
import './../../config/util';

class MemberStatusReasonSta extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
            status_reason_sta: null
        }
    }

    componentDidMount() {
        this.getStatusReasonSta();
    }

    getStatusReasonSta = () => {
        let self = this;

        this.context.get(
            'member/getStatusReasonSta',
            { status: -3 },
            function (response) {
                self.setState({
                    status_reason_sta: response.result_data.status_reason_sta
                });
            });
    };

    render() {
        let self = this;

        return (
            <div>
                <Container title={strings.status_reason_sta_title} style={{ height: "130px" }}>
                    <div className="row">
                        <div className="col-xs-6">
                            {
                                self.state.status_reason_sta && self.state.status_reason_sta.map((entry, index) => index <= 3 && (
                                    <p className="p-0 m-0" key={index}>
                                        {index + 1}. {entry.status_reason}: {entry.total_count.currencyFormat()} ({strings.male} {entry.male_count.currencyFormat()} / {strings.female} {entry.female_count.currencyFormat()})
                                    </p>
                                ))
                            }
                        </div>
                        <div className="col-xs-6">
                            {
                                self.state.status_reason_sta && self.state.status_reason_sta.map((entry, index) => index > 3 && (
                                    <p className="p-0 m-0" key={index}>
                                        {index + 1}. {entry.status_reason}: {entry.total_count.currencyFormat()} ({strings.male} {entry.male_count.currencyFormat()} / {strings.female} {entry.female_count.currencyFormat()})
                                    </p>
                                ))
                            }
                        </div>
                    </div>
                </Container>
            </div>
        )
    }
}

export default MemberStatusReasonSta;