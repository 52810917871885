import React from 'react';
import strings from '../../lang/strings.js';
import { PageSettings } from "../../config/page-settings";
import Container from './../../components/content/container.js';
import './../../config/util';

class MemberMatchingSta extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
            card_sta: null
        }
    }

    componentDidMount() {
        this.getMatchingSta();
    }

    getMatchingSta = () => {
        let self = this;

        this.context.get(
            'dashboard/card_sta',
            {},
            function (response) {
                self.setState({
                    card_sta: response.result_data.card_sta
                });
            });
    };

    render() {
        let self = this;

        return (
            <div>
                <Container title={strings.matching_sta_title} >
                    <div style={{ height: "130px" }}>
                        <div className="d-flex align-items-center text-black">
                            <div className="d-flex w-100">
                                <div>{strings.matching_sta_04}</div>
                                <div className="ml-auto">{self.state.card_sta ? `${self.state.card_sta.today.currencyFormat()} ${strings.couple}` : '-'}</div>
                            </div>
                        </div>
                        <div className="d-flex align-items-center text-black">
                            <div className="d-flex w-100">
                                <div>{strings.matching_sta_05}</div>
                                <div className="ml-auto">{self.state.card_sta ? `${self.state.card_sta.yesterday.currencyFormat()} ${strings.couple}` : '-'}</div>
                            </div>
                        </div>
                        <div className="d-flex align-items-center text-black">
                            <div className="d-flex w-100">
                                <div>{strings.matching_sta_06}</div>
                                <div className="ml-auto">{self.state.card_sta ? `${self.state.card_sta.this_week.currencyFormat()} ${strings.couple}` : '-'}</div>
                            </div>
                        </div>
                        <div className="d-flex align-items-center text-black">
                            <div className="d-flex w-100">
                                <div>{strings.matching_sta_07}</div>
                                <div className="ml-auto">{self.state.card_sta ? `${self.state.card_sta.this_month.currencyFormat()} ${strings.couple}` : '-'}</div>
                            </div>
                        </div>
                        <div className="d-flex align-items-center text-black">
                            <div className="d-flex w-100">
                                <div>{strings.matching_sta_08}</div>
                                <div className="ml-auto">{self.state.card_sta ? `${self.state.card_sta.this_year.currencyFormat()} ${strings.couple}` : '-'}</div>
                            </div>
                        </div>
                        <div className="d-flex align-items-center text-black">
                            <div className="d-flex w-100">
                                <div>{strings.matching_sta_09}</div>
                                <div className="ml-auto">{self.state.card_sta ? `${self.state.card_sta.total.currencyFormat()} ${strings.couple}` : '-'}</div>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        )
    }
}

export default MemberMatchingSta;