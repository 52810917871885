import React from 'react';
import strings from '../../lang/strings.js';
import { PageSettings } from "../../config/page-settings";
import { Panel, PanelHeader } from '../../components/panel/panel.jsx';
import DataTable from './../../components/content/data-table.jsx';
import './../../config/util';

class IapRankingFragment extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
            gender: props.gender
        }
    }

    componentDidMount() {
    }

    componentWillReceiveProps(newProps) {
        if (newProps.gender != this.state.gender) {
            this.setState({ gender: newProps.gender }, () => {
                this.tbl.draw();
            });
        }
    }

    render() {
        let self = this;
        let columnNo = 0;

        return (
            <div>
                <Panel>
                    <PanelHeader noButton={true}>{self.props.title}</PanelHeader>
                    <div style={{ height: "220px", tableLayout: 'fixed', width: '98%' }}>
                        <DataTable ref={(ref) => this.tbl = ref}
                            hideTotalCount={true}
                            customPaginate={false}
                            options={{
                                columnDefs: [
                                    {
                                        targets: [columnNo++], data: self.props.group_by, orderable: false,
                                        render: function (value) {
                                            return self.props.group_by === "gender" ? value.gender2String() : value;
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'total_count', orderable: false,
                                        render: function (value) {
                                            return value.currencyFormat();
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'total_value', orderable: false,
                                        render: function (value) {
                                            return value.currencyFormat();
                                        }
                                    },
                                ],
                                scrollY: "185px",
                                scrollCollapse: true,
                                paging: false,
                                ajax: {
                                    url: 'iap/getRankingList',
                                    data: function (req) {
                                        req.columns = null;
                                        req.group_by = self.props.group_by;
                                        req.gender = self.props.gender;
                                    }
                                },
                            }}>
                            <thead>
                                <tr>
                                    <th>{self.props.group_by === 'school' ? strings.school :
                                        self.props.group_by === 'job' ? strings.job :
                                            self.props.group_by === 'location' ? strings.location :
                                                self.props.group_by === 'birthday' ? strings.generation :
                                                    self.props.group_by === 'gender' ? strings.gender :
                                                        strings.unknown}</th>
                                    <th>{strings.iap_chart_count}</th>
                                    <th>{strings.iap_chart_money}</th>
                                </tr>
                            </thead>
                            <tbody />
                        </DataTable>
                    </div>
                </Panel>
            </div>
        )
    }
}

export default IapRankingFragment;