import React from 'react';
import strings from '../../lang/strings.js';
import $ from 'jquery';
import { PageSettings } from "../../config/page-settings";
import DataTable from './../../components/content/data-table.jsx';
import SearchInput from './../../components/content/search-input.js';
import SearchSelect from './../../components/content/search-select.js';
import './../../config/util';
import Const from './../../config/const';
import { withRouter } from 'react-router-dom';
import { inject } from 'mobx-react';
import MemberDetailPopup from './../member-detail/member-detail-popup'
import MemberSuspendPopup from './../common/member-suspend-popup'
import BoardDetailPopup from './board-detail-popup'
const queryString = require('query-string');

class CommentFragment extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        let searchQS = queryString.parse(this.props.location.search);

        this.state = {
            search_info: searchQS
        };
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.location.search != this.props.location.search) {
            let searchQS = queryString.parse(this.props.location.search);
            this.setState({ search_info: searchQS }, () => this.refreshTable());
        }
    }

    refreshTable = (refresh) => {
        this.tblMember.draw(refresh);
    };

    searchTable = () => {
        let searchQS = queryString.parse(this.props.location.search);
        Object.assign(searchQS, this.state.search_info)
        const new_query = queryString.stringify(searchQS);
        if (new_query == this.props.location.search.substr(1)) {
            this.refreshTable();
        } else {
            this.props.history.push('?' + new_query);
        }
    }

    showMemberDetailPopup(uid) {
        this.setState({
            show_member_profile: true,
            selected_uid: uid
        });
    }

    showBoardDetailPopup(board_id) {
        this.setState({
            show_board_detail: true,
            board_id: board_id
        });
    }

    blindComment(item) {
        let self = this;
        this.context.showConfirm(strings.comment_blind_title, function (response) {
            if (response) {
                self.context.post('board/blindComment', { comment_id: item.id }, function () {
                    self.context.addNotification('success', strings.alert_title, strings.comment_blind_done);
                    self.refreshTable(false);
                }, true);
            }
        });
    }

    deleteComment(item) {
        let self = this;
        this.context.showConfirm(strings.comment_delete_title, function (response) {
            if (response) {
                self.context.post('board/deleteComment', { comment_id: item.id }, function () {
                    self.context.addNotification('success', strings.alert_title, strings.comment_delete_done);
                    self.refreshTable(false);
                }, true);
            }
        });
    }

    suspendMember(uid) {
        this.setState({
            show_member_suspend: true,
            selected_uid: uid
        });
    }

    render() {
        let self = this;
        let columnNo = 0;
        const { search_info } = this.state;

        return (
            <div>
                <MemberDetailPopup isOpen={this.state.show_member_profile} uid={this.state.selected_uid} onClose={() => {
                    this.setState({ show_member_profile: false });
                }} />
                <MemberSuspendPopup isOpen={this.state.show_member_suspend} uid={this.state.selected_uid} onClose={() => {
                    this.setState({ show_member_suspend: false });
                }} onSave={() => {
                    this.setState({ show_member_suspend: false }, () => {
                        self.refreshTable(false);
                    });
                }} />
                <BoardDetailPopup isOpen={this.state.show_board_detail} board_id={this.state.board_id} onClose={() => {
                    this.setState({ show_board_detail: false });
                }} />
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='form-horizontal form-inline'>
                            {/* 키워드검색 */}
                            <SearchSelect
                                data={[{ code: 'comment', name: strings.comment_content }].concat(strings.const.SEARCH_CATEGORY2)}
                                value={search_info.column || strings.const.SEARCH_CATEGORY2[0].code}
                                onChange={(value) => {
                                    this.setState({
                                        search_info: {
                                            ...search_info,
                                            column: value,
                                            start: 0,
                                        }
                                    }, this.searchTable);
                                }}
                            />
                            <SearchInput
                                value={search_info.keyword}
                                onChange={(value) => {
                                    this.setState({
                                        search_info: {
                                            ...search_info,
                                            keyword: value,
                                            column: search_info.column || strings.const.SEARCH_CATEGORY2[0].code,
                                            start: 0,
                                        }
                                    })
                                }}
                                handler={(value) => this.searchTable()}
                            />

                            <button type='button' className='btn btn-success btn-sm ml-md-2' onClick={() => this.searchTable()}>{strings.search}</button>
                        </div>
                    </div>
                </div>
                <div className='row mt-3'>
                    <div className='col-md-12'>
                        <DataTable ref={(ref) => this.tblMember = ref}
                            customPaginate={true}
                            initialPage={search_info.start || 0}
                            onPageChange={(value) => {
                                this.setState({
                                    search_info: {
                                        ...search_info,
                                        start: value,
                                    }
                                }, this.searchTable);
                            }}
                            options={{
                                columnDefs: [
                                    {
                                        targets: [columnNo++], data: 'id', orderable: false,
                                        render: function (value) {
                                            return value;
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'comment', orderable: false,
                                        createdCell: function (td, value, item) {
                                            $(td).html('');
                                            $(td).append(`<a href="javascript:;">${value}</a>`)
                                            $(td).find('a')
                                                .on('click', function () {
                                                    self.showBoardDetailPopup(item.board_id);
                                                });
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'nickname', orderable: false,
                                        createdCell: function (td, value, item) {
                                            $(td).nickname_label(value, item.gender, () => {
                                                self.showMemberDetailPopup(item.uid);
                                            });
                                            if (item.alias) {
                                                $(td).append(`<br/>(${item.alias})`);
                                            }
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'create_date', orderable: false,
                                        render: function (value) {
                                            return value
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'like_count', orderable: false,
                                        render: function (value) {
                                            return value.currencyFormat()
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'report_count', orderable: false,
                                        render: function (value) {
                                            if (value >= 10) {
                                                return `<span style="color: red">${value.currencyFormat()}</span>`;
                                            } else {
                                                return value.currencyFormat()
                                            }
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'status', orderable: false,
                                        render: function (value) {
                                            if (value == -1) {
                                                return `<span style="color: red">삭제됨</span>`;
                                            } else {
                                                return '정상';
                                            }
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'id', orderable: false,
                                        createdCell: function (td, value, item) {
                                            $(td).html('');
                                            $(td).append(`<button type='button' class='btn btn-yellow btn-sm m-2'>${strings.suspend}</button>`)
                                                .find('button:eq(0)')
                                                .on('click', function () {
                                                    self.suspendMember(item.uid);
                                                });
                                            $(td).append(`<button type='button' class='btn btn-danger btn-sm m-2'>${strings.delete}</button>`)
                                                .find('button:eq(1)')
                                                .on('click', function () {
                                                    self.deleteComment(item);
                                                });
                                            $(td).append(`<button type='button' class='btn btn-inverse btn-sm m-2'>${strings.blind}</button>`)
                                                .find('button:eq(2)')
                                                .on('click', function () {
                                                    self.blindComment(item);
                                                });
                                        }
                                    },
                                ],
                                order: [[1, 'DESC']],
                                ajax: {
                                    url: 'board/getCommentList',
                                    data: function (req) {
                                        req.columns = null;

                                        const searchQS = queryString.parse(self.props.location.search);
                                        req.start = searchQS.start || 0;
                                        req.length = searchQS.length || 20;
                                        req.status = self.props.status;
                                        req.column = searchQS.column;
                                        req.keyword = searchQS.keyword;
                                    }
                                },
                                drawCallback: function () {
                                },
                                fnCreatedRow: function (row, data, dataInd) {
                                }
                            }}>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th width="40%">{strings.comment_content}</th>
                                    <th>{strings.creator}</th>
                                    <th>{strings.create_date}</th>
                                    <th>{strings.like}</th>
                                    <th>{strings.report}</th>
                                    <th>{strings.status}</th>
                                    <th>{strings.manage}</th>
                                </tr>
                            </thead>
                            <tbody />
                        </DataTable>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(inject('rootStore')(CommentFragment));