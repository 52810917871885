import React from 'react';
import strings from '../../lang/strings.js';
import { PageSettings } from "../../config/page-settings";
import DataTable from './../../components/content/data-table.jsx';
import './../../config/util';

class MemberHeartHistory extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {}
    }

    componentDidMount() {
    }

    render() {
        let user_info = this.props.user_info;
        let columnNo = 0;

        return (
            <div>
                <div className="row">
                    <label>{strings.member_detail_106}&nbsp;:&nbsp;</label>
                    <label>{user_info.heart}{strings.heart}</label>
                </div>
                <div className="row">
                    <label>{strings.member_detail_107}&nbsp;:&nbsp;</label>
                    <label>{user_info.point}{strings.point}</label>
                </div>
                <div className="row mt-2">
                    <DataTable ref={(ref) => this.tblMember = ref}
                        options={{
                            columnDefs: [
                                {
                                    targets: [columnNo++], data: 'create_date', orderable: false,
                                    render: function (value) {
                                        return value;
                                    }
                                },
                                {
                                    targets: [columnNo++], data: 'heart', orderable: false,
                                    render: function (value) {
                                        return value > 0 ? '<span style="color: blue">하트적립</span>' : '<span style="color: red">하트사용</span>';
                                    }
                                },
                                {
                                    targets: [columnNo++], data: 'reason', orderable: false,
                                    render: function (value) {
                                        return value
                                    }
                                },
                                {
                                    targets: [columnNo++], data: 'heart', orderable: false,
                                    render: function (value) {
                                        return value
                                    }
                                },
                            ],
                            iDisplayLength: 10,
                            order: [[1, 'DESC']],
                            ajax: {
                                url: 'member/getHeartHistory',
                                data: function (req) {
                                    req.columns = null;
                                    req.column = 'uid';
                                    req.keyword = user_info.id;
                                }
                            },
                            drawCallback: function () {
                            },
                            fnCreatedRow: function (row, data, dataInd) {
                            }
                        }}>
                        <thead>
                            <tr>
                                <th>{strings.date}</th>
                                <th>{strings.content}</th>
                                <th>{strings.detail}</th>
                                <th>{strings.heart}</th>
                            </tr>
                        </thead>
                        <tbody />
                    </DataTable>
                </div>
            </div >
        )
    }
}

export default MemberHeartHistory;