import React from 'react';
import strings from '../../lang/strings.js';
import { PageSettings } from "../../config/page-settings";
import { Panel, PanelBody, PanelHeader, PanelFooter } from '../../components/panel/panel.jsx';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import 'react-datetime/css/react-datetime.css';
import './../../config/util';
import U from './../../config/util';
import Const from './../../config/const';
import ImageSelect from './../../components/content/image-select.jsx';

class NoticeDetail extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
            notice_id: this.props.match.params.noticeId,
            item: {},
            image_list_urls: [],
        };

        this.image_list_files = [];
    }

    componentDidMount() {
        this.getData();
    }

    getData = () => {
        $('#uid').focus();

        if (this.state.notice_id === 'add') {
            return;
        }

        let self = this;

        this.context.post(
            'notice/getItem',
            {
                notice_id: this.state.notice_id
            },
            function (response) {
                let item = response.result_data.notice_info;

                self.setState({
                    item,
                    image_list_urls: U.getThumbList(item.image_urls)
                });
                self.image_list_urls = [];
            });
    };

    uploadData = (image_files, callback) => {

        let self = this;

        let formData = new FormData();
        image_files.forEach((entry, index) => {
            formData.append('file', entry, entry.name);
        });


        this.context.post(
            'upload/images/notice',
            formData,
            function (response) {
                callback(response.result_data.upload_path);
            },
            true
        );
    }

    saveData = () => {
        let self = this;
        const item = self.state.item;

        if (this.image_list_files.length > 0) {
            this.uploadData(this.image_list_files, function (image_urls) {
                let response_index = 0;
                self.image_list_files.forEach((entry, index) => {
                    self.state.image_list_urls[index] = U.getThumbUrl(image_urls[response_index])
                    response_index = response_index + 1
                });

                self.image_list_files = [];

                self.saveData();
            });
            return;
        }

        item.image_urls = self.state.image_list_urls.map(it => { return it.replace('_thumb.', '.') }).urlArray2String();

        if (!item.category) {
            self.context.addNotification('warning', strings.alert_title, strings.empty_category);
            return;
        }
        if (!item.title) {
            self.context.addNotification('warning', strings.alert_title, strings.empty_title);
            return;
        }
        if (!item.content) {
            self.context.addNotification('warning', strings.alert_title, strings.empty_content);
            return;
        }

        this.context.post('notice/save',
            {
                notice_info: item
            }, function (response) {
                self.context.addNotification('success', strings.alert_title, strings.save_done);
                if (self.props.onSave) {
                    self.props.onSave();
                }
            }, true);
    };

    onDataChange = (e) => {
        let item = this.state.item;
        item[e.target.id] = e.target.value;

        this.setState({
            item: item
        });
    };

    onImageChange = (e, idx) => {
        let self = this;

        if (e.target.files.length < 1) {
            return;
        }

        let file = e.target.files[0];
        let reader = new FileReader();

        reader.onloadend = function () {
            self.state.image_list_urls[idx] = reader.result;
            self.image_list_files[idx] = file;
            self.setState({ image_list_urls: self.state.image_list_urls });
        };
        reader.readAsDataURL(file);
    };

    render() {
        let item = this.state.item;

        return (
            <div>
                <div className="row">
                    <div className='col-xl-8 col-lg-10 col-md-12'>
                        <Panel>
                            <PanelHeader noButton={true}>{strings.notice_detail}</PanelHeader>
                            <PanelBody>
                                <div className="row">
                                    <div className="col-xs-12">
                                        <div className='row'>
                                            <label className='col-md-2 col-form-label'>{strings.category} *</label>
                                            <div className="col-md-10">
                                                <select className='form-control' id='category'
                                                    onChange={(e) => this.onDataChange(e)}
                                                    value={item.category}>
                                                    <option key={-1} value="">({strings.select_category})</option>
                                                    {strings.const.NOTICE_CATEGORY.map((entry, index) => (
                                                        <option key={index} value={entry}>{entry}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <label className='col-md-2 col-form-label'>{strings.title} *</label>
                                            <div className="col-md-10">
                                                <div className="row">
                                                    <div className='col-md-12'>
                                                        <input type='text' autoComplete='off' className='form-control' id='title'
                                                            value={item.title || ''}
                                                            onChange={(e) => this.onDataChange(e)} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <label className='col-md-2 col-form-label'>{strings.content} *</label>
                                            <div className='col-md-10'>
                                                <textarea
                                                    id='content'
                                                    className='form-control'
                                                    rows={20}
                                                    value={item.content}
                                                    onChange={(e) => this.onDataChange(e)} />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <label className='col-md-2 col-form-label'>{strings.image} ({strings.optinoal})</label>
                                            <div className="col-md-10">
                                                <div className='row mb-1 mt-1'>
                                                    {
                                                        this.state.image_list_urls.map((image_url, idx) => (
                                                            <div className='col-xs-4 col-sm-3 col-md-4 col-xl-4 col-xxl-3' key={idx}>
                                                                <ImageSelect
                                                                    height="70%"
                                                                    image_url={image_url}
                                                                    onSelect={(e) => this.onImageChange(e, idx)}
                                                                    onDelete={() => {
                                                                        this.state.image_list_urls.splice(idx, 1);
                                                                        this.image_list_files.splice(idx, 1);
                                                                        this.setState({ image_list_urls: this.state.image_list_urls });
                                                                    }} />
                                                            </div>
                                                        ))
                                                    }

                                                    <div className='col-xs-4 col-sm-3 col-md-4 col-xl-4 col-xxl-3'>
                                                        <ImageSelect
                                                            height="70%"
                                                            onSelect={(e) => this.onImageChange(e, this.state.image_list_urls.length)}
                                                            onDelete={() => { }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <label className='col-md-2 col-form-label'>{strings.show}/{strings.hide}</label>
                                            <div className="col-md-10">
                                                <select className='form-control' id='status'
                                                    onChange={(e) => this.onDataChange(e)}
                                                    value={item.status}>
                                                    <option value={'N'}>{strings.hide}</option>
                                                    <option value={'Y'}>{strings.just_show}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <label className='col-md-2 col-form-label'>{strings.visit_count}</label>
                                            <div className="col-md-10">
                                                <div className="row">
                                                    <div className='col-md-12'>
                                                        <input type='number' autoComplete='off' className='form-control' id='visit_count'
                                                            value={item.visit_count || ''}
                                                            onChange={(e) => this.onDataChange(e)} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <label className='col-md-2 col-form-label'>{strings.create_date}</label>
                                            <label className='col-md-10 col-form-label'>{item.create_date}</label>
                                        </div>
                                    </div>
                                </div>
                            </PanelBody>
                            <PanelFooter className={"text-right"}>
                                <div className="d-flex w-100">
                                    <div>
                                        <Link to="/setting/notice">
                                            <button type='button' className='btn btn-dark btn-sm m-2'>{strings.list}</button>
                                        </Link>
                                    </div>
                                    <div className="ml-auto">
                                        <button type='button' className='btn btn-success btn-sm m-2' onClick={() => { this.saveData() }}>{strings.save}</button>
                                    </div>
                                </div>
                            </PanelFooter>
                        </Panel>
                    </div >
                </div >
            </div >
        )
    }
}

export default NoticeDetail;