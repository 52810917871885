import React from 'react';
import { inject } from 'mobx-react';
import strings from '../../lang/strings.js';
import { PageSettings } from "../../config/page-settings";
import './../../config/util';
import Const from './../../config/const';
import U from './../../config/util';

class MemberBadge extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
            require_save: false,
        };
    }

    componentDidMount() {
    }

    render() {
        let user_info = this.props.user_info;
        const badge_list = user_info.badge ? user_info.badge.split('#') : [];
        const badge_data = this.props.rootStore.getBadge();

        return (
            <div>
                <div className="row">
                    <label className="text-danger">{this.state.require_save ? strings.badge_require_save : "-"}</label>
                </div>
                <div className="row mt-2">
                    <table className="table table-bordered table-td-valign-middle table-th-valign-middle">
                        <thead>
                            <tr>
                                <th>{strings.badge}</th>
                                <th>{strings.cert_content}</th>
                                <th>{strings.status}</th>
                                <th>{strings.manage}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* {
                                user_info.badge && user_info.badge.split('#').map((item, index) => {
                                    const badge_group = badge_data.find(group => group.badges.find(it => it.code == item));
                                    const badge_item = badge_group && badge_group.badges.find(it => it.code == item);

                                    if (!badge_item) {
                                        return;
                                    }

                                    return (
                                        <tr>
                                            <td>{badge_group.name}</td>
                                            <td>{badge_item.title}</td>
                                            <td>
                                                <img
                                                    key={index}
                                                    src={Const.BASE_URL + badge_item.icon_on}
                                                    style={{ height: '24px', width: 'auto', margin: 2 }}
                                                />
                                            </td>
                                            <td>
                                                <button className="btn btn-danger btn-sm m-l-5" onClick={() => {
                                                    badge_list.splice(index, 1);
                                                    user_info.badge = badge_list.join('#');
                                                    this.setState({ user_info, require_save: true });
                                                }}>{strings.delete}</button>
                                            </td>
                                        </tr>
                                    )
                                })
                            } */}
                            {
                                badge_data.map((badge_group, index) => {
                                    return (
                                        badge_group.badges.map((badge_item, index2) => {
                                            const item_idx = badge_list.indexOf(badge_item.code);
                                            return (
                                                <tr>
                                                    <td>
                                                        <img
                                                            key={index}
                                                            src={Const.BASE_URL + badge_item.icon_on}
                                                            style={{ height: '24px', width: 'auto', margin: 2 }}
                                                        />
                                                    </td>
                                                    <td>{badge_group.name} - {badge_item.title}</td>
                                                    {
                                                        item_idx >= 0 ?
                                                            <td>
                                                                <span style={{ color: 'red' }}>{strings.have}</span>
                                                            </td>
                                                            : <td>-</td>
                                                    }
                                                    <td>
                                                        {
                                                            item_idx >= 0 ?
                                                                <button className="btn btn-danger btn-sm m-l-5" onClick={() => {
                                                                    badge_list.splice(item_idx, 1);
                                                                    user_info.badge = badge_list.join('#');
                                                                    this.setState({ user_info, require_save: true });
                                                                }}>{strings.delete}</button>
                                                                :
                                                                <button className="btn btn-primary btn-sm m-l-5" onClick={() => {
                                                                    badge_list.push(badge_item.code);
                                                                    user_info.badge = badge_list.join('#');
                                                                    this.setState({ user_info, require_save: true });
                                                                }}>{strings.have}</button>
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div >
        )
    }
}

export default inject('rootStore')(MemberBadge);