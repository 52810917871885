import React from 'react';
import { inject } from 'mobx-react';
import strings from '../../lang/strings.js';
import $ from 'jquery';
import { PageSettings } from "../../config/page-settings";
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import './../../config/util';
import Const from './../../config/const';

class MemberChangeStatusPopup extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
            status_reason: ''
        };
        this.toggleModal = this.toggleModal.bind(this);
    }

    toggleModal(result) {
        if (this.props.onClose) {
            this.props.onClose(result);
            this.setState({ status_reason: '' });
        }
    }

    getData = () => {
        this.setState({ status_reason: this.props.pending_item.status_reason });
    };

    onApply() {
        if (this.props.onApply) {
            if (this.state.status_reason === '') {
                this.context.addNotification('warning', strings.alert_title, strings.empty_reject_reason);
                return;
            }

            this.props.onApply(this.state.status_reason);
            this.setState({ status_reason: '' });
        }
    }

    componentDidMount() {
    }

    render() {
        const badge_data = this.props.rootStore.getBadge();
        const pending_item = this.props.pending_item;
        const status_reason = this.state.status_reason;

        if (!pending_item) {
            return (<div />)
        }

        const badge_group = badge_data.find(it => it.code == pending_item.category);
        const badge_item = badge_group && badge_group.badges.find(it => it.code == pending_item.code)

        let title = badge_item ? (badge_item.title + ' ' + strings.reject) : '';
        let reason_list = [];
        if (pending_item.category == Const.USER_PENDING_TYPE.PROFILE_IMAGE) {
            title = strings.reject_profile_image;
            reason_list = strings.const.USER_PROFILE_REJECT_REASON;
        } else if (pending_item.category == Const.USER_PENDING_TYPE.INTRO) {
            title = strings.reject_intro;
            reason_list = strings.const.USER_INTRO_REJECT_REASON;
        } else if (pending_item.category == Const.USER_PENDING_TYPE.SCHOOL) {
            reason_list = strings.const.USER_SCHOOL_REJECT_REASON;
        } else if (pending_item.category == Const.USER_PENDING_TYPE.JOB) {
            reason_list = strings.const.USER_JOB_REJECT_REASON;
        } else {
            reason_list = strings.const.USER_OTHER_REJECT_REASON;
        }

        return (
            <div>
                <Modal isOpen={this.props.isOpen} toggle={this.toggleModal} onOpened={() => this.getData()}>
                    <ModalHeader className="popup-title" toggle={this.toggleModal}>
                        {title}
                    </ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <div className="col-xl-12">
                                <select className='form-control'
                                    onChange={(e) => this.setState({ status_reason: e.target.value })}>
                                    <option value="">{strings.select_reason}</option>
                                    {
                                        reason_list.map(it => (
                                            <option>{it}</option>
                                        ))
                                    }
                                </select>
                                <input type='text' autoComplete='off' className='form-control mt-3' id='status_reason' placeholder={strings.direct_input}
                                    value={status_reason}
                                    onChange={(e) => this.setState({ status_reason: e.target.value })} />
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-default" onClick={this.toggleModal}>{strings.close}</button>
                        <button className="btn btn-success" onClick={() => this.onApply()}>{strings.apply}</button>
                    </ModalFooter>
                </Modal>
            </div >
        )
    }
}

export default inject('rootStore')(MemberChangeStatusPopup);