import React from 'react';
import strings from '../../lang/strings.js';
import $ from 'jquery';
import { PageSettings } from "../../config/page-settings";
import { Panel, PanelBody, PanelHeader } from '../../components/panel/panel.jsx';
import DataTable from './../../components/content/data-table.jsx';
import './../../config/util';
import { withRouter } from 'react-router-dom';
import { inject } from 'mobx-react';
import Const from './../../config/const';

class Notice extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {

        }

        this.showDetail = this.showDetail.bind(this);
        this.delete = this.delete.bind(this);
    }

    componentDidMount() {
    }

    refreshTable = (refresh) => {
        this.tblMember.draw(refresh);
    };

    showDetail(notice) {
        this.props.history.push(`/setting/notice/${notice.id}`);
    }

    delete(notice) {
        let self = this;
        this.context.showConfirm(strings.confirm_delete_title, function (response) {
            if (response) {
                self.context.post('notice/delete', { notice_id: notice.id }, function () {
                    self.context.addNotification('success', strings.alert_title, strings.confirm_delete_done);
                    self.refreshTable(false);
                }, true);
            }
        });
    }

    render() {
        let self = this;
        let columnNo = 0;

        return (
            <div>
                <Panel>
                    <PanelHeader noButton={true}>{strings.notice_title}</PanelHeader>
                    <PanelBody>
                        <div className='row'>
                            <button type='button' className='btn btn-success btn-sm ml-3' onClick={() => {
                                this.props.history.push(`/setting/notice/add`);
                            }}>{strings.add}</button>
                        </div>
                        <div className='row mt-2'>
                            <div className='col-md-12'>
                                <DataTable ref={(ref) => this.tblMember = ref}
                                    options={{
                                        columnDefs: [
                                            {
                                                targets: [columnNo++], data: 'id', orderable: false,
                                                render: function (value) {
                                                    return value;
                                                }
                                            },
                                            {
                                                targets: [columnNo++], data: 'title', orderable: false,
                                                createdCell: function (td, value, item) {
                                                    $(td).html('');
                                                    $(td).append(`<a href="javascript:;">${value}</a>`)
                                                        .find('a')
                                                        .on('click', function () {
                                                            self.showDetail(item);
                                                        });
                                                }
                                            },
                                            {
                                                targets: [columnNo++], data: 'image_urls', orderable: false,
                                                createdCell: function (td, value, item) {
                                                    if (value) {
                                                        return $(td).image_lightbox(value, () => {
                                                            self.context.showGallery(value.split('#').map((it) => { return Const.BASE_URL + it }))
                                                        });
                                                    } else {
                                                        return $(td).html('');
                                                    }
                                                }
                                            },
                                            {
                                                targets: [columnNo++], data: 'status', orderable: false,
                                                render: function (value) {
                                                    return value === 'Y' ? '<span class="female">현시중</span>' : '<span class="male">숨김</span>'
                                                }
                                            },
                                            {
                                                targets: [columnNo++], data: 'create_date', orderable: false,
                                                render: function (value) {
                                                    return value
                                                }
                                            },
                                            {
                                                targets: [columnNo++], data: 'visit_count', orderable: false,
                                                render: function (value) {
                                                    return value.currencyFormat();
                                                }
                                            },
                                            {
                                                targets: [columnNo++], data: 'id', orderable: false,
                                                createdCell: function (td, value, item) {
                                                    $(td).html('');
                                                    $(td).append(`<button type='button' class='btn btn-success btn-sm ml-3'>수정</button>`)
                                                        .find('button')
                                                        .on('click', function () {
                                                            self.showDetail(item);
                                                        });
                                                    $(td).append(`<button type='button' class='btn btn-danger btn-sm ml-3'>${strings.delete}</button>`)
                                                        .find('button:last')
                                                        .on('click', function () {
                                                            self.delete(item);
                                                        });
                                                }
                                            },
                                        ],
                                        order: [[1, 'DESC']],
                                        ajax: {
                                            url: 'notice/getList',
                                            data: function (req) {
                                                req.columns = null;
                                            }
                                        },
                                        drawCallback: function () {
                                        },
                                        fnCreatedRow: function (row, data, dataInd) {
                                        }
                                    }}>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>{strings.title}</th>
                                            <th>{strings.image}</th>
                                            <th>{strings.status}</th>
                                            <th>{strings.create_date}</th>
                                            <th>{strings.visit_count}</th>
                                            <th>{strings.manage}</th>
                                        </tr>
                                    </thead>
                                    <tbody />
                                </DataTable>
                            </div>
                        </div>
                    </PanelBody>
                </Panel>
            </div>
        )
    }
}

export default withRouter(inject('rootStore')(Notice));