import React from 'react';
import strings from '../../lang/strings.js';
import { PageSettings } from "../../config/page-settings";
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import './../../config/util';
import Const from './../../config/const';
import DaumPostcode from 'react-daum-postcode';
import axios from 'axios';


class AddressSearchPopup extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
        };

        this.toggleModal = this.toggleModal.bind(this);
    }

    componentDidMount() {
    }

    toggleModal(result) {
        if (this.props.onClose) {
            this.props.onClose(result);
        }
    }

    handleAddress = async (data) => {
        let fullAddress = data.address;
        let extraAddress = '';

        if (data.addressType === 'R') {
            if (data.bname !== '') {
                extraAddress += data.bname;
            }
            if (data.buildingName !== '') {
                extraAddress += (extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName);
            }
            fullAddress += (extraAddress !== '' ? ` (${extraAddress})` : '');
        }

        console.log(fullAddress);  // e.g. '베를린 할렘구 킹텐리로2길 20 (슨워터동1가)'

        const location = await this.address2Location(data.address);

        if (this.props.onSave) {
            this.props.onSave({
                address: data.address,
                address_detail: extraAddress,
                latitude: (location && location.documents && location.documents.length > 0) ? location.documents[0].y : 0,
                longitude: (location && location.documents && location.documents.length > 0) ? location.documents[0].x : 0,
            });
        }
    }

    address2Location = async (address) => {
        try {
            let result = await axios.get('https://dapi.kakao.com/v2/local/search/keyword.json?query=' + address, {
                headers: {
                    'Authorization': `KakaoAK ${Const.KAKAO_REST_API_KEY}`,
                }
            });
            return result.data;
        } catch (error) {
            console.log(error);
            return null;
        }
    }

    render() {
        return (
            <div>
                <Modal isOpen={this.props.isOpen} toggle={this.toggleModal}>
                    <ModalHeader className="popup-title" toggle={this.toggleModal}>Daum {strings.address_search}</ModalHeader>
                    <ModalBody>
                        <DaumPostcode onComplete={this.handleAddress} />
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-default" onClick={this.toggleModal}>{strings.close}</button>
                    </ModalFooter>
                </Modal>
            </div >
        )
    }
}

export default AddressSearchPopup;