import React from 'react';
import strings from '../../lang/strings.js';
import $ from 'jquery';
import { PageSettings } from "../../config/page-settings";
import { Panel, PanelBody, PanelHeader } from '../../components/panel/panel.jsx';
import DataTable from './../../components/content/data-table.jsx';
import './../../config/util';
import { withRouter } from 'react-router-dom';
import { inject } from 'mobx-react';
import Const from './../../config/const';

class Cron extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {

        }

        this.showCond = this.showCond.bind(this);
        this.showDetail = this.showDetail.bind(this);
        this.delete = this.delete.bind(this);
    }

    componentDidMount() {
    }

    refreshTable = (refresh) => {
        this.tblMember.draw(refresh);
    };

    showCond(item) {
        let cond_str = '';
        const cond = JSON.parse(item.cond);

        if (cond.cond1_enable === 'Y' && cond.search_user_type && cond.search_user_value) {
            cond_str = cond_str + `${strings.const.SEARCH_CATEGORY[cond.search_user_type] + "이(가) "} '${cond.search_user_value}'`;
        }

        if (cond.cond2_enable === 'Y' && cond.search_gender) {
            cond_str = cond_str + `\n성별이 '${cond.search_gender === 'F' ? strings.female : 'M' ? strings.male : strings.total}'`;
        }

        if (cond.cond3_enable === 'Y' && cond.search_iap_date && cond.search_iap_value) {
            cond_str = cond_str + `\n${cond.search_iap_date}에 ${cond.search_iap_value} 이상 결제`;
        }

        if (cond.cond4_enable === 'Y' && cond.search_age_from && cond.search_age_to) {
            cond_str = cond_str + `\n나이가 ${cond.search_age_from} ~ ${cond.search_age_to} 사이`;
        }

        if (cond.cond5_enable === 'Y' && cond.search_device_os) {
            cond_str = cond_str + `\n설치기기가 ${cond.search_device_os === 'A' ? 'Android' : 'I' ? 'iOS' : '전체'}`;
        }

        if (cond.cond6_enable === 'Y' && cond.search_location) {
            cond_str = cond_str + `\n지역이 ${cond.search_location}`;
        }

        if (cond.cond7_enable === 'Y' && cond.search_job) {
            cond_str = cond_str + `\n직업이 '${cond.search_job}'`;
        }

        if (cond.cond8_enable === 'Y' && cond.search_height_from && cond.search_height_to) {
            cond_str = cond_str + `\n키가 ${cond.search_height_from} ~ ${cond.search_height_to} 사이`;
        }

        if (cond.cond9_enable === 'Y' && cond.search_religion) {
            cond_str = cond_str + `\n종교가 '${cond.search_religion}'`;
        }

        if (cond.cond10_enable === 'Y' && cond.search_from_signup_date) {
            cond_str = cond_str + `\n가입을 ${cond.search_from_signup_date}이후에 진행`;
        }

        if (cond.cond11_enable === 'Y' && cond.search_min_invite_count) {
            cond_str = cond_str + `\n친구가입을 ${cond.search_min_invite_count}명 이상 시킨 회원`;
        }

        if (cond.cond12_enable === 'Y' && cond.search_score_from && cond.search_score_to) {
            cond_str = cond_str + `\n인기도가 상위 ${cond.search_score_from} ~ ${cond.search_score_to}% 사이`;
        }

        if (cond.cond13_enable === 'Y' && cond.search_invite_code) {
            cond_str = cond_str + `\n추천인코드가 ${cond.search_invite_code}인 회원`;
        }

        if (cond_str === '') {
            cond_str = '모든 회원';
        } else {
            cond_str = cond_str + '\n-----------\n위의 모든 조건에 해당한 회원';
        }

        this.context.showAlert(cond_str);
    }

    showDetail(item) {
        let self = this;
        this.context.showConfirm(strings.confirm_cron_send, function (response) {
            if (response) {
                self.context.post('cron/handleReserveItem', { id: item.id }, function (response) {
                    self.context.addNotification('success', strings.alert_title, response.result_data);
                    self.refreshTable(false);
                }, true);
            }
        });
    }

    delete(item) {
        let self = this;
        this.context.showConfirm(strings.confirm_delete_title, function (response) {
            if (response) {
                self.context.post('cron/delete', { id: item.id }, function () {
                    self.context.addNotification('success', strings.alert_title, strings.confirm_delete_done);
                    self.refreshTable(false);
                }, true);
            }
        });
    }

    render() {
        let self = this;
        let columnNo = 0;

        return (
            <div>
                <Panel>
                    <PanelHeader noButton={true}>{strings.cron_title}</PanelHeader>
                    <PanelBody>
                        <div className='row mt-2'>
                            <div className='col-md-12'>
                                <DataTable ref={(ref) => this.tblMember = ref}
                                    options={{
                                        columnDefs: [
                                            {
                                                targets: [columnNo++], data: 'id', orderable: false,
                                                render: function (value) {
                                                    return value;
                                                }
                                            },
                                            {
                                                targets: [columnNo++], data: 'type', orderable: false,
                                                render: function (value) {
                                                    return value;
                                                }
                                            },
                                            {
                                                targets: [columnNo++], data: 'value', orderable: false,
                                                createdCell: function (td, value, item) {
                                                    let content = ''
                                                    if (item.type === 'POINT') {
                                                        content = value + strings.point
                                                    } else if (item.type === 'HEART') {
                                                        content = value + strings.heart
                                                    } else if (item.type === 'PUSH') {
                                                        content = value
                                                    } else if (item.type === 'CARD') {
                                                        content = value + '장'
                                                    }
                                                    $(td).html(content);
                                                }
                                            },
                                            {
                                                targets: [columnNo++], data: 'send_date', orderable: false,
                                                render: function (value) {
                                                    return value;
                                                }
                                            },
                                            {
                                                targets: [columnNo++], data: 'status', orderable: false,
                                                render: function (value) {
                                                    return value === 'NEW' ? `<span class="female">${strings.send_pending}</span>` : strings.send_completed;
                                                }
                                            },
                                            {
                                                targets: [columnNo++], data: 'status_date', orderable: false,
                                                createdCell: function (td, value, item) {
                                                    let content = ''
                                                    if (item.status === 'NEW') {
                                                        content = '-'
                                                    } else if (item.status === 'DONE') {
                                                        content = value
                                                    } else {
                                                        content = '-'
                                                    }
                                                    $(td).html(content);
                                                }
                                            },
                                            {
                                                targets: [columnNo++], data: 'status_reason', orderable: false,
                                                render: function (value) {
                                                    return value
                                                }
                                            },
                                            {
                                                targets: [columnNo++], data: 'create_date', orderable: false,
                                                render: function (value) {
                                                    return value
                                                }
                                            },
                                            {
                                                targets: [columnNo++], data: 'cond', orderable: false,
                                                createdCell: function (td, value, item) {
                                                    $(td).html('');
                                                    $(td).append(`<button type='button' class='btn btn-primary btn-sm m-2'>발송조건</button>`)
                                                        .find('button')
                                                        .on('click', function () {
                                                            self.showCond(item);
                                                        });
                                                }
                                            },
                                            {
                                                targets: [columnNo++], data: 'id', orderable: false,
                                                createdCell: function (td, value, item) {
                                                    $(td).html('');
                                                    $(td).append(`<button type='button' class='btn btn-success btn-sm m-2'>바로실행</button>`)
                                                        .find('button')
                                                        .on('click', function () {
                                                            self.showDetail(item);
                                                        });
                                                    $(td).append(`<button type='button' class='btn btn-danger btn-sm m-2'>${strings.delete}</button>`)
                                                        .find('button:last')
                                                        .on('click', function () {
                                                            self.delete(item);
                                                        });
                                                }
                                            },
                                        ],
                                        order: [[1, 'DESC']],
                                        ajax: {
                                            url: 'cron/getList',
                                            data: function (req) {
                                                req.columns = null;
                                            }
                                        },
                                        drawCallback: function () {
                                        },
                                        fnCreatedRow: function (row, data, dataInd) {
                                        }
                                    }}>
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <th>{strings.type}</th>
                                            <th width="20%">{strings.content}</th>
                                            <th>{strings.reserve_date}</th>
                                            <th>{strings.status}</th>
                                            <th>{strings.proceed_date}</th>
                                            <th width="25%">{strings.send_result}</th>
                                            <th>{strings.create_date}</th>
                                            <th>{strings.send_condition}</th>
                                            <th>{strings.manage}</th>
                                        </tr>
                                    </thead>
                                    <tbody />
                                </DataTable>
                            </div>
                        </div>
                    </PanelBody>
                </Panel>
            </div>
        )
    }
}

export default withRouter(inject('rootStore')(Cron));