import React from 'react';
import { withRouter } from 'react-router-dom';
import { inject } from 'mobx-react';
import strings from '../../lang/strings.js';
import { PageSettings } from "../../config/page-settings";
import { Panel, PanelBody } from '../../components/panel/panel.jsx';
import { Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import './../../config/util';
import AliasLocationFragment from './alias-location-fragment'
import AliasStarFragment from './alias-star-fragment'
const queryString = require('query-string');

class Alias extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
        };

        this.toggle = this.toggle.bind(this);
    }

    componentDidMount() {
    }

    toggle(tab, gender) {
        const searchQS = {};
        searchQS.tab = tab;
        if (gender) {
            searchQS.gender = gender;
        }
        this.props.history.push('?' + queryString.stringify(searchQS));
    }

    render() {
        const searchQS = queryString.parse(this.props.location.search);
        const tab = searchQS.tab || 'alias';
        const gender = searchQS.gender || '';

        return (
            <div>
                <div className="row">
                    <div className="col-xs-12">
                        <Panel>
                            <Nav tabs className="nav-tabs-inverse">
                                <NavItem>
                                    <NavLink className={classnames({ active: tab === 'alias' })} onClick={() => { this.toggle('alias', ''); }}>
                                        <span className="d-none d-xs-inline">{strings.alias_location}</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ active: tab === 'location' && gender === 'M' })} onClick={() => { this.toggle('location', 'M'); }}>
                                        <span className="d-none d-xs-inline">{strings.alias_name} ({strings.male})</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ active: tab === 'location' && gender === 'F' })} onClick={() => { this.toggle('location', 'F'); }}>
                                        <span className="d-none d-xs-inline">{strings.alias_name} ({strings.female})</span>
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <PanelBody>
                                {tab === "alias" && (<AliasLocationFragment />)}
                                {tab === "location" && gender === 'M' && (<AliasStarFragment gender={'M'} />)}
                                {tab === "location" && gender === 'F' && (<AliasStarFragment gender={'F'} />)}
                            </PanelBody>
                        </Panel>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(inject('rootStore')(Alias));