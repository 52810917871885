import React from 'react';
import strings from '../../lang/strings.js';
import { PageSettings } from "../../config/page-settings";
import './../../config/util';

class MemberMoreProfile extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {
    }

    render() {
        let user_info = this.props.user_info;

        return (
            <div>
                <div className='row'>
                    <label>{strings.interest}</label>
                </div>
                <div className='row'>
                    <label> : {user_info.interest && user_info.interest.split('#').join(', ')}</label>
                </div>
                <div className='row'>
                    <label>{strings.hobby}</label>
                </div>
                <div className='row'>
                    <label> : {user_info.hobby && user_info.hobby.split('#').join(', ')}</label>
                </div>
                <div className='row'>
                    <label>{strings.i_have}</label>
                </div>
                <div className='row'>
                    <label> : {user_info.i_have && user_info.i_have.split('#').join(', ')}</label>
                </div>

                <div className='row'>
                    <label>{strings.i_want}</label>
                </div>
                <div className='row'>
                    <label> : {user_info.i_want && user_info.i_want.split('#').join(', ')}</label>
                </div>

                <div className='row'>
                    <label>{strings.career}</label>
                </div>
                <div className='row'>
                    <label> : {user_info.career && user_info.career}</label>
                </div>

                <div className='row'>
                    <label>{strings.intro}</label>
                </div>
                <div className='row mt-1 ml-1'>
                    <pre>{user_info.introduction && user_info.introduction}</pre>
                </div>
            </div >
        )
    }
}

export default MemberMoreProfile;