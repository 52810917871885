import React from 'react';
import strings from '../../../lang/strings.js';
import $ from 'jquery';
import { PageSettings } from "../../../config/page-settings";
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import DataTable from './../../../components/content/data-table.jsx';
import './../../../config/util';
import Const from './../../../config/const';

class BandCardPopup extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);
        this.state = {
        };

        this.toggleModal = this.toggleModal.bind(this);
        this.refreshTable = this.refreshTable.bind(this);
    }

    componentDidMount() {
    }

    toggleModal(result) {
        if (this.props.onClose) {
            this.props.onClose(result);
        }
    }

    refreshTable = (refresh) => {
        this.tblMember.draw(refresh);
    };

    getData() {
        this.refreshTable(true);
    }

    deleteCard(item) {
        let self = this;
        this.context.showConfirm(strings.confirm_delete_title, function (response) {
            if (response) {
                self.context.post('band/deleteCard', { id: item.id }, function () {
                    self.context.addNotification('success', strings.alert_title, strings.confirm_delete_done);
                    self.refreshTable(false);
                }, true);
            }
        });
    }

    render() {
        let self = this;
        let columnNo = 0;

        return (
            <div>
                <Modal isOpen={this.props.isOpen} toggle={this.toggleModal} onOpened={() => this.getData()} size={'lg'}>
                    <ModalHeader className="popup-title" toggle={this.toggleModal}>{strings.card_list}</ModalHeader>
                    <ModalBody>
                        <div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <DataTable ref={(ref) => this.tblMember = ref}
                                        options={{
                                            columnDefs: [
                                                {
                                                    targets: [columnNo++], data: 'id', orderable: false,
                                                    render: function (value) {
                                                        return value;
                                                    }
                                                },
                                                {
                                                    targets: [columnNo++], data: 'nickname', orderable: false,
                                                    render: function (value) {
                                                        return value;
                                                    }
                                                },
                                                {
                                                    targets: [columnNo++], data: 'ok', orderable: false,
                                                    render: function (value) {
                                                        return `<span class="${strings.const.OK_TYPE[value].color}">${strings.const.OK_TYPE[value].name}</span>`;
                                                    }
                                                },
                                                {
                                                    targets: [columnNo++], data: 'create_date', orderable: false,
                                                    render: function (value) {
                                                        return value;
                                                    }
                                                },
                                                {
                                                    targets: [columnNo++], data: 'reply_ok', orderable: false,
                                                    render: function (value) {
                                                        return `<span class="${strings.const.OK_TYPE[value].color}">${strings.const.OK_TYPE[value].name}</span>`;
                                                    }
                                                },
                                                {
                                                    targets: [columnNo++], data: 'reply_date', orderable: false,
                                                    render: function (value) {
                                                        return value;
                                                    }
                                                },
                                                {
                                                    targets: [columnNo++], data: 'id', orderable: false,
                                                    createdCell: function (td, value, item) {
                                                        $(td).html('');
                                                        $(td).append(`<button type='button' class='btn btn-danger btn-sm m-2'>${strings.delete}</button>`)
                                                            .find('button:last')
                                                            .on('click', function () {
                                                                self.deleteCard(item);
                                                            });
                                                    }
                                                }
                                            ],
                                            order: [[1, 'DESC']],
                                            ajax: {
                                                url: 'band/getCardList',
                                                data: function (req) {
                                                    req.columns = null;
                                                    req.band_id = self.props.band_id;
                                                }
                                            },
                                            drawCallback: function () {
                                            },
                                            fnCreatedRow: function (row, data, dataInd) {
                                            }
                                        }}>
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>{strings.nickname}</th>
                                                <th>OK</th>
                                                <th>{strings.create_date}</th>
                                                <th>{strings.recv_ok}</th>
                                                <th>{strings.recv_date}</th>
                                                <th>{strings.manage}</th>
                                            </tr>
                                        </thead>
                                        <tbody />
                                    </DataTable>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-default" onClick={this.toggleModal}>{strings.close}</button>
                    </ModalFooter>
                </Modal>
            </div >
        )
    }
}

export default BandCardPopup;