import React from 'react';
import strings from '../../lang/strings.js';
import { PageSettings } from "../../config/page-settings";
import { Panel, PanelBody, PanelHeader, PanelFooter } from '../../components/panel/panel.jsx';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import 'react-datetime/css/react-datetime.css';
import './../../config/util';
import U from './../../config/util';
import Const from './../../config/const';
import ImageSelect from './../../components/content/image-select.jsx';
import AddressSearchPopup from './../common/address-search-popup'

class RestaurantDetailInfo extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
            item: {},
            main_image: "",
            image_list_urls: [],

            show_address_search_popup: false,
        };

        this.main_image_file = null;
        this.image_list_files = [];
    }

    componentDidMount() {
        this.getData();
    }

    getData = () => {
        $('#uid').focus();

        if (!this.props.restaurant_id) {
            return;
        }

        let self = this;

        this.context.post(
            'restaurant/getItem',
            {
                id: this.props.restaurant_id
            },
            function (response) {
                let item = response.result_data.restaurant_item;

                self.setState({
                    item,
                    main_image: U.getThumbUrl(item.main_image),
                    image_list_urls: U.getThumbList(item.image_list)
                });
                self.main_image_file = null;
                self.image_list_urls = [];
            });
    };

    uploadData = (image_files, callback) => {

        let formData = new FormData();
        image_files.forEach((entry, index) => {
            formData.append('file', entry, entry.name);
        });

        this.context.post(
            'upload/images/restaurant',
            formData,
            function (response) {
                callback(response.result_data.upload_path);
            },
            true
        );
    }

    saveData = () => {
        let self = this;
        const item = self.state.item;

        if (this.main_image_file) {
            this.uploadData([this.main_image_file], function (image_url) {
                self.state.main_image = U.getThumbUrl(image_url);
                self.main_image_file = null;
                self.saveData();
            });
            return;
        }

        if (this.image_list_files.length > 0) {
            this.uploadData(this.image_list_files, function (image_urls) {
                let response_index = 0;
                self.image_list_files.forEach((entry, index) => {
                    self.state.image_list_urls[index] = U.getThumbUrl(image_urls[response_index])
                    response_index = response_index + 1
                });

                self.image_list_files = [];

                self.saveData();
            });
            return;
        }

        item.main_image = this.state.main_image.replace('_thumb.', '.').url2String();
        item.image_list = self.state.image_list_urls.map(it => { return it.replace('_thumb.', '.') }).urlArray2String();

        if (!item.category) {
            self.context.addNotification('warning', strings.alert_title, strings.category);
            return;
        }
        if (!item.main_image) {
            self.context.addNotification('warning', strings.alert_title, strings.empty_main_image);
            return;
        }
        if (!item.title) {
            self.context.addNotification('warning', strings.alert_title, strings.empty_title);
            return;
        }
        if (!item.address) {
            self.context.addNotification('warning', strings.alert_title, strings.empty_location);
            return;
        }
        if (!item.image_list) {
            self.context.addNotification('warning', strings.alert_title, strings.empty_image_list);
            return;
        }

        this.context.post('restaurant/save',
            {
                restaurant_info: item
            }, function (response) {
                self.context.addNotification('success', strings.alert_title, strings.save_done);
                if (self.props.onSave) {
                    self.props.onSave();
                }
            }, true);
    };

    onDataChange = (e) => {
        let item = this.state.item;
        item[e.target.id] = e.target.value;

        this.setState({
            item: item
        });
    };

    onMainImageChange = (e) => {
        let self = this;

        if (e.target.files.length < 1) {
            return;
        }

        let file = e.target.files[0];
        let reader = new FileReader();

        reader.onloadend = function () {
            self.setState({ main_image: reader.result });
            self.main_image_file = file
        };
        reader.readAsDataURL(file);
    };

    onImageChange = (e, idx) => {
        let self = this;

        if (e.target.files.length < 1) {
            return;
        }

        let file = e.target.files[0];
        let reader = new FileReader();

        reader.onloadend = function () {
            self.state.image_list_urls[idx] = reader.result;
            self.image_list_files[idx] = file;
            self.setState({ image_list_urls: self.state.image_list_urls });
        };
        reader.readAsDataURL(file);
    };

    render() {
        let item = this.state.item;

        return (
            <div>
                <Panel>
                    <PanelHeader noButton={true}>{strings.restaurant_detail}</PanelHeader>
                    <PanelBody>
                        <AddressSearchPopup isOpen={this.state.show_address_search_popup} onClose={() => {
                            this.setState({ show_address_search_popup: false });
                        }} onSave={(response) => {
                            item.address = response.address;
                            item.latitude = response.latitude;
                            item.longitude = response.longitude;
                            this.setState({ item, show_address_search_popup: false });
                        }} />
                        <div className="row">
                            <div className="col-xs-12">
                                <div className='row'>
                                    <label className='col-md-2 col-form-label'>{strings.category}</label>
                                    <div className="col-md-10">
                                        <select className='form-control' id='category'
                                            onChange={(e) => this.onDataChange(e)}
                                            value={item.category}>
                                            <option key={-1} value="">({strings.select_category})</option>
                                            {strings.const.RESTAURANT_CATEGORY.map((entry, index) => (
                                                <option key={index} value={entry}>{entry}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className='row'>
                                    <label className='col-md-2 col-form-label'>{strings.main_image}</label>
                                    <div className="col-md-10">
                                        <div className="row mb-1">
                                            <div className='col-xs-4 col-sm-3 col-md-5 col-lg-4 col-xl-4 col-xxl-3'>
                                                <ImageSelect
                                                    height="70%"
                                                    image_url={this.state.main_image}
                                                    onSelect={(e) => this.onMainImageChange(e)}
                                                    onDelete={() => {
                                                        this.setState({ main_image: '' });
                                                        this.main_image_file = null;
                                                    }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <label className='col-md-2 col-form-label'>{strings.title} *</label>
                                    <div className="col-md-10">
                                        <div className="row mb-1">
                                            <div className='col-md-12'>
                                                <input type='text' autoComplete='off' className='form-control' id='title'
                                                    value={item.title || ''}
                                                    onChange={(e) => this.onDataChange(e)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <label className='col-md-2 col-form-label'>간단소개</label>
                                    <div className="col-md-10">
                                        <div className="row mb-1">
                                            <div className='col-md-12'>
                                                <input type='text' autoComplete='off' className='form-control' id='content'
                                                    value={item.content || ''}
                                                    onChange={(e) => this.onDataChange(e)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <label className='col-md-2 col-form-label'>혜택 *</label>
                                    <div className="col-md-10">
                                        <div className="row mb-1">
                                            <div className='col-md-12'>
                                                <input type='text' autoComplete='off' className='form-control' id='discount'
                                                    value={item.discount || ''}
                                                    onChange={(e) => this.onDataChange(e)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <label className='col-md-2 col-form-label'>{strings.location} *</label>
                                    <div className="col-md-10 input-group">
                                        <input type='text' autoComplete='off' className='form-control' id='address'
                                            value={item.address || ''}
                                            onChange={(e) => this.onDataChange(e)} />
                                        <div class="input-group-append" style={{ height: "calc(1.2em + 14px + 2px)" }}>
                                            <button className="btn btn-primary" onClick={() => this.setState({ show_address_search_popup: true })}>주소검색</button>
                                        </div>
                                    </div>
                                </div>
                                <div className='row' style={{ marginBottom: 20 }}>
                                    <label className='col-md-2 col-form-label'></label>
                                    <div className="col-md-10 input-group" style={{ alignItems: "center" }}>
                                        <label style={{ marginRight: 10 }}>위도</label>
                                        <input type='text' autoComplete='off' className='form-control' id='latitude'
                                            value={item.latitude || ''}
                                            onChange={(e) => this.onDataChange(e)} />
                                        <label style={{ marginRight: 10, marginLeft: 20 }}>경도</label>
                                        <input type='text' autoComplete='off' className='form-control' id='longitude'
                                            value={item.longitude || ''}
                                            onChange={(e) => this.onDataChange(e)} />
                                    </div>
                                </div>
                                <div className='row'>
                                    <label className='col-md-2 col-form-label'>{strings.image} *</label>
                                    <div className="col-md-10">
                                        <div className='row mb-1'>
                                            {
                                                this.state.image_list_urls.map((image_url, idx) => (
                                                    <div className='col-xs-4 col-sm-3 col-md-4 col-xl-4 col-xxl-3' key={idx}>
                                                        <ImageSelect
                                                            height="70%"
                                                            image_url={image_url}
                                                            onSelect={(e) => this.onImageChange(e, idx)}
                                                            onDelete={() => {
                                                                this.state.image_list_urls.splice(idx, 1);
                                                                this.image_list_files.splice(idx, 1);
                                                                this.setState({ image_list_urls: this.state.image_list_urls });
                                                            }} />
                                                    </div>
                                                ))
                                            }

                                            <div className='col-xs-4 col-sm-3 col-md-4 col-xl-4 col-xxl-3'>
                                                <ImageSelect
                                                    height="70%"
                                                    onSelect={(e) => this.onImageChange(e, this.state.image_list_urls.length)}
                                                    onDelete={() => { }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <label className='col-md-2 col-form-label'>{strings.restaurant_location}</label>
                                    <div className="col-md-10">
                                        <select className='form-control' id='location'
                                            onChange={(e) => this.onDataChange(e)}
                                            value={item.location || ''}>
                                            <option value={''}>(지역 선택)</option>
                                            {strings.const.RESTAURANT_LOCATION.map((entry, index) => (
                                                <option key={index} value={entry}>{entry}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className='row'>
                                    <label className='col-md-2 col-form-label'>{strings.restaurant_type}</label>
                                    <div className="col-md-10">
                                        <select className='form-control' id='type'
                                            onChange={(e) => this.onDataChange(e)}
                                            value={item.type || ''}>
                                            {strings.const.RESTAURANT_TYPE.map((entry, index) => (
                                                <option key={index} value={entry.code}>{entry.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className='row'>
                                    <label className='col-md-2 col-form-label'>{strings.create_date}</label>
                                    <label className='col-md-10 col-form-label'>{item.create_date}</label>
                                </div>
                                <div className='row'>
                                    <label className='col-md-2 col-form-label'>{strings.update_date}</label>
                                    <label className='col-md-10 col-form-label'>{item.update_date}</label>
                                </div>
                            </div>
                        </div>
                    </PanelBody>
                    <PanelFooter className={"text-right"}>
                        <div className="d-flex w-100">
                            <div>
                                <Link to="/meeting/restaurant">
                                    <button type='button' className='btn btn-dark btn-sm m-2'>{strings.list}</button>
                                </Link>
                            </div>
                            <div className="ml-auto">
                                <button type='button' className='btn btn-success btn-sm m-2' onClick={() => { this.saveData() }}>{strings.save}</button>
                            </div>
                        </div>
                    </PanelFooter>
                </Panel>
            </div >
        )
    }
}

export default RestaurantDetailInfo;