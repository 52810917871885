import React from 'react';
import strings from '../../lang/strings.js';
import { PageSettings } from "../../config/page-settings";
import './../../config/util';

class MemberTerms extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {
    }

    render() {
        let user_info = this.props.user_info;

        return (
            <div>
                <div className="row">
                    <label>{strings.terms_01} ({user_info.signup_date})</label>
                </div>
                <div className="row">
                    <label>{strings.terms_02} ({user_info.signup_date})</label>
                </div>
                <div className="row">
                    <label>{strings.terms_03} ({user_info.signup_date})</label>
                </div>
            </div >
        )
    }
}

export default MemberTerms;