import React from 'react';
import { withRouter } from 'react-router-dom';
import { inject } from 'mobx-react';
import strings from '../../lang/strings.js';
import { PageSettings } from "../../config/page-settings";
import { Panel, PanelBody } from '../../components/panel/panel.jsx';
import { Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import './../../config/util';
import MemberDetailPopup from './../member-detail/member-detail-popup'
import ShopHeartFragment from './shop-heart-fragment'
import ShopPointFragment from './shop-point-fragment'
import ShopOkFragment from './shop-ok-fragment'
const queryString = require('query-string');

class Shop extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);
        this.state = {
        };

        this.toggle = this.toggle.bind(this);
        this.showMemberDetailPopup = this.showMemberDetailPopup.bind(this);
    }

    componentDidMount() {
    }

    toggle(tab) {
        this.props.history.push('?tab=' + tab);
    }

    showMemberDetailPopup(uid) {
        this.setState({
            show_member_profile: true,
            selected_uid: uid
        });
    }

    render() {
        const searchQS = queryString.parse(this.props.location.search);
        const tab = searchQS.tab || 'heart';

        return (
            <div>
                <div className="row">
                    <div className="col-xs-12">
                        <Panel>
                            <Nav tabs className="nav-tabs-inverse">
                                <NavItem>
                                    <NavLink className={classnames({ active: tab === 'heart' })} onClick={() => { this.toggle('heart'); }}>
                                        <span className="d-none d-xs-inline">{strings.heart_log}</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ active: tab === 'point' })} onClick={() => { this.toggle('point'); }}>
                                        <span className="d-none d-xs-inline">{strings.point_log}</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ active: tab === 'ok' })} onClick={() => { this.toggle('ok'); }}>
                                        <span className="d-none d-xs-inline">{strings.ok_log}</span>
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <PanelBody>
                                <MemberDetailPopup isOpen={this.state.show_member_profile} uid={this.state.selected_uid} onClose={() => {
                                    this.setState({ show_member_profile: false });
                                }} />

                                {tab === "heart" && (<ShopHeartFragment onClickRow={this.showMemberDetailPopup} />)}
                                {tab === "point" && (<ShopPointFragment onClickRow={this.showMemberDetailPopup} />)}
                                {tab === "ok" && (<ShopOkFragment onClickRow={this.showMemberDetailPopup} />)}
                            </PanelBody>
                        </Panel>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(inject('rootStore')(Shop));