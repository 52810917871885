import React from 'react';
import strings from '../../lang/strings.js';
import $ from 'jquery';
import { PageSettings } from "../../config/page-settings";
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import './../../config/util';
import Const from './../../config/const';
import SearchSelect from './../../components/content/search-select.js';

class MemberBlockPopup extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
            category: strings.const.BLOCK_REASON[0],
            reason: '',
        }
        this.toggleModal = this.toggleModal.bind(this);
    }

    toggleModal(result) {
        if (this.props.onClose) {
            this.props.onClose(result);
        }
    }

    onApply() {
        let status_category = this.state.category;
        let status_reason = this.state.reason;

        if (status_reason) {
            status_category = status_category + " (" + status_reason + ")";
        }

        if (this.props.onApply) {
            this.props.onApply(status_category);
        }
    }

    componentDidMount() {
    }

    render() {
        let category = strings.const.BLOCK_REASON;

        return (
            <div>
                <Modal isOpen={this.props.isOpen} toggle={this.toggleModal}>
                    <ModalHeader className="popup-title" toggle={this.toggleModal}>{strings.member_block}</ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <div className="col-xl-12">
                                <select className='form-control' onChange={(e) => {
                                    this.setState({ category: e.target.value });
                                }}>
                                    {category.map((value, index) => (
                                        <option key={index} value={value}>{value}</option>
                                    ))}
                                </select>
                                <input type='text' autoComplete='off' className='form-control mt-3'
                                    placeholder={strings.direct_input}
                                    onChange={(e) => {
                                        this.setState({ reason: e.target.value });
                                    }} />
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-default" onClick={this.toggleModal}>{strings.close}</button>
                        <button className="btn btn-success" onClick={() => this.onApply()}>{strings.apply}</button>
                    </ModalFooter>
                </Modal>
            </div >
        )
    }
}

export default MemberBlockPopup;