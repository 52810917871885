import React from 'react';
import strings from '../../lang/strings.js';
import { PageSettings } from "../../config/page-settings";
import { withRouter } from 'react-router-dom';
import { inject } from 'mobx-react';
import { Panel, PanelBody, PanelHeader } from '../../components/panel/panel.jsx';
import DataTable from './../../components/content/data-table.jsx';
import SearchInput from './../../components/content/search-input.js';
import SearchSelect from './../../components/content/search-select.js';
import VideoEditPopup from './video-edit-popup'
import $ from 'jquery';
import './../../config/util';
import Const from './../../config/const';
import VideoPlayerPopup from './../common/video-player-popup'
const queryString = require('query-string');

class Video extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        let searchQS = queryString.parse(this.props.location.search);

        this.state = {
            search_info: searchQS,
            selected_video_id: null,
            show_video_popup: false,

            selected_video_url: '',
            show_video_player_popup: false,
        };

        this.refreshTable = this.refreshTable.bind(this);
        this.showVideoDetailPopup = this.showVideoDetailPopup.bind(this);
        this.deleteVideo = this.deleteVideo.bind(this);
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.location.search != this.props.location.search) {
            let searchQS = queryString.parse(this.props.location.search);
            this.setState({ search_info: searchQS }, () => this.refreshTable());
        }
    }

    refreshTable = (refresh) => {
        this.tblMember.draw(refresh);
    };

    searchTable = () => {
        let searchQS = queryString.parse(this.props.location.search);
        Object.assign(searchQS, this.state.search_info)
        const new_query = queryString.stringify(searchQS);
        if (new_query == this.props.location.search.substr(1)) {
            this.refreshTable();
        } else {
            this.props.history.push('?' + new_query);
        }
    }

    showVideoDetailPopup(video_id) {
        this.setState({
            show_video_popup: true,
            selected_video_id: video_id
        });
    }

    deleteVideo(video_id) {
        let self = this;
        this.context.showConfirm(strings.confirm_delete_title, function (response) {
            if (response) {
                self.context.post('video/delete', { id: video_id }, function () {
                    self.context.addNotification('success', strings.alert_title, strings.confirm_delete_done);
                    self.refreshTable(false);
                }, true);
            }
        });
    }

    showVideoPlayer(video_url) {
        this.setState({
            show_video_player_popup: true,
            selected_video_url: video_url
        })
    }

    render() {
        let self = this;
        let columnNo = 0;

        const { search_info } = this.state;

        return (
            <div>
                <Panel>
                    <PanelHeader noButton={true}>{strings.video_review_list}</PanelHeader>
                    <PanelBody>
                        <div>
                            <VideoEditPopup isOpen={this.state.show_video_popup} video_id={this.state.selected_video_id} onClose={() => {
                                this.setState({ show_video_popup: false });
                            }} onSave={() => {
                                this.setState({ show_video_popup: false });
                                this.refreshTable(false);
                            }} />
                            <VideoPlayerPopup isOpen={this.state.show_video_player_popup} video_url={this.state.selected_video_url} onClose={() => {
                                this.setState({ show_video_player_popup: false });
                            }} />
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div className='form-horizontal form-inline'>
                                        {/* 키워드검색 */}
                                        <SearchSelect
                                            data={[{ code: 'nickname', name: strings.nickname }]}
                                            value={search_info.column || 'nickname'}
                                            onChange={(value) => {
                                                this.setState({
                                                    search_info: {
                                                        ...search_info,
                                                        column: value,
                                                        start: 0,
                                                    }
                                                }, this.searchTable);
                                            }}
                                        />
                                        <SearchInput
                                            value={search_info.keyword}
                                            onChange={(value) => {
                                                this.setState({
                                                    search_info: {
                                                        ...search_info,
                                                        keyword: value,
                                                        column: search_info.column || 'nickname',
                                                        start: 0,
                                                    }
                                                })
                                            }}
                                            handler={(value) => this.searchTable()}
                                        />
                                        <button type='button' className='btn btn-success btn-sm ml-md-2' onClick={() => this.searchTable()}>{strings.search}</button>
                                        <button type='button' className='btn btn-danger btn-sm ml-md-2' onClick={() => {
                                            self.showVideoDetailPopup(null);
                                        }}>{strings.add}</button>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12 mt-3'>
                                    <DataTable ref={(ref) => this.tblMember = ref}
                                        customPaginate={true}
                                        initialPage={search_info.start || 0}
                                        onPageChange={(value) => {
                                            this.setState({
                                                search_info: {
                                                    ...search_info,
                                                    start: value,
                                                }
                                            }, this.searchTable);
                                        }}
                                        options={{
                                            columnDefs: [
                                                {
                                                    targets: [columnNo++], data: 'id', orderable: false,
                                                    render: function (value) {
                                                        return value;
                                                    }
                                                },
                                                {
                                                    targets: [columnNo++], data: 'nickname', orderable: false,
                                                    render: function (value) {
                                                        return value;
                                                    }
                                                },
                                                {
                                                    targets: [columnNo++], data: 'video_url', orderable: false,
                                                    createdCell: function (td, value, item) {
                                                        return $(td).video_label(value, () => {
                                                            self.showVideoPlayer(Const.BASE_URL + value);
                                                        });
                                                    }
                                                },
                                                {
                                                    targets: [columnNo++], data: 'create_date', orderable: false,
                                                    render: function (value) {
                                                        return value;
                                                    }
                                                },
                                                {
                                                    targets: [columnNo++], data: 'id', orderable: false,
                                                    createdCell: function (td, value, item) {
                                                        $(td).html('');
                                                        $(td).append(`<button type='button' class='btn btn-success btn-sm m-2'>${strings.detail}</button>`)
                                                            .find('button')
                                                            .on('click', function () {
                                                                self.showVideoDetailPopup(item.id);
                                                            });
                                                        $(td).append(`<button type='button' class='btn btn-danger btn-sm m-2'>${strings.delete}</button>`)
                                                            .find('button:last')
                                                            .on('click', function () {
                                                                self.deleteVideo(item.id);
                                                            });
                                                    }
                                                },
                                            ],
                                            order: [[1, 'DESC']],
                                            ajax: {
                                                url: 'video/getList',
                                                data: function (req) {
                                                    req.columns = null;
                                                    const searchQS = queryString.parse(self.props.location.search);
                                                    req.start = searchQS.start || 0;
                                                    req.length = searchQS.length || 20;
                                                    req.column = searchQS.column;
                                                    req.keyword = searchQS.keyword;
                                                }
                                            },
                                            drawCallback: function () {
                                            },
                                            fnCreatedRow: function (row, data, dataInd) {
                                            }
                                        }}>
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>{strings.nickname}</th>
                                                <th>{strings.video}</th>
                                                <th>{strings.create_date}</th>
                                                <th>{strings.manage}</th>
                                            </tr>
                                        </thead>
                                        <tbody />
                                    </DataTable>
                                </div>
                            </div>
                        </div>
                    </PanelBody>
                </Panel>
            </div>
        )
    }
}

export default withRouter(inject('rootStore')(Video));