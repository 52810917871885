import React from 'react';
import { withRouter } from 'react-router-dom';
import { inject } from 'mobx-react';
import strings from '../../lang/strings.js';
import { PageSettings } from "../../config/page-settings";
import { Panel, PanelBody } from '../../components/panel/panel.jsx';
import { Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import './../../config/util';
import MemberNormalFragment from './normal-fragment'
const queryString = require('query-string');

class MemberNormal extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);
        this.state = {
        };

        this.toggle = this.toggle.bind(this);
        this.showMemberDetail = this.showMemberDetail.bind(this);
    }

    componentDidMount() {
    }

    toggle(gender) {
        this.props.history.push('?gender=' + gender);
    }

    showMemberDetail(uid) {
        this.props.history.push(`/member/detail/${uid}`);
    }

    render() {
        const searchQS = queryString.parse(this.props.location.search);
        const gender = searchQS.gender || '';

        return (
            <div>
                <div className="row">
                    <div className="col-xs-12">
                        <Panel>
                            <Nav tabs className="nav-tabs-inverse">
                                <NavItem>
                                    <NavLink className={classnames({ active: gender === '' })} onClick={() => { this.toggle(''); }}>
                                        <span className="d-none d-xs-inline">{strings.total_member}</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ active: gender === 'F' })} onClick={() => { this.toggle('F'); }}>
                                        <span className="d-none d-xs-inline">{strings.female_member}</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ active: gender === 'M' })} onClick={() => { this.toggle('M'); }}>
                                        <span className="d-none d-xs-inline">{strings.male_member}</span>
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <PanelBody>
                                {gender === "" && (<MemberNormalFragment onClickRow={this.showMemberDetail} />)}
                                {gender === "F" && (<MemberNormalFragment gender='F' onClickRow={this.showMemberDetail} />)}
                                {gender === "M" && (<MemberNormalFragment gender='M' onClickRow={this.showMemberDetail} />)}
                            </PanelBody>
                        </Panel>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(inject('rootStore')(MemberNormal));