import React from 'react';
import strings from '../../lang/strings.js';
import { PageSettings } from "../../config/page-settings";
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import classnames from 'classnames';
import $ from 'jquery';
import '../../config/util';
import U from '../../config/util';
import Const from '../../config/const';
import menus from '../../config/menu.jsx';
import DateTime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import ImageSelect from './../../components/content/image-select.jsx';

class ZoneEventPopup extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
            item: {},
            image_list_urls: [],
        };

        this.toggleModal = this.toggleModal.bind(this);
        this.image_list_files = [];
    }

    componentDidMount() {
    }

    toggleModal(result) {
        if (this.props.onClose) {
            this.props.onClose(result);
        }
    }

    getData = () => {
        let self = this;

        if (!this.props.event_id) {
            self.setState({
                item: {},
                image_list_urls: [],
            });
            return;
        }

        this.context.post(
            'zone/getEventItem',
            {
                event_id: this.props.event_id
            }, function (response) {
                let item = response.result_data.event_info;

                self.setState({
                    item: item,
                    image_list_urls: U.getThumbList(item.image_urls),
                });

                self.image_list_urls = [];
            });
    };

    uploadData = (image_files, callback) => {

        let self = this;

        let formData = new FormData();
        image_files.forEach((entry, index) => {
            formData.append('file', entry, entry.name);
        });


        this.context.post(
            'upload/images/board',
            formData,
            function (response) {
                callback(response.result_data.upload_path);
            },
            true
        );
    }

    saveData = () => {
        let self = this;

        const event_info = self.state.item;

        if (this.image_list_files.length > 0) {
            this.uploadData(this.image_list_files, function (image_urls) {
                let response_index = 0;
                self.image_list_files.forEach((entry, index) => {
                    self.state.image_list_urls[index] = U.getThumbUrl(image_urls[response_index])
                    response_index = response_index + 1
                });

                self.image_list_files = [];

                self.saveData();
            });
            return;
        }

        event_info.image_urls = self.state.image_list_urls.map(it => { return it.replace('_thumb.', '.') }).urlArray2String();

        this.context.post('zone/postEvent',
            {
                event_info
            }, function (response) {
                self.context.addNotification('success', strings.alert_title, strings.save_done);
                if (self.props.onSave) {
                    self.props.onSave();
                }
            }, true);
    };

    onDataChange = (e) => {
        let item = this.state.item;
        item[e.target.id] = e.target.value;

        this.setState({
            item: item
        });
    };

    onChangeDate(id, value, format) {
        let item = this.state.item;
        item[id] = typeof value === 'object' ? value.format(format) : value;

        this.setState({
            item: item
        });
    }

    onImageChange = (e, idx) => {
        let self = this;

        if (e.target.files.length < 1) {
            return;
        }

        let file = e.target.files[0];
        let reader = new FileReader();

        reader.onloadend = function () {
            self.state.image_list_urls[idx] = reader.result;
            self.image_list_files[idx] = file;
            self.setState({ image_list_urls: self.state.image_list_urls });
        };
        reader.readAsDataURL(file);
    };

    render() {
        let item = this.state.item;

        return (
            <div>
                <Modal isOpen={this.props.isOpen} toggle={this.toggleModal} onOpened={() => this.getData()} size={'md'}>
                    <ModalHeader className="popup-title" toggle={this.toggleModal}>{item.id ? strings.manager_edit : strings.manager_add}</ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <div className="col-xs-12">
                                <div className='row'>
                                    <label className='col-md-3 col-form-label'>타입</label>
                                    <div className="col-md-9">
                                        <select className='form-control'
                                            id='type'
                                            onChange={(e) => this.onDataChange(e)}
                                            value={item.type || ''}>
                                            <option value={''}>타입선택</option>
                                            {['PARTNER'].map((entry, index) => (
                                                <option key={index} value={entry}>{entry}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className='row'>
                                    <label className='col-md-3 col-form-label'>제목</label>
                                    <div className="col-md-9">
                                        <input type='text' autoComplete='off' className='form-control' id='title'
                                            value={item.title || ''}
                                            onChange={(e) => this.onDataChange(e)} />
                                    </div>
                                </div>
                                <div className='row'>
                                    <label className='col-md-3 col-form-label'>이미지</label>
                                    <div className="col-md-9">
                                        <div className='row mb-1 mt-1'>
                                            {
                                                this.state.image_list_urls.map((image_url, idx) => (
                                                    <div className='col-xs-4 col-sm-3 col-md-4 col-xl-4 col-xxl-3' key={idx}>
                                                        <ImageSelect
                                                            height="70%"
                                                            image_url={image_url}
                                                            onSelect={(e) => this.onImageChange(e, idx)}
                                                            onDelete={() => {
                                                                this.state.image_list_urls.splice(idx, 1);
                                                                this.image_list_files.splice(idx, 1);
                                                                this.setState({ image_list_urls: this.state.image_list_urls });
                                                            }} />
                                                    </div>
                                                ))
                                            }

                                            <div className='col-xs-4 col-sm-3 col-md-4 col-xl-4 col-xxl-3'>
                                                <ImageSelect
                                                    height="70%"
                                                    onSelect={(e) => this.onImageChange(e, this.state.image_list_urls.length)}
                                                    onDelete={() => { }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <label className='col-md-3 col-form-label'>내용</label>
                                    <div className="col-md-9">
                                        <textarea
                                            className='form-control'
                                            rows={7} id='content'
                                            value={item.content || ''}
                                            onChange={(e) => this.onDataChange(e)} />
                                    </div>
                                </div>
                                <div className='row'>
                                    <label className='col-md-3 col-form-label'>상호</label>
                                    <div className="col-md-9">
                                        <input type='text' autoComplete='off' className='form-control' id='market_name'
                                            value={item.market_name || ''}
                                            onChange={(e) => this.onDataChange(e)} />
                                    </div>
                                </div>
                                <div className='row'>
                                    <label className='col-md-3 col-form-label'>주소</label>
                                    <div className="col-md-9">
                                        <input type='text' autoComplete='off' className='form-control' id='address'
                                            value={item.address || ''}
                                            onChange={(e) => this.onDataChange(e)} />
                                    </div>
                                </div>
                                <div className='row'>
                                    <label className='col-md-3 col-form-label'>위도</label>
                                    <div className="col-md-9">
                                        <input type='text' autoComplete='off' className='form-control' id='latitude'
                                            value={item.latitude || ''}
                                            onChange={(e) => this.onDataChange(e)} />
                                    </div>
                                </div>
                                <div className='row'>
                                    <label className='col-md-3 col-form-label'>경도</label>
                                    <div className="col-md-9">
                                        <input type='text' autoComplete='off' className='form-control' id='longitude'
                                            value={item.longitude || ''}
                                            onChange={(e) => this.onDataChange(e)} />
                                    </div>
                                </div>
                                <div className='row'>
                                    <label className='col-md-3 col-form-label'>종료일</label>
                                    <div className="col-md-9">
                                        <DateTime dateFormat={'YYYY-MM-DD'} timeFormat={false} inputProps={{ placeholder: strings.select_date }}
                                            closeOnSelect={true} id="end_at" className='width-100'
                                            value={item.end_at}
                                            onChange={(e) => { this.onChangeDate('end_at', e, 'YYYY-MM-DD') }}
                                        />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-default" onClick={this.toggleModal}>{strings.close}</button>
                        <button className="btn btn-success" onClick={() => this.saveData()}>{strings.apply}</button>
                    </ModalFooter>
                </Modal>
            </div >
        )
    }
}

export default ZoneEventPopup;