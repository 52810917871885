import React from 'react';
import strings from '../../lang/strings.js';
import { PageSettings } from "../../config/page-settings";
import DataTable from './../../components/content/data-table.jsx';
import classnames from 'classnames';
import $ from 'jquery';
import './../../config/util';
import U from './../../config/util';
import Const from './../../config/const';

class MeetingDetailLike extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
        };

        this.toggleModal = this.toggleModal.bind(this);
        this.refreshTable = this.refreshTable.bind(this);
    }

    componentDidMount() {
    }

    toggleModal(result) {
        if (this.props.onClose) {
            this.props.onClose(result);
        }
    }

    refreshTable = (refresh) => {
        this.tblMember.draw(refresh);
    };

    getData() {
        this.refreshTable(true);
    }

    deleteLike(id) {
        let self = this;
        this.context.showConfirm(strings.confirm_delete_title, function (response) {
            if (response) {
                self.context.post('restaurant/deleteLike', { id }, function () {
                    self.context.addNotification('success', strings.alert_title, strings.confirm_delete_done);
                    self.refreshTable(false);
                }, true);
            }
        });
    }

    render() {
        let self = this;
        let columnNo = 0;

        return (
            <div>
                <div className='row'>
                    <div className='col-md-12 mt-3'>
                        <DataTable ref={(ref) => this.tblMember = ref}
                            options={{
                                columnDefs: [
                                    {
                                        targets: [columnNo++], data: 'id', orderable: false,
                                        render: function (value) {
                                            return value;
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'profile_image', orderable: false,
                                        createdCell: function (td, value, item) {
                                            $(td).image_lightbox(value, () => {
                                                self.context.showGallery([Const.BASE_URL + value])
                                            });
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'nickname', orderable: false,
                                        render: function (value) {
                                            return value;
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'create_date', orderable: false,
                                        render: function (value) {
                                            return value;
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'id', orderable: false,
                                        createdCell: function (td, value, item) {
                                            $(td).html('');
                                            $(td).append(`<button type='button' class='btn btn-danger btn-sm m-2'>${strings.delete}</button>`)
                                                .find('button:last')
                                                .on('click', function () {
                                                    self.deleteLike(item.id);
                                                });
                                        }
                                    },
                                ],
                                order: [[1, 'DESC']],
                                ajax: {
                                    url: 'meeting/getLikeList',
                                    data: function (req) {
                                        req.columns = null;
                                        req.meeting_id = self.props.meeting_id;
                                    }
                                },
                                drawCallback: function () {
                                },
                                fnCreatedRow: function (row, data, dataInd) {
                                }
                            }}>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>{strings.profile}</th>
                                    <th>{strings.nickname}</th>
                                    <th>{strings.date}</th>
                                    <th>{strings.manage}</th>
                                </tr>
                            </thead>
                            <tbody />
                        </DataTable>
                    </div>
                </div>
            </div >
        )
    }
}

export default MeetingDetailLike;