import React from 'react';
import {withRouter} from 'react-router-dom';
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from 'reactstrap';
import {inject} from 'mobx-react';

class DropdownProfile extends React.Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            dropdownOpen: false
        };
    }

    toggle() {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    }

    handleLogout = () => {
        this.props.rootStore.logout();
        this.props.history.replace('/login');
    };

    handlePwd = () => {
        this.props.history.push('/manager/change_password');
    };

    render() {
        return (
            <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle} className="dropdown navbar-user" tag="li">
                <DropdownToggle tag="a">
                    <img src="../assets/img/user/user-13.jpg" alt="" />
                    <span className="d-none d-md-inline">[{this.props.rootStore.getNickname()}] 님</span> <b className="caret"/>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu dropdown-menu-right" tag="ul">
                    <DropdownItem onClick={this.handlePwd}>비밀번호 변경</DropdownItem>
                    <DropdownItem onClick={this.handleLogout}>로그 아웃</DropdownItem>
                </DropdownMenu>
            </Dropdown>
        );
    }
}

export default withRouter(inject('rootStore')(DropdownProfile));
