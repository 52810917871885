import React from 'react';
import strings from '../../lang/strings.js';
import { PageSettings } from "../../config/page-settings";

class SignupStaFragment extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
            signup_sta: null,
        };
    }

    componentDidMount() {
        this.getIapSta();
    }

    getIapSta = () => {
        let self = this;

        this.context.get(
            'dashboard/signup_sta',
            {},
            function (response) {
                self.setState({
                    signup_sta: response.result_data.signup_sta
                });
            });
    };

    render() {
        let self = this;

        return (
            <div>
                <div className="widget widget-stats bg-blue height-100">
                    <div className="d-flex align-items-center text-white">
                        <div className="d-flex w-100">
                            <div>{strings.today} {strings.signup_count}</div>
                            <div className="ml-auto">
                                {self.state.signup_sta ? `${self.state.signup_sta.today_approved.currencyFormat()}명 / ${self.state.signup_sta.today.currencyFormat()}명 (합격율: ${self.state.signup_sta.today > 0 ? (self.state.signup_sta.today_approved / self.state.signup_sta.today * 100).toFixed(0) : 0}%)` : `-`}
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center text-white">
                        <div className="d-flex w-100">
                            <div>{strings.this_week} {strings.signup_count}</div>
                            <div className="ml-auto">
                                {self.state.signup_sta ? `${self.state.signup_sta.this_week_approved.currencyFormat()}명 / ${self.state.signup_sta.this_week.currencyFormat()}명 (합격율: ${self.state.signup_sta.this_week > 0 ? (self.state.signup_sta.this_week_approved / self.state.signup_sta.this_week * 100).toFixed(0) : 0}%)` : `-`}
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center text-white">
                        <div className="d-flex w-100">
                            <div>{strings.this_month} {strings.signup_count}</div>
                            <div className="ml-auto">
                                {self.state.signup_sta ? `${self.state.signup_sta.this_month_approved.currencyFormat()}명 / ${self.state.signup_sta.this_month.currencyFormat()}명 (합격율: ${self.state.signup_sta.this_month > 0 ? (self.state.signup_sta.this_month_approved / self.state.signup_sta.this_month * 100).toFixed(0) : 0}%)` : `-`}
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center text-white">
                        <div className="d-flex w-100">
                            <div>{strings.total} {strings.signup_count}</div>
                            <div className="ml-auto">
                                {self.state.signup_sta ? `${self.state.signup_sta.total_approved.currencyFormat()}명 / ${self.state.signup_sta.total.currencyFormat()}명 (합격율: ${self.state.signup_sta.total > 0 ? (self.state.signup_sta.total_approved / self.state.signup_sta.total * 100).toFixed(0) : 0}%)` : `-`}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default SignupStaFragment;