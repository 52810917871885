import React from 'react';
import { inject } from 'mobx-react';
import $ from 'jquery';
import 'datatables.net/js/jquery.dataTables.min';
import 'datatables.net-bs4/js/dataTables.bootstrap4.min';

import { PageSettings } from "./../../config/page-settings";
import Const from "./../../config/const";
import ReactPaginate from 'react-paginate';

class DataTable extends React.Component {

    static contextType = PageSettings;

    constructor(props) {
        super(props);

        let self = this;

        this.state = {
            showLoading: false,
        }

        this.options = {
            language: {
                // sEmptyTable: "조회된 내역이 없습니다",
                // sLoadingRecords: "로딩중...",
                // sProcessing: "<div>잠시만 기다려주세요. <i className='fas fa-spinner fa-pulse'></i></div>",
                oPaginate: {
                    sFirst: "&lt;&lt;",
                    sLast: "&gt;&gt;",
                    sNext: "&gt;",
                    sPrevious: "&lt;"
                },
                sInfo: "Total: _TOTAL_",
                sInfoEmpty: 'Total: 0',
                sThousands: ",",
            },
            // dom: "<'row'<'col-md-6 col-sm-12'il><'col-md-6 col-sm-12'f>r>t<'row'p>",
            dom: "<'row'<''il><'col-md-6 col-sm-12'f>r>t<'row'p>",
            bAutoWidth: true,
            bFilter: false,
            bInfo: self.props.hideTotalCount ? false : true,
            bSort: true,
            bLengthChange: false,
            iDisplayLength: 20,
            bServerSide: true,
            bProcessing: false,
            bPaginate: this.props.customPaginate ? false : true,
            sPaginationType: 'full_numbers',
            order: [],
            columnDefs: [],
            ajax: {
                url: '',
                type: 'POST',
                headers: {
                    [Const.HEADER.AUTH_TOKEN]: this.props.rootStore.getToken(),
                    [Const.HEADER.LANG]: 'ko'
                },
                beforeSend: function () {
                    self.setState({ showLoading: true });
                },
                dataSrc: function (res) {
                    self.setState({
                        showLoading: false,
                        totalCount: res.recordsTotal,
                    });
                    return res.data;
                },
                error: function (err, xhr) {
                    self.setState({ showLoading: false });
                    console.log('DataTable ajax error => ' + xhr + ', ' + JSON.stringify(err) + ', ' + err.message);
                    self.context.showAlert('DataTable ajax error : ' + xhr);
                }
            },
            drawCallback: function () {
            },
            fnCreatedRow: function (row, data, dataInd) {
            }
        };

        this.draw = this.draw.bind(this);
    }

    componentDidMount() {

        $.extend(true, this.options, this.props.options);

        if (this.options.ajax && this.options.ajax.url) {
            this.options.ajax.url = Const.BASE_URL + Const.API_PATH + this.options.ajax.url;
        } else {
            delete this.options.ajax;
            this.options.bServerSide = false;
        }

        this.tableApi = $(this.tblData).DataTable(this.options);
    }

    draw = (refresh) => {
        if (typeof refresh === 'undefined') {
            refresh = true;
        }

        this.tableApi.draw(refresh);
    };

    render() {
        return (
            <div style={{ width: '100%', position: 'relative' }}>
                {
                    this.state.showLoading &&
                    <div className="panel-loader"
                        style={{ position: 'absolute', width: '100%', height: '200px' }}>
                        <span className="spinner-small" />
                    </div>
                }
                <div></div>
                <table className="table table-td-valign-middle table-th-valign-middle table-bordered table-search table-hover"
                    ref={(ref) => this.tblData = ref}
                    style={{ width: '100%' }}>
                    {this.props.children}
                </table>

                {
                    this.props.customPaginate &&
                    <div className="dataTables_wrapper dt-bootstrap4 no-footer">
                        <div className="row mt-2" style={{ justifyContent: "center" }}>
                            <div className="dataTables_paginate paging_full_numbers">
                                <ReactPaginate
                                    previousLabel={'<'}
                                    nextLabel={'>'}
                                    breakLabel={'...'}
                                    breakClassName={'paginate_button page-item disabled'}
                                    pageCount={Math.ceil(this.state.totalCount / 20)}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    initialPage={this.props.initialPage / 20}
                                    onPageChange={(data) => this.props.onPageChange && this.props.onPageChange(data.selected * 20)}
                                    disableInitialCallback={true}
                                    containerClassName={'pagination'}
                                    pageClassName={'paginate_button page-item'}
                                    pageLinkClassName={'page-link'}
                                    previousClassName={'paginate_button page-item'}
                                    previousLinkClassName={'page-link'}
                                    nextClassName={'paginate_button page-item'}
                                    nextLinkClassName={'page-link'}
                                    activeClassName={'active'}
                                    disabledClassName={'disabled'}
                                />
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }

}

export default inject('rootStore')(DataTable);