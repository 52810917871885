import React from 'react';
import { withRouter } from 'react-router-dom';
import { inject } from 'mobx-react';
import strings from '../../lang/strings.js';
import $ from 'jquery';
import { PageSettings } from "../../config/page-settings";
import DataTable from './../../components/content/data-table.jsx';
import SearchInput from './../../components/content/search-input.js';
import SearchSelect from './../../components/content/search-select.js';
import Const from './../../config/const';
import './../../config/util';
const queryString = require('query-string');

class MemberPathFragment extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        let searchQS = queryString.parse(this.props.location.search);

        this.state = {
            search_info: searchQS
        };

        this.refreshTable = this.refreshTable.bind(this);
        this.onClickRow = this.onClickRow.bind(this);
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.location.search != this.props.location.search) {
            let searchQS = queryString.parse(this.props.location.search);
            this.setState({ search_info: searchQS }, () => this.refreshTable());
        }
    }

    refreshTable = (refresh) => {
        this.tblMember.draw(refresh);
    };

    searchTable = () => {
        let searchQS = queryString.parse(this.props.location.search);
        Object.assign(searchQS, this.state.search_info)
        const new_query = queryString.stringify(searchQS);
        if (new_query == this.props.location.search.substr(1)) {
            this.refreshTable();
        } else {
            this.props.history.push('?' + new_query);
        }
    }

    onClickRow = (data) => {
        this.props.onClickRow(data.id);
    };

    render() {
        let self = this;
        let columnNo = 0;
        const { search_info } = this.state;

        return (
            <div>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='form-horizontal form-inline'>
                            {/* 키워드검색 */}
                            <SearchSelect
                                data={strings.const.SEARCH_CATEGORY2}
                                value={search_info.column || strings.const.SEARCH_CATEGORY2[0].code}
                                onChange={(value) => {
                                    this.setState({
                                        search_info: {
                                            ...search_info,
                                            column: value,
                                            start: 0,
                                        }
                                    }, this.searchTable);
                                }}
                            />
                            <SearchInput
                                value={search_info.keyword}
                                onChange={(value) => {
                                    this.setState({
                                        search_info: {
                                            ...search_info,
                                            keyword: value,
                                            column: search_info.column || strings.const.SEARCH_CATEGORY2[0].code,
                                            start: 0,
                                        }
                                    })
                                }}
                                handler={(value) => this.searchTable()}
                            />

                            <button type='button' className='btn btn-success btn-sm ml-md-2' onClick={() => this.searchTable()}>{strings.search}</button>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12 mt-3'>
                        <DataTable ref={(ref) => this.tblMember = ref}
                            customPaginate={true}
                            initialPage={search_info.start || 0}
                            onPageChange={(value) => {
                                this.setState({
                                    search_info: {
                                        ...search_info,
                                        start: value,
                                    }
                                }, this.searchTable);
                            }}
                            options={{
                                columnDefs: [
                                    {
                                        targets: [columnNo++], data: 'id', orderable: false,
                                        render: function (value) {
                                            return value;
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'user_id', orderable: false,
                                        render: function (value) {
                                            return value;
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'nickname', orderable: false,
                                        createdCell: function (td, value, item) {
                                            return $(td).nickname_label(value, item.gender, () => {
                                                self.onClickRow(item);
                                            });
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'status', orderable: false,
                                        render: function (value) {
                                            return Number(value).status2String();
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'birthday', orderable: false,
                                        render: function (value) {
                                            return value;
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'gender', orderable: false,
                                        render: function (value) {
                                            return value.gender2String();
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'school', orderable: false,
                                        createdCell: function (td, value, item) {
                                            return $(td).school_job_label(item.school, item.job);
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'phone_verified', orderable: false,
                                        render: function (value) {
                                            return value.verify2String();
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'path', orderable: false,
                                        render: function (value) {
                                            return `<b>${value}</b>`;
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'signup_date', orderable: false,
                                        render: function (value) {
                                            return value;
                                        }
                                    },
                                ],
                                order: [[1, 'DESC']],
                                ajax: {
                                    url: 'member/getList',
                                    data: function (req) {
                                        req.columns = null;

                                        const searchQS = queryString.parse(self.props.location.search);
                                        req.start = searchQS.start || 0;
                                        req.length = searchQS.length || 20;
                                        req.gender = self.props.gender;
                                        req.column = searchQS.column;
                                        req.keyword = searchQS.keyword;
                                    }
                                },
                                drawCallback: function () {
                                },
                                fnCreatedRow: function (row, data, dataInd) {
                                }
                            }}>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>{strings.user_id}</th>
                                    <th>{strings.nickname}</th>
                                    <th>{strings.status}</th>
                                    <th>{strings.birthday}</th>
                                    <th>{strings.gender}</th>
                                    <th>{strings.school_job}</th>
                                    <th>{strings.phone_number}</th>
                                    <th>{strings.signup_path}</th>
                                    <th>{strings.signup_date}</th>
                                </tr>
                            </thead>
                            <tbody />
                        </DataTable>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(inject('rootStore')(MemberPathFragment));