import { UiState } from "./ui-state";
import { makeAutoObservable } from "mobx";

class RootStore {

    user = {
        token: '',

        id: '',
        nickname: '',
        level: '',
        privilege: '',
        badge: [],
        school_list: [],
        job_list: [],
    };

    constructor() {
        makeAutoObservable(this);
        this.uiState = new UiState(this);
        this.loadStore();
    }

    login(user) {
        this.user = user;
        this.saveStore();
    }

    logout() {
        this.user = {
            token: '',

            id: '',
            nickname: '',
            level: '',
            privilege: '',
            badge: [],
            school_list: [],
            job_list: [],
        };
        this.saveStore();
    }

    isLogin() {
        return this.user.token !== '';
    }

    getId() {
        return this.user.id;
    }

    getNickname() {
        return this.user.nickname;
    }

    getToken() {
        return this.user.token;
    }

    getBadge() {
        return this.user.badge;
    }

    getJobList() {
        return this.user.job_list;
    }

    getSchoolList() {
        return this.user.school_list;
    }

    isOwner() {
        return this.user.level === 0;
    }

    hasPermission(path) {
        if (this.user.level === 0) {
            return true;
        }

        if (path === '/' || path === '/dashboard') {
            return true;
        }

        const path_list = path.split('/');
        if (path_list.length > 1) {
            if (this.user.privilege && this.user.privilege.indexOf(path_list[1]) >= 0) {
                return true;
            }
        }

        return false;
    }

    loadStore() {
        try {
            let store = JSON.parse(sessionStorage.getItem('store'));
            if (!store) {
                return;
            }

            this.user = store.user;
        } catch (e) {
            console.log(e);
        }
    }

    saveStore() {
        sessionStorage.setItem('store', JSON.stringify(this));
    }
}

export default RootStore;