import React from 'react';
import strings from '../../lang/strings.js';
import { withRouter } from 'react-router-dom';
import { inject } from 'mobx-react';
import { PageSettings } from "../../config/page-settings";
import Container from './../../components/content/container';
import './../../config/util';
import DateTime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import SettingCond from './cond'

class SettingBonus extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);
        this.state = {
            send_type: 'card',
            send_value: 0,
            send_date: '',
            send_time: '',
            cond: {},
        };
    }

    componentDidMount() {
    }

    onApply() {
        if (this.state.send_value === 0) {
            this.context.addNotification('warning', strings.alert_title, strings.empty_card_count);
            return;
        }

        if (this.state.send_date === '' || this.state.send_time === '') {
            this.context.addNotification('warning', strings.alert_title, strings.empty_date);
            return;
        }

        this.context.showConfirm(`"${this.state.send_value}" 장을\n${this.state.send_date + " " + this.state.send_time} 에\n예약전송하시겠습니까?`, (response) => {
            if (response) {
                this.sendCardToGroup();
            }
        });
    }

    sendCardToGroup = () => {
        let self = this;

        this.context.post(
            'cron/sendCardToGroup',
            this.state,
            function (response) {
                self.context.addNotification('success', strings.alert_title, response.result_data);
                self.props.history.push(`/setting/cron`);
            },
            true
        );
    }

    onChange(e) {
        this.setState({
            [e.target.id]: e.target.value
        });
    }

    onChangeDate(id, value, format) {
        this.setState({
            [id]: typeof value === 'object' ? value.format(format) : value
        });
    }

    onReset(e) {
        this.setState({
            send_type: 'card',
            send_value: 0,
            send_date: '',
            send_time: '',
            cond: {},
        });
    }

    render() {
        let self = this;
        return (
            <div>
                <div className="row">
                    <div className="col-xs-12">
                        <Container title={strings.bonus_title}>
                            <SettingCond cond={self.state} />
                            <div className="row mt-3">
                                <div className='col-md-12'>
                                    <div className='form-horizontal form-inline'>
                                        <select id='send_type' className='form-control'
                                            value={self.state.send_type}
                                            onChange={(e) => this.onChange(e)}>
                                            <option value={'card'}>{strings.card}</option>
                                        </select>
                                        <input type='number' autoComplete='off' className='form-control ml-md-2' id='send_value'
                                            value={self.state.send_value}
                                            onChange={(e) => this.onChange(e)} />
                                        <span>&nbsp; 장을</span>
                                        <div className="aboveDatePicker">
                                            <DateTime
                                                dateFormat={'YYYY-MM-DD'}
                                                timeFormat={false}
                                                inputProps={{ placeholder: strings.select_date }}
                                                closeOnSelect={true}
                                                className="ml-md-2"
                                                id="send_date"
                                                onChange={(e) => this.onChangeDate('send_date', e, 'YYYY-MM-DD')} />
                                        </div>
                                        <div className="aboveTimePicker">
                                            <DateTime
                                                dateFormat={false}
                                                inputProps={{ placeholder: strings.select_time }}
                                                className="ml-md-2"
                                                id="send_time"
                                                onChange={(e) => this.onChangeDate('send_time', e, 'HH:mm:ss')} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className='col-md-12'>
                                    <div className='form-horizontal form-inline'>
                                        <button type="button" className="btn btn-success btn-xl" onClick={() => {
                                            this.onApply();
                                        }}>{strings.send_reserve}</button>
                                        <button type="button" className="btn btn-dark btn-xl ml-2" onClick={() => {
                                            this.onReset();
                                        }}>{strings.reset}</button>
                                    </div>
                                </div>
                            </div>
                        </Container>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(inject('rootStore')(SettingBonus));