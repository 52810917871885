import React from 'react';
import { withRouter } from 'react-router-dom';
import { inject } from 'mobx-react';
import strings from '../../lang/strings.js';
import $ from 'jquery';
import { PageSettings } from "../../config/page-settings";
import DataTable from './../../components/content/data-table.jsx';
import SearchInput from './../../components/content/search-input.js';
import SearchSelect from './../../components/content/search-select.js';
import './../../config/util';
import Const from './../../config/const';
import MemberDetailPopup from './../member-detail/member-detail-popup'
const queryString = require('query-string');

class MemberNormalFragment extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        let searchQS = queryString.parse(this.props.location.search);

        this.state = {
            search_info: searchQS,
            user_id: 0,
            show_member_profile: false,
        };

        this.refreshTable = this.refreshTable.bind(this);
        this.onClickRow = this.onClickRow.bind(this);
        this.showMemberDetailPopup = this.showMemberDetailPopup.bind(this);
        this.deleteUser = this.deleteUser.bind(this);
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.location.search != this.props.location.search) {
            let searchQS = queryString.parse(this.props.location.search);
            this.setState({ search_info: searchQS }, () => this.refreshTable());
        }
    }

    refreshTable = (refresh) => {
        this.tblMember.draw(refresh);
    };

    searchTable = () => {
        let searchQS = queryString.parse(this.props.location.search);
        Object.assign(searchQS, this.state.search_info)
        const new_query = queryString.stringify(searchQS);
        if (new_query == this.props.location.search.substr(1)) {
            this.refreshTable();
        } else {
            this.props.history.push('?' + new_query);
        }
    }

    onClickRow = (data) => {
        this.props.onClickRow(data.id);
    };

    showMemberDetailPopup(uid) {
        this.setState({
            show_member_profile: true,
            selected_uid: uid
        });
    }

    deleteUser(uid) {
        let self = this;
        this.context.showConfirm(strings.confirm_delete_title, function (response) {
            if (response) {
                self.context.post('member/delete', { uid: uid }, function () {
                    self.context.addNotification('success', strings.alert_title, strings.confirm_delete_done);
                    self.refreshTable(false);
                }, true);
            }
        });
    }

    setVIP(uid, vip) {
        let self = this;
        let msg = '';
        if (vip == 'N') {
            msg = strings.msg_confirm_release;
        } else if (vip == 'Y') {
            msg = strings.msg_confirm_vip;
        } else if (vip == 'B') {
            msg = strings.msg_confirm_limit;
        }
        this.context.showConfirm(msg, function (response) {
            if (response) {
                self.context.post('member/setVIP', { uid, vip }, function () {
                    self.context.addNotification('success', strings.alert_title, strings.msg_applied);
                    self.refreshTable(false);
                }, true);
            }
        });
    }

    render() {
        let self = this;
        let columnNo = 0;

        const { search_info } = this.state;

        return (
            <div>
                <MemberDetailPopup isOpen={this.state.show_member_profile} uid={this.state.selected_uid} onClose={() => {
                    this.setState({ show_member_profile: false });
                }} />
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='form-horizontal form-inline'>
                            {/* 회원상태검색 */}
                            <SearchSelect
                                data={[
                                    { code: '', name: strings.total_member },
                                    { code: Const.USER_STATUS.NORMAL, name: strings.normal },
                                    { code: Const.USER_STATUS.PENDING, name: strings.pending },
                                    { code: Const.USER_STATUS.REJECT, name: strings.reject },
                                    { code: Const.USER_STATUS.SLEEP, name: strings.sleep },
                                    { code: Const.USER_STATUS.LEAVE, name: strings.leave },
                                    { code: Const.USER_STATUS.BLOCK, name: strings.block },
                                    { code: 'VIP', name: 'VIP' },
                                    { code: 'LIMIT', name: 'LIMIT' },
                                    { code: 'UGLY', name: strings.ugly_mode },
                                ]}
                                value={search_info.status || ''}
                                onChange={(value) => {
                                    this.setState({
                                        search_info: {
                                            ...search_info,
                                            status: value,
                                            start: 0,
                                        }
                                    }, this.searchTable);
                                }}
                            />

                            {/* 키워드검색 */}
                            <SearchSelect
                                data={strings.const.SEARCH_CATEGORY2}
                                value={search_info.column || strings.const.SEARCH_CATEGORY2[0].code}
                                onChange={(value) => {
                                    this.setState({
                                        search_info: {
                                            ...search_info,
                                            column: value,
                                            start: 0,
                                        }
                                    }, this.searchTable);
                                }}
                            />
                            <SearchInput
                                value={search_info.keyword}
                                onChange={(value) => {
                                    this.setState({
                                        search_info: {
                                            ...search_info,
                                            keyword: value,
                                            column: search_info.column || strings.const.SEARCH_CATEGORY2[0].code,
                                            start: 0,
                                        }
                                    })
                                }}
                                handler={(value) => this.searchTable()}
                            />

                            {/* 나이검색 */}
                            <span className='width-50 text-right'>{strings.birthday}:&nbsp;&nbsp;</span>
                            <SearchInput
                                type='number'
                                className='width-100'
                                placeholder={1980}
                                value={search_info.birth_from}
                                onChange={(value) => {
                                    this.setState({
                                        search_info: {
                                            ...search_info,
                                            birth_from: value,
                                            start: 0,
                                        }
                                    })
                                }}
                                handler={(value) => this.searchTable()}
                            />
                            <span className=''>&nbsp;&nbsp;~&nbsp;&nbsp;</span>
                            <SearchInput
                                type='number'
                                className='width-100'
                                placeholder={1990}
                                value={search_info.birth_to}
                                onChange={(value) => {
                                    this.setState({
                                        search_info: {
                                            ...search_info,
                                            birth_to: value,
                                            start: 0,
                                        }
                                    })
                                }}
                                handler={(value) => this.searchTable()}
                            />

                            {/* 키검색 */}
                            <span className='width-50 text-right'>{strings.height}:&nbsp;&nbsp;</span>
                            <SearchInput
                                type='number'
                                className='width-100'
                                placeholder={140}
                                value={search_info.height_from}
                                onChange={(value) => {
                                    this.setState({
                                        search_info: {
                                            ...search_info,
                                            height_from: value,
                                            start: 0,
                                        }
                                    })
                                }}
                                handler={(value) => this.searchTable()}
                            />
                            <span className=''>&nbsp;&nbsp;~&nbsp;&nbsp;</span>
                            <SearchInput
                                type='number'
                                className='width-100'
                                placeholder={200}
                                value={search_info.height_to}
                                onChange={(value) => {
                                    this.setState({
                                        search_info: {
                                            ...search_info,
                                            height_to: value,
                                            start: 0,
                                        }
                                    })
                                }}
                                handler={(value) => this.searchTable()}
                            />

                            <button type='button' className='btn btn-success btn-sm ml-md-2' onClick={() => this.searchTable()}>{strings.search}</button>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12 mt-3'>
                        <DataTable ref={(ref) => this.tblMember = ref}
                            customPaginate={true}
                            initialPage={search_info.start || 0}
                            onPageChange={(value) => {
                                this.setState({
                                    search_info: {
                                        ...search_info,
                                        start: value,
                                    }
                                }, this.searchTable);
                            }}
                            options={{
                                columnDefs: [
                                    {
                                        targets: [columnNo++], data: 'id', orderable: false,
                                        render: function (value) {
                                            return value;
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'user_id', orderable: false,
                                        render: function (value) {
                                            return value;
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'profile_image', orderable: false,
                                        createdCell: function (td, value, item) {
                                            if (!value) {
                                                return $(td).html(`(${strings.not_verified})`);
                                            } else {
                                                return $(td).image_lightbox(value, () => {
                                                    self.context.showGallery(value.split('#').map((it) => { return Const.BASE_URL + it }))
                                                });
                                            }
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'nickname', orderable: false,
                                        createdCell: function (td, value, item) {
                                            return $(td).nickname_label(value, item.gender, () => {
                                                self.showMemberDetailPopup(item.id);
                                            });
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'status', orderable: false,
                                        render: function (value) {
                                            return Number(value).status2String();
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'birthday', orderable: false,
                                        render: function (value) {
                                            return value;
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'phone_verified', orderable: false,
                                        createdCell: function (td, value, item) {
                                            return $(td).html(`${item.phone_verified}<br/>(${item.realname_verified})`);
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'location', orderable: false,
                                        render: function (value) {
                                            return value;
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'gender', orderable: false,
                                        render: function (value) {
                                            return value.gender2String();
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: null, orderable: false,
                                        createdCell: function (td, value, item) {
                                            return $(td).school_job_label(item.school, item.job);
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'heart', orderable: false,
                                        render: function (value) {
                                            return value.currencyFormat();
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'point', orderable: false,
                                        render: function (value) {
                                            return value.currencyFormat();
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'ugly_expire_date', orderable: false,
                                        render: function (value) {
                                            return value === '0000-00-00 00:00:00' ? '-' : value;
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'signup_date', orderable: false,
                                        render: function (value) {
                                            return value.dateFormat();
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'login_date', orderable: false,
                                        render: function (value) {
                                            return value.dateFormat();
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'version_name', orderable: false,
                                        createdCell: function (td, value, item) {
                                            if (item.login_os == "android") {
                                                $(td).html("<span style='color: green'>" + value + " (G)</span>");
                                            } else {
                                                $(td).html("<span style='color: red'>" + value + " (A)</span>");
                                            }
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'vip', orderable: false,
                                        createdCell: function (td, value, item) {
                                            $(td).html('');

                                            if (value == "Y") {
                                                $(td).append("<div><span style='color: red'>VIP</span></div>");
                                                $(td).append(`[<a href="javascript:void(0);">${strings.release}</a>]`)
                                                    .find('a')
                                                    .bind('click', () => {
                                                        self.setVIP(item.id, 'N');
                                                    });
                                            } else if (value == "B") {
                                                $(td).append("<div><span style='color: black'>LIMIT</span></div>");
                                                $(td).append(`[<a href="javascript:void(0);">${strings.release}</a>]`)
                                                    .find('a')
                                                    .bind('click', () => {
                                                        self.setVIP(item.id, 'N');
                                                    });
                                            } else {
                                                $(td).append(`[<a href="javascript:void(0);">${strings.vip_setting}</a>]`)
                                                    .find('a')
                                                    .bind('click', () => {
                                                        self.setVIP(item.id, 'Y');
                                                    });
                                            }
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'id', orderable: false,
                                        createdCell: function (td, value, item) {
                                            $(td).html('');
                                            $(td).append(`<button type='button' class='btn btn-success btn-sm m-2'>${strings.inspect}</button>`)
                                                .find('button:eq(0)')
                                                .on('click', function () {
                                                    self.onClickRow(item);
                                                });
                                            $(td).append(`<button type='button' class='btn btn-inverse btn-sm m-2'>${strings.matching_limit}</button>`)
                                                .find('button:eq(1)')
                                                .on('click', function () {
                                                    self.setVIP(item.id, 'B');
                                                });
                                            $(td).append(`<button type='button' class='btn btn-danger btn-sm m-2'>${strings.db_delete}</button>`)
                                                .find('button:eq(2)')
                                                .on('click', function () {
                                                    self.deleteUser(item.id);
                                                });
                                        }
                                    }
                                ],
                                order: [[1, 'DESC']],
                                ajax: {
                                    url: 'member/getList',
                                    data: function (req) {
                                        req.columns = null;

                                        const searchQS = queryString.parse(self.props.location.search);
                                        req.start = searchQS.start || 0;
                                        req.length = searchQS.length || 20;
                                        req.gender = searchQS.gender;
                                        req.status = searchQS.status;
                                        req.column = searchQS.column;
                                        req.keyword = searchQS.keyword;
                                        req.birth_from = searchQS.birth_from;
                                        req.birth_to = searchQS.birth_to;
                                        req.height_from = searchQS.height_from;
                                        req.height_to = searchQS.height_to;
                                    }
                                },
                                drawCallback: function () {
                                },
                                fnCreatedRow: function (row, data, dataInd) {
                                }
                            }}>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>{strings.user_id}</th>
                                    <th>{strings.profile}</th>
                                    <th>{strings.nickname}</th>
                                    <th>{strings.status}</th>
                                    <th>{strings.birthday}</th>
                                    <th>{strings.phone_number}</th>
                                    <th>{strings.location}</th>
                                    <th>{strings.gender}</th>
                                    <th>{strings.school_job}</th>
                                    <th>{strings.heart}</th>
                                    <th>{strings.point}</th>
                                    <th>{strings.ugly_mode}</th>
                                    <th>{strings.account_create_date}</th>
                                    <th>{strings.latest_login_date}</th>
                                    <th>{strings.app_version}</th>
                                    <th>{strings.matching}</th>
                                    <th>{strings.manage}</th>
                                </tr>
                            </thead>
                            <tbody />
                        </DataTable>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(inject('rootStore')(MemberNormalFragment));