import React from 'react';
import strings from '../../lang/strings.js';
import { PageSettings } from "../../config/page-settings";
import { Panel, PanelBody, PanelHeader } from '../../components/panel/panel.jsx';
import { Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import $ from 'jquery';
import '../../config/util';
import U from '../../config/util';
import Const from '../../config/const';
import BandDetailInfo from './band-detail-info';
import BandDetailCard from './band-detail-card';

class BandDetail extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.band_id = Number(this.props.match.params.bandId);

        this.state = {
            activeTab: '1',
        }

        this.toggle = this.toggle.bind(this);
    }

    componentDidMount() {
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }
    }

    render() {
        return (
            <div className="row">
                <div className="col-xl-6">
                    <BandDetailInfo band_id={this.band_id} onSave={() => {
                        if (this.band_id < 1) {
                            this.props.history.goBack();
                        }
                    }} />
                </div>
                {
                    this.band_id > 0 &&
                    <div className="col-xl-6">
                        <Panel>
                            <Nav tabs className="nav-tabs-inverse">
                                <NavItem>
                                    <NavLink className={classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.toggle('1'); }}>
                                        <span className="d-none d-xs-inline">{strings.card}</span>
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <PanelBody>
                                {this.state.activeTab === "1" && (<BandDetailCard band_id={this.band_id} />)}
                            </PanelBody>
                        </Panel>
                    </div>
                }
            </div>
        )
    }
}

export default BandDetail;