import React from 'react';
import strings from '../../lang/strings.js';
import { PageSettings } from "../../config/page-settings";
import { Panel, PanelBody } from '../../components/panel/panel.jsx';
import './../../config/util';
import IapRankingFragment from './ranking-fragment'

class IapRanking extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);
        this.state = {
            activeTab: '1'
        };

        this.toggle = this.toggle.bind(this);
        this.showMemberDetail = this.showMemberDetail.bind(this);
        this.showMemberDetailPopup = this.showMemberDetailPopup.bind(this);
        this.onChangeRadio = this.onChangeRadio.bind(this);
    }

    componentDidMount() {
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }
    }

    showMemberDetail(uid) {
        this.setState({
            activeTab: "0",
            selected_uid: uid
        });
    }

    showMemberDetailPopup(uid) {
        this.setState({
            show_member_profile: true,
            selected_uid: uid
        });
    }

    onChangeRadio(e) {
        this.setState({ gender: e.target.value });
    }

    render() {
        let self = this;

        return (
            <div>
                <Panel>
                    <PanelBody>
                        <div className='row'>
                            <div className="ml-2 radio radio-css">
                                <input type="radio" id="table_radio_1" name="table_radio"
                                    value='' onChange={self.onChangeRadio} defaultChecked />
                                <label htmlFor="table_radio_1">{strings.total}</label>
                            </div>
                            <div className="radio radio-css ml-2">
                                <input type="radio" id="table_radio_2" name="table_radio"
                                    value='M' onChange={self.onChangeRadio} />
                                <label htmlFor="table_radio_2">{strings.male}</label>
                            </div>
                            <div className="radio radio-css ml-2">
                                <input type="radio" id="table_radio_3" name="table_radio"
                                    value='F' onChange={self.onChangeRadio} />
                                <label htmlFor="table_radio_3">{strings.female}</label>
                            </div>
                        </div>
                    </PanelBody>
                </Panel>

                <div className="row">
                    <div className="col-xl-3">
                        <IapRankingFragment id='1' title={strings.iap_ranking_01} group_by={'school'} gender={this.state.gender} />
                    </div>
                    <div className="col-xl-3">
                        <IapRankingFragment id='2' title={strings.iap_ranking_02} group_by={'job'} gender={this.state.gender} />
                    </div>
                    <div className="col-xl-3">
                        <IapRankingFragment id='3' title={strings.iap_ranking_03} group_by={'school'} gender={this.state.gender} />
                    </div>
                    <div className="col-xl-3">
                        <IapRankingFragment id='4' title={strings.iap_ranking_04} group_by={'job'} gender={this.state.gender} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-3">
                        <IapRankingFragment id='5' title={strings.iap_ranking_05} group_by={'birthday'} gender={this.state.gender} />
                    </div>
                    <div className="col-xl-3">
                        <IapRankingFragment id='6' title={strings.iap_ranking_06} group_by={'gender'} gender={this.state.gender} />
                    </div>
                    <div className="col-xl-3">
                        <IapRankingFragment id='7' title={strings.iap_ranking_07} group_by={'location'} gender={this.state.gender} />
                    </div>
                </div>
            </div>
        )
    }
}

export default IapRanking;