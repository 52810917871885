import React from 'react';
import { Link } from 'react-router-dom';
import strings from '../../lang/strings.js';
import { PageSettings } from "../../config/page-settings";
import Container from './../../components/content/container.js';

class StatusStaFragment extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
            today: null,
            month: null,
            all: null,
        };
    }

    componentDidMount() {
        this.getStatusSta();
    }

    getStatusSta = () => {
        let self = this;

        this.context.get(
            'dashboard/status_sta',
            {},
            function (response) {
                self.setState({
                    today: response.result_data.today,
                    month: response.result_data.month,
                    all: response.result_data.all
                });
            });
    };

    render() {
        let self = this;

        return (
            <div>
                <Container title={strings.status_sta_01} rightButton={(
                    <Link to="/member/profile_pending" style={{ color: '#fff' }}>{strings.just_go} <i className="fa fa-arrow-alt-circle-right"></i></Link>
                )}>
                    <div className="bg-white height-150">
                        <div className="d-flex align-items-center text-black">
                            <div className="d-flex w-100">
                                <div>{strings.status_sta_01}</div>
                                <div className="ml-auto">{self.state.today ? `${self.state.today.male_count > 0 ? (self.state.today.male_normal_count / self.state.today.male_count * 100).toFixed(0) : 0}% (${self.state.today.male_normal_count}/${self.state.today.male_count})` : `-`}</div>
                            </div>
                        </div>
                        <div className="d-flex align-items-center text-black">
                            <div className="d-flex w-100">
                                <div>{strings.status_sta_02}</div>
                                <div className="ml-auto">{self.state.today ? `${self.state.today.female_count > 0 ? (self.state.today.female_normal_count / self.state.today.female_count * 100).toFixed(0) : 0}% (${self.state.today.female_normal_count}/${self.state.today.female_count})` : `-`}</div>
                            </div>
                        </div>
                        <div className="d-flex align-items-center text-black">
                            <div className="d-flex w-100">
                                <div>{strings.status_sta_03}</div>
                                <div className="ml-auto">{self.state.month ? `${self.state.month.male_count > 0 ? (self.state.month.male_normal_count / self.state.month.male_count * 100).toFixed(0) : 0}% (${self.state.month.male_normal_count}/${self.state.month.male_count})` : `-`}</div>
                            </div>
                        </div>
                        <div className="d-flex align-items-center text-black">
                            <div className="d-flex w-100">
                                <div>{strings.status_sta_04}</div>
                                <div className="ml-auto">{self.state.month ? `${self.state.month.female_count > 0 ? (self.state.month.female_normal_count / self.state.month.female_count * 100).toFixed(0) : 0}% (${self.state.month.female_normal_count}/${self.state.month.female_count})` : `-`}</div>
                            </div>
                        </div>
                        <div className="d-flex align-items-center text-black">
                            <div className="d-flex w-100">
                                <div>{strings.status_sta_05}</div>
                                <div className="ml-auto">{self.state.all ? `${self.state.all.male_count > 0 ? (self.state.all.male_normal_count / self.state.all.male_count * 100).toFixed(0) : 0}% (${self.state.all.male_normal_count}/${self.state.all.male_count})` : `-`}</div>
                            </div>
                        </div>
                        <div className="d-flex align-items-center text-black">
                            <div className="d-flex w-100">
                                <div>{strings.status_sta_06}</div>
                                <div className="ml-auto">{self.state.all ? `${self.state.all.female_count > 0 ? (self.state.all.female_normal_count / self.state.all.female_count * 100).toFixed(0) : 0}% (${self.state.all.female_normal_count}/${self.state.all.female_count})` : `-`}</div>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        )
    }
}

export default StatusStaFragment;