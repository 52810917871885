import React from 'react';
import strings from '../../lang/strings.js';
import $ from 'jquery';
import { PageSettings } from "../../config/page-settings";
import '../../config/util';
import DataTable from '../../components/content/data-table.jsx';
import MemberDetailPopup from '../member-detail/member-detail-popup'

class KeywordDetailAnswer extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
            show_member_profile: false,
            selected_uid: 0,
        }
    }

    componentDidMount() {
    }

    refreshTable = (refresh) => {
        this.tblMember.draw(refresh);
    };

    showMemberDetailPopup(uid) {
        this.setState({
            show_member_profile: true,
            selected_uid: uid
        });
    }

    render() {
        let self = this;
        let columnNo = 0;

        return (
            <div>
                <MemberDetailPopup isOpen={this.state.show_member_profile} uid={this.state.selected_uid} onClose={() => {
                    this.setState({ show_member_profile: false });
                }} />

                <div className='row'>
                    <div className='col-md-12'>
                        <DataTable ref={(ref) => this.tblMember = ref}
                            options={{
                                columnDefs: [
                                    {
                                        targets: [columnNo++], data: 'id', orderable: false,
                                        render: function (value) {
                                            return value;
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'nickname', orderable: false,
                                        createdCell: function (td, value, item) {
                                            $(td).nickname_label(value, item.gender, () => {
                                                self.showMemberDetailPopup(item.uid);
                                            });
                                            if (item.alias) {
                                                $(td).append(`<br/>(${item.alias})`);
                                            }
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'question', orderable: false,
                                        render: function (value) {
                                            return value;
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'answer', orderable: false,
                                        render: function (value) {
                                            return value;
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'create_date', orderable: false,
                                        render: function (value) {
                                            return value
                                        }
                                    },
                                ],
                                order: [[1, 'DESC']],
                                ajax: {
                                    url: 'keyword/getAnswerList',
                                    data: function (req) {
                                        req.columns = null;
                                        req.keyword_id = self.props.keyword_id;
                                    }
                                },
                                drawCallback: function () {
                                },
                                fnCreatedRow: function (row, data, dataInd) {
                                }
                            }}>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>{'참가자'}</th>
                                    <th width="40%">{'질문'}</th>
                                    <th>{'답변'}</th>
                                    <th>{strings.create_date}</th>
                                </tr>
                            </thead>
                            <tbody />
                        </DataTable>
                    </div>
                </div>
            </div>
        )
    }
}

export default KeywordDetailAnswer;