import React from 'react';
import strings from '../../lang/strings.js';
import { PageSettings } from "../../config/page-settings";
import { Panel, PanelBody } from '../../components/panel/panel.jsx';
import './../../config/util';
import MemberRankingFragment from './ranking-fragment'

class MemberRanking extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);
        this.state = {
            gender: '',
        };

        this.onChangeRadio = this.onChangeRadio.bind(this);
    }

    componentDidMount() {
    }

    onChangeRadio(e) {
        this.setState({ gender: e.target.value });
    }

    render() {
        let self = this;

        return (
            <div>
                <Panel>
                    <PanelBody>
                        <div className='row'>
                            <div className="ml-2 radio radio-css">
                                <input type="radio" id="table_radio_1" name="table_radio"
                                    value='' onChange={self.onChangeRadio} defaultChecked />
                                <label htmlFor="table_radio_1">{strings.total}</label>
                            </div>
                            <div className="radio radio-css ml-2">
                                <input type="radio" id="table_radio_2" name="table_radio"
                                    value='M' onChange={self.onChangeRadio} />
                                <label htmlFor="table_radio_2">{strings.male}</label>
                            </div>
                            <div className="radio radio-css ml-2">
                                <input type="radio" id="table_radio_3" name="table_radio"
                                    value='F' onChange={self.onChangeRadio} />
                                <label htmlFor="table_radio_3">{strings.female}</label>
                            </div>
                        </div>
                    </PanelBody>
                </Panel>

                <div className="row">
                    <div className="col-xl-3">
                        <MemberRankingFragment id='1' title={strings.ranking_01} category={'card'} group_by={'school'} gender={this.state.gender} />
                    </div>
                    <div className="col-xl-3">
                        <MemberRankingFragment id='2' title={strings.ranking_02} category={'card'} group_by={'job'} gender={this.state.gender} />
                    </div>
                    <div className="col-xl-3">
                        <MemberRankingFragment id='3' title={strings.ranking_03} category={'member'} group_by={'school'} gender={this.state.gender} />
                    </div>
                    <div className="col-xl-3">
                        <MemberRankingFragment id='4' title={strings.ranking_04} category={'member'} group_by={'job'} gender={this.state.gender} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-3">
                        <MemberRankingFragment id='5' title={strings.ranking_05} category={'member'} group_by={'birthday'} gender={this.state.gender} />
                    </div>
                    <div className="col-xl-3">
                        <MemberRankingFragment id='6' title={strings.ranking_06} category={'card'} group_by={'birthday'} gender={this.state.gender} />
                    </div>
                    <div className="col-xl-3">
                        <MemberRankingFragment id='7' title={strings.ranking_07} category={'member'} group_by={'location'} gender={this.state.gender} />
                    </div>
                    <div className="col-xl-3">
                        <MemberRankingFragment id='8' title={strings.ranking_08} category={'card'} group_by={'location'} gender={this.state.gender} />
                    </div>
                </div>
            </div>
        )
    }
}

export default MemberRanking;