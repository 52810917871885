import React from 'react';
import strings from '../../lang/strings.js';
import { PageSettings } from "../../config/page-settings";
import { Card, CardText, CardBody, CardHeader, Button } from 'reactstrap';
import './../../config/util';
import Const from './../../config/const';

class CardMessageItem extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {}
    }

    componentDidMount() {
    }

    render() {
        let self = this;
        let message = this.props.info;

        return (
            <div>
                <Card>
                    <CardHeader className="f-w-600">
                        {message.ok === 2 ? strings.top_ok_message : strings.ok_message}
                    </CardHeader>
                    <CardBody className="text-center">
                        <div className="row mt-2">
                            {
                                message.profile_image.length > 0 && (
                                    <div className='col-xs-5 text-center'>
                                        <img className='col-xs-12 mb-2' alt='' style={{ maxHeight: 200 }}
                                            src={Const.BASE_URL + message.profile_image.split('#')[0].getThumbnailUrl()}
                                            onClick={() => {
                                                self.props.onClickUser1(message.uid);
                                            }} />
                                        <p>{message.nickname}</p>
                                    </div>
                                )
                            }
                            <div className='col-xs-2 text-center'>
                                {
                                    message.ok > 0 &&
                                    <i className='fas fa-arrow-right f-s-25'></i>
                                }
                                {
                                    message.target_ok > 0 &&
                                    <i className='fas fa-arrow-left f-s-25'></i>
                                }
                            </div>
                            {
                                message.target_profile_image.length > 0 && (
                                    <div className='col-xs-5 text-center'>
                                        <img className='col-xs-12 mb-2' alt='' style={{ maxHeight: 200 }}
                                            src={Const.BASE_URL + message.target_profile_image.split('#')[0].getThumbnailUrl()}
                                            onClick={() => {
                                                self.props.onClickUser1(message.target_uid)
                                            }} />
                                        <p>{message.target_nickname}</p>
                                    </div>
                                )
                            }
                        </div>
                        <label>{message.update_date}</label>
                        <Card className="mt-1 text-left">
                            <CardBody className="height-100 overflow-auto">
                                {
                                    message.message &&
                                    <CardText><b>{message.nickname}:</b> {message.message}</CardText>
                                }
                                {
                                    message.target_message &&
                                    <CardText><b>{message.target_nickname}:</b> {message.target_message}</CardText>
                                }
                            </CardBody>
                        </Card>
                        <Button className="mt-3" color="success" size="sm" onClick={() => {
                            if (self.props.onDelete) {
                                self.props.onDelete(message)
                            }
                        }}>차단</Button>
                    </CardBody>
                </Card >
            </div>
        )
    }
}

export default CardMessageItem;