import React from 'react';
import strings from '../../lang/strings.js';
import { PageSettings } from "../../config/page-settings";
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import classnames from 'classnames';
import $ from 'jquery';
import 'react-datetime/css/react-datetime.css';
import './../../config/util';
import U from './../../config/util';
import Const from './../../config/const';
import VideoSelect from './../../components/content/video-select.jsx';


class VideoEditPopup extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
            item: {},
            video_url: "",
            video_file: null,
        };

        this.toggleModal = this.toggleModal.bind(this);
    }

    componentDidMount() {
    }

    toggleModal(result) {
        if (this.props.onClose) {
            this.props.onClose(result);
        }
    }

    getData = () => {
        if (!this.props.video_id) {
            this.setState({
                item: {},
                video_url: "",
                video_file: null,
            });
            return;
        }

        let self = this;
        this.context.post(
            'video/getItem',
            {
                id: this.props.video_id
            },
            function (response) {
                let item = response.result_data.video_item;

                self.setState({
                    item,
                    video_url: U.getVideoThumbUrl(item.video_url),
                    video_file: null,
                });
            });
    };

    uploadData = (video_files, callback) => {

        let self = this;

        let formData = new FormData();
        video_files.forEach((entry, index) => {
            formData.append('file', entry, entry.name);
        });


        this.context.post(
            'upload/videos/review',
            formData,
            function (response) {
                callback(response.result_data.upload_path);
            },
            true
        );
    }

    saveData = () => {
        let self = this;

        if (this.state.video_file) {
            this.uploadData([this.state.video_file], function (video_urls) {
                self.state.video_url = U.getVideoThumbUrl(video_urls[0]);
                self.state.video_file = null;
                self.state.item.video_url = video_urls[0];
                self.saveData();
            });
            return;
        }

        const item = self.state.item;

        if (!item.nickname) {
            self.context.addNotification('warning', strings.alert_title, strings.empty_nickname);
            return;
        }
        if (!item.video_url) {
            self.context.addNotification('warning', strings.alert_title, strings.empty_video);
            return;
        }

        this.context.post('video/save',
            {
                video_info: item
            }, function (response) {
                self.context.addNotification('success', strings.alert_title, strings.save_done);
                if (self.props.onSave) {
                    self.props.onSave();
                }
            }, true);
    };

    onDataChange = (e) => {
        let item = this.state.item;
        item[e.target.id] = e.target.value;

        this.setState({
            item: item
        });
    };

    onVideoChange = (e) => {
        let self = this;

        if (e.target.files.length < 1) {
            return;
        }

        let file = e.target.files[0];
        self.setState({
            video_url: file.name,
            video_file: file,
        });
    };

    render() {
        let item = this.state.item;

        return (
            <div>
                <Modal isOpen={this.props.isOpen} toggle={this.toggleModal} onOpened={() => this.getData()}>
                    <ModalHeader className="popup-nickname" toggle={this.toggleModal}>{strings.review_edit}</ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <div className="col-xs-12">
                                <div className='row'>
                                    <label className='col-md-3 col-form-label'>{strings.nickname} *</label>
                                    <div className="col-md-9">
                                        <input type='text' autoComplete='off' className='form-control' id='nickname'
                                            value={item.nickname || ''}
                                            onChange={(e) => this.onDataChange(e)} />
                                    </div>
                                </div>
                                <div className='row'>
                                    <label className='col-md-3 col-form-label'>{strings.video} *</label>
                                    <div className="col-md-9">
                                        <div className="row mt-1 mb-1">
                                            <div className='col-xs-8 col-md-6'>
                                                <VideoSelect
                                                    height="100%"
                                                    video_url={this.state.video_url}
                                                    onSelect={(e) => this.onVideoChange(e)}
                                                    onDelete={() => {
                                                        this.setState({
                                                            video_url: '',
                                                            video_file: null,
                                                        });
                                                    }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-default" onClick={this.toggleModal}>{strings.close}</button>
                        <button className="btn btn-success" onClick={() => this.saveData()}>{strings.apply}</button>
                    </ModalFooter>
                </Modal>
            </div >
        )
    }
}

export default VideoEditPopup;