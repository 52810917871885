import React from 'react';
import strings from '../../lang/strings.js';
import { PageSettings } from "../../config/page-settings";
import { Panel, PanelBody, PanelHeader, PanelFooter } from '../../components/panel/panel.jsx';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import ReactTags from 'react-tag-autocomplete';
import 'react-datetime/css/react-datetime.css';
import '../../config/util';
import U from '../../config/util';
import Const from '../../config/const';
import ImageSelect from '../../components/content/image-select.jsx';
import AddressSearchPopup from '../common/address-search-popup'
import ReactAudioPlayer from 'react-audio-player';

class BandDetailInfo extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
            item: {},
            image_url: "",
        };

        this.image_url_file = null;

        this.toggleModal = this.toggleModal.bind(this);

        this.handleCategoryDelete = (i) => {
            const tag_list = this.state.item.tag.split('#')
            tag_list.splice(i, 1)
            this.state.item.tag = tag_list.join('#');
            this.setState({ item: this.state.item })
        }

        this.handleCategoryAddition = (item) => {
            var tag_list = [];
            if (this.state.item.tag) {
                tag_list = this.state.item.tag.split('#')
            }
            tag_list = [].concat(tag_list, item.name)
            this.state.item.tag = tag_list.join('#');
            this.setState({ item: this.state.item })
        }
    }

    componentDidMount() {
        this.getData();
    }

    toggleModal(result) {
        if (this.props.onClose) {
            this.props.onClose(result);
        }
    }

    getData = () => {
        $('#title').focus();

        if (!this.props.band_id) {
            return;
        }

        let self = this;

        this.context.post(
            'band/getItem',
            {
                band_id: this.props.band_id
            },
            function (response) {
                let item = response.result_data.band_info;

                self.setState({
                    item,
                    image_url: U.getThumbUrl(item.image),
                });
                self.image_url_file = null;
            });
    };

    uploadData = (image_files, callback) => {

        let self = this;

        let formData = new FormData();
        image_files.forEach((entry, index) => {
            formData.append('file', entry, entry.name);
        });


        this.context.post(
            'upload/images/band',
            formData,
            function (response) {
                callback(response.result_data.upload_path);
            },
            true
        );
    }

    saveData = () => {
        let self = this;
        const item = self.state.item;

        if (!item.tag) {
            self.context.addNotification('warning', strings.alert_title, strings.empty_tag);
            return;
        }
        if (!item.title) {
            self.context.addNotification('warning', strings.alert_title, strings.empty_title);
            return;
        }
        if (!item.content) {
            self.context.addNotification('warning', strings.alert_title, strings.empty_content);
            return;
        }

        if (this.image_url_file) {
            this.uploadData([this.image_url_file], function (image_url) {
                self.state.image_url = U.getThumbUrl(image_url);
                self.image_url_file = null;
                self.saveData();
            });
            return;
        }

        item.image = this.state.image_url.replace('_thumb.', '.').url2String();

        if (!item.image) {
            self.context.addNotification('warning', strings.alert_title, strings.empty_main_image);
            return;
        }

        this.context.post('band/save',
            {
                band_info: item
            }, function (response) {
                self.context.addNotification('success', strings.alert_title, strings.save_done);
                if (self.props.onSave) {
                    self.props.onSave();
                }
            }, true);
    };

    onDataChange = (e) => {
        let item = this.state.item;
        item[e.target.id] = e.target.value;

        this.setState({
            item: item
        });
    };

    onMainImageChange = (e) => {
        let self = this;

        if (e.target.files.length < 1) {
            return;
        }

        let file = e.target.files[0];
        let reader = new FileReader();

        reader.onloadend = function () {
            self.setState({ image_url: reader.result });
            self.image_url_file = file
        };
        reader.readAsDataURL(file);
    };

    render() {
        let item = this.state.item;
        let tag_list = item.tag ? item.tag.split('#') : [];

        return (
            <div>
                <Panel>
                    <PanelHeader noButton={true}>{strings.band_detail}</PanelHeader>
                    <PanelBody>
                        <div className="row">
                            <div className="col-xs-12">
                                <div className='row'>
                                    <label className='col-md-2 col-form-label'>{strings.category}</label>
                                    <div className="col-md-10">
                                        <select className='form-control'
                                            onChange={(e) => this.onDataChange(e, 'category')}
                                            value={item.category || ''}>
                                            {strings.const.BAND_CATEGORY.map((entry, index) => (
                                                <option key={index} value={entry}>{entry}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className='row'>
                                    <label className='col-md-2 col-form-label'>{strings.type}</label>
                                    <div className="col-md-10">
                                        <select className='form-control'
                                            onChange={(e) => this.onDataChange(e, 'type')}
                                            value={item.type || ''}>
                                            {strings.const.BAND_TYPE.map((entry, index) => (
                                                <option key={index} value={entry.code}>{entry.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className='row'>
                                    <label className='col-md-2 col-form-label'>{strings.tag} *</label>
                                    <div className="col-md-10 mb-1" style={{ zIndex: 1002 }}>
                                        <ReactTags
                                            tags={tag_list.map((item, index) => {
                                                return { id: index, name: item }
                                            })}
                                            suggestions={
                                                strings.const.BAND_TAG_LIST.filter(it => {
                                                    return tag_list.indexOf(it) < 0
                                                }).map((item, index) => {
                                                    return { id: index, name: item }
                                                })
                                            }
                                            handleDelete={this.handleCategoryDelete.bind(this)}
                                            handleAddition={this.handleCategoryAddition.bind(this)}
                                            placeholder={'Add new tag'}
                                            minQueryLength={0}
                                            maxSuggestionsLength={100}
                                            autofocus={false}
                                        />
                                    </div>
                                </div>
                                <div className='row'>
                                    <label className='col-md-2 col-form-label'>{strings.location} *</label>
                                    <div className="col-md-10">
                                        <select className='form-control'
                                            onChange={(e) => this.onDataChange(e, 'location')}
                                            value={item.location || ''}>
                                            {strings.const.BAND_LOCATION.map((entry, index) => (
                                                <option key={index} value={entry}>{entry}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className='row'>
                                    <label className='col-md-2 col-form-label'>{strings.title} *</label>
                                    <div className="col-md-10">
                                        <div className="row mb-1">
                                            <div className='col-md-12'>
                                                <input type='text' autoComplete='off' className='form-control' id='title'
                                                    value={item.title || ''}
                                                    onChange={(e) => this.onDataChange(e)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <label className='col-md-2 col-form-label'>{strings.content} *</label>
                                    <div className="col-md-10">
                                        <div className="row mb-1">
                                            <div className='col-md-12'>
                                                <textarea type='text' autoComplete='off' className='form-control m-b-2' id='content'
                                                    value={item.content || ''} rows={20}
                                                    onChange={(e) => this.onDataChange(e)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <label className='col-md-2 col-form-label'>{strings.image}</label>
                                    <div className="col-md-10">
                                        <div className="row mb-1">
                                            <div className='col-xs-4 col-sm-3 col-md-5 col-lg-4 col-xl-4 col-xxl-3'>
                                                <ImageSelect image_url={this.state.image_url}
                                                    onSelect={(e) => this.onMainImageChange(e)}
                                                    onDelete={() => {
                                                        this.setState({ image_url: '' });
                                                        this.image_url_file = null;
                                                    }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <label className='col-md-2 col-form-label'>{'목소리'}</label>
                                    <div className="col-md-10">
                                        <div className="row mb-1">
                                            {
                                                item.audio &&
                                                <ReactAudioPlayer src={Const.BASE_URL + item.audio} controls />
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <label className='col-md-2 col-form-label'>{strings.create_date}</label>
                                    <label className='col-md-10 col-form-label'>{item.create_date}</label>
                                </div>
                                <div className='row'>
                                    <label className='col-md-2 col-form-label'>{strings.update_date}</label>
                                    <label className='col-md-10 col-form-label'>{item.update_date}</label>
                                </div>
                            </div>
                        </div>
                    </PanelBody>
                    <PanelFooter className={"text-right"}>
                        <div className="d-flex w-100">
                            <div>
                                <Link to="/band/band">
                                    <button type='button' className='btn btn-dark btn-sm m-2'>{strings.list}</button>
                                </Link>
                            </div>
                            <div className="ml-auto">
                                <button type='button' className='btn btn-success btn-sm m-2' onClick={() => { this.saveData() }}>{strings.save}</button>
                            </div>
                        </div>
                    </PanelFooter>
                </Panel>
            </div >
        )
    }
}

export default BandDetailInfo;