import React from 'react';
import strings from '../../lang/strings.js';
import { PageSettings } from "../../config/page-settings";
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import classnames from 'classnames';
import $ from 'jquery';
import '../../config/util';
import U from '../../config/util';
import Const from '../../config/const';
import menus from '../../config/menu.jsx';

class ZoneLocationPopup extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
            item: {},
        };

        this.toggleModal = this.toggleModal.bind(this);
    }

    componentDidMount() {
    }

    toggleModal(result) {
        if (this.props.onClose) {
            this.props.onClose(result);
        }
    }

    getData = () => {
        if (!this.props.location_item) {
            this.setState({
                item: {},
            });
            return;
        }

        this.setState({
            item: this.props.location_item,
        });
    };

    saveData = () => {
        let self = this;

        const location_info = self.state.item;

        this.context.post('zone/postLocation',
            {
                id: location_info.id || 0,
                parent: this.props.parent,
                location: location_info.location,
                latitude: location_info.latitude || 0,
                longitude: location_info.longitude || 0,
                bot_count: location_info.bot_count || 0,
            }, function (response) {
                self.context.addNotification('success', strings.alert_title, strings.save_done);
                if (self.props.onSave) {
                    self.props.onSave();
                }
            }, true);
    };

    onDataChange = (e) => {
        let item = this.state.item;
        item[e.target.id] = e.target.value;

        this.setState({
            item: item
        });
    };

    render() {
        let item = this.state.item;
        const privilege_list = item.privilege ? item.privilege.split('#') : [];

        return (
            <div>
                <Modal isOpen={this.props.isOpen} toggle={this.toggleModal} onOpened={() => this.getData()} size={'md'}>
                    <ModalHeader className="popup-title" toggle={this.toggleModal}>{item.id ? strings.manager_edit : strings.manager_add}</ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <div className="col-xs-12">
                                <div className='row'>
                                    <label className='col-md-3 col-form-label'>지역명</label>
                                    <div className="col-md-9">
                                        <input type='text' autoComplete='off' className='form-control' id='location'
                                            value={item.location || ''}
                                            onChange={(e) => this.onDataChange(e)} />
                                    </div>
                                </div>
                                <div className='row'>
                                    <label className='col-md-3 col-form-label'>위도</label>
                                    <div className="col-md-9">
                                        <input type='text' autoComplete='off' className='form-control' id='latitude'
                                            value={item.latitude || 0}
                                            onChange={(e) => this.onDataChange(e)} />
                                    </div>
                                </div>
                                <div className='row'>
                                    <label className='col-md-3 col-form-label'>경도</label>
                                    <div className="col-md-9">
                                        <input type='text' autoComplete='off' className='form-control' id='longitude'
                                            value={item.longitude || 0}
                                            onChange={(e) => this.onDataChange(e)} />
                                    </div>
                                </div>
                                <div className='row'>
                                    <label className='col-md-3 col-form-label'>인원수</label>
                                    <div className="col-md-9">
                                        <input type='text' autoComplete='off' className='form-control' id='bot_count'
                                            value={item.bot_count || 0}
                                            onChange={(e) => this.onDataChange(e)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-default" onClick={this.toggleModal}>{strings.close}</button>
                        <button className="btn btn-success" onClick={() => this.saveData()}>{strings.apply}</button>
                    </ModalFooter>
                </Modal>
            </div >
        )
    }
}

export default ZoneLocationPopup;