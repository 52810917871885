import React from 'react';
import strings from '../../../lang/strings.js';
import { PageSettings } from "../../../config/page-settings";
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import classnames from 'classnames';
import Const from './../../../config/const';
import U from './../../../config/util';
import ReactAudioPlayer from 'react-audio-player';

class BandDetailPopup extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
            band_info: {}
        }

        this.toggleModal = this.toggleModal.bind(this);
    }

    componentDidMount() {
    }

    toggleModal(result) {
        if (this.props.onClose) {
            this.props.onClose(result);
        }
    }

    getData = () => {
        let self = this;

        if (!this.props.band_id) {
            this.setState({
                band_info: {}
            });
            return;
        }

        this.context.post(
            'band/getItem',
            {
                band_id: this.props.band_id
            }, function (response) {
                let item = response.result_data.band_info;

                self.setState({
                    band_info: item,
                });
            });
    };

    render() {
        let band_info = this.state.band_info;

        return (
            <div>
                <Modal isOpen={this.props.isOpen} toggle={this.toggleModal} onOpened={() => this.getData()}>
                    <ModalHeader className="popup-nickname" toggle={this.toggleModal}>{strings.band_detail}</ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <div className='col-md-12'>
                                <div className='row'>
                                    <label className='col-md-2 col-form-label'>{strings.category}</label>
                                    <label className='col-md-10 col-form-label'>{band_info.category}</label>
                                </div>
                                <div className='row'>
                                    <label className='col-md-2 col-form-label'>{strings.type}</label>
                                    <label className='col-md-10 col-form-label'>{band_info.type}</label>
                                </div>
                                <div className='row'>
                                    <label className='col-md-2 col-form-label'>{strings.tag}</label>
                                    <label className='col-md-10 col-form-label'>{band_info.tag ? band_info.tag.split("#").join(", ") : ""}</label>
                                </div>
                                <div className='row'>
                                    <label className='col-md-2 col-form-label'>{strings.title}</label>
                                    <label className='col-md-10 col-form-label'>{band_info.title}</label>
                                </div>
                                <div className='row'>
                                    <label className='col-md-2 col-form-label'>{strings.content}</label>
                                    <label className='col-md-10 col-form-label'>{band_info.content}</label>
                                </div>
                                <div className='row'>
                                    <label className='col-md-2 col-form-label'>{strings.image}</label>
                                    <div className='col-md-10'>
                                        {
                                            U.getThumbList(band_info.image).map((image, idx) => (
                                                <a onClick={() => {
                                                    this.context.showGallery(band_info.image.split("#").map((it) => { return Const.BASE_URL + it }), idx)
                                                }}>
                                                    <img src={image} style={{ height: '50px', width: 'auto', margin: 2 }} />
                                                </a>
                                            ))
                                        }
                                    </div>
                                </div>
                                <div className='row'>
                                    <label className='col-md-2 col-form-label'>{'지역'}</label>
                                    <label className='col-md-10 col-form-label'>{band_info.location}</label>
                                </div>
                                <div className='row'>
                                    <label className='col-md-2 col-form-label'>{'하이엔드'}</label>
                                    <label className='col-md-10 col-form-label'>{band_info.is_highend}</label>
                                </div>
                                <div className='row'>
                                    <label className='col-md-2 col-form-label'>{'비공개'}</label>
                                    <label className='col-md-10 col-form-label'>{band_info.is_private}</label>
                                </div>
                                <div className='row'>
                                    <label className='col-md-2 col-form-label'>{'목소리'}</label>
                                    <div className='col-md-10 col-form-label'>
                                        {
                                            band_info.audio &&
                                            <ReactAudioPlayer src={Const.BASE_URL + band_info.audio} controls />
                                        }
                                    </div>
                                </div>
                                <div className='row'>
                                    <label className='col-md-2 col-form-label'>{strings.create_date}</label>
                                    <label className='col-md-10 col-form-label'>{band_info.create_date}</label>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-default" onClick={this.toggleModal}>{strings.close}</button>
                    </ModalFooter>
                </Modal>
            </div >
        )
    }
}

export default BandDetailPopup;