import React from 'react';
import strings from '../../lang/strings.js';
import { PageSettings } from "../../config/page-settings";
import './../../config/util';

class MemberVersion extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {
    }

    render() {
        let user_info = this.props.user_info;

        return (
            <div>
                <div className="row">
                    <label>{strings.version_01} : {user_info.version_name}({user_info.version_code})</label>
                </div>
                <div className="row">
                    <label>{strings.version_02} ({user_info.login_date})</label>
                </div>
                <div className="row">
                    <label>{strings.version_03} : {user_info.signup_os}</label>
                </div>
                <div className="row">
                    <label>{strings.version_04} : {user_info.login_os}</label>
                </div>
            </div >
        )
    }
}

export default MemberVersion;