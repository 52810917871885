import React from 'react';
import strings from '../../lang/strings.js';
import { PageSettings } from "../../config/page-settings";
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import classnames from 'classnames';
import $ from 'jquery';
import '../../config/util';
import U from '../../config/util';
import Const from '../../config/const';
import menus from '../../config/menu.jsx';

class ZoneCouponPopup extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
            item: {},
        };

        this.toggleModal = this.toggleModal.bind(this);
    }

    componentDidMount() {
    }

    toggleModal(result) {
        if (this.props.onClose) {
            this.props.onClose(result);
        }
    }

    getData = () => {
        if (!this.props.coupon_item) {
            this.setState({
                item: {},
            });
            return;
        }

        this.setState({
            item: this.props.coupon_item,
        });
    };

    saveData = () => {
        let self = this;

        const coupon_info = self.state.item;

        this.context.post('zone/postCoupon',
            {
                id: coupon_info.id || 0,
                type: coupon_info.type,
                title: coupon_info.title,
                code: coupon_info.code,
                rate: coupon_info.rate,
            }, function (response) {
                self.context.addNotification('success', strings.alert_title, strings.save_done);
                if (self.props.onSave) {
                    self.props.onSave();
                }
            }, true);
    };

    onDataChange = (e) => {
        let item = this.state.item;
        item[e.target.id] = e.target.value;

        this.setState({
            item: item
        });
    };

    render() {
        let item = this.state.item;

        return (
            <div>
                <Modal isOpen={this.props.isOpen} toggle={this.toggleModal} onOpened={() => this.getData()} size={'md'}>
                    <ModalHeader className="popup-title" toggle={this.toggleModal}>{item.id ? strings.manager_edit : strings.manager_add}</ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <div className="col-xs-12">
                                <div className='row'>
                                    <label className='col-md-3 col-form-label'>타입</label>
                                    <div className="col-md-9">
                                        <select className='form-control'
                                            id='type'
                                            onChange={(e) => this.onDataChange(e)}
                                            value={item.type || ''}>
                                            <option value={''}>타입선택</option>
                                            {['BARCODE', 'HEART'].map((entry, index) => (
                                                <option key={index} value={entry}>{entry}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className='row'>
                                    <label className='col-md-3 col-form-label'>제목</label>
                                    <div className="col-md-9">
                                        <input type='text' autoComplete='off' className='form-control' id='title'
                                            value={item.title || ''}
                                            onChange={(e) => this.onDataChange(e)} />
                                    </div>
                                </div>
                                <div className='row'>
                                    <label className='col-md-3 col-form-label'>{item.type == 'BARCODE' ? '바코드' : item.type == 'HEART' ? '하트' : '미정'}</label>
                                    <div className="col-md-9">
                                        <input type='text' autoComplete='off' className='form-control' id='code'
                                            value={item.code || ''}
                                            onChange={(e) => this.onDataChange(e)} />
                                    </div>
                                </div>
                                <div className='row'>
                                    <label className='col-md-3 col-form-label'>출현빈도(0-100)%</label>
                                    <div className="col-md-9">
                                        <input type='text' autoComplete='off' className='form-control' id='rate'
                                            value={item.rate || '0'}
                                            onChange={(e) => this.onDataChange(e)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-default" onClick={this.toggleModal}>{strings.close}</button>
                        <button className="btn btn-success" onClick={() => this.saveData()}>{strings.apply}</button>
                    </ModalFooter>
                </Modal>
            </div >
        )
    }
}

export default ZoneCouponPopup;