import React from 'react';
import strings from '../../lang/strings.js';
import { withRouter } from 'react-router-dom';
import { inject } from 'mobx-react';
import { PageSettings } from "../../config/page-settings";
import DataTable from './../../components/content/data-table.jsx';
import classnames from 'classnames';
import $ from 'jquery';
import './../../config/util';
import U from './../../config/util';
import Const from './../../config/const';

class RestaurantDetailMeeting extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
        };

        this.toggleModal = this.toggleModal.bind(this);
        this.refreshTable = this.refreshTable.bind(this);
    }

    componentDidMount() {
    }

    toggleModal(result) {
        if (this.props.onClose) {
            this.props.onClose(result);
        }
    }

    refreshTable = (refresh) => {
        this.tblMember.draw(refresh);
    };

    getData() {
        this.refreshTable(true);
    }

    showMeetingDetail(id) {
        this.props.history.push(`/meeting/meeting/${id}`);
    }

    deleteMeeting(id) {
        let self = this;
        this.context.showConfirm(strings.confirm_delete_title, function (response) {
            if (response) {
                self.context.post('restaurant/deleteMeeting', { id }, function () {
                    self.context.addNotification('success', strings.alert_title, strings.confirm_delete_done);
                    self.refreshTable(false);
                }, true);
            }
        });
    }

    render() {
        let self = this;
        let columnNo = 0;

        return (
            <div>
                <div className='row'>
                    <div className='col-md-12 mt-3'>
                        <DataTable ref={(ref) => this.tblMember = ref}
                            options={{
                                columnDefs: [
                                    {
                                        targets: [columnNo++], data: 'id', orderable: false,
                                        render: function (value) {
                                            return value;
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'image_url', orderable: false,
                                        createdCell: function (td, value, item) {
                                            $(td).image_lightbox(value, () => {
                                                self.context.showGallery([Const.BASE_URL + value])
                                            });
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'title', orderable: false,
                                        createdCell: function (td, value, item) {
                                            $(td).html(value + "<br>" + item.tag.replace(/#/g, ', '));
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'nickname', orderable: false,
                                        createdCell: function (td, value, item) {
                                            $(td).html(value + "<br>" + item.create_date);
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'user_count', orderable: false,
                                        render: function (value) {
                                            return value;
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'id', orderable: false,
                                        createdCell: function (td, value, item) {
                                            $(td).html('');
                                            $(td).append(`<button type='button' class='btn btn-primary btn-sm m-2'>${strings.detail}</button>`)
                                                .find('button:eq(0)')
                                                .on('click', function () {
                                                    self.showMeetingDetail(item.id);
                                                });
                                            $(td).append(`<button type='button' class='btn btn-danger btn-sm m-2'>${strings.delete}</button>`)
                                                .find('button:eq(1)')
                                                .on('click', function () {
                                                    self.deleteMeeting(item.id);
                                                });
                                        }
                                    },
                                ],
                                order: [[1, 'DESC']],
                                ajax: {
                                    url: 'restaurant/getMeetingList',
                                    data: function (req) {
                                        req.columns = null;
                                        req.restaurant_id = self.props.restaurant_id;
                                    }
                                },
                                drawCallback: function () {
                                },
                                fnCreatedRow: function (row, data, dataInd) {
                                }
                            }}>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>{strings.image}</th>
                                    <th>{strings.title}/{strings.tag}</th>
                                    <th>{strings.creator}</th>
                                    <th>{strings.card}</th>
                                    <th>{strings.manage}</th>
                                </tr>
                            </thead>
                            <tbody />
                        </DataTable>
                    </div>
                </div>
            </div >
        )
    }
}

export default withRouter(inject('rootStore')(RestaurantDetailMeeting));