import React from 'react';
import strings from '../../lang/strings.js';
import { PageSettings } from "../../config/page-settings";
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import classnames from 'classnames';
import Const from './../../config/const';
import U from './../../config/util';

class MeetingDetailPopup extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
            meeting_info: {}
        }

        this.toggleModal = this.toggleModal.bind(this);
    }

    componentDidMount() {
    }

    toggleModal(result) {
        if (this.props.onClose) {
            this.props.onClose(result);
        }
    }

    getData = () => {
        let self = this;

        if (!this.props.meeting_id) {
            this.setState({
                meeting_info: {}
            });
            return;
        }

        this.context.post(
            'meeting/getItem',
            {
                id: this.props.meeting_id
            }, function (response) {
                let item = response.result_data.meeting_item;

                self.setState({
                    meeting_info: item,
                });
            });
    };

    render() {
        let meeting_info = this.state.meeting_info;

        return (
            <div>
                <Modal isOpen={this.props.isOpen} toggle={this.toggleModal} onOpened={() => this.getData()}>
                    <ModalHeader className="popup-nickname" toggle={this.toggleModal}>{strings.meeting_detail}</ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <div className='col-md-12'>
                                <div className='row'>
                                    <label className='col-md-2 col-form-label'>{strings.tag}</label>
                                    <label className='col-md-10 col-form-label'>{meeting_info.tag ? meeting_info.tag.split("#").join(", ") : ""}</label>
                                </div>
                                <div className='row'>
                                    <label className='col-md-2 col-form-label'>{strings.alias}</label>
                                    <label className='col-md-10 col-form-label'>{meeting_info.alias}</label>
                                </div>
                                <div className='row'>
                                    <label className='col-md-2 col-form-label'>{strings.title}</label>
                                    <label className='col-md-10 col-form-label'>{meeting_info.title}</label>
                                </div>
                                <div className='row'>
                                    <label className='col-md-2 col-form-label'>{strings.content}</label>
                                    <label className='col-md-10 col-form-label'>{meeting_info.intro}</label>
                                </div>
                                <div className='row'>
                                    <label className='col-md-2 col-form-label'>{strings.image}</label>
                                    <div className='col-md-10'>
                                        {
                                            U.getThumbList(meeting_info.image_url).map((image_url, idx) => (
                                                <a onClick={() => {
                                                    this.context.showGallery(meeting_info.image_url.split("#").map((it) => { return Const.BASE_URL + it }), idx)
                                                }}>
                                                    <img src={image_url} style={{ height: '50px', width: 'auto', margin: 2 }} />
                                                </a>
                                            ))
                                        }
                                    </div>
                                </div>
                                <div className='row'>
                                    <label className='col-md-2 col-form-label'>{strings.create_date}</label>
                                    <label className='col-md-10 col-form-label'>{meeting_info.create_date}</label>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-default" onClick={this.toggleModal}>{strings.close}</button>
                    </ModalFooter>
                </Modal>
            </div >
        )
    }
}

export default MeetingDetailPopup;