import React from 'react';
import { Link } from 'react-router-dom';
import strings from '../../lang/strings.js';
import { PageSettings } from "../../config/page-settings";
import Container from './../../components/content/container.js';

class CardStaFragment extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
            card_sta: null,
        };
    }

    componentDidMount() {
        this.getCardSta();
    }

    getCardSta = () => {
        let self = this;

        this.context.get(
            'dashboard/card_sta',
            {},
            function (response) {
                self.setState({
                    card_sta: response.result_data.card_sta
                });
            });
    };

    render() {
        let self = this;

        return (
            <div>
                <Container title={strings.matched_member_count} rightButton={(
                    <Link to="/sta/matching" style={{ color: '#fff' }}>{strings.just_go} <i className="fa fa-arrow-alt-circle-right"></i></Link>
                )}>
                    <div className="bg-white height-150">
                        <div className="d-flex align-items-center text-black">
                            <div className="d-flex w-100">
                                <div>{strings.today} {strings.matched_member_count}</div>
                                <div className="ml-auto">{self.state.card_sta ? `${self.state.card_sta.today.currencyFormat()} ${strings.couple}` : `-`}</div>
                            </div>
                        </div>
                        <div className="d-flex align-items-center text-black">
                            <div className="d-flex w-100">
                                <div>{strings.yesterday} {strings.matched_member_count}</div>
                                <div className="ml-auto">{self.state.card_sta ? `${self.state.card_sta.yesterday.currencyFormat()} ${strings.couple}` : `-`}</div>
                            </div>
                        </div>
                        <div className="d-flex align-items-center text-black">
                            <div className="d-flex w-100">
                                <div>{strings.this_month} {strings.matched_member_count}</div>
                                <div className="ml-auto">{self.state.card_sta ? `${self.state.card_sta.this_month.currencyFormat()} ${strings.couple}` : `-`}</div>
                            </div>
                        </div>
                        <div className="d-flex align-items-center text-black">
                            <div className="d-flex w-100">
                                <div>{strings.this_year} {strings.matched_member_count}</div>
                                <div className="ml-auto">{self.state.card_sta ? `${self.state.card_sta.this_year.currencyFormat()} ${strings.couple}` : `-`}</div>
                            </div>
                        </div>
                        <div className="d-flex align-items-center text-black">
                            <div className="d-flex w-100">
                                <div>{strings.total} {strings.matched_member_count}</div>
                                <div className="ml-auto">{self.state.card_sta ? `${self.state.card_sta.total.currencyFormat()} ${strings.couple}` : `-`}</div>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        )
    }
}

export default CardStaFragment;