import React from 'react';
import strings from '../../lang/strings.js';
import { PageSettings } from "../../config/page-settings";
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import './../../config/util';
import ReactPlayer from 'react-player'

class VideoPlayerPopup extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
            video_url: '',
        };

        this.toggleModal = this.toggleModal.bind(this);
    }

    componentDidMount() {
    }

    toggleModal(result) {
        if (this.props.onClose) {
            this.props.onClose(result);
        }
    }

    render() {
        return (
            <div>
                <Modal isOpen={this.props.isOpen} toggle={this.toggleModal} onOpened={() => {
                    this.setState({ video_url: this.props.video_url });
                }}>
                    <ModalHeader className="popup-title" toggle={this.toggleModal}>{strings.video_player}</ModalHeader>
                    <ModalBody>
                        <ReactPlayer url={this.state.video_url} controls width='100%' height='100%' />
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-default" onClick={this.toggleModal}>{strings.close}</button>
                    </ModalFooter>
                </Modal>
            </div >
        )
    }
}

export default VideoPlayerPopup;