import React from 'react';
import strings from '../../lang/strings.js';
import { PageSettings } from "../../config/page-settings";
import DataTable from './../../components/content/data-table.jsx';
import './../../config/util';
import Const from './../../config/const';
import $ from 'jquery';
import MemberAdvicePopup from './member-advice-popup'

class MemberAdvice extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {}
    }

    componentDidMount() {
    }

    refreshTable = (refresh) => {
        this.tblMember.draw(refresh);
    };

    deleteAdvice(item) {
        let self = this;
        this.context.showConfirm(strings.confirm_delete_title, function (response) {
            if (response) {
                self.context.post('member/deleteAdvice', { advice_id: item.id }, function () {
                    self.context.addNotification('success', strings.alert_title, strings.confirm_delete_done);
                    self.refreshTable(false);
                }, true);
            }
        });
    }

    render() {
        let self = this;
        let user_info = this.props.user_info;
        let columnNo = 0;

        return (
            <div>
                <MemberAdvicePopup isOpen={this.state.show_advice_popup} advice_id={this.state.selected_advice_id} uid={user_info.id} onClose={() => {
                    this.setState({ show_advice_popup: false });
                }} onSave={() => {
                    this.setState({ show_advice_popup: false });
                    this.refreshTable(false);
                }} />
                <div className="row">
                    <button type='button' className='btn btn-success btn-sm' onClick={() => {
                        this.setState({ show_advice_popup: true });
                    }}>{strings.add}</button>
                </div>
                <div className="row mt-2">
                    <DataTable ref={(ref) => this.tblMember = ref}
                        options={{
                            columnDefs: [
                                {
                                    targets: [columnNo++], data: 'create_date', orderable: false,
                                    render: function (value) {
                                        return value;
                                    }
                                },
                                {
                                    targets: [columnNo++], data: 'content', orderable: false,
                                    render: function (value) {
                                        return '<pre>' + value + '</pre>';
                                    }
                                },
                                {
                                    targets: [columnNo++], data: 'image_url', orderable: false,
                                    createdCell: function (td, value, item) {
                                        return $(td).image_label(value, () => {
                                            self.context.showGallery(value.split('#').map((it) => { return Const.BASE_URL + it }))
                                        });
                                    }
                                },
                                {
                                    targets: [columnNo++], data: 'id', orderable: false,
                                    createdCell: function (td, value, item) {
                                        $(td).html('');
                                        $(td).append(`<button type='button' class='btn btn-primary btn-sm m-2'>${strings.detail}</button>`)
                                            .find('button:eq(0)')
                                            .on('click', function () {
                                                self.setState({
                                                    selected_advice_id: item.id,
                                                    show_advice_popup: true
                                                });
                                            });
                                        $(td).append(`<button type='button' class='btn btn-danger btn-sm m-2'>${strings.delete}</button>`)
                                            .find('button:eq(1)')
                                            .on('click', function () {
                                                self.deleteAdvice(item);
                                            });
                                    }
                                }
                            ],
                            iDisplayLength: 10,
                            order: [[1, 'DESC']],
                            ajax: {
                                url: 'member/getAdviceList',
                                data: function (req) {
                                    req.columns = null;
                                    req.uid = user_info.id;
                                }
                            },
                            drawCallback: function () {
                            },
                            fnCreatedRow: function (row, data, dataInd) {
                            }
                        }}>
                        <thead>
                            <tr>
                                <th>{strings.date}</th>
                                <th>{strings.content}</th>
                                <th>{strings.image}</th>
                                <th>{strings.manage}</th>
                            </tr>
                        </thead>
                        <tbody />
                    </DataTable>
                </div>
            </div >
        )
    }
}

export default MemberAdvice;