import React from 'react';
import strings from '../../lang/strings.js';
import { Redirect, Switch } from 'react-router';
import { Route, withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import routes from './../../config/page-route.jsx';
import { PageSettings } from './../../config/page-settings.js';

function setTitle(path, routeArray) {
    var pageTitle;
    for (var i = 0; i < routeArray.length; i++) {
        if (routeArray[i].path === path) {
            pageTitle = strings.app_name + ' | ' + routeArray[i].title;
        }
    }
    document.title = (pageTitle) ? pageTitle : strings.app_name + ' | ' + strings.admin;
}

class Content extends React.Component {
    constructor(props) {
        super(props);

        props.history.listen(() => {
            setTitle(this.props.history.location.pathname, routes);
        });
    }

    componentDidMount() {
        setTitle(this.props.history.location.pathname, routes);
    }

    render() {
        return (
            <PageSettings.Consumer>
                {({ pageContentFullWidth, pageContentClass, pageContentInverseMode }) => (
                    <div
                        className={'content ' + (pageContentFullWidth ? 'content-full-width ' : '') + (pageContentInverseMode ? 'content-inverse-mode ' : '') + pageContentClass}>
                        <Switch>
                            {routes.map((route, index) => (
                                <Route
                                    key={index}
                                    path={route.path}
                                    exact={route.exact}
                                    component={
                                        route.access === 'public' || 
                                        (this.props.rootStore.isLogin() && this.props.rootStore.hasPermission(route.path))
                                        ? route.component :
                                            (() => (
                                                <Redirect to='/login' />
                                            )
                                            )}
                                />
                            ))}
                            <Route component={() => <Redirect to='/dashboard' />} />
                        </Switch>
                    </div>
                )}
            </PageSettings.Consumer>
        )
    }
}

export default withRouter(inject('rootStore')(observer(Content)));
