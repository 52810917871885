import React from 'react';
import { inject } from 'mobx-react';
import { PageSettings } from "./../../config/page-settings";

class ImageSelect extends React.Component {

    static contextType = PageSettings;

    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    render() {
        const image_url = this.props.image_url;

        return (
            <div style={{
                display: 'block', position: 'relative',
                paddingTop: this.props.height ? this.props.height : '100%',
                width: this.props.width ? this.props.width : '100%'
            }}>
                <button className="btn btn-white"
                    style={{
                        backgroundImage: `url(${image_url})`,
                        backgroundSize: "cover", backgroundPosition: "center",
                        position: "absolute", top: "1px", left: "1px", right: "1px", bottom: "1px", width: "100%"
                    }} onClick={() => this.imageRef.click()}>
                    {
                        !image_url &&
                        <i className="fa fa-plus"></i>
                    }
                    <input type='file' accept='image/*' className='hide'
                        onChange={(e) => this.props.onSelect(e)}
                        ref={(ref) => this.imageRef = ref} />
                </button>
                {
                    image_url &&
                    <button className="btn btn-danger btn-icon btn-sm"
                        style={{ position: "absolute", top: "2px", right: "0px" }}
                        onClick={() => this.props.onDelete()}>
                        <i className="fa fa-times"></i>
                    </button>
                }
            </div>
        );
    }

}

export default inject('rootStore')(ImageSelect);