import React from 'react';
import strings from '../../lang/strings.js';
import { PageSettings } from "../../config/page-settings.js";
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import 'react-datetime/css/react-datetime.css';
import '../../config/util.js';
import U from '../../config/util.js';
import ImageSelect from '../../components/content/image-select.jsx';


class WeddingLoanEditPopup extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
            item: {},
            main_image: "",
        };

        this.toggleModal = this.toggleModal.bind(this);
        this.main_image_file = null;
    }

    componentDidMount() {
    }

    toggleModal(result) {
        if (this.props.onClose) {
            this.props.onClose(result);
        }
    }

    getData = () => {
        const loan_id = this.props.data?.id ?? 0;
        if (!loan_id) {
            return;
        }

        let self = this;

        this.context.post(
            'wedding/getLoanItem',
            {
                id: loan_id
            },
            function (response) {
                let item = response.result_data.loan_item;

                self.setState({
                    item,
                    main_image: U.getThumbUrl(item.main_image),
                });
                self.main_image_file = null;
            });
    };

    uploadData = (image_files, callback) => {

        let formData = new FormData();
        image_files.forEach((entry, index) => {
            formData.append('file', entry, entry.name);
        });

        this.context.post(
            'upload/images/wedding',
            formData,
            function (response) {
                callback(response.result_data.upload_path);
            },
            true
        );
    }

    saveData = () => {
        let self = this;

        const item = self.state.item;

        if (this.main_image_file) {
            this.uploadData([this.main_image_file], function (image_url) {
                self.state.main_image = U.getThumbUrl(image_url);
                self.main_image_file = null;
                self.saveData();
            });
            return;
        }

        item.main_image = this.state.main_image.replace('_thumb.', '.').url2String();

        if (!item.main_image) {
            self.context.addNotification('warning', strings.alert_title, strings.empty_main_image);
            return;
        }
        if (!item.bank) {
            self.context.addNotification('warning', strings.alert_title, '은행명');
            return;
        }
        if (!item.type) {
            self.context.addNotification('warning', strings.alert_title, '대출종류');
            return;
        }
        if (!item.limit) {
            self.context.addNotification('warning', strings.alert_title, '대출한도');
            return;
        }
        if (!item.rates) {
            self.context.addNotification('warning', strings.alert_title, '금리');
            return;
        }
        if (!item.content) {
            self.context.addNotification('warning', strings.alert_title, '비고');
            return;
        }

        this.context.post('wedding/saveLoanItem',
            {
                loan_info: item
            }, function (response) {
                self.context.addNotification('success', strings.alert_title, strings.save_done);
                if (self.props.onSave) {
                    self.props.onSave();
                }
            }, true);
    };

    onDataChange = (e) => {
        let item = this.state.item;
        item[e.target.id] = e.target.value;

        this.setState({
            item: item
        });
    };

    onMainImageChange = (e) => {
        let self = this;

        if (e.target.files.length < 1) {
            return;
        }

        let file = e.target.files[0];
        let reader = new FileReader();

        reader.onloadend = function () {
            self.setState({ main_image: reader.result });
            self.main_image_file = file
        };
        reader.readAsDataURL(file);
    };

    render() {
        let item = this.state.item;

        return (
            <div>
                <Modal isOpen={this.props.isOpen} toggle={this.toggleModal} onOpened={() => this.getData()}>
                    <ModalHeader className="popup-nickname" toggle={this.toggleModal}>{'상품 등록'}</ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <div className="col-xs-12">
                                <div className='row'>
                                    <label className='col-md-3 col-form-label'>{strings.main_image} *</label>
                                    <div className="col-md-9">
                                        <div className="row mb-1">
                                            <div className='col-xs-4 col-sm-3 col-md-5 col-lg-4 col-xl-4 col-xxl-3'>
                                                <ImageSelect
                                                    height="70%"
                                                    image_url={this.state.main_image}
                                                    onSelect={(e) => this.onMainImageChange(e)}
                                                    onDelete={() => {
                                                        this.setState({ main_image: '' });
                                                        this.main_image_file = null;
                                                    }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <label className='col-md-3 col-form-label'>{'은행명'} *</label>
                                    <div className="col-md-9">
                                        <input type='text' autoComplete='off' className='form-control' id='bank'
                                            value={item.bank ?? ''}
                                            onChange={(e) => this.onDataChange(e)} />
                                    </div>
                                </div>
                                <div className='row'>
                                    <label className='col-md-3 col-form-label'>{'대출종류'} *</label>
                                    <div className="col-md-9">
                                        <input type='text' autoComplete='off' className='form-control' id='type'
                                            value={item.type ?? ''}
                                            onChange={(e) => this.onDataChange(e)} />
                                    </div>
                                </div>
                                <div className='row'>
                                    <label className='col-md-3 col-form-label'>{'대출한도'} *</label>
                                    <div className="col-md-9">
                                        <input type='text' autoComplete='off' className='form-control' id='limit'
                                            value={item.limit ?? ''}
                                            onChange={(e) => this.onDataChange(e)} />
                                    </div>
                                </div>
                                <div className='row'>
                                    <label className='col-md-3 col-form-label'>{'금리'} *</label>
                                    <div className="col-md-9">
                                        <input type='text' autoComplete='off' className='form-control' id='rates'
                                            value={item.rates ?? ''}
                                            onChange={(e) => this.onDataChange(e)} />
                                    </div>
                                </div>
                                <div className='row'>
                                    <label className='col-md-3 col-form-label'>{'비고'} *</label>
                                    <div className="col-md-9">
                                        <textarea
                                            id='content'
                                            className='form-control'
                                            rows={7}
                                            value={item.content ?? ''}
                                            onChange={(e) => this.onDataChange(e)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-default" onClick={this.toggleModal}>{strings.close}</button>
                        <button className="btn btn-success" onClick={() => this.saveData()}>{strings.apply}</button>
                    </ModalFooter>
                </Modal>
            </div >
        )
    }
}

export default WeddingLoanEditPopup;