import React from 'react';
import { Panel, PanelBody, PanelHeader } from "../panel/panel";


class Container extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        let props = this.props;

        return (
            <div className="row">
                <div className="col-xs-12">
                    <Panel>
                        <PanelHeader noButton={true} rightButton={this.props.rightButton}>{props.title}</PanelHeader>
                        <PanelBody>
                            {this.props.children}
                        </PanelBody>
                    </Panel>
                </div>
            </div>
        );
    }

}

export default Container;