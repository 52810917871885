import React from 'react';
import strings from '../../lang/strings.js';
import $ from 'jquery';
import { PageSettings } from "../../config/page-settings";
import './../../config/util';
import DataTable from './../../components/content/data-table.jsx';
import MemberDetailPopup from './../member-detail/member-detail-popup'
import MemberSuspendPopup from './../common/member-suspend-popup'
import CommentCreatePopup from './comment-create-popup'

class BoardDetailComment extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
            show_member_profile: false,
            selected_uid: 0,
            selected_comment_id: 0,
        }
    }

    componentDidMount() {
    }

    refreshTable = (refresh) => {
        this.tblMember.draw(refresh);
    };

    showMemberDetailPopup(uid) {
        this.setState({
            show_member_profile: true,
            selected_uid: uid
        });
    }

    showCommentCreatePopup(comment_id) {
        this.setState({
            show_comment_create_popup: true,
            selected_comment_id: comment_id
        });
    }

    blindComment(item) {
        let self = this;
        this.context.showConfirm(strings.comment_blind_title, function (response) {
            if (response) {
                self.context.post('board/blindComment', { comment_id: item.id }, function () {
                    self.context.addNotification('success', strings.alert_title, strings.comment_blind_done);
                    self.refreshTable(false);
                }, true);
            }
        });
    }

    deleteComment(item) {
        let self = this;
        this.context.showConfirm(strings.comment_delete_title, function (response) {
            if (response) {
                self.context.post('board/deleteComment', { comment_id: item.id }, function () {
                    self.context.addNotification('success', strings.alert_title, strings.comment_delete_done);
                    self.refreshTable(false);
                }, true);
            }
        });
    }

    suspendMember(uid) {
        this.setState({
            show_member_suspend: true,
            selected_uid: uid
        });
    }

    render() {
        let self = this;
        let columnNo = 0;

        return (
            <div>
                <div className="d-flex w-100">
                    <div className="ml-auto">
                        <button type='button' className='btn btn-primary btn-sm' onClick={() => this.showCommentCreatePopup(0)}>{strings.comment_add}</button>
                    </div>
                </div>

                <MemberDetailPopup isOpen={this.state.show_member_profile} uid={this.state.selected_uid} onClose={() => {
                    this.setState({ show_member_profile: false });
                }} />
                <MemberSuspendPopup isOpen={this.state.show_member_suspend} uid={this.state.selected_uid} onClose={() => {
                    this.setState({ show_member_suspend: false });
                }} onSave={() => {
                    this.setState({ show_member_suspend: false }, () => {
                        self.refreshTable(false);
                    });
                }} />
                <CommentCreatePopup
                    isOpen={this.state.show_comment_create_popup}
                    board_id={this.props.board_id}
                    parent={this.state.selected_comment_id}
                    onClose={() => {
                        this.setState({ show_comment_create_popup: false });
                    }}
                    onSave={() => {
                        this.setState({ show_comment_create_popup: false }, () => {
                            self.refreshTable(true);
                        });
                    }}
                />

                <div className='row'>
                    <div className='col-md-12 mt-3'>
                        <DataTable ref={(ref) => this.tblMember = ref}
                            options={{
                                columnDefs: [
                                    {
                                        targets: [columnNo++], data: 'id', orderable: false,
                                        render: function (value) {
                                            return value;
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'comment', orderable: false,
                                        class: 'text-left',
                                        createdCell: function (td, value, item) {
                                            $(td).html('');
                                            if (item.depth > 0) {
                                                $(td).append(`<span style="margin-left: ${item.depth * 10}px; padding-bottom: 5px">→  </span>`);
                                            }
                                            $(td).append(value);
                                            if (item.status == -1) {
                                                $(td).append(`<span style="color: red"> [${strings.deleted}]</span>`)
                                            }
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'nickname', orderable: false,
                                        createdCell: function (td, value, item) {
                                            $(td).nickname_label(value, item.gender, () => {
                                                self.showMemberDetailPopup(item.uid);
                                            });
                                            if (item.alias) {
                                                $(td).append(`<br/>(${item.alias})`);
                                            }
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'create_date', orderable: false,
                                        render: function (value) {
                                            return value
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'like_count', orderable: false,
                                        render: function (value) {
                                            return value.currencyFormat()
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'report_count', orderable: false,
                                        render: function (value) {
                                            if (value >= 10) {
                                                return `<span style="color: red">${value.currencyFormat()}</span>`;
                                            } else {
                                                return value.currencyFormat()
                                            }
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'id', orderable: false,
                                        createdCell: function (td, value, item) {
                                            $(td).html('');
                                            $(td).append(`<button type='button' class='btn btn-inverse btn-sm m-2'>${strings.blind}</button>`)
                                                .find('button:eq(0)')
                                                .on('click', function () {
                                                    self.blindComment(item);
                                                });
                                            $(td).append(`<button type='button' class='btn btn-danger btn-sm m-2'>${strings.delete}</button>`)
                                                .find('button:eq(1)')
                                                .on('click', function () {
                                                    self.deleteComment(item);
                                                });
                                            $(td).append(`<button type='button' class='btn btn-yellow btn-sm m-2'>${strings.suspend}</button>`)
                                                .find('button:eq(2)')
                                                .on('click', function () {
                                                    self.suspendMember(item.uid);
                                                });
                                            if (Number(item.depth) === 0) {
                                                $(td).append(`<button type='button' class='btn btn-primary btn-sm m-2'>${strings.answer_add}</button>`)
                                                    .find('button:eq(3)')
                                                    .on('click', function () {
                                                        self.showCommentCreatePopup(item.id);
                                                    });
                                            }
                                        }
                                    },
                                ],
                                order: [[1, 'DESC']],
                                ajax: {
                                    url: 'board/getCommentList',
                                    data: function (req) {
                                        req.columns = null;
                                        req.board_id = self.props.board_id;
                                    }
                                },
                                drawCallback: function () {
                                },
                                fnCreatedRow: function (row, data, dataInd) {
                                }
                            }}>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th width="40%">{strings.comment_content}</th>
                                    <th>{strings.creator}</th>
                                    <th>{strings.create_date}</th>
                                    <th>{strings.like}</th>
                                    <th>{strings.report}</th>
                                    <th>{strings.manage}</th>
                                </tr>
                            </thead>
                            <tbody />
                        </DataTable>
                    </div>
                </div>
            </div>
        )
    }
}

export default BoardDetailComment;