import React from 'react';
import { Link } from 'react-router-dom';
import strings from '../../lang/strings.js';
import { PageSettings } from "../../config/page-settings";
import { Bar } from 'react-chartjs-2';
import Container from './../../components/content/container.js';
import moment from 'moment';

class LoginChartFragment extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
            date_list: [],
            total_list: [],
            male_list: [],
            female_list: [],
        };

        this.lineChartOptions = {
            maintainAspectRatio: false,
            responsive: true,
            hover: {
                mode: 'nearest',
                intersect: true
            },
            tooltips: {
                mode: 'index',
                intersect: false,
            },
            scales: {
                yAxes: [{
                    // stacked: true,
                    ticks: {
                        beginAtZero: true,
                        fontColor: 'black',
                        stepSize: 50,
                        unitStepSize: 1
                    }
                }],
                xAxes: [{
                    // stacked: true,
                    ticks: {
                        fontColor: 'black'
                    }
                }]
            },
            legend: {
                labels: {
                    fontColor: 'black'
                }
            }
        };
    }

    componentDidMount() {
        this.getLoginChart();
    }

    getLoginChart = () => {
        let self = this;

        this.context.get(
            'dashboard/login_chart',
            {},
            function (response) {
                let login_chart = response.result_data.login_chart;

                const date_list = [];
                const male_list = [];
                const female_list = [];

                login_chart.forEach((entry) => {
                    date_list.push(moment(entry.date).format('M/D'));
                    male_list.push(entry.unique_male_count);
                    female_list.push(entry.unique_female_count);
                })

                self.setState({
                    date_list: date_list,
                    male_list: male_list,
                    female_list: female_list,
                });
            });
    };

    render() {
        this.lineChartData = {
            labels: this.state.date_list,
            datasets: [{
                label: strings.male,
                fill: true,
                lineTension: 0.1,
                backgroundColor: 'rgba(0, 172, 172, 0.25)',
                borderColor: '#00acac',
                borderWidth: 2,
                pointBorderColor: '#00acac',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 2,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: '#00acac',
                pointHoverBorderWidth: 3,
                pointRadius: 3,
                pointHitRadius: 10,
                data: this.state.male_list
            }, {
                label: strings.female,
                fill: true,
                lineTension: 0.1,
                backgroundColor: 'rgba(52, 143, 226, 0.25)',
                borderColor: '#348fe2',
                borderWidth: 2,
                pointBorderColor: '#348fe2',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 2,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: '#348fe2',
                pointHoverBorderWidth: 3,
                pointRadius: 3,
                pointHitRadius: 10,
                data: this.state.female_list
            }]
        };

        return (
            <div>
                <Container title={strings.login_chart_title} rightButton={(
                    <Link to="/sta/login" style={{ color: '#fff' }}>{strings.just_go} <i className="fa fa-arrow-alt-circle-right"></i></Link>
                )}>
                    <Bar data={this.lineChartData} height={150} options={this.lineChartOptions} />
                </Container>
            </div>
        )
    }
}

export default LoginChartFragment;