import React from 'react';
import strings from '../../lang/strings.js';
import { PageSettings } from "../../config/page-settings";
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import '../../config/util';
import ReactAudioPlayer from 'react-audio-player';

class AudioPlayerPopup extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
            audio_url: '',
        };

        this.toggleModal = this.toggleModal.bind(this);
    }

    componentDidMount() {
    }

    toggleModal(result) {
        if (this.props.onClose) {
            this.props.onClose(result);
        }
    }

    render() {
        return (
            <div>
                <Modal isOpen={this.props.isOpen} toggle={this.toggleModal} onOpened={() => {
                    this.setState({ audio_url: this.props.audio_url });
                }}>
                    <ModalHeader className="popup-title" toggle={this.toggleModal}>{strings.audio_player}</ModalHeader>
                    <ModalBody>
                        <ReactAudioPlayer src={this.state.audio_url} controls autoPlay style={{width: '100%'}}/>
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-default" onClick={this.toggleModal}>{strings.close}</button>
                    </ModalFooter>
                </Modal>
            </div >
        )
    }
}

export default AudioPlayerPopup;