import React from 'react';
import strings from '../../lang/strings.js';
import { PageSettings } from "../../config/page-settings";
import { Panel, PanelBody } from '../../components/panel/panel.jsx';
import { Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import BoardDetailInfo from './board-detail-info';
import BoardDetailComment from './board-detail-comment';
import BoardDetailLike from './board-detail-like';
import BoardDetailCard from './board-detail-card';

class BoardDetail extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.board_id = Number(this.props.match.params.boardId);

        this.state = {
            activeTab: '1',
        }

        this.toggle = this.toggle.bind(this);
    }

    componentDidMount() {
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }
    }

    render() {
        return (
            <div className="row">
                <div className="col-xl-6">
                    <BoardDetailInfo board_id={this.board_id} onSave={() => {
                        if (this.board_id < 1) {
                            this.props.history.goBack();
                        }
                    }} />
                </div>
                {
                    this.board_id > 0 &&
                    <div className="col-xl-6">
                        <Panel>
                            <Nav tabs className="nav-tabs-inverse">
                                <NavItem>
                                    <NavLink className={classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.toggle('1'); }}>
                                        <span className="d-none d-xs-inline">{strings.comment_list}</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ active: this.state.activeTab === '2' })} onClick={() => { this.toggle('2'); }}>
                                        <span className="d-none d-xs-inline">{strings.card}</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ active: this.state.activeTab === '3' })} onClick={() => { this.toggle('3'); }}>
                                        <span className="d-none d-xs-inline">{strings.like}/{strings.dislike}</span>
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <PanelBody>
                                {this.state.activeTab === "1" && (<BoardDetailComment board_id={this.board_id} />)}
                                {this.state.activeTab === "2" && (<BoardDetailCard board_id={this.board_id} />)}
                                {this.state.activeTab === "3" && (<BoardDetailLike board_id={this.board_id} />)}
                            </PanelBody>
                        </Panel>
                    </div>
                }
            </div>
        )
    }
}

export default BoardDetail;