import React from 'react';
import strings from '../../lang/strings.js';
import $ from 'jquery';
import { PageSettings } from "../../config/page-settings";
import DataTable from './../../components/content/data-table.jsx';
import './../../config/util';
import Const from './../../config/const';

class MemberOkHistory extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {
    }

    render() {
        let user_info = this.props.user_info;
        let more_info = this.props.more_info;
        let columnNo = 0;

        return (
            <div>
                <div className="row">
                    <div className="col-xs-6">
                        <div className='row'>
                            <label>{strings.ok_history_01}&nbsp;:&nbsp;</label>
                            <label>{more_info.card_ok_send}</label>
                        </div>
                        <div className='row'>
                            <label>{strings.ok_history_02}&nbsp;:&nbsp;</label>
                            <label>{more_info.card_ok_recv}</label>
                        </div>
                        <div className='row'>
                            <label>{strings.ok_history_03}&nbsp;:&nbsp;</label>
                            <label>{more_info.card_topok_send}</label>
                        </div>
                        <div className='row'>
                            <label>{strings.ok_history_04}&nbsp;:&nbsp;</label>
                            <label>{more_info.card_topok_recv}</label>
                        </div>
                        <div className='row'>
                            <label>{strings.ok_history_05}&nbsp;:&nbsp;</label>
                            <label>{more_info.meeting_ok_send}</label>
                        </div>
                        <div className='row'>
                            <label>{strings.ok_history_06}&nbsp;:&nbsp;</label>
                            <label>{more_info.meeting_ok_recv}</label>
                        </div>
                        <div className='row'>
                            <label>{strings.ok_history_07}&nbsp;:&nbsp;</label>
                            <label>{more_info.meeting_topok_send}</label>
                        </div>
                        <div className='row'>
                            <label>{strings.ok_history_08}&nbsp;:&nbsp;</label>
                            <label>{more_info.meeting_topok_recv}</label>
                        </div>
                    </div>
                    <div className="col-xs-6">
                        <div className='row'>
                            <label>{strings.ok_history_09}&nbsp;:&nbsp;</label>
                            <label>{more_info.matching_count}</label>
                        </div>
                        <div className='row'>
                            <label>{strings.ok_history_10}&nbsp;:&nbsp;</label>
                            <label>{more_info.matching_month_count}</label>
                        </div>
                    </div>
                </div>
                <div className="row mt-2">
                    <DataTable ref={(ref) => this.tblMember = ref}
                        options={{
                            columnDefs: [
                                {
                                    targets: [columnNo++], data: 'ok', orderable: false,
                                    render: function (value) {
                                        return `<span class="${strings.const.OK_TYPE[value].color}">${strings.const.OK_TYPE[value].name}</span>`;
                                    }
                                },
                                {
                                    targets: [columnNo++], data: 'uid', orderable: false,
                                    render: function (value) {
                                        return user_info.id === value ? strings.sent : strings.received;
                                    }
                                },
                                {
                                    targets: [columnNo++], data: 'uid', orderable: false,
                                    createdCell: function (td, value, item) {
                                        let text = '';
                                        if (item.uid === user_info.id) {
                                            text = item.target_nickname;
                                        } else {
                                            text = item.nickname;
                                        }
                                        $(td).html(text);
                                    }
                                },
                                {
                                    targets: [columnNo++], data: 'uid', orderable: false,
                                    createdCell: function (td, value, item) {
                                        let text = '';
                                        if (item.uid === user_info.id) {
                                            text = Number(item.target_user_status).status2String();
                                        } else {
                                            text = Number(item.user_status).status2String();
                                        }
                                        $(td).html(text);
                                    }
                                },
                                {
                                    targets: [columnNo++], data: 'birthday', orderable: false,
                                    createdCell: function (td, value, item) {
                                        $(td).html(item.uid === user_info.id ? item.target_birthday : item.birthday);
                                    }
                                },
                                {
                                    targets: [columnNo++], data: 'status', orderable: false,
                                    createdCell: function (td, value, item) {
                                        $(td).html(item.uid === user_info.id && item.target_status === 1 ? '읽음' : '-');
                                    }
                                },
                                {
                                    targets: [columnNo++], data: 'update_date', orderable: false,
                                    render: function (value) {
                                        return value;
                                    }
                                },
                                {
                                    targets: [columnNo++], data: 'status', orderable: false,
                                    render: function (value) {
                                        return value === -1 ? strings.deleted : '';
                                    }
                                },
                            ],
                            iDisplayLength: 10,
                            order: [[1, 'DESC']],
                            ajax: {
                                url: 'card/getOkHistory',
                                data: function (req) {
                                    req.columns = null;
                                    req.uid = user_info.id;
                                }
                            },
                            drawCallback: function () {
                            },
                            fnCreatedRow: function (row, data, dataInd) {
                            }
                        }}>
                        <thead>
                            <tr>
                                <th>{strings.type}</th>
                                <th>{strings.sent}/{strings.received}</th>
                                <th>{strings.target} {strings.nickname}</th>
                                <th>{strings.target} {strings.status}</th>
                                <th>{strings.target} {strings.birthday}</th>
                                <th>{strings.target} {strings.status}</th>
                                <th>{strings.date}</th>
                                <th>{strings.status}</th>
                            </tr>
                        </thead>
                        <tbody />
                    </DataTable>
                </div>
            </div >
        )
    }
}

export default MemberOkHistory;