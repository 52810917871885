import React from 'react';
import strings from '../../lang/strings.js';
import $ from 'jquery';
import { PageSettings } from "../../config/page-settings";
import DataTable from './../../components/content/data-table.jsx';
import './../../config/util';
import Const from './../../config/const';
import U from './../../config/util';

class MemberMessageHistory extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {
    }

    render() {
        let self = this;
        let user_info = this.props.user_info;
        let columnNo = 0;

        return (
            <div>
                <div className="row">
                    <div className="col-xs-3">
                        <div className='row'>
                            <label>{strings.birthday}: {U.birth2Age(user_info.birthday)}</label>
                        </div>
                    </div>
                    <div className="col-xs-3">
                        <div className='row'>
                            <label>{strings.height}: {user_info.height}cm</label>
                        </div>
                    </div>
                    <div className="col-xs-6">
                        <div className='row'>
                            <label>{strings.location}: {user_info.location}</label>
                        </div>
                    </div>
                </div>
                <div className="row mt-2">
                    <DataTable ref={(ref) => this.tblMember = ref}
                        options={{
                            columnDefs: [
                                {
                                    targets: [columnNo++], data: 'target_uid', orderable: false,
                                    render: function (value) {
                                        return value
                                    }
                                },
                                {
                                    targets: [columnNo++], data: 'profile_image', orderable: false,
                                    createdCell: function (td, value, item) {
                                        const profile_image = item.uid === user_info.id ? item.target_profile_image : item.profile_image;
                                        return $(td).image_lightbox(profile_image, () => {
                                            self.context.showGallery(profile_image.split('#').map((it) => { return Const.BASE_URL + it }))
                                        });
                                    }
                                },
                                {
                                    targets: [columnNo++], data: 'user_id', orderable: false,
                                    createdCell: function (td, value, item) {
                                        const nickname = item.uid === user_info.id ? item.target_nickname : item.nickname;
                                        $(td).html(nickname);
                                        if (item.uid === user_info.id) {
                                            $(td).append(
                                                '<br/>' +
                                                item.target_gender.gender2String() + ' ' +
                                                U.birth2Age(item.target_birthday) + '세 <br/>' +
                                                item.target_height + 'cm ' +
                                                (item.target_location.split(",").length > 0 ? item.target_location.split(",")[0] : item.target_location)
                                            );
                                        } else {
                                            $(td).append(
                                                '<br/>' +
                                                item.gender.gender2String() + ' ' +
                                                U.birth2Age(item.birthday) + '세 <br/>' +
                                                item.height + 'cm ' +
                                                (item.location.split(",").length > 0 ? item.location.split(",")[0] : item.location)
                                            );
                                        }
                                    }
                                },
                                {
                                    targets: [columnNo++], data: 'uid', orderable: false,
                                    createdCell: function (td, value, item) {
                                        if (user_info.id === value) {
                                            $(td).html('<span style="color: blue">' + strings.sent + '</span>');
                                        } else {
                                            $(td).html('<span style="color: green">' + strings.received + '</span>');
                                        }
                                        if (item.ok > 0 && item.target_ok > 0) {
                                            $(td).append('<br/><span style="color: red">(매칭완료)</span>');
                                        }
                                    }
                                },
                                {
                                    targets: [columnNo++], data: 'message', orderable: false,
                                    render: function (value) {
                                        return value;
                                    }
                                },
                            ],
                            iDisplayLength: 10,
                            order: [[1, 'DESC']],
                            ajax: {
                                url: 'card/getCardList',
                                data: function (req) {
                                    req.columns = null;
                                    req.uid = user_info.id;
                                    req.status = 'message';
                                }
                            },
                            drawCallback: function () {
                            },
                            fnCreatedRow: function (row, data, dataInd) {
                            }
                        }}>
                        <thead>
                            <tr>
                                <th>UID</th>
                                <th>{strings.profile}</th>
                                <th>{strings.nickname}</th>
                                <th>{strings.status}</th>
                                <th width="50%">{strings.message}</th>
                            </tr>
                        </thead>
                        <tbody />
                    </DataTable>
                </div>
            </div >
        )
    }
}

export default MemberMessageHistory;