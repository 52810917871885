import React from 'react';
import strings from '../../lang/strings.js';
import { PageSettings } from "../../config/page-settings";
import DataTable from './../../components/content/data-table.jsx';
import './../../config/util';
import $ from 'jquery';

class MemberRetryHistory extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
            retry_count: 0
        };
    }

    componentDidMount() {
    }

    render() {
        let user_info = this.props.user_info;
        let columnNo = 0;
        let self = this;

        return (
            <div>
                <div className="row">
                    <label>{strings.retry_count}&nbsp;:&nbsp;</label>
                    <label>{this.state.retry_count}</label>
                </div>
                <div className="row mt-2">
                    <DataTable ref={(ref) => this.tblMember = ref}
                        options={{
                            columnDefs: [
                                {
                                    targets: [columnNo++], data: 'status_date', orderable: false,
                                    render: function (value) {
                                        return value;
                                    }
                                },
                                {
                                    targets: [columnNo++], data: 'status', orderable: false,
                                    render: function (value) {
                                        return value;
                                    }
                                },
                                {
                                    targets: [columnNo++], data: 'status_reason', orderable: false,
                                    render: function (value) {
                                        return value
                                    }
                                },
                            ],
                            iDisplayLength: 10,
                            order: [[1, 'DESC']],
                            ajax: {
                                url: 'member/getStatusHistory',
                                data: function (req) {
                                    req.columns = null;
                                    req.column = 'uid';
                                    req.keyword = user_info.id;
                                    req.status = -1;
                                },
                            },
                            drawCallback: function () {
                            },
                            fnCreatedRow: function (row, data, dataInd) {
                            }
                        }}>
                        <thead>
                            <tr>
                                <th>{strings.retry_date}</th>
                                <th>{strings.reject_reason}</th>
                                <th>{strings.detail}</th>
                            </tr>
                        </thead>
                        <tbody />
                    </DataTable>
                </div>
            </div >
        )
    }
}

export default MemberRetryHistory;