import React from 'react';
import strings from '../../lang/strings.js';
import { PageSettings } from "../../config/page-settings";
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import classnames from 'classnames';
import MemberMainProfile from './member-main-profile'
import MemberMoreProfile from './member-more-profile'
import MemberPendingHistory from './member-pending-history'
import MemberCardHistory from './member-card-history'
import MemberOkHistory from './member-ok-history'
import MemberIapHistory from './member-iap-history'
import MemberReferrer from './member-referrer'
import MemberRetryHistory from './member-retry-history'
import MemberStatusHistory from './member-status-history'
import MemberHeartHistory from './member-heart-history'
import MemberPointHistory from './member-point-history'
import MemberTerms from './member-terms'
import MemberInvite from './member-invite'
import MemberPhoneBlock from './member-phone-block'
import MemberVersion from './member-version'
import MemberAdvice from './member-advice'
import MemberBadge from './member-badge'
import MemberCandidate from './member-candidate'
import MemberMessageHistory from './member-message-history'
import MemberNotificationHistory from './member-notification-history'
import './../../config/util';

/*
    Popup 보여지는 사용자 상세
*/
class MemberDetailPopup extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
            activeTab: '1',
            user_info: {},
            more_info: {},
        };

        this.toggle = this.toggle.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }
    }

    toggleModal(result) {
        if (this.props.onClose) {
            this.props.onClose(result);
        }
    }

    getUserInfo = () => {
        let self = this;

        self.setState({
            user_info: {},
            more_info: {},
            activeTab: '1',
        });

        this.context.post(
            'member/getItem',
            {
                uid: this.props.uid
            },
            function (response) {
                self.setState({
                    user_info: response.result_data.member_info,
                    more_info: response.result_data.more_info,
                });
            });
    };

    saveData = () => {
        let self = this;

        this.context.showConfirm(strings.confirm_save_title, (response) => {
            if (response) {
                this.context.post(
                    'member/save',
                    {
                        user_info: self.state.user_info,
                        path: 'popup',
                    }, function (response) {
                        self.context.addNotification('success', strings.alert_title, strings.save_done);
                    }, true);
            }
        });
    };

    componentDidMount() {
    }

    render() {
        return (
            <div style={{ position: 'relative' }}>
                <Modal isOpen={this.props.isOpen} toggle={this.toggleModal} onOpened={() => this.getUserInfo()} size={'lg'}>
                    <ModalHeader className="popup-title" toggle={this.toggleModal}>
                        {strings.member_detail} ({this.state.user_info.nickname}, {this.state.user_info.gender}, {this.state.user_info.birthday}, {this.state.user_info.location}, {Number(this.state.user_info.status).status2String2()})
                    </ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <div className="col-xl-3">
                                <button type="button" className={classnames('width-full', 'text-left', 'btn', 'btn-white', 'btn-xs', 'mt-1', { active: this.state.activeTab === '1' })} onClick={() => { this.toggle('1'); }}>{strings.member_detail_01}</button>
                                <button type="button" className={classnames('width-full', 'text-left', 'btn', 'btn-white', 'btn-xs', 'mt-1', { active: this.state.activeTab === '2' })} onClick={() => { this.toggle('2'); }}>{strings.member_detail_02}</button>
                                <button type="button" className={classnames('width-full', 'text-left', 'btn', 'btn-white', 'btn-xs', 'mt-1', { active: this.state.activeTab === '20' })} onClick={() => { this.toggle('20'); }}>{strings.member_detail_20}</button>
                                <button type="button" className={classnames('width-full', 'text-left', 'btn', 'btn-white', 'btn-xs', 'mt-1', { active: this.state.activeTab === '17' })} onClick={() => { this.toggle('17'); }}>{strings.member_detail_03}</button>
                                <button type="button" className={classnames('width-full', 'text-left', 'btn', 'btn-white', 'btn-xs', 'mt-1', { active: this.state.activeTab === '16' })} onClick={() => { this.toggle('16'); }}>{strings.member_detail_04}</button>
                                <button type="button" className={classnames('width-full', 'text-left', 'btn', 'btn-white', 'btn-xs', 'mt-1', { active: this.state.activeTab === '19' })} onClick={() => { this.toggle('19'); }}>{strings.member_detail_18}</button>
                                <button type="button" className={classnames('width-full', 'text-left', 'btn', 'btn-white', 'btn-xs', 'mt-1', { active: this.state.activeTab === '3' })} onClick={() => { this.toggle('3'); }}>{strings.member_detail_05}</button>
                                <button type="button" className={classnames('width-full', 'text-left', 'btn', 'btn-white', 'btn-xs', 'mt-1', { active: this.state.activeTab === '4' })} onClick={() => { this.toggle('4'); }}>{strings.member_detail_06}</button>
                                <button type="button" className={classnames('width-full', 'text-left', 'btn', 'btn-white', 'btn-xs', 'mt-1', { active: this.state.activeTab === '8' })} onClick={() => { this.toggle('8'); }}>{strings.member_detail_07}</button>
                                <button type="button" className={classnames('width-full', 'text-left', 'btn', 'btn-white', 'btn-xs', 'mt-1', { active: this.state.activeTab === '9' })} onClick={() => { this.toggle('9'); }}>{strings.member_detail_08}</button>
                                <button type="button" className={classnames('width-full', 'text-left', 'btn', 'btn-white', 'btn-xs', 'mt-1', { active: this.state.activeTab === '5' })} onClick={() => { this.toggle('5'); }}>{strings.member_detail_09}</button>
                                <button type="button" className={classnames('width-full', 'text-left', 'btn', 'btn-white', 'btn-xs', 'mt-1', { active: this.state.activeTab === '6' })} onClick={() => { this.toggle('6'); }}>{strings.member_detail_10}</button>
                                <button type="button" className={classnames('width-full', 'text-left', 'btn', 'btn-white', 'btn-xs', 'mt-1', { active: this.state.activeTab === '7' })} onClick={() => { this.toggle('7'); }}>{strings.member_detail_11}</button>
                                <button type="button" className={classnames('width-full', 'text-left', 'btn', 'btn-white', 'btn-xs', 'mt-1', { active: this.state.activeTab === '12' })} onClick={() => { this.toggle('12'); }}>{strings.member_detail_13}</button>
                                <button type="button" className={classnames('width-full', 'text-left', 'btn', 'btn-white', 'btn-xs', 'mt-1', { active: this.state.activeTab === '13' })} onClick={() => { this.toggle('13'); }}>{strings.member_detail_14}</button>
                                <button type="button" className={classnames('width-full', 'text-left', 'btn', 'btn-white', 'btn-xs', 'mt-1', { active: this.state.activeTab === '14' })} onClick={() => { this.toggle('14'); }}>{strings.member_detail_15}</button>
                                <button type="button" className={classnames('width-full', 'text-left', 'btn', 'btn-white', 'btn-xs', 'mt-1', { active: this.state.activeTab === '15' })} onClick={() => { this.toggle('15'); }}>{strings.member_detail_16}</button>
                                <button type="button" className={classnames('width-full', 'text-left', 'btn', 'btn-white', 'btn-xs', 'mt-1', { active: this.state.activeTab === '18' })} onClick={() => { this.toggle('18'); }}>{strings.member_detail_17}</button>
                                <button type="button" className={classnames('width-full', 'text-left', 'btn', 'btn-white', 'btn-xs', 'mt-1', { active: this.state.activeTab === '21' })} onClick={() => { this.toggle('21'); }}>{strings.member_detail_21}</button>
                                <button type="button" className={classnames('width-full', 'text-left', 'btn', 'btn-white', 'btn-xs', 'mt-1', { active: this.state.activeTab === '22' })} onClick={() => { this.toggle('22'); }}>{strings.member_detail_22}</button>
                                <button type="button" className={classnames('width-full', 'text-left', 'btn', 'btn-white', 'btn-xs', 'mt-1', { active: this.state.activeTab === '23' })} onClick={() => { this.toggle('23'); }}>{strings.member_detail_23}</button>
                            </div>
                            {
                                this.state.user_info.id ?
                                    <div className="col-xl-9">
                                        {this.state.activeTab === "1" && (<MemberMainProfile user_info={this.state.user_info} more_info={this.state.more_info} />)}
                                        {this.state.activeTab === "2" && (<MemberMoreProfile user_info={this.state.user_info} more_info={this.state.more_info} />)}
                                        {this.state.activeTab === "20" && (<MemberBadge user_info={this.state.user_info} more_info={this.state.more_info} />)}
                                        {this.state.activeTab === "17" && (<MemberPendingHistory user_info={this.state.user_info} more_info={this.state.more_info} />)}
                                        {this.state.activeTab === "3" && (<MemberOkHistory user_info={this.state.user_info} more_info={this.state.more_info} />)}
                                        {this.state.activeTab === "4" && (<MemberIapHistory user_info={this.state.user_info} more_info={this.state.more_info} />)}
                                        {this.state.activeTab === "5" && (<MemberReferrer user_info={this.state.user_info} more_info={this.state.more_info} />)}
                                        {this.state.activeTab === "6" && (<MemberRetryHistory user_info={this.state.user_info} more_info={this.state.more_info} />)}
                                        {this.state.activeTab === "7" && (<MemberStatusHistory user_info={this.state.user_info} more_info={this.state.more_info} />)}
                                        {this.state.activeTab === "8" && (<MemberHeartHistory user_info={this.state.user_info} more_info={this.state.more_info} />)}
                                        {this.state.activeTab === "9" && (<MemberPointHistory user_info={this.state.user_info} more_info={this.state.more_info} />)}
                                        {this.state.activeTab === "12" && (<MemberTerms user_info={this.state.user_info} more_info={this.state.more_info} />)}
                                        {this.state.activeTab === "13" && (<MemberInvite user_info={this.state.user_info} more_info={this.state.more_info} />)}
                                        {this.state.activeTab === "14" && (<MemberPhoneBlock user_info={this.state.user_info} more_info={this.state.more_info} />)}
                                        {this.state.activeTab === "15" && (<MemberVersion user_info={this.state.user_info} more_info={this.state.more_info} />)}
                                        {this.state.activeTab === "16" && (<MemberCardHistory user_info={this.state.user_info} type="received" more_info={this.state.more_info} />)}
                                        {this.state.activeTab === "19" && (<MemberCardHistory user_info={this.state.user_info} type="sent" more_info={this.state.more_info} />)}
                                        {this.state.activeTab === "18" && (<MemberAdvice user_info={this.state.user_info} more_info={this.state.more_info} />)}
                                        {this.state.activeTab === "21" && (<MemberCandidate user_info={this.state.user_info} more_info={this.state.more_info} />)}
                                        {this.state.activeTab === "22" && (<MemberMessageHistory user_info={this.state.user_info} more_info={this.state.more_info} />)}
                                        {this.state.activeTab === "23" && (<MemberNotificationHistory user_info={this.state.user_info} more_info={this.state.more_info} />)}
                                    </div>
                                    : null
                            }
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-default" onClick={this.toggleModal}>{strings.close}</button>
                        <button className="btn btn-success" onClick={() => this.saveData()}>{strings.apply}</button>
                    </ModalFooter>
                </Modal>
            </div >
        )
    }
}

export default MemberDetailPopup;