import React from 'react';
import { inject } from 'mobx-react';
import strings from '../../lang/strings.js';
import $ from 'jquery';
import { PageSettings } from "../../config/page-settings";
import DataTable from './../../components/content/data-table.jsx';
import './../../config/util';
import Const from './../../config/const';
import U from './../../config/util';

class MemberPendingHistory extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {
    }

    render() {
        let self = this;
        let user_info = this.props.user_info;
        const badge_data = this.props.rootStore.getBadge();
        let columnNo = 0;

        return (
            <div>
                <div className="row">
                    <div className="col-xs-3">
                        <div className='row'>
                            <label>{strings.school}: {user_info.school}</label>
                        </div>
                    </div>
                    <div className="col-xs-3">
                        <div className='row'>
                            <label>{strings.job}: {user_info.job}</label>
                        </div>
                    </div>
                </div>
                <div className="row mt-2">
                    <DataTable ref={(ref) => this.tblMember = ref}
                        options={{
                            columnDefs: [
                                {
                                    targets: [columnNo++], data: 'category', orderable: false,
                                    createdCell: function (td, value, item) {
                                        if (value == Const.USER_PENDING_TYPE.PROFILE_IMAGE) {
                                            return $(td).html(strings.profile_image);
                                        } else if (value == Const.USER_PENDING_TYPE.INTRO) {
                                            return $(td).html(strings.intro);
                                        } else {
                                            const badge_group = badge_data.find(group => group.badges.find(it => it.code == item.code));
                                            const badge_item = badge_group && badge_group.badges.find(it => it.code == item.code);
                                            if (!badge_item) {
                                                return $(td).html(``);
                                            }
                                            return $(td).html(`${badge_group.name}<br/>(${badge_item.title})`);
                                        }
                                    }
                                },
                                {
                                    targets: [columnNo++], data: 'cert_value', orderable: false,
                                    createdCell: function (td, value, item) {
                                        if (item.cert_type == Const.USER_PENDING_CATEGORY.IMAGE) {
                                            return $(td).image_label(value, () => {
                                                self.context.showGallery(value.split('#').map((it) => { return Const.BASE_URL + it }))
                                            });
                                        } else {
                                            return $(td).html(`<p>${value}</p>`);
                                        }
                                    }
                                },
                                {
                                    targets: [columnNo++], data: 'create_date', orderable: false,
                                    render: function (value) {
                                        return value;
                                    }
                                },
                                {
                                    targets: [columnNo++], data: 'status', orderable: false,
                                    render: function (value) {
                                        return Number(value).pendingStatus2String();
                                    }
                                },
                                {
                                    targets: [columnNo++], data: 'status_reason', orderable: false,
                                    render: function (value) {
                                        return value;
                                    }
                                },
                                {
                                    targets: [columnNo++], data: 'create_date', orderable: false,
                                    render: function (value) {
                                        return value;
                                    }
                                },
                            ],
                            iDisplayLength: 10,
                            order: [[1, 'DESC']],
                            ajax: {
                                url: 'member/getPendingHistory',
                                data: function (req) {
                                    req.columns = null;
                                    req.uid = user_info.id;
                                }
                            },
                            drawCallback: function () {
                            },
                            fnCreatedRow: function (row, data, dataInd) {
                            }
                        }}>
                        <thead>
                            <tr>
                                <th width="10%">{strings.cert_type}</th>
                                <th width="25%">{strings.cert_content}</th>
                                <th width="10%">{strings.request_date}</th>
                                <th width="6%">{strings.status}</th>
                                <th width="20%">{strings.reason}</th>
                                <th width="10%">{strings.process_date}</th>
                            </tr>
                        </thead>
                        <tbody />
                    </DataTable>
                </div>
            </div >
        )
    }
}

export default inject('rootStore')(MemberPendingHistory);