import React from 'react';
import strings from '../../lang/strings.js';
import { PageSettings } from "../../config/page-settings";
import DataTable from './../../components/content/data-table.jsx';
import './../../config/util';

class MemberPhoneBlock extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {
    }

    render() {
        let user_info = this.props.user_info;
        let more_info = this.props.more_info;
        let columnNo = 0;

        return (
            <div>
                <div className="row">
                    <div className="col-xs-4">
                        <div className="row">
                            <label>{strings.member_detail_15}&nbsp;:&nbsp;</label>
                            <label>{more_info.block_count}건</label>
                        </div>
                    </div>
                    <div className="col-xs-8">
                        <label>{strings.member_detail_120}&nbsp;:&nbsp;</label>
                        <label>{more_info.block_existing_count}건</label>
                    </div>
                </div>
                <div className="row mt-2">
                    <DataTable ref={(ref) => this.tblMember = ref}
                        options={{
                            columnDefs: [
                                {
                                    targets: [columnNo++], data: 'phone_number', orderable: false,
                                    render: function (value) {
                                        return value;
                                    }
                                },
                                {
                                    targets: [columnNo++], data: 'nickname', orderable: false,
                                    render: function (value) {
                                        return value ? value : '(미가입)';
                                    }
                                },
                                {
                                    targets: [columnNo++], data: 'create_date', orderable: false,
                                    render: function (value) {
                                        return value
                                    }
                                },
                            ],
                            iDisplayLength: 10,
                            order: [[1, 'DESC']],
                            ajax: {
                                url: 'member/getBlockPhoneList',
                                data: function (req) {
                                    req.columns = null;
                                    req.uid = user_info.id;
                                }
                            },
                            drawCallback: function () {
                            },
                            fnCreatedRow: function (row, data, dataInd) {
                            }
                        }}>
                        <thead>
                            <tr>
                                <th>{strings.phone_number}</th>
                                <th>{strings.nickname}</th>
                                <th>{strings.date}</th>
                            </tr>
                        </thead>
                        <tbody />
                    </DataTable>
                </div>
            </div >
        )
    }
}

export default MemberPhoneBlock;