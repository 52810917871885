import React from 'react';
import ReactDOM from 'react-dom';
import App from './app.jsx';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'mobx-react';
import RootStore from './stores/root-store';
// css
import 'bootstrap/dist/css/bootstrap.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/css/default/app.min.css';
import './scss/react.scss';
import './assets/css/custom.css';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import 'react-image-lightbox/style.css';

// ========================================

ReactDOM.render(
    <BrowserRouter>
        <Provider rootStore={new RootStore()}>
            <App/>
        </Provider>
    </BrowserRouter>,
    document.getElementById('root')
);
