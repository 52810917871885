import React from 'react';
import strings from '../../lang/strings.js';
import { PageSettings } from "../../config/page-settings";
import './../../config/util';
import { Panel, PanelBody, PanelHeader } from '../../components/panel/panel.jsx';
import DataTable from './../../components/content/data-table.jsx';
import DateTime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import { UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';
import moment from 'moment';

class Sales extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
            type: 0, // 일별
            date_from: moment().subtract(1, 'year').format('YYYY-MM-DD'),
            date_to: moment().format('YYYY-MM-DD'),
        }
    }

    componentDidMount() {
    }

    refreshTable = (refresh) => {
        this.tblMember.draw(refresh);
    };

    onChangeDate(id, value, format) {
        this.setState({
            [id]: typeof value === 'object' ? value.format(format) : value
        });
    }

    changeViewMode = (type) => {
        this.setState({
            type: type
        }, () => {
            this.refreshTable(true);
        });
    };

    render() {
        let self = this;
        let columnNo = 0;

        return (
            <div>
                <Panel>
                    <PanelHeader noButton={true}>{strings.sales_title}</PanelHeader>
                    <PanelBody>
                        <div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div className='form-horizontal form-inline'>
                                        <div className="btn-group">
                                            <button
                                                className={'btn btn-sm ' + (this.state.type === 0 ? 'btn-success' : 'btn-white')}
                                                onClick={() => this.changeViewMode(0)}>{strings.daily}
                                            </button>
                                            <button
                                                className={'btn btn-sm ' + (this.state.type === 1 ? 'btn-success' : 'btn-white')}
                                                onClick={() => this.changeViewMode(1)}>{strings.monthly}
                                            </button>
                                        </div>
                                        <DateTime
                                            dateFormat={'YYYY-MM-DD'}
                                            timeFormat={false}
                                            inputProps={{ placeholder: strings.select_start_time }}
                                            defaultValue={this.state.date_from}
                                            closeOnSelect={true}
                                            className="ml-md-2"
                                            id="date_from"
                                            onChange={(e) => this.onChangeDate('date_from', e, 'YYYY-MM-DD')} />
                                        <DateTime
                                            dateFormat={'YYYY-MM-DD'}
                                            timeFormat={false}
                                            inputProps={{ placeholder: strings.select_end_time }}
                                            defaultValue={this.state.date_to}
                                            closeOnSelect={true}
                                            className="ml-md-1"
                                            id="date_to"
                                            onChange={(e) => this.onChangeDate('date_to', e, 'YYYY-MM-DD')} />
                                        <button type='button' className='btn btn-success btn-sm ml-md-2' onClick={this.refreshTable}>{strings.search}</button>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12 mt-3'>
                                    <DataTable ref={(ref) => this.tblMember = ref}
                                        options={{
                                            columnDefs: [
                                                {
                                                    targets: [columnNo++], data: 'date', orderable: false,
                                                    render: function (value) {
                                                        if (self.state.type === 1) {
                                                            return value;
                                                        } else {
                                                            const week_idx = moment(value).weekday();
                                                            const week_color = week_idx == 0 ? 'red' : week_idx == 6 ? 'blue' : '';
                                                            return value + ` <span style="color: ${week_color}">(${strings.const.WEEK_LIST[week_idx]})</span>`;
                                                        }
                                                    }
                                                },
                                                {
                                                    targets: [columnNo++], data: 'google_value', orderable: false,
                                                    render: function (value) {
                                                        return value.currencyFormat();
                                                    }
                                                },
                                                {
                                                    targets: [columnNo++], data: 'google_count', orderable: false,
                                                    render: function (value) {
                                                        return value.currencyFormat();
                                                    }
                                                },
                                                {
                                                    targets: [columnNo++], data: 'apple_value', orderable: false,
                                                    render: function (value) {
                                                        return value.currencyFormat();
                                                    }
                                                },
                                                {
                                                    targets: [columnNo++], data: 'apple_count', orderable: false,
                                                    render: function (value) {
                                                        return value.currencyFormat();
                                                    }
                                                },
                                                {
                                                    targets: [columnNo++], data: 'onestore_value', orderable: false,
                                                    render: function (value) {
                                                        return value.currencyFormat();
                                                    }
                                                },
                                                {
                                                    targets: [columnNo++], data: 'onestore_count', orderable: false,
                                                    render: function (value) {
                                                        return value.currencyFormat();
                                                    }
                                                },
                                                {
                                                    targets: [columnNo++], data: 'total_value', orderable: false,
                                                    render: function (value) {
                                                        return '<b>' + value.currencyFormat() + '</b>';
                                                    }
                                                },
                                                {
                                                    targets: [columnNo++], data: 'total_count', orderable: false,
                                                    render: function (value) {
                                                        return value.currencyFormat();
                                                    }
                                                },
                                                {
                                                    targets: [columnNo++], data: 'total_value', orderable: false,
                                                    createdCell: function (td, value, item) {
                                                        // google 85%, apple 85%, onestore 80% 적용
                                                        const real_value = Math.round((item.google_value * 0.85) + (item.apple_value * 0.85) + (item.onestore_value * 0.8));
                                                        $(td).html('<span style="color: blue">' + real_value.currencyFormat() + '</span>');
                                                    }
                                                },
                                            ],
                                            order: [[1, 'DESC']],
                                            ajax: {
                                                url: 'iap/sales',
                                                data: function (req) {
                                                    req.columns = null;
                                                    req.date_from = self.state.date_from;
                                                    req.date_to = self.state.date_to;
                                                    req.is_monthly = self.state.type;
                                                }
                                            },
                                            drawCallback: function () {
                                            },
                                            fnCreatedRow: function (row, data, dataInd) {
                                            }
                                        }}>
                                        <thead>
                                            <tr>
                                                <th rowspan={2} width="15%">{strings.date}</th>
                                                <th colspan={2}>{strings.sales_google}</th>
                                                <th colspan={2}>{strings.sales_appstore}</th>
                                                <th colspan={2}>{strings.sales_onestore}</th>
                                                <th colspan={2}>{strings.sales_total}</th>
                                                <th rowspan={2}>
                                                    {strings.sales_real_income}
                                                    <span className="ml-2 text-muted">
                                                        <i className="fa fa-info-circle" id="popover1"></i>
                                                        <UncontrolledPopover trigger="hover" placement="bottom" target="popover1">
                                                            <PopoverHeader>{strings.sales_real_income}</PopoverHeader>
                                                            <PopoverBody>
                                                                {strings.sales_real_income_hint}<br />
                                                            </PopoverBody>
                                                        </UncontrolledPopover>
                                                    </span>
                                                </th>
                                            </tr>
                                            <tr>
                                                <th>{strings.money}</th>
                                                <th>{strings.count}</th>
                                                <th>{strings.money}</th>
                                                <th>{strings.count}</th>
                                                <th>{strings.money}</th>
                                                <th>{strings.count}</th>
                                                <th>{strings.money}</th>
                                                <th>{strings.count}</th>
                                            </tr>
                                        </thead>
                                        <tbody />
                                    </DataTable>
                                </div>
                            </div>
                        </div>
                    </PanelBody>
                </Panel>
            </div>
        )
    }
}

export default Sales;