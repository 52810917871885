import React from 'react';
import strings from '../../lang/strings.js';
import { PageSettings } from "../../config/page-settings.js";
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import 'react-datetime/css/react-datetime.css';
import '../../config/util.js';
import U from '../../config/util.js';
import ImageSelect from '../../components/content/image-select.jsx';


class WeddingPlaceEditPopup extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
            item: {},
            main_image: "",
            image_list_urls: [],
        };

        this.toggleModal = this.toggleModal.bind(this);
        this.main_image_file = null;
        this.image_list_files = [];
    }

    componentDidMount() {
    }

    toggleModal(result) {
        if (this.props.onClose) {
            this.props.onClose(result);
        }
    }

    getData = () => {
        const place_id = this.props.data?.id ?? 0;
        if (!place_id) {
            return;
        }

        let self = this;

        this.context.post(
            'wedding/getPlaceItem',
            {
                id: place_id
            },
            function (response) {
                let item = response.result_data.place_item;

                self.setState({
                    item,
                    main_image: U.getThumbUrl(item.main_image),
                    image_list_urls: U.getThumbList(item.image_list)
                });
                self.main_image_file = null;
                self.image_list_urls = [];
            });
    };

    uploadData = (image_files, callback) => {

        let formData = new FormData();
        image_files.forEach((entry, index) => {
            formData.append('file', entry, entry.name);
        });

        this.context.post(
            'upload/images/wedding',
            formData,
            function (response) {
                callback(response.result_data.upload_path);
            },
            true
        );
    }

    saveData = () => {
        let self = this;

        const item = self.state.item;

        if (this.main_image_file) {
            this.uploadData([this.main_image_file], function (image_url) {
                self.state.main_image = U.getThumbUrl(image_url);
                self.main_image_file = null;
                self.saveData();
            });
            return;
        }

        if (this.image_list_files.length > 0) {
            this.uploadData(this.image_list_files, function (image_urls) {
                let response_index = 0;
                self.image_list_files.forEach((entry, index) => {
                    self.state.image_list_urls[index] = U.getThumbUrl(image_urls[response_index])
                    response_index = response_index + 1
                });

                self.image_list_files = [];

                self.saveData();
            });
            return;
        }

        item.main_image = this.state.main_image.replace('_thumb.', '.').url2String();
        item.image_list = self.state.image_list_urls.map(it => { return it.replace('_thumb.', '.') }).urlArray2String();

        if (!item.main_image) {
            self.context.addNotification('warning', strings.alert_title, strings.empty_main_image);
            return;
        }
        if (!item.title) {
            self.context.addNotification('warning', strings.alert_title, strings.empty_title);
            return;
        }
        if (!item.content) {
            self.context.addNotification('warning', strings.alert_title, strings.empty_content);
            return;
        }
        if (!item.max_person) {
            self.context.addNotification('warning', strings.alert_title, '수용인원');
            return;
        }
        if (!item.food_price) {
            self.context.addNotification('warning', strings.alert_title, '식대');
            return;
        }
        if (!item.ceromony_price) {
            self.context.addNotification('warning', strings.alert_title, '대관료');
            return;
        }

        this.context.post('wedding/savePlaceItem',
            {
                place_info: item
            }, function (response) {
                self.context.addNotification('success', strings.alert_title, strings.save_done);
                if (self.props.onSave) {
                    self.props.onSave();
                }
            }, true);
    };

    onDataChange = (e) => {
        let item = this.state.item;
        item[e.target.id] = e.target.value;

        this.setState({
            item: item
        });
    };

    onMainImageChange = (e) => {
        let self = this;

        if (e.target.files.length < 1) {
            return;
        }

        let file = e.target.files[0];
        let reader = new FileReader();

        reader.onloadend = function () {
            self.setState({ main_image: reader.result });
            self.main_image_file = file
        };
        reader.readAsDataURL(file);
    };

    onImageChange = (e, idx) => {
        let self = this;

        if (e.target.files.length < 1) {
            return;
        }

        let file = e.target.files[0];
        let reader = new FileReader();

        reader.onloadend = function () {
            self.state.image_list_urls[idx] = reader.result;
            self.image_list_files[idx] = file;
            self.setState({ image_list_urls: self.state.image_list_urls });
        };
        reader.readAsDataURL(file);
    };

    render() {
        let item = this.state.item;

        return (
            <div>
                <Modal isOpen={this.props.isOpen} toggle={this.toggleModal} onOpened={() => this.getData()}>
                    <ModalHeader className="popup-nickname" toggle={this.toggleModal}>{'웨딩홀 등록'}</ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <div className="col-xs-12">
                                <div className='row'>
                                    <label className='col-md-3 col-form-label'>{strings.main_image} *</label>
                                    <div className="col-md-9">
                                        <div className="row mb-1">
                                            <div className='col-xs-4 col-sm-3 col-md-5 col-lg-4 col-xl-4 col-xxl-3'>
                                                <ImageSelect
                                                    height="70%"
                                                    image_url={this.state.main_image}
                                                    onSelect={(e) => this.onMainImageChange(e)}
                                                    onDelete={() => {
                                                        this.setState({ main_image: '' });
                                                        this.main_image_file = null;
                                                    }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <label className='col-md-3 col-form-label'>{strings.title} *</label>
                                    <div className="col-md-9">
                                        <input type='text' autoComplete='off' className='form-control' id='title'
                                            value={item.title ?? ''}
                                            onChange={(e) => this.onDataChange(e)} />
                                    </div>
                                </div>
                                <div className='row'>
                                    <label className='col-md-3 col-form-label'>{strings.content} *</label>
                                    <div className="col-md-9">
                                        <textarea
                                            id='content'
                                            className='form-control'
                                            rows={7}
                                            value={item.content ?? ''}
                                            onChange={(e) => this.onDataChange(e)} />
                                    </div>
                                </div>
                                <div className='row m-t-3'>
                                    <label className='col-md-3 col-form-label'>{'수용인원(최대)'} *</label>
                                    <div className="col-md-9">
                                        <input type='text' autoComplete='off' className='form-control' id='max_person'
                                            value={item.max_person ?? ''}
                                            onChange={(e) => this.onDataChange(e)} />
                                    </div>
                                </div>
                                <div className='row'>
                                    <label className='col-md-3 col-form-label'>{'식대(최대)'} *</label>
                                    <div className="col-md-9">
                                        <input type='text' autoComplete='off' className='form-control' id='food_price'
                                            value={item.food_price ?? ''}
                                            onChange={(e) => this.onDataChange(e)} />
                                    </div>
                                </div>
                                <div className='row'>
                                    <label className='col-md-3 col-form-label'>{'대관료'} *</label>
                                    <div className="col-md-9">
                                        <input type='text' autoComplete='off' className='form-control' id='ceromony_price'
                                            value={item.ceromony_price ?? ''}
                                            onChange={(e) => this.onDataChange(e)} />
                                    </div>
                                </div>
                                <div className='row'>
                                    <label className='col-md-3 col-form-label'>{'꽃장식'}</label>
                                    <div className="col-md-9">
                                        <input type='text' autoComplete='off' className='form-control' id='flower_price'
                                            value={item.flower_price ?? ''}
                                            onChange={(e) => this.onDataChange(e)} />
                                    </div>
                                </div>
                                <div className='row'>
                                    <label className='col-md-3 col-form-label'>{strings.image}</label>
                                    <div className="col-md-9">
                                        <div className='row mb-1'>
                                            {
                                                this.state.image_list_urls.map((image_url, idx) => (
                                                    <div className='col-xs-4 col-sm-3 col-md-4 col-xl-4 col-xxl-3' key={idx}>
                                                        <ImageSelect
                                                            height="70%"
                                                            image_url={image_url}
                                                            onSelect={(e) => this.onImageChange(e, idx)}
                                                            onDelete={() => {
                                                                this.state.image_list_urls.splice(idx, 1);
                                                                this.image_list_files.splice(idx, 1);
                                                                this.setState({ image_list_urls: this.state.image_list_urls });
                                                            }} />
                                                    </div>
                                                ))
                                            }

                                            <div className='col-xs-4 col-sm-3 col-md-4 col-xl-4 col-xxl-3'>
                                                <ImageSelect
                                                    height="70%"
                                                    onSelect={(e) => this.onImageChange(e, this.state.image_list_urls.length)}
                                                    onDelete={() => { }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-default" onClick={this.toggleModal}>{strings.close}</button>
                        <button className="btn btn-success" onClick={() => this.saveData()}>{strings.apply}</button>
                    </ModalFooter>
                </Modal>
            </div >
        )
    }
}

export default WeddingPlaceEditPopup;