import React from 'react';
import { inject } from 'mobx-react';
import strings from '../../lang/strings.js';
import { PageSettings } from "../../config/page-settings";
import './../../config/util';
import Const from './../../config/const';
import U from './../../config/util';

class MemberMainProfile extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
            user_info: this.props.user_info,
        }
    }

    componentDidMount() {
    }

    componentWillReceiveProps(newProps) {
        this.setState({
            user_info: newProps.user_info
        });
    }

    onDataChange = (e) => {
        let user_info = this.state.user_info;
        user_info[e.target.id] = e.target.value;

        this.setState({
            user_info: user_info
        });
    };

    render() {
        let user_info = this.state.user_info;

        return (
            <div>
                <div className="row">
                    <div className="col-xs-6">
                        <div className='row'>
                            <label className='col-xl-4 col-form-label'>UID</label>
                            <label className='col-xl-8 col-form-label'>{user_info.id}</label>
                        </div>
                        <div className='row'>
                            <label className='col-xl-4 col-form-label'>{strings.profile}</label>
                            <div className="col-xl-8">
                                {
                                    U.getThumbList(user_info.profile_image).map((image, index) => (
                                        <a key={index} onClick={() => {
                                            this.context.showGallery(user_info.profile_image.split('#').map((it) => { return Const.BASE_URL + it }), index)
                                        }}>
                                            <img src={image} style={{ height: '50px', width: 'auto', margin: 2 }} />
                                        </a>
                                    ))
                                }
                            </div>
                        </div>
                        <div className='row'>
                            <label className='col-xl-4 col-form-label'>{strings.user_id}</label>
                            <div className="col-xl-8">
                                <input type='text' autoComplete='off' className='form-control' id="user_id"
                                    value={user_info.user_id || ''}
                                    onChange={(e) => this.onDataChange(e)} />
                            </div>
                        </div>
                        <div className='row'>
                            <label className='col-xl-4 col-form-label'>{strings.nickname}</label>
                            <div className="col-xl-8">
                                <input type='text' autoComplete='off' className='form-control' id="nickname"
                                    value={user_info.nickname || ''}
                                    onChange={(e) => this.onDataChange(e)} />
                            </div>
                        </div>
                        <div className='row'>
                            <label className='col-xl-4 col-form-label'>{strings.phone_number}</label>
                            <div className="col-xl-8">
                                <input type='text' autoComplete='off' className='form-control' id="phone_verified"
                                    value={user_info.phone_verified || ''}
                                    onChange={(e) => this.onDataChange(e)} />
                            </div>
                        </div>
                        <div className='row'>
                            <label className='col-xl-4 col-form-label'>{strings.real_name}</label>
                            <div className="col-xl-8">
                                <input type='text' autoComplete='off' className='form-control' id="realname_verified"
                                    value={user_info.realname_verified || ''}
                                    onChange={(e) => this.onDataChange(e)} />
                            </div>
                        </div>
                        <div className='row'>
                            <label className='col-xl-4 col-form-label'>{strings.gender}</label>
                            <div className="col-xl-8">
                                <select id='gender' className='form-control'
                                    onChange={(e) => this.onDataChange(e)}
                                    value={user_info.gender || ''}>
                                    {Object.keys(strings.const.SEARCH_GENDER).map((key, index) => (
                                        <option key={index} value={key}>{strings.const.SEARCH_GENDER[key]}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className='row'>
                            <label className='col-xl-4 col-form-label'>{strings.birthday}</label>
                            <div className="col-xl-8">
                                <input type='text' autoComplete='off' className='form-control' id="birthday"
                                    value={user_info.birthday || ''}
                                    onChange={(e) => this.onDataChange(e)} />
                            </div>
                        </div>
                        <div className='row'>
                            <label className='col-xl-4 col-form-label'>{strings.height}</label>
                            <div className="col-xl-8">
                                <input type='text' autoComplete='off' className='form-control' id="height"
                                    value={user_info.height || ''}
                                    onChange={(e) => this.onDataChange(e)} />
                            </div>
                        </div>
                        <div className='row'>
                            <label className='col-xl-4 col-form-label'>{strings.school}</label>
                            <div className="col-xl-8">
                                <input type='text' autoComplete='off' className='form-control' id="school"
                                    value={user_info.school || ''}
                                    onChange={(e) => this.onDataChange(e)} />
                            </div>
                        </div>
                        {/* <div className='row'>
                            <label className='col-xl-4 col-form-label'>{strings.school}({strings.manager_select})</label>
                            <div className="col-xl-8">
                                <select id='school_admin' className='form-control'
                                    onChange={(e) => this.onDataChange(e)}
                                    value={user_info.school_admin || ''}>
                                    <option key={-1} value={''}>{'(선택안함)'}</option>
                                    {school_list.map((entry, index) => (
                                        <option key={index} value={entry}>{entry}</option>
                                    ))}
                                </select>
                            </div>
                        </div> */}
                        <div className='row'>
                            <label className='col-xl-4 col-form-label'>{strings.job}</label>
                            <div className="col-xl-8">
                                <input type='text' autoComplete='off' className='form-control' id="job"
                                    value={user_info.job || ''}
                                    onChange={(e) => this.onDataChange(e)} />
                            </div>
                        </div>
                        {/* <div className='row'>
                            <label className='col-xl-4 col-form-label'>{strings.job}({strings.manager_select})</label>
                            <div className="col-xl-8">
                                <select id='job_admin' className='form-control'
                                    onChange={(e) => this.onDataChange(e)}
                                    value={user_info.job_admin || ''}>
                                    <option key={-1} value={''}>{'(선택안함)'}</option>
                                    {job_list.map((entry, index) => (
                                        <option key={index} value={entry}>{entry}</option>
                                    ))}
                                </select>
                            </div>
                        </div> */}
                        <div className='row'>
                            <label className='col-xl-4 col-form-label'>{strings.badge}</label>
                            <div className="col-xl-8">
                                {user_info.badge && user_info.badge.split('#').map((item, index) => {
                                    const badge_data = this.props.rootStore.getBadge();
                                    const badge_group = badge_data.find(group => group.badges.find(it => it.code == item));
                                    const badge_item = badge_group && badge_group.badges.find(it => it.code == item);
                                    if (!badge_item) {
                                        return null;
                                    }

                                    return (
                                        <img
                                            key={index}
                                            title={badge_item.title}
                                            alt={badge_item.title}
                                            src={Const.BASE_URL + badge_item.icon_on}
                                            style={{ height: '32px', width: 'auto', margin: 2 }}
                                        />
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-6">
                        <div className='row'>
                            <label className='col-xl-12'>{strings.intro}</label>
                            <label className='col-xl-12'>
                                <textarea type='text' autoComplete='off' className='form-control m-b-2' id='introduction'
                                    value={user_info.introduction || ''}
                                    rows={15}
                                    onChange={(e) => this.onDataChange(e)} />
                            </label>
                        </div>
                        <div className='row mt-2'>
                            <label className='col-xl-5'>{strings.account_create_date}</label>
                            <label className='col-xl-7'>{user_info.signup_date || ''}</label>
                        </div>
                        {
                            user_info.status == Const.USER_STATUS.SLEEP &&
                            <div className='row'>
                                <label className='col-xl-5'>{strings.sleep}{strings.date}</label>
                                <label className='col-xl-7'>{user_info.status_date || ''}</label>
                            </div>
                        }
                        <div className='row'>
                            <label className='col-xl-5'>{strings.latest_login_date}</label>
                            <label className='col-xl-7'>{user_info.login_date || ''}</label>
                        </div>
                        <div className='row'>
                            <label className='col-xl-5'>{strings.ip}</label>
                            <label className='col-xl-7'>{user_info.login_ip || ''}</label>
                        </div>
                        <div className='row'>
                            <label className='col-xl-5'>{strings.location}</label>
                            <label className='col-xl-7'>{user_info.location || ''}</label>
                        </div>
                        <div className='row'>
                            <label className='col-xl-5'>{strings.body_type}</label>
                            <label className='col-xl-7'>{user_info.body_type || ''}</label>
                        </div>
                        <div className='row'>
                            <label className='col-xl-5'>{strings.character}</label>
                            <label className='col-xl-7'>{user_info.character && user_info.character.split('#').join(', ')}</label>
                        </div>
                        <div className='row'>
                            <label className='col-xl-5'>{strings.target_character}</label>
                            <label className='col-xl-7'>{user_info.target_character && user_info.target_character.split('#').join(', ')}</label>
                        </div>
                        <div className='row'>
                            <label className='col-xl-5'>{strings.religion}</label>
                            <label className='col-xl-7'>{user_info.religion || ''}</label>
                        </div>
                        <div className='row'>
                            <label className='col-xl-5'>{strings.drinking}</label>
                            <label className='col-xl-7'>{user_info.drinking || ''}</label>
                        </div>
                        <div className='row'>
                            <label className='col-xl-5'>{strings.favor_all}</label>
                            <label className='col-xl-7'>
                                {user_info.favor_senior === 'Y' && strings.favor_senior}
                                {user_info.favor_junior === 'Y' && strings.favor_junior}
                            </label>
                        </div>
                        <div className='row'>
                            <label className='col-xl-5'>{strings.best_restaurant}</label>
                            <label className='col-xl-7'>{user_info.restaurant && user_info.restaurant.split('#').join(', ')}</label>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}

export default inject('rootStore')(MemberMainProfile);