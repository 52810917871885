import React from 'react';
import strings from '../../lang/strings.js';
import $ from 'jquery';
import { PageSettings } from "../../config/page-settings";
import { Panel, PanelHeader } from '../../components/panel/panel.jsx';
import DataTable from './../../components/content/data-table.jsx';
import './../../config/util';

class MemberMatchingTop extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.onClickRow = this.onClickRow.bind(this);
    }

    componentDidMount() {
    }

    onClickRow = (uid) => {
        this.props.onClickRow(uid);
    };

    render() {
        let self = this;
        let columnNo = 0;

        return (
            <div>
                <Panel>
                    <PanelHeader noButton={true}>{self.props.title}</PanelHeader>
                    <div style={{ height: "160px" }}>
                        <DataTable ref={(ref) => this.tblMember = ref}
                            hideTotalCount={true}
                            options={{
                                columnDefs: [
                                    {
                                        targets: [columnNo++], data: 'nickname', orderable: false,
                                        createdCell: function (td, value, item) {
                                            return $(td).nickname_label(value, item.gender, () => {
                                                self.onClickRow(item.uid);
                                            });
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'birthday', orderable: false,
                                        render: function (value) {
                                            return value;
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: null, orderable: false,
                                        createdCell: function (td, value, item) {
                                            return $(td).html(item.school ? item.school : item.job);
                                        }
                                    },
                                    {
                                        targets: [columnNo++], data: 'matching_count', orderable: false,
                                        render: function (value) {
                                            return value;
                                        }
                                    },
                                ],
                                scrollY: "125px",
                                scrollCollapse: true,
                                paging: false,
                                order: [[1, 'DESC']],
                                ajax: {
                                    url: 'member/getMatchingTopList',
                                    data: function (req) {
                                        req.columns = null;
                                        req.gender = self.props.gender;
                                    }
                                },
                                drawCallback: function () {
                                },
                                fnCreatedRow: function (row, data, dataInd) {
                                }
                            }}>
                            <thead>
                                <tr>
                                    <th>{strings.nickname}</th>
                                    <th>{strings.birthday}</th>
                                    <th>{strings.school_job}</th>
                                    <th>{strings.matching_count}</th>
                                </tr>
                            </thead>
                            <tbody />
                        </DataTable>
                    </div>
                </Panel>
            </div>
        )
    }
}

export default MemberMatchingTop;