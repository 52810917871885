import React from 'react';
import { PageSettings } from "../../config/page-settings";

class AuStaFragment extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
            au_sta: null,
        };
    }

    componentDidMount() {
        this.getAuInfo();
    }

    getAuInfo = () => {
        let self = this;

        this.context.get(
            'dashboard/au_sta',
            {},
            function (response) {
                self.setState({
                    au_sta: response.result_data.au_sta
                });
            });
    };

    render() {
        let au_sta = this.state.au_sta;

        return (
            <div>
                <div className="widget widget-stats bg-black-lighter height-100">
                    <div className="d-flex align-items-center text-white">
                        <div className="d-flex w-100">
                            <div>DAU, MAU</div>
                            <div className="ml-auto"> </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center text-white">
                        <div className="d-flex w-100">
                            <div>DAU</div>
                            <div className="ml-auto">{au_sta ? au_sta.dau.currencyFormat() : "-"}</div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center text-white">
                        <div className="d-flex w-100">
                            <div>MAU</div>
                            <div className="ml-auto">{au_sta ? au_sta.mau.currencyFormat() : "-"}</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AuStaFragment;