import React from 'react';
import strings from '../../lang/strings.js';
import { PageSettings } from "../../config/page-settings";
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import classnames from 'classnames';
import $ from 'jquery';
import 'react-datetime/css/react-datetime.css';
import './../../config/util';
import U from './../../config/util';
import Const from './../../config/const';
import ImageSelect from './../../components/content/image-select.jsx';


class MemberAdvicePopup extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
            item: {},
            image_url: "",
            image_file: null,
        };

        this.toggleModal = this.toggleModal.bind(this);
    }

    componentDidMount() {
    }

    toggleModal(result) {
        if (this.props.onClose) {
            this.props.onClose(result);
        }
    }

    getData = () => {
        if (!this.props.advice_id) {
            this.setState({
                item: {},
                image_url: "",
                image_file: null,
            });
            return;
        }

        let self = this;
        this.context.post(
            'member/getAdviceItem',
            {
                advice_id: this.props.advice_id
            },
            function (response) {
                let item = response.result_data.advice_info;

                self.setState({
                    item,
                    image_url: U.getThumbUrl(item.image_url),
                    image_file: null,
                });
            });
    };

    uploadData = (image_files, callback) => {

        let self = this;

        let formData = new FormData();
        image_files.forEach((entry, index) => {
            formData.append('file', entry, entry.name);
        });


        this.context.post(
            'upload/image/advice',
            formData,
            function (response) {
                callback(response.result_data.upload_path);
            },
            true
        );
    }

    saveData = () => {
        let self = this;

        if (this.state.image_file) {
            this.uploadData([this.state.image_file], function (image_url) {
                self.state.image_url = U.getThumbUrl(image_url);
                self.state.image_file = null;
                self.state.item.image_url = image_url;
                self.saveData();
            });
            return;
        }

        const item = self.state.item;

        if (!item.content) {
            self.context.addNotification('warning', strings.alert_title, strings.empty_content);
            return;
        }
        if (!item.uid) {
            item.uid = this.props.uid;
        }

        this.context.post('member/saveAdvice',
            {
                advice_info: item
            }, function (response) {
                self.context.addNotification('success', strings.alert_title, strings.save_done);
                if (self.props.onSave) {
                    self.props.onSave();
                }
            }, true);
    };

    onDataChange = (e) => {
        let item = this.state.item;
        item[e.target.id] = e.target.value;

        this.setState({
            item: item
        });
    };

    onImageChange = (e) => {
        let self = this;

        if (e.target.files.length < 1) {
            return;
        }

        let file = e.target.files[0];
        let reader = new FileReader();

        reader.onloadend = function () {
            self.setState({ 
                image_url: reader.result,
                image_file: file,
            });
        };
        reader.readAsDataURL(file);
    };

    render() {
        let item = this.state.item;

        return (
            <div>
                <Modal isOpen={this.props.isOpen} toggle={this.toggleModal} onOpened={() => this.getData()}>
                    <ModalHeader className="popup-nickname" toggle={this.toggleModal}>{strings.member_detail_17}</ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <div className="col-xs-12">
                                <div className='row'>
                                    <label className='col-md-3 col-form-label'>{strings.content} *</label>
                                    <div className="col-md-9">
                                        <textarea
                                            className='form-control'
                                            rows={10}
                                            value={item.content}
                                            id={"content"}
                                            onChange={(e) => this.onDataChange(e)} />
                                    </div>
                                </div>
                                <div className='row'>
                                    <label className='col-md-3 col-form-label'>{strings.image}</label>
                                    <div className="col-md-9">
                                        <div className="row mt-1 mb-1">
                                            <div className='col-xs-8 col-md-6'>
                                                <ImageSelect
                                                    height="100%"
                                                    image_url={this.state.image_url}
                                                    onSelect={(e) => this.onImageChange(e)}
                                                    onDelete={() => {
                                                        this.setState({ 
                                                            image_url: '', 
                                                            image_file: null 
                                                        });
                                                    }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-default" onClick={this.toggleModal}>{strings.close}</button>
                        <button className="btn btn-success" onClick={() => this.saveData()}>{strings.apply}</button>
                    </ModalFooter>
                </Modal>
            </div >
        )
    }
}

export default MemberAdvicePopup;