import React from 'react';
import { withRouter } from 'react-router-dom';
import { inject } from 'mobx-react';
import strings from '../../lang/strings.js';
import $ from 'jquery';
import { PageSettings } from "../../config/page-settings";
import { Panel, PanelBody, PanelHeader } from '../../components/panel/panel.jsx';
import DataTable from './../../components/content/data-table.jsx';
import './../../config/util';
import ZoneLocationPopup from './location-popup.js';
const queryString = require('query-string');

class ZoneLocation extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.parent = Number(this.props.match.params.locationId || 0);
        let searchQS = queryString.parse(this.props.location.search);

        this.state = {
            search_info: searchQS,
            show_location_popup: false,
            selected_location_id: null,
        };

        this.refreshTable = this.refreshTable.bind(this);
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.location.search != this.props.location.search) {
            let searchQS = queryString.parse(this.props.location.search);
            this.setState({ search_info: searchQS }, () => this.refreshTable());
        }
    }

    refreshTable = (refresh) => {
        this.tblMember.draw(refresh);
    };

    searchTable = () => {
        let searchQS = queryString.parse(this.props.location.search);
        Object.assign(searchQS, this.state.search_info)
        const new_query = queryString.stringify(searchQS);
        if (new_query == this.props.location.search.substr(1)) {
            this.refreshTable();
        } else {
            this.props.history.push('?' + new_query);
        }
    }

    showLocationDetailPopup(item) {
        this.setState({
            show_location_popup: true,
            selected_location: item
        });
    }

    showLocationDetail(id) {
        this.props.history.push(`/zone/location/${id}`);
    }

    deleteLocation(id) {
        let self = this;
        this.context.showConfirm(strings.confirm_delete_title, function (response) {
            if (response) {
                self.context.post('zone/deleteLocation', { id }, function () {
                    self.context.addNotification('success', strings.alert_title, strings.confirm_delete_done);
                    self.refreshTable(false);
                }, true);
            }
        });
    }

    render() {
        let self = this;
        let columnNo = 0;
        const { search_info } = this.state;

        return (
            <div>
                <Panel>
                    <PanelHeader noButton={true}>{strings.menu.location_list}</PanelHeader>
                    <PanelBody>
                        <ZoneLocationPopup isOpen={this.state.show_location_popup} parent={this.parent} location_item={this.state.selected_location} onClose={() => {
                            this.setState({ show_location_popup: false });
                        }} onSave={() => {
                            this.setState({ show_location_popup: false });
                            this.refreshTable(false);
                        }} />
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='form-horizontal form-inline'>
                                    <button type='button' className='btn btn-danger btn-sm ml-md-2' onClick={() => {
                                        self.showLocationDetailPopup(null);
                                    }}>{strings.add}</button>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-12'>
                                <DataTable ref={(ref) => this.tblMember = ref}
                                    customPaginate={true}
                                    initialPage={search_info.start || 0}
                                    onPageChange={(value) => {
                                        this.setState({
                                            search_info: {
                                                ...search_info,
                                                start: value,
                                            }
                                        }, this.searchTable);
                                    }}
                                    options={{
                                        columnDefs: [
                                            {
                                                targets: [columnNo++], data: 'id', orderable: false,
                                                render: function (value) {
                                                    return value;
                                                }
                                            },
                                            {
                                                targets: [columnNo++], data: 'location', orderable: false,
                                                render: function (value) {
                                                    return value;
                                                }
                                            },
                                            {
                                                targets: [columnNo++], data: 'create_date', orderable: false,
                                                render: function (value) {
                                                    return value;
                                                }
                                            },
                                            {
                                                targets: [columnNo++], data: 'bot_count', orderable: false,
                                                render: function (value) {
                                                    return value;
                                                }
                                            },
                                            {
                                                targets: [columnNo++], data: 'id', orderable: false,
                                                createdCell: function (td, value, item) {
                                                    $(td).html('');
                                                    $(td).append(`<button type='button' class='btn btn-primary btn-sm m-2'>지역목록</button>`)
                                                        .find('button')
                                                        .on('click', function () {
                                                            self.showLocationDetail(item.id);
                                                        });
                                                }
                                            },
                                            {
                                                targets: [columnNo++], data: 'id', orderable: false,
                                                createdCell: function (td, value, item) {
                                                    $(td).html('');
                                                    $(td).append(`<button type='button' class='btn btn-success btn-sm m-2'>${strings.detail}</button>`)
                                                        .find('button')
                                                        .on('click', function () {
                                                            self.showLocationDetailPopup(item);
                                                        });
                                                    $(td).append(`<button type='button' class='btn btn-danger btn-sm m-2'>${strings.delete}</button>`)
                                                        .find('button:last')
                                                        .on('click', function () {
                                                            self.deleteLocation(item.id);
                                                        });
                                                }
                                            }
                                        ],
                                        order: [[1, 'DESC']],
                                        ajax: {
                                            url: 'zone/getLocationList',
                                            data: function (req) {
                                                req.columns = null;

                                                const searchQS = queryString.parse(self.props.location.search);
                                                req.start = searchQS.start || 0;
                                                req.length = searchQS.length || 20;
                                                req.parent = self.parent;
                                            }
                                        },
                                        drawCallback: function () {
                                            if (self.parent > 0) {
                                                self.tblMember.tableApi.column(4).visible(false);
                                            } else {
                                                self.tblMember.tableApi.column(4).visible(true);
                                            }
                                        },
                                        fnCreatedRow: function (row, data, dataInd) {
                                        }
                                    }}>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>{'이름'}</th>
                                            <th>{'생성일'}</th>
                                            <th>{'인원수'}</th>
                                            <th>{'지역목록'}</th>
                                            <th>{'관리'}</th>
                                        </tr>
                                    </thead>
                                    <tbody />
                                </DataTable>
                            </div>
                        </div>
                    </PanelBody>
                </Panel>
            </div>
        )
    }
}

export default withRouter(inject('rootStore')(ZoneLocation));