import React from 'react';
import strings from '../../lang/strings.js';
import { PageSettings } from "../../config/page-settings";
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import 'react-datetime/css/react-datetime.css';
import './../../config/util';
import Const from './../../config/const';
const moment = require('moment');

class MemberSuspendPopup extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
            item: {},
            board_block_period: "",
            board_block_until: "",
        };

        this.toggleModal = this.toggleModal.bind(this);
    }

    componentDidMount() {
    }

    toggleModal(result) {
        if (this.props.onClose) {
            this.props.onClose(result);
        }
    }

    getData = () => {
        if (!this.props.uid) {
            this.setState({
                item: {},
                board_block_period: "",
                board_block_until: "",
            })
            return;
        }

        let self = this;
        this.context.post(
            'member/getItem',
            {
                uid: this.props.uid
            },
            function (response) {
                let item = response.result_data.member_info;

                self.setState({
                    item,
                    board_block_period: "",
                    board_block_until: item.board_block_until,
                });
            });
    };

    saveData = () => {
        let self = this;

        const item = self.state.item;

        if (!this.state.board_block_period) {
            self.context.addNotification('warning', strings.alert_title, strings.empty_suspend_period);
            return;
        }

        if (!item.board_block_reason) {
            self.context.addNotification('warning', strings.alert_title, strings.empty_suspend_reason);
            return;
        }

        this.context.post('member/suspend',
            {
                uid: item.id,
                board_block_until: this.state.board_block_until,
                board_block_reason: item.board_block_reason,
            }, function (response) {
                self.context.addNotification('success', strings.alert_title, strings.save_done);
                if (self.props.onSave) {
                    self.props.onSave();
                }
            }, true);
    };

    calcPeriod = () => {
        var { board_block_period, board_block_until, item } = this.state;
        if (item.board_block_until && moment(item.board_block_until) > moment()) {
            board_block_until = moment(item.board_block_until).add(board_block_period, 'day').format("YYYY-MM-DD HH:mm:ss")
        } else {
            board_block_until = moment().add(board_block_period, 'day').format("YYYY-MM-DD HH:mm:ss")
        }

        this.setState({
            board_block_until
        });
    }

    onDataChange = (e) => {
        let item = this.state.item;
        item[e.target.id] = e.target.value;

        this.setState({
            item: item
        });
    };

    render() {
        let item = this.state.item;
        let profile_image = item.profile_image ? item.profile_image.split('#')[0] : "";

        return (
            <div>
                <Modal isOpen={this.props.isOpen} toggle={this.toggleModal} onOpened={() => this.getData()}>
                    <ModalHeader className="popup-nickname" toggle={this.toggleModal}>{item.nickname} {strings.suspend}</ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <div className="col-xs-12">
                                <p className="text-danger"><b>{item.nickname}</b>님이 올린 탑뮤니티 글과 댓글은 모두 블라인드 처리됩니다.</p>
                                <div className='row'>
                                    <label className='col-md-3 col-form-label'>{strings.profile}</label>
                                    <div className="col-md-9">
                                        <img src={Const.BASE_URL + profile_image} style={{ height: '50px', width: 'auto', margin: 2 }} />
                                    </div>
                                </div>
                                <div className='row'>
                                    <label className='col-md-3 col-form-label'>{strings.profile}</label>
                                    <label className='col-md-9 col-form-label'>{item.nickname}</label>
                                </div>
                                <div className='row'>
                                    <label className='col-md-3 col-form-label'>{strings.current_suspend_date} *</label>
                                    <label className='col-md-9 col-form-label'>{item.board_block_until == "0000-00-00 00:00:00" ? "없음" : item.board_block_until + " 까지"}</label>
                                </div>
                                <div className='row'>
                                    <label className='col-md-3 col-form-label'>{strings.new_suspend_date} *</label>
                                    <label className='col-md-9 col-form-label'>{this.state.board_block_period ? this.state.board_block_until + "까지" : "-"}</label>
                                </div>
                                <div className='row'>
                                    <label className='col-md-3 col-form-label'></label>
                                    <div className="col-md-9">
                                        <select className='form-control'
                                            onChange={(e) => {
                                                this.setState({ board_block_period: e.target.value }, () => {
                                                    this.calcPeriod();
                                                })
                                            }}
                                            value={this.state.board_block_period}>
                                            <option value="">{strings.select_period}</option>
                                            <option value="3">+ 3{strings.day}</option>
                                            <option value="7">+ 1{strings.week}</option>
                                            <option value="30">+ 1{strings.month}</option>
                                            <option value="90">+ 3{strings.month}</option>
                                            <option value="180">+ 6{strings.month}</option>
                                            <option value="365">+ 1{strings.year}</option>
                                        </select>
                                    </div>
                                </div>
                                <div className='row mt-1'>
                                    <label className='col-md-3 col-form-label'>{strings.suspend_reason} *</label>
                                    <div className="col-md-9">
                                        <select className='form-control' id='board_block_reason'
                                            onChange={(e) => this.onDataChange(e)}
                                            value={item.board_block_reason}>
                                            <option value="">{strings.select_reason}</option>
                                            {
                                                strings.const.USER_SUSPEND_REASON.map((it, idx) => (
                                                    <option key={idx}>{it}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-default" onClick={this.toggleModal}>{strings.close}</button>
                        <button className="btn btn-success" onClick={() => this.saveData()}>{strings.apply}</button>
                    </ModalFooter>
                </Modal>
            </div >
        )
    }
}

export default MemberSuspendPopup;