import React from 'react';
import { withRouter } from 'react-router-dom';
import { inject } from 'mobx-react';
import strings from '../../lang/strings.js';
import { PageSettings } from "../../config/page-settings";
import { Panel, PanelHeader, PanelBody } from '../../components/panel/panel.jsx';
import MemberDetailPopup from './../member-detail/member-detail-popup'
import CardMessageItem from './message-item'
import ReactPaginate from 'react-paginate';
import SearchInput from '../../components/content/search-input.js';
import $ from 'jquery';

class CardMessage extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.perPage = 60;

        this.state = {
            ok_list: [],
            offset: 0,
            pageCount: 0,
            selected_uid: 0,
        };

        this.showMemberDetailPopup = this.showMemberDetailPopup.bind(this);
        this.onDelete = this.onDelete.bind(this);
    }

    componentDidMount() {
        this.getData()
    }

    getData = () => {
        let self = this;

        this.context.post(
            'card/getCardList',
            {
                column: $('#column').val(),
                keyword: $('#keyword').val(),
                start: this.state.offset,
                length: this.perPage,
                status: 'message',
            },
            function (response) {
                self.setState({
                    ok_list: response.data,
                    pageCount: Math.ceil(response.recordsTotal / self.perPage),
                });
            }, true);
    };

    handlePageClick = (data) => {
        let selected = data.selected;
        let offset = Math.ceil(selected * this.perPage);

        this.setState({ offset: offset }, () => {
            this.getData()
        });
    };

    onDelete = (data) => {
        this.context.showConfirm(strings.msg_confirm_block, (response) => {
            if (response) {
                let self = this;

                this.context.post(
                    'card/delete',
                    {
                        id: data.id
                    },
                    function (response) {
                        let pos = self.state.ok_list.indexOf(data);
                        self.state.ok_list.splice(pos, 1);
                        self.setState({
                            ok_list: self.state.ok_list,
                        })
                    },
                    true
                );
            }
        })
    };

    showMemberDetailPopup = (uid) => {
        this.setState({
            show_member_profile: true,
            selected_uid: uid
        });
    }

    render() {
        return (
            <div>
                <Panel>
                    <PanelHeader noButton={true}>{strings.menu.card_message}</PanelHeader>
                    <PanelBody>
                        <MemberDetailPopup isOpen={this.state.show_member_profile} uid={this.state.selected_uid} onClose={() => {
                            this.setState({ show_member_profile: false });
                        }} />

                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='form-horizontal form-inline'>
                                    <select id='column' className='form-control'>
                                        {Object.keys(strings.const.SEARCH_CATEGORY).map((key, index) => (
                                            <option key={index} value={key}>{strings.const.SEARCH_CATEGORY[key]}</option>
                                        ))}
                                    </select>
                                    <SearchInput id='keyword' handler={() => this.getData()} />
                                    <button type='button' className='btn btn-success btn-sm ml-md-2' onClick={() => this.getData()}>{strings.search}</button>
                                </div>
                            </div>
                        </div>

                        <div className="row mt-2">
                            {
                                this.state.ok_list.map((entry, index) => (
                                    <div className="col-xs-12 col-md-6 col-lg-4 col-xl-3 mt-1 mb-1" key={index}>
                                        <CardMessageItem info={entry}
                                            onClickUser1={this.showMemberDetailPopup}
                                            onClickUser2={this.showMemberDetailPopup}
                                            onDelete={this.onDelete} />
                                    </div>
                                ))
                            }
                        </div>
                        
                        {
                            this.state.ok_list.length > 0 &&
                            <div className="row mt-2" style={{ justifyContent: "center" }}>
                                <ReactPaginate
                                    previousLabel={'«'}
                                    nextLabel={'»'}
                                    breakLabel={'...'}
                                    breakClassName={'break-me'}
                                    pageCount={this.state.pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={this.handlePageClick}
                                    containerClassName={'pagination'}
                                    pageClassName={'page-item'}
                                    pageLinkClassName={'page-link'}
                                    previousClassName={'page-item'}
                                    previousLinkClassName={'page-link'}
                                    nextClassName={'page-item'}
                                    nextLinkClassName={'page-link'}
                                    activeClassName={'active'}
                                    disabledClassName={'disabled'}
                                />
                            </div>
                        }
                    </PanelBody>
                </Panel>
            </div>
        )
    }
}

export default withRouter(inject('rootStore')(CardMessage));