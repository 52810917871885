import React from 'react';
import { withRouter } from 'react-router-dom';
import { inject } from 'mobx-react';
import strings from '../../lang/strings.js';
import $ from 'jquery';
import { PageSettings } from "../../config/page-settings";
import { Panel, PanelBody, PanelHeader } from '../../components/panel/panel.jsx';
import DataTable from './../../components/content/data-table.jsx';
import SearchInput from './../../components/content/search-input.js';
import SearchSelect from './../../components/content/search-select.js';
import './../../config/util';
import Const from './../../config/const';
import MemberDetailPopup from './../member-detail/member-detail-popup'
import MemberCouponStatusPopup from './coupon_status_popup.js';
const queryString = require('query-string');

class MemberCoupon extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        let searchQS = queryString.parse(this.props.location.search);

        this.state = {
            search_info: searchQS,
            show_member_profile: false,
            selected_uid: 0,
            selected_item: null,
        };

        this.showMemberDetailPopup = this.showMemberDetailPopup.bind(this);
        this.refreshTable = this.refreshTable.bind(this);
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.location.search != this.props.location.search) {
            let searchQS = queryString.parse(this.props.location.search);
            this.setState({ search_info: searchQS }, () => this.refreshTable());
        }
    }

    refreshTable = (refresh) => {
        this.tblMember.draw(refresh);
    };

    searchTable = () => {
        let searchQS = queryString.parse(this.props.location.search);
        Object.assign(searchQS, this.state.search_info)
        const new_query = queryString.stringify(searchQS);
        if (new_query == this.props.location.search.substr(1)) {
            this.refreshTable();
        } else {
            this.props.history.push('?' + new_query);
        }
    }

    showMemberDetailPopup(uid) {
        this.setState({
            show_member_profile: true,
            selected_uid: uid
        });
    }

    changeStatus = (item, status, coupon_no, target_coupon_no) => {
        let self = this;

        this.context.post(
            'member/changeCouponStatus',
            {
                id: item.id,
                status: status,
                coupon_no: coupon_no || item.coupon_no,
                target_coupon_no: target_coupon_no || item.target_coupon_no,
            },
            function (response) {
                self.context.addNotification('success', strings.alert_title, strings.msg_applied);
                self.setState({ show_coupon_status_popup: false })
                self.refreshTable();
            },
            true);
    }

    render() {
        let self = this;
        let columnNo = 0;
        const { search_info } = this.state;

        return (
            <div>
                <Panel>
                    <PanelHeader noButton={true}>{strings.menu.member_coupon}</PanelHeader>
                    <PanelBody>
                        <MemberDetailPopup isOpen={this.state.show_member_profile} uid={this.state.selected_uid} onClose={() => {
                            this.setState({ show_member_profile: false });
                        }} />

                        <MemberCouponStatusPopup
                            isOpen={this.state.show_coupon_status_popup}
                            onClose={() => this.setState({ show_coupon_status_popup: false })}
                            onApply={(coupon_no, target_coupon_no) => {
                                this.changeStatus(this.state.selected_item, 1, coupon_no, target_coupon_no)
                            }}
                        />

                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='form-horizontal form-inline'>
                                    {/* 키워드검색 */}
                                    <SearchSelect
                                        data={strings.const.SEARCH_CATEGORY2}
                                        value={search_info.column || strings.const.SEARCH_CATEGORY2[0].code}
                                        onChange={(value) => {
                                            this.setState({
                                                search_info: {
                                                    ...search_info,
                                                    column: value,
                                                    start: 0,
                                                }
                                            }, this.searchTable);
                                        }}
                                    />
                                    <SearchInput
                                        value={search_info.keyword}
                                        onChange={(value) => {
                                            this.setState({
                                                search_info: {
                                                    ...search_info,
                                                    keyword: value,
                                                    column: search_info.column || strings.const.SEARCH_CATEGORY2[0].code,
                                                    start: 0,
                                                }
                                            })
                                        }}
                                        handler={(value) => this.searchTable()}
                                    />

                                    <button type='button' className='btn btn-success btn-sm ml-md-2' onClick={() => this.searchTable()}>{strings.search}</button>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-12 mt-3'>
                                <DataTable ref={(ref) => this.tblMember = ref}
                                    customPaginate={true}
                                    initialPage={search_info.start || 0}
                                    onPageChange={(value) => {
                                        this.setState({
                                            search_info: {
                                                ...search_info,
                                                start: value,
                                            }
                                        }, this.searchTable);
                                    }}
                                    options={{
                                        columnDefs: [
                                            {
                                                targets: [columnNo++], data: 'id', orderable: false,
                                                render: function (value) {
                                                    return value;
                                                }
                                            },
                                            {
                                                targets: [columnNo++], data: 'profile_image', orderable: false,
                                                createdCell: function (td, value, item) {
                                                    if (!value) {
                                                        return $(td).html(`(${strings.not_verified})`);
                                                    } else {
                                                        return $(td).image_lightbox(value, () => {
                                                            self.context.showGallery(value.split('#').map((it) => { return Const.BASE_URL + it }))
                                                        });
                                                    }
                                                }
                                            },
                                            {
                                                targets: [columnNo++], data: 'nickname', orderable: false,
                                                createdCell: function (td, value, item) {
                                                    return $(td).nickname_label(value, item.gender, () => {
                                                        self.showMemberDetailPopup(item.uid);
                                                    });
                                                }
                                            },
                                            {
                                                targets: [columnNo++], data: 'birthday', orderable: false,
                                                render: function (value) {
                                                    return value;
                                                }
                                            },
                                            {
                                                targets: [columnNo++], data: null, orderable: false,
                                                createdCell: function (td, value, item) {
                                                    return $(td).html(item.school ? item.school : item.job);
                                                }
                                            },
                                            {
                                                targets: [columnNo++], data: 'coupon_no', orderable: false,
                                                render: function (value) {
                                                    return value;
                                                }
                                            },
                                            {
                                                targets: [columnNo++], data: 'target_profile_image', orderable: false,
                                                createdCell: function (td, value, item) {
                                                    if (!value) {
                                                        return $(td).html(`(${strings.not_verified})`);
                                                    } else {
                                                        return $(td).image_lightbox(value, () => {
                                                            self.context.showGallery(value.split('#').map((it) => { return Const.BASE_URL + it }))
                                                        });
                                                    }
                                                }
                                            },
                                            {
                                                targets: [columnNo++], data: 'target_nickname', orderable: false,
                                                createdCell: function (td, value, item) {
                                                    return $(td).nickname_label(value, item.target_gender, () => {
                                                        self.showMemberDetailPopup(item.target_uid);
                                                    });
                                                }
                                            },
                                            {
                                                targets: [columnNo++], data: 'target_birthday', orderable: false,
                                                render: function (value) {
                                                    return value;
                                                }
                                            },
                                            {
                                                targets: [columnNo++], data: null, orderable: false,
                                                createdCell: function (td, value, item) {
                                                    return $(td).html(item.target_school ? item.target_school : item.target_job);
                                                }
                                            },
                                            {
                                                targets: [columnNo++], data: 'target_coupon_no', orderable: false,
                                                render: function (value) {
                                                    return value;
                                                }
                                            },
                                            {
                                                targets: [columnNo++], data: 'create_date', orderable: false,
                                                render: function (value) {
                                                    return value;
                                                }
                                            },
                                            {
                                                targets: [columnNo++], data: 'status', orderable: false,
                                                render: function (value) {
                                                    const str = strings.const.COUPON_STATUS[value];
                                                    if (value == 0) {
                                                        return `<span style='color: red'>${str}</span>`;
                                                    } else if (value == 1) {
                                                        return `<span style='color: purple'>${str}</span>`;
                                                    } else if (value == 2) {
                                                        return `<span style='color: blue'>${str}</span>`;
                                                    } else {
                                                        return "unknown";
                                                    }
                                                }
                                            },
                                            {
                                                targets: [columnNo++], data: 'id', orderable: false,
                                                createdCell: function (td, value, item) {
                                                    $(td).html('');
                                                    if (item.status == 0) {
                                                        $(td).append(`<button type='button' class='btn btn-success btn-sm m-2'>${strings.const.COUPON_STATUS[1]}</button>`)
                                                            .find('button:eq(0)')
                                                            .on('click', function () {
                                                                self.setState({
                                                                    show_coupon_status_popup: true,
                                                                    selected_item: item,
                                                                })
                                                            });
                                                    } else if (item.status == 1) {
                                                        $(td).append(`<button type='button' class='btn btn-danger btn-sm m-2'>${strings.const.COUPON_STATUS[2]}</button>`)
                                                            .find('button:eq(0)')
                                                            .on('click', function () {
                                                                self.changeStatus(item, 2, '', '');
                                                            });
                                                    }
                                                }
                                            },
                                        ],
                                        order: [[1, 'DESC']],
                                        ajax: {
                                            url: 'member/getCouponList',
                                            data: function (req) {
                                                req.columns = null;

                                                const searchQS = queryString.parse(self.props.location.search);
                                                req.start = searchQS.start || 0;
                                                req.length = searchQS.length || 20;
                                                req.column = searchQS.column;
                                                req.keyword = searchQS.keyword;
                                            }
                                        },
                                        drawCallback: function () {
                                        },
                                        fnCreatedRow: function (row, data, dataInd) {
                                        }
                                    }}>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>초대자 {strings.profile}</th>
                                            <th>초대자 {strings.nickname}</th>
                                            <th>초대자 {strings.birthday}</th>
                                            <th>초대자 {strings.school_job}</th>
                                            <th>초대자 {strings.coupon_no}</th>
                                            <th>수락자 {strings.profile}</th>
                                            <th>수락자 {strings.nickname}</th>
                                            <th>수락자 {strings.birthday}</th>
                                            <th>수락자 {strings.school_job}</th>
                                            <th>수락자 {strings.coupon_no}</th>
                                            <th>{strings.coupon_create_date}</th>
                                            <th>{strings.coupon_status}</th>
                                            <th>{strings.manage}</th>
                                        </tr>
                                    </thead>
                                    <tbody />
                                </DataTable>
                            </div>
                        </div>
                    </PanelBody>
                </Panel>
            </div>
        )
    }
}

export default withRouter(inject('rootStore')(MemberCoupon));