import React from 'react';
import strings from '../../lang/strings.js';
import { PageSettings } from "../../config/page-settings";
import DataTable from './../../components/content/data-table.jsx';
import './../../config/util';
import $ from 'jquery';
import Const from './../../config/const';

class MemberInvite extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {
    }

    render() {
        let self = this;
        let user_info = this.props.user_info;
        let more_info = this.props.more_info;
        let columnNo = 0;

        return (
            <div>
                <div className="row">
                    <div className="col-xs-6">
                        <div className="row">
                            <label>{strings.member_detail_119}&nbsp;:&nbsp;</label>
                            <label>{more_info.invite_count}</label>
                        </div>
                        <div className="row">
                            <label>{strings.invite_code}&nbsp;:&nbsp;</label>
                            <label>{user_info.invite_code}</label>
                        </div>
                    </div>
                    <div className="col-xs-6">
                        <label>{strings.recv_heart}&nbsp;:&nbsp;</label>
                        <label>{more_info.invite_heart}</label>
                    </div>
                </div>
                <div className="row mt-2">
                    <DataTable ref={(ref) => this.tblMember = ref}
                        options={{
                            columnDefs: [
                                {
                                    targets: [columnNo++], data: 'id', orderable: false,
                                    render: function (value) {
                                        return value;
                                    }
                                },
                                {
                                    targets: [columnNo++], data: 'profile_image', orderable: false,
                                    createdCell: function (td, value, item) {
                                        if (!value) {
                                            return $(td).html(`(${strings.not_verified})`);
                                        } else {
                                            return $(td).image_lightbox(value, () => {
                                                // this.context.showGallery(value.split('#').map((it) => { return Const.BASE_URL + it }))
                                            });
                                        }
                                    }
                                },
                                {
                                    targets: [columnNo++], data: 'nickname', orderable: false,
                                    createdCell: function (td, value, item) {
                                        return $(td).nickname_label(value, item.gender, () => {
                                            // self.showMemberDetailPopup(item.id);
                                        });
                                    }
                                },
                                {
                                    targets: [columnNo++], data: 'invite_heart', orderable: false,
                                    render: function (value) {
                                        return value
                                    }
                                },
                                {
                                    targets: [columnNo++], data: 'signup_date', orderable: false,
                                    render: function (value) {
                                        return value;
                                    }
                                },
                                {
                                    targets: [columnNo++], data: 'status', orderable: false,
                                    render: function (value) {
                                        return Number(value).status2String();
                                    }
                                },
                            ],
                            iDisplayLength: 10,
                            order: [[1, 'DESC']],
                            ajax: {
                                url: 'member/getInviteHistory',
                                data: function (req) {
                                    req.columns = null;
                                    req.uid = user_info.id;
                                }
                            },
                            drawCallback: function () {
                            },
                            fnCreatedRow: function (row, data, dataInd) {
                            }
                        }}>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>{strings.invited_profile}</th>
                                <th>{strings.invited_nickname}</th>
                                <th>{strings.recv_heart}</th>
                                <th>{strings.date}</th>
                                <th>{strings.status}</th>
                            </tr>
                        </thead>
                        <tbody />
                    </DataTable>
                </div>
            </div >
        )
    }
}

export default MemberInvite;