import React from 'react';
import strings from '../../lang/strings.js';
import { PageSettings } from "../../config/page-settings";
import { Panel, PanelBody } from '../../components/panel/panel.jsx';
import { Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import './../../config/util';
import { withRouter } from 'react-router-dom';
import { inject } from 'mobx-react';
import MemberDetailPopup from './../member-detail/member-detail-popup'
import MemberStatusFragment from './status-fragment'
import MemberStatusReasonSta from './status-reason-sta'
const queryString = require('query-string');

class MemberStatus extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);
        this.state = {
        };

        this.toggle = this.toggle.bind(this);
        this.showMemberDetailPopup = this.showMemberDetailPopup.bind(this);
        this.showMemberDetail = this.showMemberDetail.bind(this);
    }

    componentDidMount() {
    }

    toggle(tab) {
        this.props.history.push('?status=' + tab);
    }

    showMemberDetailPopup(uid) {
        this.setState({
            show_member_profile: true,
            selected_uid: uid
        });
    }

    showMemberDetail(uid) {
        this.props.history.push(`/member/detail/${uid}`);
    }

    render() {
        const searchQS = queryString.parse(this.props.location.search);
        const tab = searchQS.status || -3;

        return (
            <div>
                <div className="row">
                    <div className="col-xs-12">
                        <MemberStatusReasonSta />
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12">
                        <Panel>
                            <Nav tabs className="nav-tabs-inverse">
                                <NavItem>
                                    <NavLink className={classnames({ active: tab == -3 })} onClick={() => { this.toggle(-3); }}>
                                        <span className="d-none d-xs-inline">{strings.leave}</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ active: tab == -4 })} onClick={() => { this.toggle(-4); }}>
                                        <span className="d-none d-xs-inline">{strings.block}</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ active: tab == -2 })} onClick={() => { this.toggle(-2); }}>
                                        <span className="d-none d-xs-inline">{strings.sleep}</span>
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <PanelBody>
                                <MemberDetailPopup isOpen={this.state.show_member_profile} uid={this.state.selected_uid} onClose={() => {
                                    this.setState({ show_member_profile: false });
                                }} />

                                {tab == -3 && (<MemberStatusFragment status={-3} onClickRow={this.showMemberDetailPopup} />)}
                                {tab == -4 && (<MemberStatusFragment status={-4} onClickRow={this.showMemberDetailPopup} />)}
                                {tab == -2 && (<MemberStatusFragment status={-2} onClickRow={this.showMemberDetailPopup} />)}
                            </PanelBody>
                        </Panel>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(inject('rootStore')(MemberStatus));