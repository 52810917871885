import React from 'react';
import strings from '../../lang/strings.js';
import { PageSettings } from "../../config/page-settings";
import Container from './../../components/content/container.js';
import './../../config/util';

class IapSta extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
            iap_sta: null
        }
    }

    componentDidMount() {
        this.getMatchingSta();
    }

    getMatchingSta = () => {
        let self = this;

        this.context.get(
            'iap/getIapSta',
            {},
            function (response) {
                self.setState({
                    iap_sta: response.result_data.iap_sta
                });
            });
    };

    render() {
        let self = this;

        return (
            <div>
                <Container title={strings.iap_sta_title}>
                    <div style={{ height: "150px" }}>
                        <p className="p-0 m-0">{strings.iap_sta_01}: {self.state.iap_sta ? self.state.iap_sta.normal_today.currencyFormat() : '-'} {strings.won}</p>
                        <p className="p-0 m-0">{strings.iap_sta_02}: {self.state.iap_sta ? self.state.iap_sta.normal_yesterday.currencyFormat() : '-'} {strings.won}</p>
                        <p className="p-0 m-0">{strings.iap_sta_03}: {self.state.iap_sta ? self.state.iap_sta.normal_this_week.currencyFormat() : '-'} {strings.won}</p>
                        <p className="p-0 m-0">{strings.iap_sta_04}: {self.state.iap_sta ? self.state.iap_sta.normal_this_month.currencyFormat() : '-'} {strings.won}</p>
                        <p className="p-0 m-0">{strings.iap_sta_05}: {self.state.iap_sta ? self.state.iap_sta.refund_today.currencyFormat() : '-'} {strings.won}</p>
                        <p className="p-0 m-0">{strings.iap_sta_06}: {self.state.iap_sta ? self.state.iap_sta.refund_yesterday.currencyFormat() : '-'} {strings.won}</p>
                        <p className="p-0 m-0">{strings.iap_sta_07}: {self.state.iap_sta ? self.state.iap_sta.refund_this_week.currencyFormat() : '-'} {strings.won}</p>
                        <p className="p-0 m-0">{strings.iap_sta_08}: {self.state.iap_sta ? self.state.iap_sta.refund_this_month.currencyFormat() : '-'} {strings.won}</p>
                    </div>
                </Container>
            </div>
        )
    }
}

export default IapSta;