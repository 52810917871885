import React from 'react';
import { withRouter } from 'react-router-dom';
import { inject } from 'mobx-react';
import strings from '../../lang/strings.js';
import { PageSettings } from "../../config/page-settings";
import { Panel, PanelHeader, PanelBody } from '../../components/panel/panel.jsx';
import { Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import './../../config/util';
import MemberMatchingFragment from './matching-fragment'
import MemberMatchingTop from './matching-top'
import MemberMatchingSta from './matching-sta'
const queryString = require('query-string');

class MemberMatching extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);
        this.state = {
        };

        this.toggle = this.toggle.bind(this);
    }

    componentDidMount() {
    }

    toggle(tab) {
        this.props.history.push('?tab=' + tab);
    }

    render() {
        if (this.props.tab) {
            return (
                <div>
                    <Panel>
                        <PanelHeader noButton={true}>{this.props.tab_title}</PanelHeader>
                        <PanelBody>
                            <MemberMatchingFragment type={this.props.tab} />
                        </PanelBody>
                    </Panel>
                </div>
            )
        } else {
            const searchQS = queryString.parse(this.props.location.search);
            const tab = searchQS.tab || 'card';

            return (
                <div>
                    <div className="row">
                        <div className="col-xl-4">
                            <MemberMatchingTop id={'topMale'} title={strings.matching_sta_01} gender={'M'} onClickRow={this.showMemberDetailPopup} />
                        </div>
                        <div className="col-xl-4">
                            <MemberMatchingSta />
                        </div>
                        <div className="col-xl-4">
                            <MemberMatchingTop id={'topFemale'} title={strings.matching_sta_02} gender={'F'} onClickRow={this.showMemberDetailPopup} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12">
                            <Panel>
                                <Nav tabs className="nav-tabs-inverse">
                                    <NavItem>
                                        <NavLink className={classnames({ active: tab === 'card' })} onClick={() => { this.toggle('card'); }}>
                                            <span className="d-none d-xs-inline">{strings.card_matching}</span>
                                        </NavLink>
                                    </NavItem>
                                    {/* <NavItem>
                                        <NavLink className={classnames({ active: tab === 'meeting' })} onClick={() => { this.toggle('meeting'); }}>
                                            <span className="d-none d-xs-inline">{strings.meeting_matching}</span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink className={classnames({ active: tab === 'board' })} onClick={() => { this.toggle('board'); }}>
                                            <span className="d-none d-xs-inline">{strings.community_matching}</span>
                                        </NavLink>
                                    </NavItem> */}
                                </Nav>
                                <PanelBody>
                                    {tab === "card" && (<MemberMatchingFragment type={'card'} />)}
                                    {/* {tab === "meeting" && (<MemberMatchingFragment type={'meeting'} />)}
                                    {tab === "board" && (<MemberMatchingFragment type={'board'} />)} */}
                                </PanelBody>
                            </Panel>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

export default withRouter(inject('rootStore')(MemberMatching));