import React from 'react';
import { inject } from 'mobx-react';
import { PageSettings } from "./../../config/page-settings";

class VideoSelect extends React.Component {

    static contextType = PageSettings;

    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    render() {
        const video_url = this.props.video_url;
        const is_remote_url = video_url && video_url.indexOf('://') >= 0;

        return (
            <div style={{
                display: 'block', position: 'relative',
                paddingTop: this.props.height ? this.props.height : '100%',
                width: this.props.width ? this.props.width : '100%'
            }}>
            <button className="btn btn-white"
                    style={{
                        backgroundImage: is_remote_url ? `url(${video_url})` : '',
                        backgroundSize: "cover", backgroundPosition: "center",
                        position: "absolute", top: "1px", left: "1px", right: "1px", bottom: "1px", width: "100%"
                    }} onClick={() => this.profileImageRef.click()}>
                    {
                        !video_url ?
                            <i className="fa fa-plus"></i> :
                            is_remote_url ? null : 
                            video_url
                    }
                    <input type='file' accept='video/*' className='hide'
                        onChange={(e) => this.props.onSelect(e)}
                        ref={(ref) => this.profileImageRef = ref} />
                </button>
                {
                    video_url &&
                    <button className="btn btn-danger btn-icon btn-sm"
                        style={{ position: "absolute", top: "2px", right: "0px" }}
                        onClick={() => this.props.onDelete()}>
                        <i className="fa fa-times"></i>
                    </button>
                }
            </div>
        );
    }

}

export default inject('rootStore')(VideoSelect);