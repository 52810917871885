import React from 'react';
import strings from '../../lang/strings.js';
import $ from 'jquery';
import { PageSettings } from "../../config/page-settings";
import { Panel, PanelBody, PanelHeader } from '../../components/panel/panel.jsx';
import DataTable from '../../components/content/data-table.jsx';
import '../../config/util';
import { withRouter } from 'react-router-dom';
import { inject } from 'mobx-react';
import Const from '../../config/const';
import SearchInput from './../../components/content/search-input.js';
import SearchSelect from './../../components/content/search-select.js';
const queryString = require('query-string');

class Keyword extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        let searchQS = queryString.parse(this.props.location.search);

        this.state = {
            search_info: searchQS
        };

        this.showDetail = this.showDetail.bind(this);
        this.delete = this.delete.bind(this);
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.location.search != this.props.location.search) {
            let searchQS = queryString.parse(this.props.location.search);
            this.setState({ search_info: searchQS }, () => this.refreshTable());
        }
    }

    refreshTable = (refresh) => {
        this.tblMember.draw(refresh);
    };

    searchTable = () => {
        let searchQS = queryString.parse(this.props.location.search);
        Object.assign(searchQS, this.state.search_info)
        const new_query = queryString.stringify(searchQS);
        if (new_query == this.props.location.search.substr(1)) {
            this.refreshTable();
        } else {
            this.props.history.push('?' + new_query);
        }
    }

    showDetail(keyword) {
        this.props.history.push(`/keyword/keyword/${keyword.id}`);
    }

    delete(keyword) {
        let self = this;
        this.context.showConfirm(strings.confirm_delete_title, function (response) {
            if (response) {
                self.context.post('keyword/delete', { keyword_id: keyword.id }, function () {
                    self.context.addNotification('success', strings.alert_title, strings.confirm_delete_done);
                    self.refreshTable(false);
                }, true);
            }
        });
    }

    setBest(keyword_id, is_best) {
        let self = this;
        let msg = '';
        if (is_best == 'N') {
            msg = strings.msg_confirm_release;
        } else if (is_best == 'Y') {
            msg = strings.msg_confirm_best;
        }
        this.context.showConfirm(msg, function (response) {
            if (response) {
                self.context.post('keyword/setBest', { keyword_id, is_best }, function () {
                    self.context.addNotification('success', strings.alert_title, strings.msg_applied);
                    self.refreshTable(false);
                }, true);
            }
        });
    }

    render() {
        let self = this;
        let columnNo = 0;

        const { search_info } = this.state;

        return (
            <div>
                <Panel>
                    <PanelHeader noButton={true}>{strings.keyword_title}</PanelHeader>
                    <PanelBody>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='form-horizontal form-inline'>
                                    <select className='form-control'
                                        value={search_info.category || ''}
                                        onChange={(e) => {
                                            this.setState({
                                                search_info: {
                                                    ...search_info,
                                                    category: e.target.value,
                                                    start: 0,
                                                }
                                            }, this.searchTable);
                                        }}
                                    >
                                        {strings.const.KEYWORD_CATEGORY.map((entry, index) => (
                                            <option key={index} value={entry.code}>{entry.name}</option>
                                        ))}
                                    </select>

                                    {/* 키워드검색 */}
                                    <SearchSelect
                                        data={[
                                            { code: 'title', name: strings.title },
                                        ].concat(strings.const.SEARCH_CATEGORY2)}
                                        value={search_info.column || 'title'}
                                        onChange={(value) => {
                                            this.setState({
                                                search_info: {
                                                    ...search_info,
                                                    column: value,
                                                    start: 0,
                                                }
                                            }, this.searchTable);
                                        }}
                                    />
                                    <SearchInput
                                        value={search_info.keyword}
                                        onChange={(value) => {
                                            this.setState({
                                                search_info: {
                                                    ...search_info,
                                                    keyword: value,
                                                    column: search_info.column || 'title',
                                                    start: 0,
                                                }
                                            })
                                        }}
                                        handler={(value) => this.searchTable()}
                                    />

                                    <button type='button' className='btn btn-success btn-sm ml-md-2' onClick={() => this.searchTable()}>{strings.search}</button>
                                </div>
                            </div>
                        </div>
                        <div className='row mt-2'>
                            <div className='col-md-12'>
                                <DataTable ref={(ref) => this.tblMember = ref}
                                    customPaginate={true}
                                    initialPage={search_info.start || 0}
                                    onPageChange={(value) => {
                                        this.setState({
                                            search_info: {
                                                ...search_info,
                                                start: value,
                                            }
                                        }, this.searchTable);
                                    }}
                                    options={{
                                        columnDefs: [
                                            {
                                                targets: [columnNo++], data: 'id', orderable: false,
                                                render: function (value) {
                                                    return value;
                                                }
                                            },
                                            {
                                                targets: [columnNo++], data: 'title', orderable: false,
                                                createdCell: function (td, value, item) {
                                                    $(td).html('');
                                                    $(td).append(`<a href="javascript:;">${value}</a>`)
                                                        .find('a')
                                                        .on('click', function () {
                                                            self.showDetail(item);
                                                        });
                                                }
                                            },
                                            {
                                                targets: [columnNo++], data: 'image', orderable: false,
                                                createdCell: function (td, value, item) {
                                                    if (value) {
                                                        return $(td).image_lightbox(value, () => {
                                                            self.context.showGallery(value.split('#').map((it) => { return Const.BASE_URL + it }))
                                                        });
                                                    } else {
                                                        return $(td).html('');
                                                    }
                                                }
                                            },
                                            {
                                                targets: [columnNo++], data: 'keyword_count', orderable: false,
                                                render: function (value) {
                                                    return value.currencyFormat()
                                                }
                                            },
                                            {
                                                targets: [columnNo++], data: 'join_count', orderable: false,
                                                render: function (value) {
                                                    return value.currencyFormat()
                                                }
                                            },
                                            {
                                                targets: [columnNo++], data: 'create_date', orderable: false,
                                                render: function (value) {
                                                    return value
                                                }
                                            },
                                            {
                                                targets: [columnNo++], data: 'is_best', orderable: false,
                                                createdCell: function (td, value, item) {
                                                    $(td).html('');
        
                                                    if (value == "Y") {
                                                        $(td).append("<div><span style='color: red'>BEST</span></div>");
                                                        $(td).append(`[<a href="javascript:void(0);">${strings.release}</a>]`)
                                                            .find('a')
                                                            .bind('click', () => {
                                                                self.setBest(item.id, 'N');
                                                            });
                                                    } else {
                                                        $(td).append(`[<a href="javascript:void(0);">${strings.best_setting}</a>]`)
                                                            .find('a')
                                                            .bind('click', () => {
                                                                self.setBest(item.id, 'Y');
                                                            });
                                                    }
                                                }
                                            },
                                            {
                                                targets: [columnNo++], data: 'id', orderable: false,
                                                createdCell: function (td, value, item) {
                                                    $(td).html('');
                                                    $(td).append(`<button type='button' class='btn btn-success btn-sm ml-3'>수정</button>`)
                                                        .find('button')
                                                        .on('click', function () {
                                                            self.showDetail(item);
                                                        });
                                                    $(td).append(`<button type='button' class='btn btn-danger btn-sm ml-3'>${strings.delete}</button>`)
                                                        .find('button:last')
                                                        .on('click', function () {
                                                            self.delete(item);
                                                        });
                                                }
                                            },
                                        ],
                                        order: [[1, 'DESC']],
                                        ajax: {
                                            url: 'keyword/getList',
                                            data: function (req) {
                                                req.columns = null;

                                                const searchQS = queryString.parse(self.props.location.search);
                                                req.start = searchQS.start || 0;
                                                req.length = searchQS.length || 20;
                                                req.column = searchQS.column;
                                                req.keyword = searchQS.keyword;
                                                req.category = searchQS.category;
                                            }
                                        },
                                        drawCallback: function () {
                                        },
                                        fnCreatedRow: function (row, data, dataInd) {
                                        }
                                    }}>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>{strings.title}</th>
                                            <th>{strings.image}</th>
                                            <th>{'키워드'}</th>
                                            <th>{'참가자수'}</th>
                                            <th>{strings.create_date}</th>
                                            <th>{'베스트'}</th>
                                            <th>{strings.manage}</th>
                                        </tr>
                                    </thead>
                                    <tbody />
                                </DataTable>
                            </div>
                        </div>
                    </PanelBody>
                </Panel>
            </div>
        )
    }
}

export default withRouter(inject('rootStore')(Keyword));